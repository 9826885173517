/**
 * When configuring interaction with the graph via hover or tooltips, a number of different modes are available.
 * https://www.chartjs.org/docs/latest/general/interactions/modes.html
 */

export enum GraphEvents {
  Mousemove = 'mousemove',
  Mouseout = 'mouseout',
  Click = 'click',
  Touchstart = 'touchstart',
  Touchmove = 'touchmove',
}

export enum GraphType {
  Line = 'line',
  Bar = 'bar',
  RoundedBar = 'roundedBar',
  HorizontalBar = 'horizontalBar',
  Radar = 'radar',
  Doughnut = 'doughnut',
  PolarArea = 'polarArea',
  Bubble = 'bubble',
  Pie = 'pie',
  Scatter = 'scatter',
}

export enum GraphTypeScale {
  Category = 'category',
  Linear = 'linear',
  Logarithmic = 'logarithmic',
  Time = 'time',
  RadialLinear = 'radialLinear',
}

export enum GraphElementPosition {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}
