import { PetInfo } from '@app/core/models';
import { selectBreeds } from '@app/store/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { smartRecoFeatureKey } from '../smart-reco-feature-key';
import { SmartRecoState } from '../smart-reco.state';
import { selectSmartRecoPathologies, selectSmartRecoProfile, selectSmartRecoWeight } from './smart-reco-patient.selectors';

const selectSmartReco = createFeatureSelector<SmartRecoState>(smartRecoFeatureKey);

export const selectSmartRecoSteps = createSelector(selectSmartReco, (state) => state.steps);
export const selectSmartRecoStep = createSelector(selectSmartReco, (state) => state.step);
/**
 * Deprecated pet info selector for old components compatibilty
 */
export const selectSmartRecoDeprecatedPetInfo = createSelector(
  selectSmartRecoProfile,
  selectSmartRecoWeight,
  selectSmartRecoPathologies,
  selectBreeds,
  (profile, weight, pathologies, breeds) => {
    return (
      !!profile &&
      <PetInfo>{
        ...profile,
        breedObject: breeds?.find((b) => b.breedCode === profile.breed),
        bcs: weight?.bcs,
        weight: weight?.currentWeight,
        IBW: weight?.targetWeight,
        pathologies: pathologies.filter((p) => !p.isRiskFactor).map((p) => p.value),
        sensitivities: pathologies.filter((p) => p.isRiskFactor).map((p) => p.value),
      }
    );
  }
);
/**
 * Selector for panel summary
 */
export const selectSmartRecoSummary = createSelector(selectSmartRecoDeprecatedPetInfo, (petInfo) => petInfo);
