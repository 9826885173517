<form [formGroup]="form" class="form">
  <div class="form-fields" formArrayName="packages">
    <div class="header">
      <div class="header-product-details">
        <h6 i18n="@@order-title_product" class="header-product-name">Product</h6>
        <h6 i18n="@@rc-table_size-weight-title" class="header-product-size">Size/Weight</h6>
      </div>
      <div class="header-quantity-container">
        <h6 i18n="@@order-title_quantity" class="header-quantity">Quantity</h6>
        <h6 *ngIf="showRetailPrices" i18n="@@rc-table_price-title" class="header-price">Price</h6>
        <h6 *ngIf="showWholesalePrices" i18n="@@rc-table_b2b-price" class="header-price">Wholesale Price</h6>
      </div>
    </div>
    <div *ngFor="let packageForm of packagesFormArray.controls; let index = index" [formGroupName]="index">
      <ng-container *appLet="getProductItem(index) as product">
        <div class="item">
          <div class="product-container">
            <div class="product-details">
              <img [src]="product.image | publicUrl: 150" alt="{{ product.name }}" />
              <div class="name-container">
                <p class="type" i18n="@@product-type_wet" *ngIf="product.productType === ProductType.Wet">Wet</p>
                <p class="type" i18n="@@product-type_dry" *ngIf="product.productType === ProductType.Dry">Dry</p>
                <p class="name">
                  {{ product.name }}
                </p>
              </div>
              <p class="product-package">{{ getProductSelectedPackWeight(product) }}</p>
            </div>

            <div class="quantity-container">
              <rc-input-number class="quantity" formControlName="quantity"></rc-input-number>
              <p *ngIf="showRetailPrices" class="price" id="order-product-{{ packagesFormArray.at(index).value.scode }}-price">
                {{ (getProductPrice(product, index) | currency: (clinicCurrency$ | async)) || '--' }}
              </p>
              <p *ngIf="showWholesalePrices" class="price" id="order-product-{{ packagesFormArray.at(index).value.scode }}-price">
                {{ (getWholesalePrice$(index) | async | currency: (clinicCurrency$ | async)) || '--' }}
              </p>
            </div>
          </div>
          <div class="trash-container">
            <button *ngIf="packagesFormArray.value.length > 1" class="trash" (click)="removeFormArrayItem(index)">
              <app-icon [name]="IconName.trash"></app-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="showWholesalePrices">
      <app-wholesale-price-total [wholesalePricesTotal]="getWholesalePricesTotal$() | async"></app-wholesale-price-total>
    </ng-container>
  </div>
  <div class="footer">
    <app-checkbox class="consent" formControlName="consent">
      <span i18n="@@i_accept">I accept</span>
      <a [href]="termUrl$ | async" rel="noopener" target="_blank" class="rc-link" i18n="@@terms_and_conditions">terms and conditions</a>
    </app-checkbox>
    <button
      class="rc-button rc-button--filled-small"
      id="pet-owner-submit-button"
      type="button"
      [disabled]="form.invalid"
      (click)="submit()"
    >
      <span class="rc-button__title">{{ submitButtonLabel }}</span>
    </button>
  </div>
</form>
