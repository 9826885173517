import { Consultation, Patient, PetInfo, PetOwner, Product, RenalDetectRecommendation } from '@app/core/models';
import { AllowanceParams } from '@app/pages/allowance/allowance';
import { NutritionalChoice, Tool } from '@app/shared/utils';
import { createAction, props } from '@ngrx/store';

export const setConsultationAllowanceParams = createAction('[Consultation] Set Allowance Params', props<{ value: AllowanceParams }>());
export const setCurrentConsultationVisitDate = createAction('[Consultation] Set Current Visit Date', props<{ value: Date }>());
export const setConsultationPatient = createAction('[Consultation] Set Patient', props<{ value: Patient }>());
export const setConsultationRenalDetectRecommendation = createAction(
  '[Consultation] Set Renal Detect Recommendation',
  props<{ value: RenalDetectRecommendation }>()
);
export const setConsultationRenalDetectNutritionalChoice = createAction(
  '[Consultation] Set Renal Detect Nutritional Choice',
  props<{ value: NutritionalChoice }>()
);
export const setConsultationLastConsultation = createAction('[Consultation] Set Last Consultation', props<{ value: Consultation }>());

export const setConsultationHasWetProducts = createAction('[Consultation] Set Has Wet Products', props<{ value: boolean }>());
export const setConsultationDryProduct = createAction('[Consultation] Set Dry Product', props<{ product: Product; packId?: string }>());
export const setConsultationDryProductPackId = createAction('[Consultation] Set Dry Product Pack Id', props<{ value: string }>());
export const setConsultationWetProduct = createAction('[Consultation] Set Wet Product', props<{ product: Product; packId?: string }>());
export const setConsultationWetProductPackId = createAction('[Consultation] Set Wet Product Pack Id', props<{ value: string }>());
export const resetConsultationData = createAction('[Consultation] Reset Data');
/***
 * Create consultation action
 */
export const createCurrentConsultation = createAction(
  '[Consultation] Create Current Consultation',
  props<{
    consultationData: Partial<Consultation>;
    petInfo: PetInfo;
    tool: Tool;
    createPatientPopinTitle?: string;
    owner?: PetOwner;
    isRenewal?: boolean;
  }>()
);
export const createCurrentConsultationSuccess = createAction(
  '[Consultation] Create Current Consultation Success',
  props<{ consultation: Consultation }>()
);
export const createCurrentConsultationFail = createAction('[Consultation] Create Current Consultation Fail', props<{ error: string }>());
export const setCreatingCurrentConsultation = createAction('[Consultation] Creating Current Consultation', props<{ value: boolean }>());
export const resetCurrentConsultation = createAction('[Consultation] Reset Current Consultation');

/***
 * Pet owner actions
 */
export const setConsultationPetOwner = createAction('[Consultation] Set Pet Owner', props<{ value: PetOwner }>());
export const updatePetOwner = createAction('[Consultation] Update Pet Owner', props<{ value: PetOwner }>());
export const updatePetOwnerSuccess = createAction('[Consultation] Update Pet Owner Success', props<{ value: PetOwner }>());
export const deletePetOwnerAddress = createAction('[Consultation] Delete Pet Owner Address', props<{ id: string }>());
export const petOwnerFail = createAction('[Consultation] Pet Owner Fail', props<{ error: string }>());
