import { Component, EventEmitter, Input, OnInit, AfterViewInit, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Observable, of, Subject, timer } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

export interface Tab {
  id: string;
  short: string;
  long: string;
  enabled?: boolean;
  link?: string;
}

@Component({
  selector: 'app-rc-tabs',
  templateUrl: './rc-tabs.component.html',
  styleUrls: ['./rc-tabs.component.scss'],
})
export class RcTabsComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Properties
   */
  /**
   * Tabs must be a list of hashmaps with these properties:
   * - id: string - Used to navigate between tabs, sent back as selected tab when one tab is clicked
   * - short: string - Text of the tab displayed on mobile
   * - long: string - Text of the tab displayed on desktop
   * - enabled: boolean - If the tab is enabled or not (can be clicked)
   */
  @Input() tabs: Tab[];
  // id of the tab selected. If not set, the first tab is selected
  @Input()
  set tabId(_tabId: string) {
    this._setTab(_tabId);
  }
  get tabId(): string {
    return this.currentTab.id;
  }

  @Output() selectedTab: EventEmitter<Tab> = new EventEmitter<Tab>();

  // Current tab as hashmap from tabs
  currentTab: Tab;
  // Current tab DOM element
  currentTabElem: HTMLElement;

  // Bar bellow the active tab
  @ViewChild('underline', { static: true }) underline: ElementRef;

  private _destroyed$ = new Subject();

  /**
   * Initializer
   */

  /**
   * Life Cycle
   */
  ngOnInit() {
    if (!this.currentTab && this.tabs && this.tabs.length > 0) {
      this._setTab(this.tabs[0].id);
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngAfterViewInit() {
    this._setCurrentTabElemToCurrentTabId();
    fromEvent(window, 'resize').pipe(debounceTime(50), takeUntil(this._destroyed$)).subscribe(this._repositionUnderline.bind(this));
  }

  private _getTabIdElem$(tabId: string): Observable<HTMLElement> {
    if (!tabId) {
      return of(null);
    }
    const elem = this._getTabIdElem(tabId);
    if (!elem) {
      return timer(100).pipe(
        map(() => {
          return this._getTabIdElem(tabId);
        })
      );
    } else {
      return of(elem);
    }
  }

  private _getTabIdElem(tabId: string): HTMLElement {
    if (!tabId) {
      return null;
    }
    return document.getElementById(`rc-tab-${this.currentTab.id}`);
  }

  private _setCurrentTabElemToCurrentTabId() {
    if (this.currentTab) {
      this._setCurrentTabElemToTabId(this.currentTab.id);
    }
  }

  private _setCurrentTabElemToTabId(tabId: string) {
    this._getTabIdElem$(tabId)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((elem) => {
        this._setCurrentTabElem(elem);
      });
  }

  private _setCurrentTabElem(elem: HTMLElement): void {
    if (elem) {
      this.currentTabElem = elem;
      this._repositionUnderline();
    }
  }

  /**
   * Position the underline bellow the active tab (called at init, whenever a tab is clicked, and whenever the window is resized
   */
  private _repositionUnderline(): void {
    if (this.currentTabElem && this.currentTabElem.getBoundingClientRect) {
      const boundingClientRect = this.currentTabElem.getBoundingClientRect();
      const parentBoundingClientRect = this.currentTabElem.parentElement.getBoundingClientRect();
      this.underline.nativeElement.style.width = `${boundingClientRect.width}px`;
      this.underline.nativeElement.style.left = `${boundingClientRect.left - parentBoundingClientRect.left}px`;
    }
  }

  private _getTabIndex(id: string): number {
    return this.tabs?.map((tab) => tab.id).indexOf(id);
  }

  private _getTab(id: string): Tab {
    return this.tabs[this._getTabIndex(id)];
  }

  onTabClick(event: MouseEvent, id): void {
    event.preventDefault();
    this._setTab(id, event.currentTarget as HTMLElement);
  }

  private _setTab(id: string, htmlElem?: HTMLElement): void {
    if (this.currentTab && this.currentTab.id === id) {
      return;
    }
    const newTab = this._getTab(id);

    if (newTab && newTab.enabled) {
      this.currentTab = newTab;
      if (htmlElem) {
        this._setCurrentTabElem(htmlElem);
      } else {
        this._setCurrentTabElemToTabId(id);
      }
      this.selectedTab.emit(newTab);
    }
  }
}
