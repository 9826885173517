<div class="container">
  <img class="logo" alt="logo" src="./assets/standalone/logo.svg" />

  <h3 class="title" i18n="@@srs_access_forbidden">Access forbidden</h3>

  <p class="description" i18n="@@srs_access_forbidden_text">
    The request requires authentification. You do not have permission to access this page. Please reach out to your Royal Canin Sales Rep or
    our Consumer Care team.
  </p>
</div>
