import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Segmented } from '@app/core/models';

@Component({
  selector: 'app-rc-segmented-control',
  templateUrl: './rc-segmented-control.component.html',
  styleUrls: ['./rc-segmented-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RcSegmentedControlComponent),
      multi: true,
    },
  ],
})
export class RcSegmentedControlComponent implements ControlValueAccessor {
  @Input() value: string;
  @Input() isSelected: boolean;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() items: Segmented[];
  @Input() error?: string;

  @Output() didSelectControl = new EventEmitter<string>();

  private onChangeFn: (value: string) => void = () => undefined;
  private onTouchedFn: () => void = () => undefined;

  registerOnChange(fn: (...args: unknown[]) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  /**
   * Should be called when the control receives a disabled attribute
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  didSelect(event: MouseEvent, item: Segmented): void {
    event.preventDefault();
    event.stopPropagation();

    this.writeValue(item.value);
    this.onChangeFn(item.value);
    this.onTouchedFn();
    this.didSelectControl.emit(item.value);
  }
}
