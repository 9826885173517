import { ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

export class DialogConfig<D = any> {
  viewContainerRef?: ViewContainerRef;
  id?: string;
  panelClass?: string = '';
  hasBackdrop?: boolean = true;
  backdropClass?: string = '';
  disableClose?: boolean = false;
  width?: string = '';
  height?: string = '';
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string = '80vw';
  maxHeight?: number | string;
  data?: D | null = null;
  closeOnNavigation?: boolean = true;
  componentFactoryResolver?: ComponentFactoryResolver;
}
