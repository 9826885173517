import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {
  /**
   * Return native window
   * Consider adding a mock if window not available
   */
  get nativeWindow(): Window {
    return window;
  }
  /**
   * Smooth x scroll for Safari compatibility
   */
  scrollX(el: HTMLElement, x: number, duration: number): Promise<void> {
    const initialX = el.scrollLeft;
    const difference = x - initialX;
    const startTime = performance.now();

    return new Promise<void>((resolve) => {
      const step = () => {
        let normalizedTime = (performance.now() - startTime) / duration;
        if (normalizedTime > 1) normalizedTime = 1;

        const targetX = initialX + (difference * (-Math.cos(normalizedTime * Math.PI) + 1)) / 2;
        el.scrollTo(targetX, 0);

        if (normalizedTime < 1) this.nativeWindow.requestAnimationFrame(step);
        else resolve();
      };
      this.nativeWindow.requestAnimationFrame(step);
    });
  }
  /**
   * Smooth y scroll for Safari compatibility
   */
  scrollY(y: number, duration = 200): Promise<void> {
    const initialY = window.pageYOffset;
    const difference = y - initialY;
    const startTime = performance.now();

    return new Promise<void>((resolve) => {
      const step = () => {
        let normalizedTime = (performance.now() - startTime) / duration;
        if (normalizedTime > 1) normalizedTime = 1;

        const targetY = initialY + (difference * (-Math.cos(normalizedTime * Math.PI) + 1)) / 2;
        window.scrollTo(0, targetY);

        if (normalizedTime < 1) this.nativeWindow.requestAnimationFrame(step);
        else resolve();
      };
      this.nativeWindow.requestAnimationFrame(step);
    });
  }
}
