import { Pet, PetProfileFormValues } from '@app/core/models';
import { Pathology } from '@app/core/models/pathology';
import { WeightFormValue } from '@app/shared/components/weight-form/weight-form';
import { createAction, props } from '@ngrx/store';
/**
 * Actions to patch the form values in smart reco store
 * from existing patient, lastConsultation or renal detect recommendation
 */
export const setSmartRecoFormValues = createAction('[Smart Reco] Set Form Values');
/**
 * Actions to set forms
 */
export const setSmartRecoProfile = createAction('[Smart Reco] Set Profile', props<{ value: PetProfileFormValues }>());
export const setSmartRecoWeight = createAction('[Smart Reco] Set Weight', props<{ value: WeightFormValue }>());
export const setSmartRecoPathologies = createAction('[Smart Reco] Set Pathologies', props<{ value: Pathology[] }>());
/**
 * Action to update pet with new data, currently used after a weight change
 */
export const updateSmartRecoPet = createAction('[Smart Reco] Update Pet', props<{ pet: Partial<Pet> }>());
