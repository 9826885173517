import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AttributeType, FormField } from '@app/core/models';
import { FieldsFormService } from '@app/core/services';
@Component({
  selector: 'app-rc-form',
  templateUrl: './rc-form.component.html',
  styleUrls: ['./rc-form.component.scss'],
})
export class RcFormComponent {
  AttributeType = AttributeType;

  /**
   * Properties
   */
  @Input() mode: string;
  @Input() object: any;
  formGroup: FormGroup;
  @Input() fields: FormField[];
  private _editMode = false;
  @Input()
  set editMode(val) {
    if (val) {
      this.initFormGroup();
    }
    this._editMode = val;
  }

  get editMode() {
    return this._editMode;
  }

  @Output() submitForm: EventEmitter<any> = new EventEmitter();
  @Output() cancelForm: EventEmitter<any> = new EventEmitter();

  /**
   * Initializer
   */
  constructor(private fieldsFormService: FieldsFormService) {}

  initFormGroup() {
    this.formGroup = this.fieldsFormService.fieldsToForm(this.fields, this.object);
  }

  onSubmit(event) {
    event.preventDefault();
    const newObject = this.fieldsFormService.formToModel(this.fields, this.formGroup);
    this.submitForm.emit(newObject);
  }

  onCancel(event?) {
    this.cancelForm.emit(event);
  }

  getFormControl(field: FormField) {
    return this.formGroup.get(field.id);
  }
}
