import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { Breed, Consultation, Patient, Pet } from '@app/core/models';

@Injectable()
export class PetCommunicationService {
  // Observable string sources
  patient = new BehaviorSubject<Patient>(null);
  consultations = new BehaviorSubject<Consultation[]>([]);
  petUpdates = new Subject<Pet>();
  breeds = new BehaviorSubject<Breed[]>([]);
  lastConsultation = new BehaviorSubject<Consultation>(null);
  expectedNextConsultation = new BehaviorSubject<Consultation>(null);
  totalMedicalRecord = new BehaviorSubject<number>(0);

  // Service message commands
  setBreeds(breeds: Breed[]) {
    this.breeds.next(breeds);
  }

  setPatient(patient: Patient) {
    this.patient.next(patient);
  }

  setMedicalRecord(consultations: Consultation[], countConsultation: number) {
    this.totalMedicalRecord.next(countConsultation);
    this.consultations.next(consultations);
  }

  updatePet(pet: Pet) {
    this.petUpdates.next(pet);
  }

  updateNextConsultation(consultation: Consultation) {
    this.expectedNextConsultation.next(consultation);
  }

  updateLastConsultation(consultation: Consultation) {
    this.lastConsultation.next(consultation);
  }
}
