import { Inject, Pipe, PipeTransform, LOCALE_ID } from '@angular/core';

@Pipe({
  name: 'possessive',
})
export class PossessivePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  private static checkNoun(noun: string): RegExpMatchArray | null {
    return noun.match(/^[aeiou]/i);
  }

  transform(noun: string, value?: string): string {
    if (!noun) {
      return '--';
    }
    switch (this.locale.toLowerCase().substr(0, 2)) {
      case 'en':
        return `${noun}’s ${value}`;
      case 'fr':
        if (value) {
          return PossessivePipe.checkNoun(noun) ? `${value} d’${noun}` : `${value} de ${noun}`;
        }

        return PossessivePipe.checkNoun(noun) ? `d’${noun}` : `de ${noun}`;
      default:
        return `${noun} ${value}`;
    }
  }
}
