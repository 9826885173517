<div class="pet-panel">
  <!-- Name block-->
  <div class="pet-panel__fixed-title">
    <div class="pet-panel__pet-name-container">
      <img alt="" class="pet-panel__pet-name-container-picture" src="assets/other/placeholder_{{ pet.speciesCode }}.svg" />
      <p class="pet-panel__pet-name-container-name">{{ pet.name }}</p>
    </div>
    <div class="divider-without-margin"></div>
  </div>

  <div class="pet-panel__scroll-container">
    <!-- Action edit pet details block-->
    <div class="pet-panel__action-details-container">
      <button [disabled]="!isEditing?.petDetails" class="rc-button" (click)="editPetDetails(PanelSection.PetDetails)">
        <em class="rc-icon rc-icon--list"></em>
        <span
          class="rc-button__title rc-button__title--link"
          i18n="@@action-edit-pet-details"
          *ngIf="isEditing?.petDetails; else pet_details_content"
          >Edit pet details</span
        >
        <ng-template #pet_details_content>
          <span class="rc-button__title rc-button__title--link" i18n="@@path_pet-details">Pet details</span>
        </ng-template>
      </button>
      <div class="space"></div>
      <span class="arrow-down" [class.reversed]="isPanelPetDetailsExpended" (click)="showPanelDetails(PanelSection.PetDetails)"
        ><app-icon [name]="iconName.chevron_down"></app-icon
      ></span>
    </div>
    <div class="divider-without-margin"></div>
    <div class="details-form-expended" [class.details-form-retracted]="!isPanelPetDetailsExpended">
      <app-rc-form [object]="pet" [fields]="petDetailsFields"></app-rc-form>
      <div class="divider-without-margin"></div>
    </div>

    <!-- Action edit pet weight block-->
    <div class="pet-panel__action-details-container">
      <button [disabled]="!isEditing?.petWeight" class="rc-button" (click)="editPetDetails(PanelSection.PetWeight)">
        <em class="rc-icon rc-icon--list"></em>
        <span
          class="rc-button__title rc-button__title--link"
          i18n="@@action_edit-body-weight"
          *ngIf="isEditing && isEditing.petWeight; else body_weight_content"
          >Edit body weight</span
        >
        <ng-template #body_weight_content>
          <span class="rc-button__title rc-button__title--link" i18n="@@path_Body_Weight">Body weight</span>
        </ng-template>
      </button>
      <div class="space"></div>
      <span class="arrow-down" [class.reversed]="isPanelPetWeightExpended" (click)="showPanelDetails(PanelSection.PetWeight)"
        ><app-icon [name]="iconName.chevron_down"></app-icon
      ></span>
    </div>
    <div class="divider-without-margin"></div>
    <div class="details-form-expended" [class.details-form-retracted]="!isPanelPetWeightExpended">
      <app-rc-form [object]="pet" [fields]="petWeightFields"></app-rc-form>
      <div class="divider-without-margin"></div>
    </div>

    <ng-container *ngIf="displayDiagnosis">
      <!-- Action edit pet diagnosis block-->
      <div class="pet-panel__action-details-container">
        <button [disabled]="!isEditing?.petDiagnosis" class="rc-button" (click)="editPetDetails(PanelSection.PetDiagnosis)">
          <em class="rc-icon rc-icon--list"></em>
          <span
            class="rc-button__title rc-button__title--link"
            i18n="@@action_edit-diagnosis"
            *ngIf="isEditing?.petDiagnosis; else body_diagnosis_content"
            >Edit diagnosis</span
          >
          <ng-template #body_diagnosis_content>
            <span class="rc-button__title rc-button__title--link" i18n="@@path_diagnosis">Diagnosis</span>
          </ng-template>
        </button>
        <div class="space"></div>
        <span
          class="arrow-down"
          *ngIf="pet?.pathologies.length || pet?.sensitivities.length"
          [class.reversed]="isPanelPetDiagnosisExpended"
          (click)="showPanelDetails(PanelSection.PetDiagnosis)"
          ><app-icon [name]="iconName.chevron_down"></app-icon
        ></span>
      </div>
      <div class="divider-without-margin"></div>
      <div class="pet-panel__disease-container details-form-expended" [class.details-form-retracted]="!isPanelPetDiagnosisExpended">
        <p *ngIf="pet?.pathologies.length" class="pet-panel__disease-container-title" i18n="@@title_pathologies">Pathologies</p>
        <div class="pet-panel__disease-container-tag">
          <span class="pet-panel__tag rc-tag" *ngFor="let selectedItem of pet.pathologies" id="summary-pathology">
            {{ 'pathology-' + selectedItem | translateKey }}
          </span>
        </div>
        <p *ngIf="pet?.sensitivities.length" class="pet-panel__disease-container-title" i18n="@@title_sensitivities">Other conditions</p>
        <div class="pet-panel__disease-container-tag">
          <span class="pet-panel__tag rc-tag" *ngFor="let selectedItem of pet.sensitivities">
            {{ 'sensitivity-' + selectedItem | translateKey }}
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
