import { Pipe, PipeTransform } from '@angular/core';
import { EnumsService } from '@app/core/services/network/enums/enums.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'breedIcd',
})
// TODO: remove it after migration
export class BreedIcdPipe implements PipeTransform {
  constructor(private enumsService: EnumsService) {}

  transform(speciesICDorBreedCode?: string): Observable<string> {
    if (speciesICDorBreedCode?.includes('-')) {
      const [species, ICD] = speciesICDorBreedCode.split('-');
      return this.enumsService.fetchBreedNameFromSpeciesICD(species, +ICD);
    } else if (speciesICDorBreedCode) {
      return this.enumsService.fetchBreedName(speciesICDorBreedCode);
    }
  }
}
