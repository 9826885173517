import { Environement, StandaloneType } from './environment.type';
import { environment as envUAT } from './environment.uat';

const domain = 'https://rc-das-external-gmvet-webapp.uat-rc-df-aks.cloud-effem.com';

export const environment: Environement = {
  ...envUAT,
  isStandalone: true,
  standaloneType: StandaloneType.GMVET,
  // https://github.com/angular/angular-cli/issues/19622
  graphColor: 'rgb(97, 173, 188)',
  marsFooterScript: 'https://footer.mars.com/js/footer.js.aspx?vetmanager-srs-royalcanin-com',
  oneTrustId: 'ea1e50db-2200-4819-8700-03b7949ef1bb',
  ciam: {
    ...envUAT.ciam,
    redirectCallback: domain,
  },
  oktaConfig: {
    issuer: 'https://accountdev.royalcanin.com/oauth2/default',
    clientId: '0oa173r45u3TdaOwx0h8',
    redirectUri: `${domain}/callback`,
    postLogoutRedirectUri: `${domain}/login`,
    scopes: ['openid', 'groups'],
    pkce: true,
    tokenManager: {
      autoRenew: true,
      autoRemove: true,
    },
  },
};
