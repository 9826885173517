import { PanelSectionTypeEnum } from '@app/shared/utils';
import { createAction, props } from '@ngrx/store';
import { WeightManagementStep } from '../../weight-management-step';
/**
 * Action dispatched to start a weight management flow
 * It resets weight management store data and consultation store data
 */
export const startWeightManagement = createAction(
  '[Weight Management] Start',
  props<{ initFlow?: boolean; step?: WeightManagementStep }>()
);
/**
 * Set current step
 */
export const setWeightManagementStep = createAction('[Weight Management] Set Step', props<{ value: WeightManagementStep }>());
/**
 * Reset weight management store
 */
export const resetWeightManagement = createAction('[Weight Management] Reset');
/**
 * Navigate back to step from panel from another module (allowance) during consultation
 */
export const navigateBackToStep = createAction('[Weight Management] Navigate back to step', props<{ value: PanelSectionTypeEnum }>());
