<div
  class="rc-select"
  [class.error-state]="inputError"
  [class.rc-select--has-prefix]="showPrefix"
  [class.is-disabled]="disabled"
  [class.is-active]="!!labelValue"
>
  <div class="rc-select__label" *ngIf="label" [class.error-state]="inputError">
    {{ label }}
    <span *ngIf="!required" i18n="@@form-attribute_optional">Optional</span>
  </div>

  <div
    class="rc-select-wrapper"
    (focus)="isFocused = true"
    (focusout)="isFocused = false"
    [class.rc-select--active]="(isFocused && !inputError) || (value && !inputError)"
    [class.error-bottom]="inputError"
  >
    <div class="rc-select__value-wrapper">
      <em class="autocomplete-icon rc-icon rc-icon--search{{ bigIcon ? '-big' : '' }}" [class.error]="inputError"></em>
      <input
        class="autocomplete"
        type="text"
        id="input-autocomplete"
        [placeholder]="validInput ? placeholder || '' : noResultText"
        [(ngModel)]="currentSearch"
        (ngModelChange)="didChangeSearch($event)"
        (keydown.tab)="onKey($event)"
        (keydown.enter)="disableKeyDown($event)"
        (keyup)="onKey($event)"
        [disabled]="disabled"
        [class.error-state]="inputError"
        autocomplete="disabled"
        name="autocomplete"
      />
      <em class="autocomplete__cross rc-icon rc-icon--cross" *ngIf="inputError"></em>
    </div>
  </div>

  <div class="rc-select-list" *ngIf="filteredItems && filteredItems.length > 0" [class.is-opened]="dropDownOpen">
    <ng-container *ngIf="dropDownOpen">
      <div class="rc-option" *ngFor="let item of filteredItems; trackBy: trackByFn" (click)="didSelectOption($event, item)">
        <span class="rc-option__text" id="rc-autocomplete-option-{{ item.__key }}" [title]="item.__label">{{ item.__label }}</span>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="loading !== null">
  <div class="rc-select__loading-bar" [class.rc-select__loading-bar--loading]="loading"></div>
</ng-container>
