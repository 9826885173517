import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { CoreEffects, coreReducer, CoreState, initialCoreState } from './core';
import { initialVetState, VetEffects, VetFacade, legacyVetReducer, VetState } from '@app/store/vet';
import { PetState, initialPetState, petReducer, PetEffects } from '@app/store/pet';
import { PatientState, initialPatientState, patientReducer, PatientEffects, PatientFacade } from '@app/store/patient';
import { consultationReducer, ConsultationState, initialConsultationState } from './consultation';
import { ConsultationEffects } from './consultation/effects';
import { initialProductsState, ProductsEffects, productsReducer, ProductsState } from './products';
import { ProductsFacade } from './products/products.facade';

export interface AppState {
  router?: RouterReducerState;
  core: CoreState;
  vet: VetState;
  patient: PatientState;
  consultation: ConsultationState;
  products: ProductsState;
  pet: PetState;
}

export const initialAppState: AppState = {
  core: initialCoreState,
  vet: initialVetState,
  patient: initialPatientState,
  consultation: initialConsultationState,
  products: initialProductsState,
  pet: initialPetState,
};

export function getInitialState(): AppState {
  return initialAppState;
}

export const appReducers: ActionReducerMap<AppState, any> = {
  router: routerReducer,
  core: coreReducer,
  vet: legacyVetReducer,
  patient: patientReducer,
  consultation: consultationReducer,
  products: productsReducer,
  pet: petReducer,
};

export const appEffects: any[] = [
  ...CoreEffects,
  ...VetEffects,
  ...PatientEffects,
  ...ConsultationEffects,
  ...ProductsEffects,
  ...PetEffects,
];

export const appFacades: any[] = [VetFacade, PatientFacade, ProductsFacade];

export const appServices: any[] = [];
