<!-- Pet page: Header profile -->
<app-pet-profile-header [patient]="patient">
  <app-dropdown-list
    (eventClick)="onDropdownClick()"
    (eventSelect)="onDropdownSelect($event)"
    [buttonTitle]="buttonTitle"
    [dropdownOpen$]="dropdownIsOpen$"
    [selectables]="selectables"
    right
  ></app-dropdown-list>
</app-pet-profile-header>

<!-- Pet page: Menu -->
<app-rc-tabs [tabs]="menuOptions" [tabId]="currentTab"></app-rc-tabs>

<app-page-wrapper [withPanel]="false" [withFooter]="false" [size]="contentWrapperSizes.MEDIUM">
  <ng-container page-content>
    <router-outlet></router-outlet>
  </ng-container>
</app-page-wrapper>
