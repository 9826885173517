import { Injectable } from '@angular/core';
import { PathologyCategory } from '@app/core/models/pathology';
import { ApiService } from '@app/core/services';
import { GtmEventsService } from '@app/core/services/tracking';
import { SmartRecoStep } from '@app/pages/smart-reco/smart-reco-step';
import {
  setSmartRecoPathologies,
  setSmartRecoProfile,
  setSmartRecoWeight,
} from '@app/pages/smart-reco/store/actions/smart-reco-patient.actions';
import { setSmartRecoStep, startSmartReco } from '@app/pages/smart-reco/store/actions/smart-reco-steps.actions';
import { selectSmartRecoStep } from '@app/pages/smart-reco/store/selectors/smart-reco-steps.selectors';
import { ActivityCode, GenderCode, Helper, LifestageType, ReproductionStatusCode, SpeciesCode, Tool } from '@app/shared/utils';
import { AppState } from '@app/store';
import { resetConsultationData } from '@app/store/consultation/consultation.actions';
import { setTool } from '@app/store/core';
import { resetProductsData } from '@app/store/products/products.actions';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';

@Injectable()
export class TutorialService extends ApiService {
  constructor(private _gtmEventsService: GtmEventsService, private store$: Store<AppState>) {
    super();
  }

  public startTutorialTour$ = new BehaviorSubject(false);

  mockSmartRecoTutorial(): void {
    this.store$.dispatch(
      setSmartRecoProfile({
        value: {
          name: 'Pongo',
          gender: GenderCode.Male,
          speciesCode: SpeciesCode.Dog,
          breed: 'dalmatian',
          birthdate: new Date('2018-10-08T08:48:13.423Z'),
          mixed: false,
          petActivity: ActivityCode.Moderate,
          neutered: true,
          reproductionStatus: ReproductionStatusCode.None,
          lifestage: LifestageType.Adult,
        },
      })
    );
    this.store$.dispatch(setSmartRecoWeight({ value: { bcs: 5, currentWeight: 27.8, targetWeight: 30.8 } }));
    this.store$.dispatch(
      setSmartRecoPathologies({
        value: [
          {
            categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
            description: 'Acute Vomiting or Diarrhea',
            value: 'acute_diarrhea',
          },
          {
            categories: [PathologyCategory.SKIN_AND_COAT_DISORDERS],
            description: 'Skin and coat sensitivity',
            value: 'skin_and_coat_sensitivity',
            isRiskFactor: true,
          },
        ],
      })
    );
  }

  openStep(smartRecoStep: SmartRecoStep, callback: () => void): void {
    this.store$
      .select(selectSmartRecoStep)
      .pipe(
        debounceTime(400),
        filter((step) => step === smartRecoStep),
        take(1)
      )
      .subscribe(() => {
        callback();
      });
    this.store$.dispatch(setSmartRecoStep({ value: smartRecoStep }));
  }

  startSmartRecoWithoutTour(): void {
    this.startTutorialTour$.next(false);
    this.store$.dispatch(resetConsultationData());
    this.store$.dispatch(resetProductsData());
    this.store$.dispatch(startSmartReco({ initFlow: true }));
    this.store$.dispatch(setTool({ tool: Tool.SmartReco, tool_flow_id: Helper.randomId() }));
  }
}
