import { SpeciesCode } from '@app/shared/utils/enums/specie-code.enum';

/**
 * Pathology categories
 */
export enum PathologyCategory {
  GASTROINTESTINAL_DISORDERS = 'disorder_category_gastrointestinal',
  LIVER_DISEASES = 'disorder_category_liver',
  RENAL_DISEASES = 'disorder_category_renal',
  ADVERSE_FOOD_REACTIONS = 'disorder_category_adverse',
  SKIN_AND_COAT_DISORDERS = 'disorder_category_skin_coat',
  BODY_CONDITION = 'disorder_category_body_weight',
  STRESS_AND_ANXIETY_DISORDERS = 'disorder_category_stress_anxiety',
  CARDIOVASCULAR_DISEASES = 'disorder_category_cardiovascular_diseases',
  ORAL_DENTAL_DISORDERS = 'disorder_category_oral_dental',
  ENDOCRINE_DISORDERS = 'disorder_category_endocrine',
  URINARY_TRACT_DISORDERS = 'disorder_category_urinary',
  OSTEOARTICULAR_DISORDERS = 'disorder_category_osteo',
  OTHER_CONDITIONS = 'disorder_category_other_conditions',
}
/**
 * Pathologies
 * speciesCode is to be specified if pathology applies only to one species
 */
export interface Pathology {
  value: string;
  description: string;
  categories: PathologyCategory[];
  speciesCode?: SpeciesCode;
  /**
   * Risk factor are treated differently by smart algo
   */
  isRiskFactor?: boolean;
  /**
   * Set if pathology is linked to a BCS
   */
  bcs?: number;
  /**
   * Set if you want to add a tag next to the result item value
   */
  tag?: boolean;
}
