import { Pathology, PathologyCategory } from '@app/core/models/pathology';
import { SpeciesCode } from '../utils/enums/specie-code.enum';

export const PATHOLOGIES: Pathology[] = [
  {
    value: 'acute_colitis',
    description: 'Acute Colitis',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'acute_diarrhea',
    description: 'Acute Vomiting or Diarrhea',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'acute_hepatitis',
    description: 'Acute Hepatitis',
    categories: [PathologyCategory.LIVER_DISEASES],
  },
  {
    value: 'acute_kidney_injury_(aki)',
    description: 'Acute Kidney Injury (AKI)',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'acute_pancreatitis',
    description: 'Acute Pancreatitis',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'adverse_food_reactions_(afr)',
    description: 'Adverse Food Reactions (AFR)',
    categories: [PathologyCategory.ADVERSE_FOOD_REACTIONS],
  },
  {
    value: 'adverse_food_reactions_suspicion',
    description: 'Adverse Food Reactions (AFR) Suspicion / Elimination Diet Challenge',
    categories: [PathologyCategory.ADVERSE_FOOD_REACTIONS],
  },
  {
    value: 'anorexia',
    description: 'Anorexia',
    categories: [PathologyCategory.OTHER_CONDITIONS],
  },
  {
    value: 'antibiotic_responsive_diarrhea(ard)',
    description: 'Antibiotic Responsive Enteropathy (ARE)/Small Intestinal Bacterial Overgrowth (SIBO)',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'atopic_dermatitis_(atopy)',
    description: 'Atopic Dermatitis (Atopy)',
    categories: [PathologyCategory.SKIN_AND_COAT_DISORDERS],
  },
  {
    value: 'azotemic_ckd_iris_stage_2',
    description: 'Chronic Kidney Disease (CKD) IRIS Stage 2',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'azotemic_ckd_iris_stage_3',
    description: 'Chronic Kidney Disease (CKD) IRIS Stage 3',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'azotemic_ckd_iris_stage_4',
    description: 'Chronic Kidney Disease (CKD) IRIS Stage 4',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'cachexia',
    description: 'Cachexia ',
    categories: [PathologyCategory.BODY_CONDITION],
  },
  {
    value: 'cholangiohepatitis',
    description: 'Cholangitis / Cholangiohepatitis',
    categories: [PathologyCategory.LIVER_DISEASES],
  },
  {
    value: 'chronic_anxiety',
    description: 'Chronic Anxiety',
    categories: [PathologyCategory.STRESS_AND_ANXIETY_DISORDERS],
  },
  {
    value: 'chronic_colitis',
    description: 'Chronic Colitis',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'chronic_enteropathy_(ce)',
    description: 'Chronic Enteropathy (includes food and steroid responsive)',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'chronic_hepatitis',
    description: 'Chronic Hepatitis',
    categories: [PathologyCategory.LIVER_DISEASES],
  },
  {
    value: 'chronic_pancreatitis',
    description: 'Chronic Pancreatitis',
    speciesCode: SpeciesCode.Cat,
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'chronic_pancreatitis_(mild_moderate)',
    description: 'Moderate Chronic Pancreatitis (moderate fat restriction tolerated)',
    speciesCode: SpeciesCode.Dog,
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'chronic_pancreatitis_(severe)',
    description: 'Severe Chronic Pancreatitis (significant fat restriction needed)',
    speciesCode: SpeciesCode.Dog,
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'chronic_valvular_heart_disease_(cvhd)',
    description: 'Chronic Valvular Heart Disease (CVHD)',
    categories: [PathologyCategory.CARDIOVASCULAR_DISEASES],
  },
  {
    value: 'ckd_with_hypertension',
    description: 'Chronic Kidney Disease (CKD) With Hypertension',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'ckd_with_proteinuria',
    description: 'Chronic Kidney Disease (CKD) With Proteinuria',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'congestive_heart_failure_(chf)',
    description: 'Congestive Heart Failure (CHF)',
    categories: [PathologyCategory.CARDIOVASCULAR_DISEASES],
  },
  {
    value: 'constipation',
    description: 'Constipation (mild/moderate)',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'copper_storage_disease',
    description: 'Copper Storage Disease',
    categories: [PathologyCategory.LIVER_DISEASES],
  },
  {
    value: 'cutaneous_afr',
    description: 'Cutaneous Adverse Food Reactions (AFR)',
    categories: [PathologyCategory.ADVERSE_FOOD_REACTIONS],
  },
  {
    value: 'delayed_gastric_emptying',
    description: 'Delayed Gastric Emptying',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'dental_plaque',
    description: 'Dental Plaque',
    categories: [PathologyCategory.ORAL_DENTAL_DISORDERS],
  },
  {
    value: 'dermatitis',
    description: 'Dermatitis / Dermatosis',
    categories: [PathologyCategory.SKIN_AND_COAT_DISORDERS],
  },
  {
    value: 'diabetes_mellitus_(dm)',
    description: 'Diabetes Mellitus (DM)',
    categories: [PathologyCategory.ENDOCRINE_DISORDERS],
  },
  {
    value: 'dilated_cardiomyopathy_(dcm)',
    description: 'Dilated Cardiomyopathy (DCM)',
    categories: [PathologyCategory.CARDIOVASCULAR_DISEASES],
  },
  {
    value: 'dysrexia',
    description: 'Dysrexia',
    categories: [PathologyCategory.OTHER_CONDITIONS],
  },
  {
    value: 'early_ckd_(iris_stage_1)',
    description: 'Chronic Kidney Disease (CKD) IRIS Stage 1',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'exocrine_pancreatic_insufficiency_(epi)',
    description: 'Exocrine Pancreatic Insufficiency (EPI) ',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'feline_hepatic_lipidosis',
    description: 'Feline Hepatic Lipidosis',
    speciesCode: SpeciesCode.Cat,
    categories: [PathologyCategory.LIVER_DISEASES],
  },
  {
    value: 'feline_idiopathic_cystitis_(fic)',
    description: 'Feline Idiopathic Cystitis (FIC)',
    speciesCode: SpeciesCode.Cat,
    categories: [PathologyCategory.URINARY_TRACT_DISORDERS],
  },
  {
    value: 'feline_triaditis(enteritis+cholangiohepatitis+pancreatitis)',
    description: 'Feline Triaditis (Enteritis+Cholangiohepatitis+Pancreatitis)',
    speciesCode: SpeciesCode.Cat,
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'fibre_responsive_colitis_(including_stress_diarrhea)',
    description: 'Fiber Responsive Colitis (Including Stress Diarrhea)',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'flea_bite_allergic_dermatitis',
    description: 'Flea Bite Allergic Dermatitis',
    categories: [PathologyCategory.SKIN_AND_COAT_DISORDERS],
  },
  {
    value: 'gastritis',
    description: 'Gastritis',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'gastrointestinal_afr',
    description: 'Gastrointestinal Adverse Food Reactions (AFR)',
    categories: [PathologyCategory.ADVERSE_FOOD_REACTIONS],
  },
  {
    value: 'gastrointestinal_condition_requiring_high_fibre_content',
    description: 'Gastrointestinal Condition Requiring High Fiber Content',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'glomerulonephritis',
    description: 'Glomerulonephritis',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'hairball_management',
    description: 'Hairball Management',
    speciesCode: SpeciesCode.Cat,
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'hepatic_encephalopathy',
    description: 'Hepatic Encephalopathy',
    categories: [PathologyCategory.LIVER_DISEASES],
  },
  {
    value: 'hyperlipidemia_(fasting_hyperlipidemia)',
    description: 'Hyperlipidemia (mild/moderate)',
    categories: [PathologyCategory.ENDOCRINE_DISORDERS],
  },
  {
    value: 'hyperlipidemia_severe',
    description: 'Hyperlipidemia (severe)',
    categories: [PathologyCategory.ENDOCRINE_DISORDERS],
  },
  {
    value: 'hypertension',
    description: 'Hypertension',
    categories: [PathologyCategory.CARDIOVASCULAR_DISEASES],
  },
  {
    value: 'hyperthyroidism',
    description: 'Hyperthyroidism',
    categories: [PathologyCategory.ENDOCRINE_DISORDERS],
  },
  {
    value: 'hypertrophic_cardiomyopathy_(hcm)',
    description: 'Hypertrophic Cardiomyopathy (HCM)',
    categories: [PathologyCategory.CARDIOVASCULAR_DISEASES],
  },
  {
    value: 'hypothyroidism',
    description: 'Hypothyroidism',
    categories: [PathologyCategory.ENDOCRINE_DISORDERS],
  },
  {
    value: 'inflammatory_bowel_disease_(ibd)',
    description: 'Steroid Responsive Enteropathy (SRE)/Inflammatory Bowel Disease (IBD)  ',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'kidney_dysplasia',
    description: 'Kidney Dysplasia',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'liver_failure',
    description: 'Liver Failure',
    categories: [PathologyCategory.LIVER_DISEASES],
  },
  {
    value: 'lymphangiectasia',
    description: 'Lymphangiectasia',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'maldigestion_malabsorption',
    description: 'Maldigestion Malabsorption',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'megacolon',
    description: 'Megacolon (non-obstructive)',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
    speciesCode: SpeciesCode.Cat,
  },
  {
    value: 'megaoesophagus',
    description: 'Megaesophagus',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'neoplasia_(cancer)',
    description: 'Neoplasia (Cancer)',
    categories: [PathologyCategory.OTHER_CONDITIONS],
  },
  {
    value: 'obesity_bcs_7_(out_of_9)',
    description: 'Obesity BCS 7 (Out Of 9)',
    categories: [PathologyCategory.BODY_CONDITION],
    bcs: 7,
  },
  {
    value: 'obesity_bcs_8_(out_of_9)',
    description: 'Obesity BCS 8 (Out Of 9)',
    categories: [PathologyCategory.BODY_CONDITION],
    bcs: 8,
  },
  {
    value: 'obesity_bcs_9_(out_of_9)',
    description: 'Obesity BCS 9 (Out Of 9)',
    categories: [PathologyCategory.BODY_CONDITION],
    bcs: 9,
  },
  {
    value: 'obstipation',
    description: 'Constipation (severe) / Obstipation (prevention of reoccurrence)',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
    speciesCode: SpeciesCode.Cat,
  },
  {
    value: 'oesophagitis',
    description: 'Oesophagitis',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'osteoarthritis',
    description: 'Osteoarthritis',
    categories: [PathologyCategory.OSTEOARTICULAR_DISORDERS],
  },
  {
    value: 'osteochondritis',
    description: 'Osteochondritis',
    categories: [PathologyCategory.OSTEOARTICULAR_DISORDERS],
  },
  {
    value: 'overweight_bcs_6_(out_of_9)',
    description: 'Overweight BCS 6 (Out Of 9)',
    categories: [PathologyCategory.BODY_CONDITION],
    bcs: 6,
  },
  {
    value: 'perianal_fistula',
    description: 'Perianal Fistula',
    categories: [PathologyCategory.OTHER_CONDITIONS],
  },
  {
    value: 'polycystic_kidney_disease_(pkd)',
    description: 'Polycystic Kidney Disease (PKD)',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'portosystemic_shunt_(pss)',
    description: 'Portosystemic Shunt (PSS)',
    categories: [PathologyCategory.LIVER_DISEASES],
  },
  {
    value: 'prone_cardiac',
    description: 'Prone to Cardiac Disease',
    categories: [PathologyCategory.CARDIOVASCULAR_DISEASES],
    isRiskFactor: true,
  },
  {
    value: 'gastrointestinal_sensitivity',
    description: 'Gastrointestinal tract sensitivity',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
    isRiskFactor: true,
  },
  {
    value: 'hairball',
    description: 'Prone to Hairball',
    speciesCode: SpeciesCode.Cat,
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
    isRiskFactor: true,
  },
  {
    value: 'bone_and_joint',
    description: 'Osteo-articular sensitivity',
    categories: [PathologyCategory.OSTEOARTICULAR_DISORDERS],
    isRiskFactor: true,
  },
  {
    value: 'oxalate_urolithiasis',
    description: 'Prone to calcium oxalate urolithiasis',
    categories: [PathologyCategory.URINARY_TRACT_DISORDERS],
    isRiskFactor: true,
  },
  {
    value: 'skin_and_coat_sensitivity',
    description: 'Skin and coat sensitivity',
    categories: [PathologyCategory.SKIN_AND_COAT_DISORDERS],
    isRiskFactor: true,
  },
  {
    value: 'stress_and_anxiety',
    description: 'Prone to stress and anxiety',
    categories: [PathologyCategory.STRESS_AND_ANXIETY_DISORDERS],
    isRiskFactor: true,
  },
  {
    value: 'struvite_urolithiasis',
    description: 'Prone to struvite urolithiasis',
    categories: [PathologyCategory.URINARY_TRACT_DISORDERS],
    isRiskFactor: true,
  },
  {
    value: 'tartar',
    description: 'Prone to tarter',
    categories: [PathologyCategory.ORAL_DENTAL_DISORDERS],
    isRiskFactor: true,
  },
  {
    value: 'weight_gain',
    description: 'Prone to weight gain',
    categories: [PathologyCategory.BODY_CONDITION],
    isRiskFactor: true,
  },
  {
    value: 'protein_losing_enteropathy_(ple)',
    description: 'Protein Losing Enteropathy (PLE) ',
    categories: [PathologyCategory.GASTROINTESTINAL_DISORDERS],
  },
  {
    value: 'protein-losing_nephropathy_(pln)',
    description: 'Protein-Losing Nephropathy (PLN) ',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'proteinuria',
    description: 'Proteinuria',
    categories: [PathologyCategory.RENAL_DISEASES],
  },
  {
    value: 'pyodermatitis',
    description: 'Pyoderma / Pyodermatitis',
    categories: [PathologyCategory.SKIN_AND_COAT_DISORDERS],
  },
  {
    value: 'sarcopenia',
    description: 'Sarcopenia',
    categories: [PathologyCategory.BODY_CONDITION],
  },
  {
    value: 'self-induced_alopecia',
    description: 'Self-Induced Alopecia',
    categories: [PathologyCategory.STRESS_AND_ANXIETY_DISORDERS],
  },
  {
    value: 'tartar',
    description: 'Tarter',
    categories: [PathologyCategory.ORAL_DENTAL_DISORDERS],
  },
  {
    value: 'underweight_bcs_1_(out_of_9)',
    description: 'Underweight BCS 1 (Out Of 9)',
    categories: [PathologyCategory.BODY_CONDITION],
    bcs: 1,
  },
  {
    value: 'underweight_bcs_2_(out_of_9)',
    description: 'Underweight BCS 2 (Out Of 9)',
    categories: [PathologyCategory.BODY_CONDITION],
    bcs: 2,
  },
  {
    value: 'underweight_bcs_3_(out_of_9)',
    description: 'Underweight BCS 3 (Out Of 9)',
    categories: [PathologyCategory.BODY_CONDITION],
    bcs: 3,
  },
  {
    value: 'urinary_tract_infection_(uti)',
    description: 'Urinary Tract Infection (UTI) / Struvite Prevention',
    categories: [PathologyCategory.URINARY_TRACT_DISORDERS],
  },
  {
    value: 'urolithiasis_calcium_oxalate_(or_calcium_phosphate)',
    description: 'Calcium Oxalate Urolithiasis (disease management)',
    categories: [PathologyCategory.URINARY_TRACT_DISORDERS],
  },
  {
    value: 'urolithiasis_cystine',
    description: 'Cystine Urolithiasis',
    categories: [PathologyCategory.URINARY_TRACT_DISORDERS],
  },
  {
    value: 'urolithiasis_struvite',
    description: 'Struvite Urolithiasis (dissolution/disease management)',
    categories: [PathologyCategory.URINARY_TRACT_DISORDERS],
  },
  {
    value: 'urolithiasis_urate_(or_xanthine)',
    description: 'Urate Urolithiasis',
    categories: [PathologyCategory.URINARY_TRACT_DISORDERS],
  },
];
