import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { lifestageTypeTranslationCodes } from '@app/shared/data';
import { ActivityCode } from '@app/shared/utils/enums/activity-code.enum';
import { IconName } from '@app/shared/utils/icon/icons';
import {
  AlertNotification,
  Breed,
  LifestageInfo,
  PetProfileFormValueKeys,
  PetProfileFormValues,
  PET_PROFILE_NOTIFICATION_ID,
  Segmented,
} from '@app/core/models';
import { GenderCode, Helper, LifestageType, ReproductionStatusCode, SpeciesCode, Tool } from '@app/shared/utils';
import { RCAlertType, RCAutocompleteItem, RCSelectItem } from '@rc/ui';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { getMUFAvailabilityError, getRDAvailabilityError, getWLPAvailabilityError, getWLPAvailabilityWarning } from './patient-helper';

export function getPlaceholders(): Record<string, string> {
  return {
    [PetProfileFormValueKeys.NAME]: translateKey('form-attribute_name'),
    [PetProfileFormValueKeys.SPECIES_CODE]: translateKey('form-attribute_species'),
    [PetProfileFormValueKeys.GENDER]: translateKey('form-attribute_gender'),
    [PetProfileFormValueKeys.BREED]: translateKey('form-attribute_breed'),
    [PetProfileFormValueKeys.MIXED]: translateKey('form-attribute_select-unknown-or-mixed'),
    [PetProfileFormValueKeys.BIRTHDATE]: translateKey('form-attribute_birth-date'),
    [PetProfileFormValueKeys.NEUTERED]: translateKey('form-attribute_neutered'),
    [PetProfileFormValueKeys.REPRODUCTION_STATUS]: `${translateKey('form-attribute_reproduction-status')} ${translateKey(
      'form-attribute_optional'
    )}`,
    [PetProfileFormValueKeys.PET_ACTIVITY]: translateKey('form-attribute_select-activity'),
  };
}

export function getSpeciesItems(): Segmented[] {
  return [
    {
      label: $localize`:@@specie-dog:`,
      icon: IconName.dog,
      value: SpeciesCode.Dog,
    },
    {
      label: $localize`:@@specie-cat:`,
      icon: IconName.cat,
      value: SpeciesCode.Cat,
    },
  ];
}

export function getGenderItems(): Segmented[] {
  return [
    {
      label: $localize`:@@gender-male:`,
      icon: IconName.male,
      value: GenderCode.Male,
    },
    {
      label: $localize`:@@gender-female:`,
      icon: IconName.female,
      value: GenderCode.Female,
    },
  ];
}

export function getActivityItems(): RCSelectItem[] {
  return [
    {
      label: $localize`:@@activity-low:`,
      value: ActivityCode.Low,
      id: 'rc-select-option-low',
    },
    {
      label: $localize`:@@activity-moderate:`,
      value: ActivityCode.Moderate,
      id: 'rc-select-option-moderate',
    },
    {
      label: $localize`:@@activity-high:`,
      value: ActivityCode.High,
      id: 'rc-select-option-high',
    },
  ];
}

export function getReproductionStatusItems(): RCSelectItem[] {
  return [
    {
      label: $localize`:@@status-gestation:`,
      value: ReproductionStatusCode.Gestation,
      id: 'rc-select-option-gestation',
    },
    {
      label: $localize`:@@status-lactation:`,
      value: ReproductionStatusCode.Lactation,
      id: 'rc-select-option-lactation',
    },
    {
      label: $localize`:@@status-none:`,
      value: ReproductionStatusCode.None,
      id: 'rc-select-option-none',
    },
  ];
}

export function isLifestageAdult(lifestageType?: LifestageType): boolean {
  return !!(lifestageType && Helper.OLD_LIFESTAGES.includes(lifestageType));
}

export function sortMixedBreeds(breed1: RCAutocompleteItem<string>, breed2: RCAutocompleteItem<string>): number {
  const order = [
    'mixed_breed_giant_45kg',
    'mixed_breed_maxi_26_44kg',
    'mixed_breed_medium_11_25kg',
    'mixed_breed_mini_4_10kg',
    'mixed_breed_xsmall_1_4kg',
  ];
  return order.indexOf(breed1.id) - order.indexOf(breed2.id);
}

export function formatAndFilterBreeds(breeds: Breed[], speciesCode?: SpeciesCode, mixed?: boolean): RCAutocompleteItem<string>[] {
  return breeds
    .filter((breed) => (!speciesCode || speciesCode === breed.speciesCode) && (!mixed || Helper.isMixed(breed)))
    .map((b) => ({
      label: b.localName,
      value: b.breedCode,
      id: b.breedCode,
    }));
}

export function getMessagesToEmit(
  tool: Tool,
  {
    lifestage,
    reproductionStatus,
    speciesCode,
    birthDate,
  }: {
    lifestage: LifestageType | null;
    reproductionStatus: ReproductionStatusCode | null;
    speciesCode?: SpeciesCode | null;
    birthDate?: Date | null;
  }
): { messages: AlertNotification[]; messageIdsToRemove: PET_PROFILE_NOTIFICATION_ID[]; isBlocking: boolean } {
  const messages: AlertNotification[] = [];
  const messageIdsToRemove: PET_PROFILE_NOTIFICATION_ID[] = [];

  switch (tool) {
    case Tool.WeightManagement: {
      const error = lifestage ? getWLPAvailabilityError(lifestage) : '';
      const warning = reproductionStatus ? getWLPAvailabilityWarning(reproductionStatus) : '';
      if (error) {
        messages.push({ message: error, alertType: RCAlertType.ERROR, id: PET_PROFILE_NOTIFICATION_ID.WM_LIFESTAGE });
      } else {
        messageIdsToRemove.push(PET_PROFILE_NOTIFICATION_ID.WM_LIFESTAGE);
      }
      if (warning) {
        messages.push({ message: warning, alertType: RCAlertType.WARNING, id: PET_PROFILE_NOTIFICATION_ID.WM_REPRODUCTION_STATUS });
      } else {
        messageIdsToRemove.push(PET_PROFILE_NOTIFICATION_ID.WM_REPRODUCTION_STATUS);
      }
      break;
    }
    case Tool.RenalDetect: {
      const error = speciesCode && birthDate ? getRDAvailabilityError(speciesCode, birthDate) : '';
      if (error) {
        messages.push({ message: error, alertType: RCAlertType.ERROR, id: PET_PROFILE_NOTIFICATION_ID.RD_SPECIE_BIRTHDATE });
      } else {
        messageIdsToRemove.push(PET_PROFILE_NOTIFICATION_ID.RD_SPECIE_BIRTHDATE);
      }
      break;
    }
    case Tool.PersonalizedBag: {
      const error = speciesCode && birthDate ? getMUFAvailabilityError(lifestage) : '';
      if (error) {
        messages.push({ message: error, alertType: RCAlertType.ERROR, id: PET_PROFILE_NOTIFICATION_ID.MUF_PB_SPECIE_BIRTHDATE });
      } else {
        messageIdsToRemove.push(PET_PROFILE_NOTIFICATION_ID.MUF_PB_SPECIE_BIRTHDATE);
      }
      break;
    }
    default:
      break;
  }

  return { messages, messageIdsToRemove, isBlocking: !!messages.find((item) => item.alertType === RCAlertType.ERROR) };
}

export function getAdditionalLifestageInfo(lifestage?: LifestageType): LifestageInfo {
  if (!lifestage) {
    return { code: null, message: null, error: translateKey('label_life-stage-msg-error') };
  } else if (lifestage === LifestageType.NotAvailable) {
    return { code: null, message: null, error: translateKey('error_lifetsage-not-available') };
  } else {
    const lifestageTranslation = lifestageTypeTranslationCodes.find((item) => lifestage === item.code);
    return {
      code: lifestage,
      message: `${translateKey('label_life-stage-msg')} ${translateKey(lifestageTranslation?.value) || ''}`,
      error: null,
    };
  }
}

export function filterFormValues(values: PetProfileFormValues): PetProfileFormValues {
  const isLifesageAdult = isLifestageAdult(values.lifestage);
  return {
    ...values,
    reproductionStatus: values.gender !== GenderCode.Male && isLifesageAdult ? values.reproductionStatus : null,
    petActivity: isLifesageAdult ? values.petActivity : null,
  };
}

export const genderValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }
  return control.value.toString() === GenderCode.Unknown ? { gender: { value: control.value } } : null;
};
