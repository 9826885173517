import { Injectable } from '@angular/core';
import { DeprecatedLanguageItem } from '@app/core/models';
import { Language } from '@app/core/models/language';
import { Env } from '@app/shared/utils/enums/environment.enum';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { environment } from '@env/environment';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE, FALLBACK_LANGUAGES, INIT_LANGUAGE_KEY, PHRASE_SDK_KEY } from './localization';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
  /**
   * Get init language
   * Sould only be used in locale-loader & app.module
   * Use LOCALE_ID for the rest of the app
   */
  static get initLanguage() {
    return localStorage.getItem(INIT_LANGUAGE_KEY);
  }
  /**
   * Set init language
   * Should only be used in locale-loader and in this service
   * Use setLanguageAndReload if you need to change app language
   *
   */
  static set initLanguage(language) {
    localStorage.setItem(INIT_LANGUAGE_KEY, language);
  }
  /**
   * Get market active language list
   * it should have a phraseId and a translation
   * return format is for retrocompatibilty
   */
  static get enabledLanguageItems(): DeprecatedLanguageItem[] {
    return AVAILABLE_LANGUAGES.reduce((prev: DeprecatedLanguageItem[], curr) => {
      const key = `language_${curr.code}`;
      const translation = translateKey(key);
      return this.isLanguageEnabled(curr) && key !== translation ? [...prev, { code: curr.code, localizedText: translation }] : prev;
    }, []);
  }
  /**
   * Get phrase SDK enabled state
   * Cannot return true in production(-like) envs
   */
  static get isPhraseSdkEnabled(): boolean {
    const isPhraseSdkEnabled = localStorage.getItem(PHRASE_SDK_KEY);
    if (!isPhraseSdkEnabled) return false;
    try {
      return JSON.parse(isPhraseSdkEnabled) && environment.envName !== Env.PRD && environment.envName !== Env.DEMO;
    } catch (err) {
      return false;
    }
  }
  /**
   * Set phrase SDK enabled state
   */
  static set isPhraseSdkEnabled(value: boolean) {
    localStorage.setItem(PHRASE_SDK_KEY, JSON.stringify(value));
  }
  /**
   * Set language and reload
   */
  static setLanguageAndReload(language) {
    this.initLanguage = language;
    window.location.reload();
  }
  /**
   * Complete language code
   * If locale is not found, returns fallback language if defined,
   * closest language if possible, default language otherwise
   */
  static completeLanguageCode(lang: string) {
    if (!lang) {
      lang = DEFAULT_LANGUAGE;
    }
    const enabledLanguages = AVAILABLE_LANGUAGES.filter((l) => this.isLanguageEnabled(l));
    const locale = enabledLanguages.find(({ code }) => code === lang);
    if (locale) {
      return locale.code;
    }

    const languageWithFallback = FALLBACK_LANGUAGES.find((l) => l.code === lang);
    if (languageWithFallback) {
      return languageWithFallback.fallback;
    }
    const sameLangLocale = enabledLanguages.find((l) => l.code.split('-')[0] === lang.split('-')[0]);
    if (sameLangLocale) {
      return sameLangLocale.code;
    }
    return DEFAULT_LANGUAGE;
  }
  /**
   * Shorten language code
   */
  static shortenLanguageCode(lang) {
    if (!lang) {
      lang = DEFAULT_LANGUAGE;
    }
    return lang.split('-')[0];
  }
  /**
   * Check if language is enabled for current env
   */
  static isLanguageEnabled(language: Language) {
    return !language.envs || language.envs.includes(environment.envName);
  }
}
