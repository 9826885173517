import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class DataSubscriber {
  static subscribe<T>(obs: Observable<T>, fn: (val: T) => void, _destroyed$: Subject<any>, onError: (err) => void): void {
    obs.pipe(takeUntil(_destroyed$)).subscribe(fn, onError);
  }

  static subscribeLatest<T1, T2>(
    observables: [Observable<T1>, Observable<T2>],
    fn: (v1: T1, v2: T2) => void,
    _destroyed$: Subject<any>,
    onError: (err) => void
  ): void;
  static subscribeLatest<T1, T2, T3>(
    observables: [Observable<T1>, Observable<T2>, Observable<T3>],
    fn: (v1: T1, v2: T2, v3: T3) => void,
    _destroyed$: Subject<any>,
    onError: (err) => void
  ): void;
  static subscribeLatest<T1, T2, T3, T4>(
    observables: [Observable<T1>, Observable<T2>, Observable<T3>, Observable<T4>],
    fn: (v1: T1, v2: T2, v3: T3, v4: T4) => void,
    _destroyed$: Subject<any>,
    onError: (err) => void
  ): void;
  static subscribeLatest<T1, T2, T3, T4, T5>(
    observables: [Observable<T1>, Observable<T2>, Observable<T3>, Observable<T4>, Observable<T5>],
    fn: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5) => void,
    _destroyed$: Subject<any>,
    onError: (err) => void
  ): void;
  static subscribeLatest<T1, T2, T3, T4, T5, T6>(
    observables: [Observable<T1>, Observable<T2>, Observable<T3>, Observable<T4>, Observable<T5>, Observable<T6>],
    fn: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6) => void,
    _destroyed$: Subject<any>,
    onError: (err) => void
  ): void;
  static subscribeLatest<T1, T2, T3, T4, T5, T6, T7>(
    observables: [Observable<T1>, Observable<T2>, Observable<T3>, Observable<T4>, Observable<T5>, Observable<T6>, Observable<T7>],
    fn: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6, v7: T7) => void,
    _destroyed$: Subject<any>,
    onError: (err) => void
  ): void;
  static subscribeLatest<T>(observables: Observable<T>[], fn, _destroyed$: Subject<any>, onError: (err) => void): void {
    this.subscribe(combineLatest(observables), (vals: any[]) => fn(...vals), _destroyed$, onError);
  }
}
