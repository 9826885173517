import { Clinic, Invitation, Vet } from '@app/core/models';
import { Market } from '@app/core/models/market';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import { createAction, props } from '@ngrx/store';
import { UserClaims } from '@okta/okta-auth-js';
/**
 * Get vet actions
 */
/**
 * Action to load vet
 */
export const getVet = createAction('[Vet] Get Vet');
/**
 * Action dispatched when vet has been found
 */
export const getVetSuccess = createAction(
  '[Vet] Get Vet Success',
  props<{ vet: Vet; currentClinic: Clinic | undefined; oktaUser: UserClaims }>()
);
/**
 * Action dispatched on error (including new vet not found)
 */
export const getVetFail = createAction('[Vet] Get Vet Fail');
/**
 * Action to set vet validity
 * See vet.effects for criteria
 */
export const setVetValidity = createAction('[Vet] Get Vet Valid', props<{ value: boolean }>());
/**
 * Set invitation action
 */
export const setInvitation = createAction('[Vet] Get Set Invitation', props<{ invitation: Invitation | undefined }>());
/**
 * Actions to load market data
 */
export const getMarket = createAction('[Vet] Get Market', props<{ countryCode: CountryCode }>());
export const getMarketSuccess = createAction('[Vet] Get Market Success', props<{ market: Market }>());
export const getMarketFail = createAction('[Vet] Get Market Fail', props<{ error: string }>());

export const displayInfoPopin = createAction('[Vet] Display info popin');
/**
 * Action to update clinic
 */
export const updateClinic = createAction('[Vet] Update Clinic', props<{ clinicId: string; clinic: Clinic; redirectTo?: string[] }>());
/**
 * Action dispatched when clinic update has succeeded
 */
export const updateClinicSuccess = createAction('[Vet] Update Clinic Success', props<{ clinic: Clinic }>());

export const setInfoPopin = createAction('[Vet] Set info popin', props<{ clinic: Clinic; market: Market; vet: Vet }>());
export const setInfoAlert = createAction('[Vet] Set info alert', props<{ clinic: Clinic; market: Market; vet: Vet }>());
