import { PetInfo } from './../../../core/models/pet-info';
import { Injectable } from '@angular/core';
import { Dialog } from '@app/core/cdk/dialog';
import { Consultation, Patient, Pet, PetOwner } from '@app/core/models';
import { VetService } from '@app/core/services';
import { CreatePatientPopinComponent, CreatePatientPopinOutput } from '@app/shared/components';
import { Helper, Tool, VetPreferences } from '@app/shared/utils';
import { AppState } from '@app/store';
import { selectCurrentClinicId } from '@app/store/vet';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RCAlertType } from '@rc/ui';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  createCurrentConsultation,
  createCurrentConsultationFail,
  createCurrentConsultationSuccess,
  setConsultationPatient,
  setCreatingCurrentConsultation,
} from '../consultation.actions';
import { selectConsultationPatient } from '../consultation.selectors';
import { buildConsultationApiBody } from '@app/shared/utils/static-helpers/consultation-helper';
import { formatOwnerFormValuesToOwner } from '@app/shared/utils/static-helpers/patient-helper';
import { setAlert } from '@app/store/core';

@Injectable()
export class ConsultationCreateEffects {
  /**
   * Create a new consultation;
   * if needed, create a new patient or update existing one
   */
  createConsultation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCurrentConsultation),
      withLatestFrom(this.store$.select(selectConsultationPatient), this.store$.select(selectCurrentClinicId)),
      switchMap(([{ consultationData, petInfo, tool, createPatientPopinTitle, owner, isRenewal = false }, patient, clinicId]) => {
        let firstStep: Observable<{ patient: Patient }> | null = null;
        /**
         * First we need a valid patient,
         * if it exists, we only update the pet and create the consultation
         */
        if (patient) {
          firstStep = this.updatePetAndCreateConsultation(patient, petInfo, consultationData, isRenewal);
          /**
           * If we don't have the patient but a valid owner,
           * we need to create the patient and the consultation
           */
        } else if (owner) {
          firstStep = this.createPatientAndConsultation(petInfo, owner, clinicId, consultationData, isRenewal);
          /**
           * If we don't have a valid patient not a valid owner,
           * we need to create the patient, the owner and the consultation through the create-patient-popin
           */
        } else {
          firstStep = this.createPatientAndOwnerAndConsultation(
            petInfo,
            createPatientPopinTitle,
            tool,
            clinicId,
            consultationData,
            isRenewal
          );
        }
        return firstStep.pipe(
          /**
           * We now have a valid & up to date patient and consultation, we store the consultation and the patient in the store
           */
          switchMap(({ patient }) => [
            setConsultationPatient({ value: patient }),
            createCurrentConsultationSuccess({ consultation: patient.consultation }),
          ])
        );
      })
    )
  );
  /**
   * Create consultation fail
   */
  createConsultationFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCurrentConsultationFail),
      switchMap(() => [
        setAlert({
          alert: {
            message: $localize`:@@error_general_text:`,
            alertType: RCAlertType.ERROR,
          },
        }),
        setCreatingCurrentConsultation({ value: false }),
      ])
    )
  );

  constructor(private actions$: Actions, private store$: Store<AppState>, private dialog: Dialog, private vetService: VetService) {}

  private createPatientAndOwnerAndConsultation(
    petInfo: PetInfo,
    createPatientPopinTitle: string,
    tool: Tool,
    clinicId: string,
    consultationData: Partial<Consultation>,
    isRenewal: boolean
  ): Observable<{ patient: Patient }> {
    return (
      this.dialog
        .open<CreatePatientPopinComponent, CreatePatientPopinOutput>(CreatePatientPopinComponent, {
          panelClass: 'medium',
          data: {
            petInfo,
            title: createPatientPopinTitle,
            tool,
          },
        })
        .afterClosed()
        /**
         * Once the popin is closed :
         */
        .pipe(
          tap((data) => !data && this.store$.dispatch(setCreatingCurrentConsultation({ value: false }))),
          filter((data) => !!data),
          /**
           * we create a new patient with the consultation (with existing owner or not)
           */
          switchMap(({ petInfo, values }) =>
            this.createPatientAndConsultation(petInfo, formatOwnerFormValuesToOwner(values), clinicId, consultationData, isRenewal)
          )
        )
    );
  }

  private createPatientAndConsultation(
    petInfo: PetInfo,
    owner: PetOwner,
    clinicId: string,
    consultationData: Partial<Consultation>,
    isRenewal: boolean
  ): Observable<{ patient: Patient }> {
    return this.vetService
      .createPatient(
        Helper.petInfoToPet(petInfo),
        { ...owner, organizationId: clinicId },
        buildConsultationApiBody(consultationData, isRenewal)
      )
      .pipe(
        map((newPatient) => ({ patient: newPatient })),
        catchError((error) => {
          this.store$.dispatch(createCurrentConsultationFail({ error }));
          return EMPTY;
        })
      );
  }

  private updatePetAndCreateConsultation(
    patient: Patient,
    petInfo: PetInfo,
    consultationData: Partial<Consultation>,
    isRenewal: boolean
  ): Observable<{ patient: Patient }> {
    return this.vetService
      .updatePet(patient.petId, {
        breedCode: petInfo.breed,
        genderCode: petInfo.gender,
        neutered: petInfo.neutered,
        petActivityCode: petInfo.petActivity,
        reproductionStatusCode: petInfo.reproductionStatus,
        weight: {
          weightDate: new Date(),
          measure: petInfo?.weight,
          measureUnit: VetPreferences.currentBigMeasurementUnit,
        },
        idealBodyWeight: {
          weightDate: new Date(),
          bcs: petInfo.bcs,
          measure: petInfo.IBW,
          measureUnit: VetPreferences.currentBigMeasurementUnit,
        },
      })
      .pipe(
        /**
         * We can now update the pet inside the patient & return it
         */
        map((pet: Pet) => ({
          patient: {
            ...patient,
            pet,
          },
        })),
        concatMap(({ patient: updatedPatient }) =>
          this.vetService.createConsultation(updatedPatient, buildConsultationApiBody(consultationData, isRenewal)).pipe(
            map((consultation) => ({ patient: { ...updatedPatient, consultation } })),
            catchError((error) => {
              this.store$.dispatch(createCurrentConsultationFail({ error }));
              return EMPTY;
            })
          )
        )
      );
  }
}
