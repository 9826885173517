import { Injectable } from '@angular/core';
import { VetService } from '@app/core/services/network/vet/vet.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { getPatients, getPatientsSuccess } from '../patient.actions';
import { errorPatientState, getOwners, getOwnersSuccess } from './../patient.actions';

@Injectable()
export class PatientsEffects {
  LoadPatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPatients),
      switchMap((action) => {
        return this.vetService.searchPatients(action.filters).pipe(
          switchMap((patients) => {
            return [getPatientsSuccess({ patients })];
          }),
          catchError((error: string) => of(errorPatientState({ error })))
        );
      })
    )
  );

  LoadOwners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOwners),
      switchMap((action) =>
        this.vetService.searchPetOwners(action.contactIds).pipe(
          map((owners) => getOwnersSuccess({ owners })),
          catchError((error: string) => of(errorPatientState({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private vetService: VetService) {}
}
