import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { DeprecatedLoaderComponent } from '@app/core/components/loader-deprecated/loader.component';
import { OverlayComponent } from '@app/core/components/overlay/overlay.component';
import { ComponentInjector, ConfigInjector } from '@app/core/services/utils/injector';
import { WindowService } from '@app/core/services/utils/window';
import { CoreFacade } from '@app/store/core/core.facade';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  /**
   * Variables & Properties
   */
  private _window: any;
  private _overlayComponentRef: any;
  private _childComponentRef: any;
  private _hasOverlay = false;
  private _loaderActive = false;
  private _container = document.body;

  /**
   * Initializer
   * @param window - Window
   * @param componentFactoryResolver - Too
   * @param appRef - ApplicationRef
   * @param injector - Injector
   */
  constructor(
    private window: WindowService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private coreFacade: CoreFacade
  ) {
    this._window = window.nativeWindow;
  }

  startLoader(hasOverlay = false, container: any = document.body) {
    if (!this._loaderActive) {
      this._container = container;
      this._loaderActive = true;
      this.coreFacade.setDeprecatedLoaderState(true);
      this.appendComponentToBody(hasOverlay);
    }
  }

  stopLoaderObservable(): Subject<unknown> {
    const obs = new Subject();
    this.stopLoader(() => {
      obs.next();
      obs.complete();
    });
    return obs;
  }

  stopLoader(callback?: () => void) {
    if (this._loaderActive) {
      const _callback = () => {
        this._loaderActive = false;
        if (callback) {
          callback();
        }
      };
      if (this._hasOverlay) {
        this.removeComponentWithOverlay(_callback);
      } else {
        this.removeComponent(_callback);
      }
      this.coreFacade.setDeprecatedLoaderState(false);
    } else if (callback) {
      callback();
    }
  }

  /**
   * Append component to the body
   * @param hasOverlay - Show the overlay or not
   * 1 - Put no-scroll outside _windows for change tab during loading
   */
  private appendComponentToBody(hasOverlay = false) {
    const map = new WeakMap();
    map.set(ConfigInjector, { data: { backgroundColor: 'white' } });

    this._hasOverlay = hasOverlay;

    if (hasOverlay) {
      // Create a component reference from the component
      const overlayRef = this.componentFactoryResolver
        .resolveComponentFactory(OverlayComponent)
        .create(new ComponentInjector(this.injector, map));

      overlayRef.changeDetectorRef.detectChanges();

      this._overlayComponentRef = overlayRef;
      this.appRef.attachView(overlayRef.hostView);
      const overlayElm = (overlayRef.hostView as EmbeddedViewRef<any>).rootNodes[0];
      this._container.appendChild(overlayElm);
    }

    const childRef = this.componentFactoryResolver.resolveComponentFactory(DeprecatedLoaderComponent).create(this.injector);

    this._childComponentRef = childRef;
    this.appRef.attachView(childRef.hostView);

    const domElem = (childRef.hostView as EmbeddedViewRef<any>).rootNodes[0];

    /**1*/
    this._container.appendChild(domElem);
    this._container.scrollTop = 0;
    this._container.classList.add('no-scroll');

    this._window.requestAnimationFrame(() => {
      if (hasOverlay) {
        const overlay = this._container.querySelector('.overlay');
        if (overlay) {
          overlay.classList.add('active');
        }
      }

      const loader = this._container.querySelector('.rc-loader-deprecated');
      if (loader) {
        loader.classList.add('active');
      }
    });
  }

  private removeComponentWithOverlay(callback?: () => void) {
    this._container.querySelector('.overlay').classList.remove('active');
    this._container.querySelector('.rc-loader-deprecated').classList.remove('active');

    setTimeout(() => {
      this._container.classList.remove('no-scroll');
      this.appRef.detachView(this._overlayComponentRef.hostView);
      this.appRef.detachView(this._childComponentRef.hostView);

      this._overlayComponentRef.destroy();
      this._childComponentRef.destroy();
      if (callback) {
        callback();
      }
    }, 100);
  }

  private removeComponent(callback?: () => void) {
    this._container.classList.remove('no-scroll');

    setTimeout(() => {
      this.appRef.detachView(this._childComponentRef.hostView);
      this._childComponentRef.destroy();
      if (callback) {
        callback();
      }
    }, 100);
  }
}
