import { HttpErrorResponse } from '@angular/common/http';
import { AlertNotification, Breed } from '@app/core/models';
import { Localization } from '@app/core/models/localization';
import { Tool } from '@app/shared/utils';
import { createAction, props } from '@ngrx/store';

/**
 * Action to handle blocking loader display
 */
export const toggleLoader = createAction('[Core] Toggle Loader', props<{ value: boolean; transparent?: boolean }>());
/**
 * Action to handle progress bar display
 */
export const toggleProgressBar = createAction('[Core] Toggle Progress Bar', props<{ value: boolean }>());
/**
 * Set deprecated loader state
 * Used to hide progress bar if it is active
 */
export const setDeprecatedLoaderState = createAction('[Core] Set Deprecated Loader State', props<{ value: boolean }>());
/*
 * Actions to load global localization data
 */
export const getLocalization = createAction('[Core] Get Localization');
export const getLocalizationSuccess = createAction('[Core] Get Localization Success', props<{ localization: Localization }>());
export const getLocalizationFail = createAction('[Core] Get Localization Fail', props<{ error: HttpErrorResponse }>());
/**
 * Set tool when starting new consultation
 * Used by the token interceptor (but might be used more once router flow is deleted)
 */
export const setTool = createAction('[Core] Set Tool', props<{ tool: Tool; tool_flow_id: string }>());
/**
 * Set tool when leaving a consultation
 * Used by the token interceptor (but might be used more once router flow is deleted)
 */
export const clearTool = createAction('[Core] Clear Tool');
/**
 * Action used to set breeds;
 * Currently dispatched from legagy EnumService.fetchBreed for compatibility,
 * awaiting full migration to store
 */
export const setBreeds = createAction('[Core] Set breeds', props<{ breeds: Breed[] }>());
/**
 * Action used to set alert messages;
 */
export const setAlert = createAction('[Core] Set Alert', props<{ alert: AlertNotification }>());
/**
 * Action used to remove an alert messages;
 */
export const removeAlert = createAction('[Core] Remove Alert', props<{ alert: AlertNotification }>());
/**
 * Action used to remove multiple alert messages
 * specify ids if you want to filter the messages to remove
 * otherwise all message are removed
 */
export const resetAlerts = createAction('[Core] Reset Alerts', props<{ ids: string[] }>());
