import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import {
  selectDailyAllowanceStep,
  selectDailyAllowanceSteps,
} from '@app/pages/daily-allowance/store/selectors/daily-allowance-steps.selectors';
import { setDailyAllowanceStep } from '@app/pages/daily-allowance/store/actions/daily-allowance-steps.actions';
import { DailyAllowanceStep } from '@app/pages/daily-allowance/daily-allowance-step';

enum DasPath {
  patient = '/daily-allowance/patient',
  recommendation = '/daily-allowance/recommendation',
  allowance = '/allowance',
}
interface DasPage {
  path: DasPath;
  key: string;
  index: number;
  steps: DailyAllowanceStep[];
}

@Component({
  selector: 'app-das-nav',
  templateUrl: './standalone-nav.component.html',
  styleUrls: ['./standalone-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DasNavComponent {
  /**
   * Srs pages
   */
  pages: DasPage[] = [
    {
      path: DasPath.patient,
      key: 'path_patient',
      index: 1,
      steps: [DailyAllowanceStep.Profile, DailyAllowanceStep.Weight],
    },
    {
      path: DasPath.recommendation,
      key: 'path_products-recommendation',
      index: 2,
      steps: [DailyAllowanceStep.Recommendation],
    },
    { path: DasPath.allowance, key: 'path_daily-allowance', index: 3, steps: [DailyAllowanceStep.Allowance] },
  ];
  /**
   * Step number to display
   */
  standalonePage$ = this.router.events.pipe(
    filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
    map((e: NavigationEnd) => this.getDailyAllowancePage(e.urlAfterRedirects)),
    startWith(this.getDailyAllowancePage(this.location.path()))
  );
  /**
   * Progress bar percentage value
   */
  progressValue$ = combineLatest([this.store$.select(selectDailyAllowanceSteps), this.store$.select(selectDailyAllowanceStep)]).pipe(
    map(([steps, currentStep]) => (steps.indexOf(currentStep) + 1) * 20)
  );

  constructor(private store$: Store<AppState>, private router: Router, private location: Location) {}
  /**
   * Go back to previous step
   */
  goToStep(pageIndex: number): void {
    const page = this.pages.find((s) => s.index === pageIndex);
    this.store$.dispatch(setDailyAllowanceStep({ value: page.steps[0] }));
  }
  /**
   * Get page number from url
   */
  private getDailyAllowancePage(path: string): DasPage | null {
    if (path.includes(DasPath.allowance)) {
      return this.pages[2];
    } else if (path.includes(DasPath.recommendation)) {
      return this.pages[1];
    } else if (path.includes(DasPath.patient)) {
      return this.pages[0];
    }
    return null;
  }
}
