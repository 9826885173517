import { transformArrayToEntities } from '@app/shared/utils/static-helpers/array.utils';
import { createReducer, on } from '@ngrx/store';
import { clearPatientState, errorPatientState, getOwners, getOwnersSuccess, getPatients, getPatientsSuccess } from './patient.actions';
import { initialPatientState } from './patient.state';

/**
 * Patient reducer
 */
export const patientReducer = createReducer(
  initialPatientState,
  on(getPatientsSuccess, (state, { patients }) => ({
    ...state,
    patients: transformArrayToEntities(state.patients, patients),
    patientsLoading: false,
    patientsLoaded: true,
    error: null,
  })),
  on(getOwners, (state) => ({
    ...state,
    owners: {},
    ownersLoading: true,
    ownersLoaded: false,
    error: null,
  })),
  on(getOwnersSuccess, (state, { owners }) => ({
    ...state,
    owners: transformArrayToEntities(state.owners, owners),
    ownersLoading: false,
    ownersLoaded: true,
    error: null,
  })),
  on(getPatients, (state) => {
    return {
      ...state,
      patients: {},
      patientsLoading: true,
      patientsLoaded: false,
      error: null,
    };
  }),
  on(errorPatientState, (state, { error }) => ({
    ...initialPatientState,
    error,
  })),
  on(clearPatientState, () => initialPatientState)
);
