<rc-dialog>
  <rc-dialog-content class="rc-dialog-content">
    <div class="multifunction-popin">
      <div class="multifunction-popin__switch-container">
        <div class="multifunction-popin__starter" (click)="navigateToStarter()" id="muf-popin_starter-kit">
          <svg
            width="114px"
            height="124px"
            viewBox="0 0 114 124"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
            <title>Illustrations/clinic</title>
            <desc>Created with Sketch.</desc>
            <g id="Personalized-bag" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Vet_Vet-Services_Multifunction_version_0.5_Screen_03" transform="translate(-411.000000, -397.000000)">
                <g id="Starter-Kits" transform="translate(355.000000, 399.000000)">
                  <g id="Illustrations/clinic" transform="translate(56.000000, 0.000000)">
                    <path
                      d="M56.8091955,116.733135 C25.4343569,116.733135 0,91.2998409 0,59.924624 C0,28.5494071 25.4343569,3.11611335 56.8091955,3.11611335 C88.1826644,3.11611335 113.617021,28.5494071 113.617021,59.924624 C113.617021,91.2998409 88.1826644,116.733135 56.8091955,116.733135 Z"
                      id="Fill-1"
                      fill-opacity="0.5"
                      fill="#FCD2D7"
                    ></path>
                    <g id="Group" transform="translate(12.202652, 0.000000)">
                      <rect
                        id="Combined-Shape"
                        stroke="#9F0000"
                        stroke-width="3"
                        fill="#FFFFFF"
                        x="3.19148936"
                        y="28.0851064"
                        width="82.9787234"
                        height="91.9148936"
                      ></rect>
                      <rect
                        id="Rectangle"
                        stroke="#AC0000"
                        stroke-width="2"
                        fill="#FFFFFF"
                        x="10.7973483"
                        y="40"
                        width="9.57446809"
                        height="15.3191489"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-6"
                        stroke="#AC0000"
                        stroke-width="2"
                        fill="#FFFFFF"
                        x="39.9462844"
                        y="40"
                        width="9.57446809"
                        height="15.3191489"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-10"
                        stroke="#AC0000"
                        stroke-width="2"
                        fill="#FFFFFF"
                        x="69.0952206"
                        y="40"
                        width="9.57446809"
                        height="15.3191489"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-4"
                        stroke="#AC0000"
                        stroke-width="2"
                        fill="#FFFFFF"
                        x="10.7973483"
                        y="60.3191489"
                        width="9.57446809"
                        height="15.3191489"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-7"
                        stroke="#AC0000"
                        stroke-width="2"
                        fill="#FFFFFF"
                        x="39.9462844"
                        y="60.3191489"
                        width="9.57446809"
                        height="15.3191489"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-11"
                        stroke="#AC0000"
                        stroke-width="2"
                        fill="#FFFFFF"
                        x="69.0952206"
                        y="60.3191489"
                        width="9.57446809"
                        height="15.3191489"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-3"
                        stroke="#AC0000"
                        stroke-width="2"
                        fill="#FFFFFF"
                        x="25.3718163"
                        y="40"
                        width="9.57446809"
                        height="15.3191489"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-8"
                        stroke="#AC0000"
                        stroke-width="2"
                        fill="#FFFFFF"
                        x="54.5207525"
                        y="40"
                        width="9.57446809"
                        height="15.3191489"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-5"
                        stroke="#AC0000"
                        stroke-width="2"
                        fill="#FFFFFF"
                        x="25.3718163"
                        y="60.3191489"
                        width="9.57446809"
                        height="15.3191489"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-9"
                        stroke="#AC0000"
                        stroke-width="2"
                        fill="#FFFFFF"
                        x="54.5207525"
                        y="60.3191489"
                        width="9.57446809"
                        height="15.3191489"
                      ></rect>
                      <rect
                        id="Rectangle"
                        stroke="#9F0000"
                        stroke-width="3"
                        fill="#9F0000"
                        x="0"
                        y="28.0851064"
                        width="89.3617021"
                        height="5.10638298"
                        rx="2"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-2"
                        stroke="#9F0000"
                        stroke-width="3"
                        fill="#9F0000"
                        x="24.2553191"
                        y="0"
                        width="40.8510638"
                        height="5.10638298"
                        rx="2"
                      ></rect>
                      <rect
                        id="Rectangle-Copy"
                        stroke="#9F0000"
                        stroke-width="3"
                        fill="#9F0000"
                        x="0"
                        y="82.3404255"
                        width="89.3617021"
                        height="5.10638298"
                        rx="2"
                      ></rect>
                      <rect
                        id="Rectangle"
                        stroke="#9F0000"
                        stroke-width="3"
                        fill="#FFFFFF"
                        x="28.0851064"
                        y="5.10638298"
                        width="33.1914894"
                        height="28.0851064"
                      ></rect>
                      <circle id="Oval" fill="#F40000" cx="44.6808511" cy="19.1489362" r="8.93617021"></circle>
                      <rect
                        id="Rectangle"
                        fill="#FFFFFF"
                        x="43.4042553"
                        y="13.4042553"
                        width="2.55319149"
                        height="11.4893617"
                        rx="1.27659574"
                      ></rect>
                      <path
                        d="M44.6808511,13.4042553 C45.3858954,13.4042553 45.9574468,13.9758067 45.9574468,14.6808511 L45.9574468,23.6170213 C45.9574468,24.3220656 45.3858954,24.893617 44.6808511,24.893617 C43.9758067,24.893617 43.4042553,24.3220656 43.4042553,23.6170213 L43.4042553,14.6808511 C43.4042553,13.9758067 43.9758067,13.4042553 44.6808511,13.4042553 Z"
                        id="Rectangle"
                        fill="#FFFFFF"
                        transform="translate(44.680851, 19.148936) rotate(90.000000) translate(-44.680851, -19.148936) "
                      ></path>
                      <rect
                        id="Rectangle"
                        stroke="#9F0000"
                        stroke-width="3"
                        fill="#FFFFFF"
                        x="32.5531915"
                        y="98.2978723"
                        width="12.1276596"
                        height="21.7021277"
                      ></rect>
                      <rect
                        id="Rectangle-Copy-12"
                        stroke="#9F0000"
                        stroke-width="3"
                        fill="#FFFFFF"
                        x="44.6808511"
                        y="98.2978723"
                        width="12.1276596"
                        height="21.7021277"
                      ></rect>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <h3 class="multifunction-popin__title rc-title rc-title-epsilon" i18n="@@muf-popin_starter-kits_desc">
            Order Multifunction for the clinic
          </h3>
          <p class="multifunction-popin__sub-title" i18n="@@muf-popin_starter-kits_title">Starter Kits</p>
        </div>

        <div class="multifunction-popin__personalised" (click)="navigateToPersonalized()" id="muf-popin_perso-bag">
          <svg
            width="120px"
            height="120px"
            viewBox="0 0 120 120"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
            <title>Illustrations/Personalised Bag</title>
            <desc>Created with Sketch.</desc>
            <g id="Personalized-bag" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Vet_Vet-Services_Multifunction_version_0.5_Screen_03" transform="translate(-897.000000, -400.000000)">
                <g id="Personalised-bag" transform="translate(841.500000, 400.000000)">
                  <g id="Illustrations/Personalised-Bag" transform="translate(55.500000, 0.000000)">
                    <path
                      d="M60.0007233,120 C26.8632533,120 0,93.1378695 0,60 C0,26.8621305 26.8632533,0 60.0007233,0 C93.1367467,0 120,26.8621305 120,60 C120,93.1378695 93.1367467,120 60.0007233,120 Z"
                      id="Fill-1"
                      fill-opacity="0.5"
                      fill="#FCD2D7"
                    ></path>
                    <path
                      d="M97,118.5 L21,118.5 C20.5857864,118.5 20.2107864,118.332107 19.9393398,118.06066 C19.6678932,117.789214 19.5,117.414214 19.5,117 L19.5,2.95622719 L24.723725,6.778465 C25.1632671,7.10008113 25.6816335,7.26088919 26.2,7.26088919 C26.7183665,7.26088919 27.2367329,7.10008113 27.676275,6.778465 L34.4,1.85866621 L41.123725,6.778465 C41.5632671,7.10008113 42.0816335,7.26088919 42.6,7.26088919 C43.1183665,7.26088919 43.6367329,7.10008113 44.076275,6.778465 L50.8,1.85866621 L57.523725,6.778465 C57.9632671,7.10008113 58.4816335,7.26088919 59,7.26088919 C59.5183665,7.26088919 60.0367329,7.10008113 60.476275,6.778465 L67.2,1.85866621 L73.923725,6.778465 C74.3632671,7.10008113 74.8816335,7.26088919 75.4,7.26088919 C75.9183665,7.26088919 76.4367329,7.10008113 76.876275,6.778465 L83.6,1.85866621 L90.323725,6.778465 C90.7632671,7.10008113 91.2816335,7.26088919 91.8,7.26088919 C92.3183665,7.26088919 92.8367329,7.10008113 93.276275,6.778465 L98.5,2.95622719 L98.5,117 C98.5,117.414214 98.3321068,117.789214 98.0606602,118.06066 C97.7892136,118.332107 97.4142136,118.5 97,118.5 Z"
                      id="Rectangle"
                      stroke="#960011"
                      stroke-width="3"
                      fill="#FFFFFF"
                    ></path>
                    <rect id="Rectangle" fill="#E2001A" x="25" y="95" width="67" height="18"></rect>
                    <rect id="Rectangle" fill="#960011" x="25" y="104" width="67" height="9"></rect>
                    <g id="Group-2" transform="translate(37.000000, 37.000000)" fill="#A30001">
                      <path
                        d="M1,0 L5,0 L5,0 L5,3 L0,3 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"
                        id="Rectangle-Copy-13"
                      ></path>
                      <path
                        d="M0,41 L5,41 L5,44 L1,44 C0.44771525,44 6.76353751e-17,43.5522847 0,43 L0,41 L0,41 Z"
                        id="Rectangle-Copy-33"
                      ></path>
                      <rect id="Rectangle-Copy-15" x="8" y="0" width="5" height="3"></rect>
                      <rect id="Rectangle-Copy-34" x="8" y="41" width="5" height="3"></rect>
                      <rect id="Rectangle-Copy-24" x="16" y="0" width="5" height="3"></rect>
                      <rect id="Rectangle-Copy-35" x="16" y="41" width="5" height="3"></rect>
                      <rect id="Rectangle-Copy-25" x="31" y="0" width="5" height="3"></rect>
                      <path
                        d="M39,0 L43,0 C43.5522847,-1.01453063e-16 44,0.44771525 44,1 L44,3 L44,3 L39,3 L39,0 Z"
                        id="Rectangle-Copy-32"
                      ></path>
                      <rect id="Rectangle-Copy-36" x="23" y="41" width="5" height="3"></rect>
                      <rect id="Rectangle-Copy-46" x="31" y="41" width="5" height="3"></rect>
                      <rect id="Rectangle-Copy-26" x="23" y="0" width="5" height="3"></rect>
                      <path
                        d="M39,41 L44,41 L44,43 C44,43.5522847 43.5522847,44 43,44 L39,44 L39,44 L39,41 Z"
                        id="Rectangle-Copy-37"
                      ></path>
                      <path
                        d="M41,0 L43,0 C43.5522847,-1.01453063e-16 44,0.44771525 44,1 L44,5 L44,5 L41,5 L41,0 Z"
                        id="Rectangle-Copy-27"
                      ></path>
                      <path
                        d="M1,0 L3,0 L3,0 L3,5 L0,5 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"
                        id="Rectangle-Copy-38"
                      ></path>
                      <rect id="Rectangle-Copy-28" x="41" y="8" width="3" height="5"></rect>
                      <rect id="Rectangle-Copy-39" x="0" y="16" width="3" height="5"></rect>
                      <rect id="Rectangle-Copy-43" x="0" y="8" width="3" height="5"></rect>
                      <rect id="Rectangle-Copy-29" x="41" y="16" width="3" height="5"></rect>
                      <rect id="Rectangle-Copy-40" x="0" y="23" width="3" height="5"></rect>
                      <rect id="Rectangle-Copy-30" x="41" y="23" width="3" height="5"></rect>
                      <rect id="Rectangle-Copy-45" x="41" y="31" width="3" height="5"></rect>
                      <rect id="Rectangle-Copy-41" x="0" y="31" width="3" height="5"></rect>
                      <path
                        d="M41,39 L44,39 L44,43 C44,43.5522847 43.5522847,44 43,44 L41,44 L41,44 L41,39 Z"
                        id="Rectangle-Copy-31"
                      ></path>
                      <path
                        d="M0,39 L3,39 L3,44 L1,44 C0.44771525,44 6.76353751e-17,43.5522847 0,43 L0,39 L0,39 Z"
                        id="Rectangle-Copy-42"
                      ></path>
                    </g>
                    <g id="Logos/Crown-Logo" transform="translate(50.000000, 54.000000)" fill="#EC001A">
                      <g id="Crown">
                        <path
                          d="M3.68208317,8.0805845 C3.5248,8.0805845 3.36878719,8.01355077 3.26730705,7.88693341 C3.09639992,7.67291066 3.14299757,7.36953828 3.3715488,7.20936964 C4.74626218,6.24796206 6.85033642,5.67358139 8.99953544,5.67358139 L8.99971954,5.67358139 C11.1409835,5.67358139 13.2397186,6.24763515 14.6138061,7.20936964 C14.8432962,7.36953828 14.8901516,7.67248052 14.7177716,7.88652047 C14.5478587,8.10056042 14.223056,8.14417708 13.9935842,7.98376756 C13.0657737,7.33493745 11.2666002,6.64152716 9.00008776,6.64152716 L8.99953544,6.64152716 C6.72252889,6.64152716 4.92031755,7.33493745 3.99186269,7.98411167 C3.89940383,8.0493388 3.7905778,8.0805845 3.68208317,8.0805845"
                          id="Fill-28"
                        ></path>
                        <path
                          d="M4.15541472,8.90738607 C4.05338226,8.90738607 3.95164438,8.86598896 3.88116797,8.78656706 C3.75796313,8.64534199 3.77968784,8.43672186 3.93071134,8.32070326 C5.11942077,7.4078332 7.01425666,6.86297764 9.00046518,6.86297764 C10.9767135,6.86297764 12.8661918,7.40757511 14.0550854,8.32061723 C14.2064955,8.43620569 14.2288462,8.64498067 14.1046287,8.78629177 C13.9803008,8.92760287 13.7570703,8.94783687 13.6060468,8.83192151 C12.5391982,8.01344237 10.8177549,7.52479812 9.00046518,7.52479812 C7.20119956,7.52479812 5.43012083,8.02558966 4.38048632,8.83209357 C4.31492558,8.88293666 4.23498604,8.90738607 4.15541472,8.90738607"
                          id="Fill-30"
                        ></path>
                        <path
                          d="M1.38093458,1.94215962 C1.17024179,1.94215962 0.961923993,1.99544593 0.779399673,2.09639227 C0.506809892,2.24708052 0.312336985,2.48875296 0.233041822,2.77576264 C0.155035412,3.0633057 0.199239658,3.36143031 0.361309619,3.61617915 C0.571652605,3.94837121 0.961003455,4.15706016 1.37835708,4.16067338 L1.38645781,4.16112073 C1.59790545,4.16094867 1.80657305,4.10750751 1.98909737,4.00621706 C2.26205537,3.85542557 2.45544204,3.61435533 2.53427693,3.32751771 C2.61346163,3.040422 2.56741631,2.74186724 2.40589867,2.48701517 C2.19389872,2.15077975 1.8004975,1.94215962 1.38093458,1.94215962"
                          id="Fill-32"
                        ></path>
                        <path
                          d="M8.99308614,2.79582974 C8.33777339,2.79582974 7.80439513,3.2936963 7.80439513,3.90517265 C7.80439513,4.51707914 8.33777339,5.01503173 8.99308614,5.01503173 C9.64766247,5.01503173 10.1797704,4.51707914 10.1797704,3.90517265 C10.1797704,3.2936963 9.64766247,2.79582974 8.99308614,2.79582974"
                          id="Fill-34"
                        ></path>
                        <path
                          d="M8.99308614,0.0171954603 C8.33777339,0.0171954603 7.80439513,0.515079225 7.80439513,1.12655557 C7.80439513,1.73846207 8.33777339,2.2362426 8.99308614,2.2362426 C9.64766247,2.2362426 10.1797704,1.73846207 10.1797704,1.12655557 C10.1797704,0.515079225 9.64766247,0.0171954603 8.99308614,0.0171954603"
                          id="Fill-36"
                        ></path>
                        <path
                          d="M5.83440833,3.18240585 C5.72867531,3.18240585 5.62297911,3.19541342 5.52028387,3.22139416 C4.88827914,3.38302529 4.51476155,3.99465649 4.68843029,4.58471164 C4.8276709,5.06046877 5.29640897,5.39620522 5.8288851,5.40102284 L5.85576482,5.40155622 C5.95448334,5.39981844 6.05285205,5.38655278 6.14860644,5.36212057 C6.4541699,5.28416116 6.70851461,5.09904413 6.8661844,4.8416112 C7.02309935,4.58395459 7.06325323,4.2853138 6.97963153,3.99959455 C6.83836574,3.51814231 6.36778659,3.18240585 5.83440833,3.18240585"
                          id="Fill-39"
                        ></path>
                        <path
                          d="M2.89452773,4.33392358 C2.68309851,4.33392358 2.47496481,4.38712386 2.29336103,4.48824225 C2.02003482,4.63867242 1.82593013,4.88025883 1.74689272,5.16737174 C1.66881266,5.45481157 1.71393745,5.75345235 1.87470025,6.00861413 C2.08524575,6.34030723 2.4745966,6.5487725 2.89268665,6.55254057 L2.89968274,6.55288468 C3.1118484,6.55288468 3.31887744,6.49944352 3.50269052,6.39858322 C3.77546441,6.24763688 3.9694034,6.0064634 4.04750186,5.71928167 C4.12668656,5.43244405 4.08100946,5.13406134 3.92004414,4.87879634 C3.70675543,4.54280179 3.31445886,4.33392358 2.89452773,4.33392358"
                          id="Fill-41"
                        ></path>
                        <path
                          d="M12.4643327,3.22139588 C12.3616559,3.19541514 12.2567513,3.18240757 12.1512024,3.18240757 C11.6180083,3.18240757 11.1472634,3.51814403 11.0065315,3.99959627 C10.8337833,4.58946216 11.2064725,5.20049116 11.8379433,5.36212229 C11.9406937,5.38829228 12.0467766,5.40179883 12.1521414,5.40179883 C12.6842309,5.40179883 13.1559147,5.06554619 13.2966281,4.58471336 C13.4686583,3.99465821 13.0958772,3.38302701 12.4643327,3.22139588"
                          id="Fill-43"
                        ></path>
                        <path
                          d="M13.2483735,0.54124234 C13.1458992,0.515158371 13.0417127,0.501806683 12.9352801,0.501806683 C12.401865,0.501806683 11.9320407,0.838076521 11.7907749,1.31899538 C11.6180083,1.9089473 11.9920966,2.52042365 12.6227205,2.68179669 C12.7253053,2.70803551 12.8310199,2.72145602 12.9360165,2.72145602 C13.4686583,2.72145602 13.940158,2.3852206 14.0808715,1.90438776 C14.2539879,1.31416056 13.8801021,0.702873472 13.2483735,0.54124234"
                          id="Fill-45"
                        ></path>
                        <path
                          d="M15.6922589,4.48823881 C15.5093664,4.38712042 15.3007724,4.33392014 15.0894353,4.33392014 C14.6711795,4.33392014 14.2791223,4.54279835 14.0664964,4.8788101 C13.9050708,5.1340579 13.8593937,5.43244061 13.9383022,5.71927823 C14.0164191,6.00647717 14.2105422,6.24763343 14.4831136,6.39857977 C14.6673133,6.49961214 14.8750419,6.55289845 15.087226,6.55289845 C15.5050215,6.55289845 15.8968762,6.34445038 16.1097967,6.00862789 C16.2711302,5.75346612 16.3159972,5.45480812 16.2372544,5.1673683 C16.1582354,4.88027259 15.964278,4.63866898 15.6922589,4.48823881"
                          id="Fill-47"
                        ></path>
                        <path
                          d="M17.7510998,2.7757678 C17.6721912,2.48875812 17.4782522,2.24708568 17.2052942,2.09639743 C17.0222176,1.99545109 16.8141023,1.94216478 16.6026547,1.94216478 C16.1849328,1.94216478 15.7928204,2.15078491 15.580084,2.48686548 C15.4183822,2.74211329 15.3727051,3.04066805 15.4518898,3.32752287 C15.5303565,3.61436049 15.7241298,3.85543073 15.9969773,4.00622222 C16.180146,4.10775355 16.3886479,4.16112589 16.6004638,4.16112589 C17.0190878,4.16112589 17.4112186,3.95250576 17.623642,3.61618431 C17.7849019,3.36143547 17.8302292,3.06331086 17.7510998,2.7757678"
                          id="Fill-49"
                        ></path>
                        <path
                          d="M5.05090879,2.72146118 C5.1566234,2.72146118 5.26233801,2.70793744 5.36400225,2.68178465 C5.99464458,2.52042881 6.36779396,1.90893525 6.19539556,1.31898334 C6.0550503,0.838081683 5.58373473,0.501811845 5.04978573,0.501811845 C4.94425523,0.501811845 4.83835651,0.515163533 4.73632405,0.541247501 C4.10413522,0.702878634 3.73087538,1.31416572 3.90345788,1.90454778 C4.04343493,2.38056299 4.51217299,2.71612739 5.0439127,2.72094501 L5.05090879,2.72146118 Z"
                          id="Fill-51"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <h3 class="multifunction-popin__title rc-title rc-title-epsilon" i18n="@@muf-popin_personalised-bags_desc">
            Order Multifunction for the pet owner
          </h3>
          <p class="multifunction-popin__sub-title" i18n="@@muf-popin_personalised-bags_title">Personalised Bag</p>
        </div>
      </div>
    </div>
  </rc-dialog-content>
</rc-dialog>
