export enum OrderStatusEnum {
  Pending = 'Pending',
  AwaitingApproval = 'Awaiting Approval',
  Validated = 'Validated',
  Personalized = 'Personalized',
  ReadyForWMS = 'Ready For WMS',
  Processed = 'Processed',
  Shipped = 'Shipped',
  AwaitingLoyalty = 'Awaiting B2B Loyalty',
  ReadyForVet = 'Ready for vet invoiced',
  Invoiced = 'Invoiced',
  Closed = 'Closed',
  Refused = 'Refused',
  Canceled = 'Canceled',
}
