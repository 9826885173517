import { Product, RenalDetectRecommendation } from '@app/core/models';
import { AllowanceParams } from '@app/pages/allowance/allowance';
import { PackType } from '@app/shared/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '..';
import { ConsultationState } from './consultation.state';

export const selectConsultation = createFeatureSelector<AppState, ConsultationState>('consultation');

export const selectConsultationPatient = createSelector(selectConsultation, (state: ConsultationState) => state.patient);
export const selectConsultationPetOwner = createSelector(selectConsultation, (state: ConsultationState) => state.petOwner);
export const selectConsultationRenalDetectRecommendation = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.renalDetectRecommendation
);
export const selectConsultationRenalDetectNutritionalChoice = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.renalDetectNutritionalChoice
);
export const selectConsultationRenalDetectPrediction = createSelector(
  selectConsultationRenalDetectRecommendation,
  (recommendation: RenalDetectRecommendation) => recommendation?.prediction
);
export const selectConsultationLastConsultation = createSelector(selectConsultation, (state: ConsultationState) => state.lastConsultation);
export const selectConsultationCurrentConsultation = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.currentConsultation
);

export const selectConsultationDryProduct = createSelector(selectConsultation, (state: ConsultationState) => state.selectedDryProduct);
export const selectConsultationWetProduct = createSelector(selectConsultation, (state: ConsultationState) => state.selectedWetProduct);
export const selectConsultationProducts = createSelector(
  selectConsultationDryProduct,
  selectConsultationWetProduct,
  (dryProduct, wetProduct) => <Product[]>[dryProduct, wetProduct]
      .filter((p) => !!p)
      .map((product) => ({
        ...product,
        packages: product.packages.filter((pack) => pack.type !== PackType.StarterKit),
      }))
);
export const selectConsultationSelectedPackIds = createSelector(selectConsultation, (state: ConsultationState) =>
  [state.selectedDryPackId, state.selectedWetPackId].filter((item) => !!item)
);

export const selectConsultationSelectedDryPackId = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.selectedDryPackId
);

export const selectConsultationSelectedWetPackId = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.selectedWetPackId
);

export const selectConsultationAllowanceParams = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.allowanceParams || ({} as AllowanceParams)
);

export const selectConsultationCreatingConsultation = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.creatingCurrentConsultation
);
