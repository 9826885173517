import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, of, BehaviorSubject, from } from 'rxjs';

import { Helper } from '@app/shared/utils';
import { Logger } from '@app/core/services/utils/logger';
import { OKTA_AUTH } from '@okta/okta-angular';
import { UserClaims, OktaAuth } from '@okta/okta-auth-js';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  public loginWithSessionTokenPending$ = new BehaviorSubject(false);

  CountryCode = CountryCode;
  /**
   * Initializer
   */
  constructor(
    private logger: Logger,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    @Inject(LOCALE_ID) protected localeId: string,
    private router: Router
  ) {
    this.oktaAuth.tokenManager.on('error', (error) => {
      console.error('tokenManager error callback :', error);
      this.logout();
    });
    this.oktaAuth.tokenManager.on('renewed', (key, newToken, oldToken) => {
      console.info('Token with key', key, 'has been renewed');
      console.info('Old token:', oldToken);
      console.info('New token:', newToken);
    });
  }

  login(): void {
    this.oktaAuth.signInWithRedirect({ originalUri: '/home' });
  }

  loginWithSessionToken(sessionToken: string): void {
    this.loginWithSessionTokenPending$.next(true);
    this.oktaAuth.token
      .getWithoutPrompt({ sessionToken })
      .then((res) => {
        this.oktaAuth.tokenManager.setTokens(res.tokens);
        this.router.navigateByUrl('/home');
        this.loginWithSessionTokenPending$.next(false);
      })
      .catch((error) => {
        this.logger.error(error);
        this.router.navigateByUrl('/');
        this.loginWithSessionTokenPending$.next(false);
      });
  }

  register(): void {
    const localValue = Helper.getLocalBrowser();
    if (localValue.countryCode === CountryCode.CA) {
      window.location.href = `https://vet-royalcanin-ca.force.com/portalRequest?l=${localValue.languageCode}`;
    } else {
      window.location.href = `${environment.ciam.app}?redirect_uri=${encodeURIComponent(environment.ciam.redirectCallback)}&client=${
        environment.oktaConfig.clientId
      }`;
    }
  }

  /**
   * @description Logout current user & clean local storage
   */
  logout(): void {
    this.oktaAuth
      .signOut({ postLogoutRedirectUri: window.location.origin + '/logout' })
      .then(() => {
        localStorage.clear();
      })
      .catch((error) => {
        this.logger.errorString(error);
        localStorage.clear();
        window.location.replace('/');
      });
  }

  /**
   * @description Check if the user is logged in or not.
   */
  isAuthenticated(): Observable<boolean> {
    return from(this.oktaAuth.isAuthenticated());
  }

  /**
   * @description Function for access token observable
   * @return token observable
   */
  getToken$(): Observable<string> {
    return this.isAuthenticated().pipe(
      map((isAuthenticated) => {
        const token = this.oktaAuth.getAccessToken();
        if (!token && isAuthenticated) {
          return null;
        }
        return token;
      }),
      catchError((error) => {
        this.logger.error(error);
        return of(null);
      })
    );
  }

  /**
   * Get oktaUser
   */
  getUser$(): Observable<UserClaims> {
    return from(this.oktaAuth.getUser());
  }
}
