import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { ArianeTranslationPipe } from '@app/shared/pipes';
import { AppStoreModule } from '@app/store/app-store.module';
import { environment } from '@env/environment';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocalizationService } from './core/services/utils/localization/localization.service';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as locales from 'date-fns/locale';
/**
 * Change pkce to false for IE in order not to crash the app
 * and display deprecation page
 */
function getOktaConfig() {
  const isIE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
  return {
    ...environment.oktaConfig,
    pkce: !isIE,
  };
}

const oktaAuth = new OktaAuth(getOktaConfig());

@NgModule({
  declarations: [AppComponent, ...routingComponents],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    AppStoreModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    OktaAuthModule,
    SharedModule,
  ],
  providers: [
    /* Pipes */
    ArianeTranslationPipe,

    AppInsightsService,
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    { provide: LOCALE_ID, useValue: LocalizationService.initLanguage },
    {
      provide: MAT_DATE_LOCALE,
      useValue:
        locales[LocalizationService.initLanguage.split('-').join('')] ||
        locales[LocalizationService.initLanguage.split('-')[0]] ||
        locales.enGB,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'dd/MM/yyyy',
        },
        display: {
          dateInput: 'dd/MM/yyyy',
          monthYearLabel: 'LLL y',
          dateA11yLabel: 'MMMM d, y',
          monthYearA11yLabel: 'MMMM y',
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
