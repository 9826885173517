import { Injectable } from '@angular/core';
import { FetchProductDataFilters } from '@app/core/models/fetch-product-data-filters';
import { ProductCatalogFilterValues } from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { getAllProducts, resetProductsData, setProductFilters } from './products.actions';
import { selectCurrentPillar, selectCurrentViewType, selectFilteredProduct, selectSelectedCategory } from './products.selectors';

@Injectable()
export class ProductsFacade {
  selectedCategory$ = this.store$.select(selectSelectedCategory);
  currentViewType$ = this.store$.select(selectCurrentViewType);
  currentPillar$ = this.store$.select(selectCurrentPillar);
  selectFilteredProduct$ = this.store$.select(selectFilteredProduct);

  constructor(private store$: Store<AppState>) {}

  getAllProducts(filterParams: FetchProductDataFilters): void {
    this.store$.dispatch(getAllProducts({ filterParams }));
  }

  resetProductsData(): void {
    this.store$.dispatch(resetProductsData());
  }

  setProductFilters(filtersValues: ProductCatalogFilterValues): void {
    this.store$.dispatch(setProductFilters({ filtersValues }));
  }
}
