import { AllowanceParams } from '@app/pages/allowance/allowance';
import {
  Product,
  RationingApiBody,
  RationingApiBodyProduct,
  NutritionRecommendationRation,
  NutritionRecommendation,
  Consultation,
  Diagnostic,
  RationingApiBodyVisit,
  FollowUpAction,
} from '@app/core/models';
import { ActivityCode, VetPreferences, ProductType, MeasurementCodeType, Programs, ActionCode } from '@app/shared/utils';
import { MeasureHelper } from './measure-helper';
import { getPackFromProductAndSelectedPacks } from './product-helper';
import { translateKey } from './translate';

export function buildRationingApiBody(
  currentProgram: Programs,
  params: AllowanceParams,
  products: Product[],
  selectedPacks: { productId: string; packId: string }[],
  currentRecommendation: NutritionRecommendation | null,
  currentConsultationVisitDate: Date | null,
  lastConsultation: Consultation,
  automaticCalculation: boolean,
  manualRation?: { value: number; productType: ProductType }
): RationingApiBody {
  const { deprecatedPetInfo: petInfo, followUpAction } = params;

  let dryProduct = getRationingApiBodyProduct(products, ProductType.Dry, selectedPacks);
  let wetProduct = getRationingApiBodyProduct(products, ProductType.Wet, selectedPacks);
  let dryQuantityPerDay = undefined;
  let wetQuantityPerDay = undefined;

  if (manualRation?.productType === ProductType.Dry) {
    // get converted dry quantity per day (based on previous recommendation ration unit or default in gram)
    dryQuantityPerDay = getManualDryQuantityPerDay(manualRation.value, currentRecommendation);
    // in case of a mixed feeding (dry + wet) and if the automatic rationing is disabled
    // is the quantity per day for the WET was previously filled (by a first automatic rationing or by the user)
    // then we can send this wet quantity per day to the API to calculate the kCalAllocation resulting from both products
    if (!automaticCalculation && wetProduct && currentRecommendation?.basicWetRation?.quantityPerDay) {
      wetQuantityPerDay = currentRecommendation?.basicWetRation?.quantityPerDay;
    } else if (!automaticCalculation) {
      // else if the quantity per day is not filled, we must call the API as if the wet product does not exist
      wetProduct = undefined;
    }
  } else if (manualRation?.productType === ProductType.Wet) {
    wetQuantityPerDay = manualRation.value;
    // in case of a mixed feeding (dry + wet) and if the automatic rationing is disabled
    // is the quantity per day for the DRY was previously filled (by a first automatic rationing or by the user)
    // then we can send this dry quantity per day to the API to calculate the kCalAllocation resulting from both products
    if (!automaticCalculation && dryProduct && currentRecommendation?.basicDryRation?.quantityPerDay) {
      dryQuantityPerDay = currentRecommendation?.basicDryRation?.quantityPerDay;
    } else if (!automaticCalculation) {
      // else if the quantity per day is not filled, we must call the API as if the dry product does not exist
      dryProduct = undefined;
    }
  }

  return {
    pet: {
      speciesCode: petInfo.speciesCode,
      breedCode: petInfo.breed,
      genderCode: petInfo.gender,
      neutered: petInfo.neutered,
      birthDate: petInfo.birthdate,
      petActivityCode: getActivityRationingFromBcs(petInfo?.bcs),
      weight: {
        measure: petInfo.weight,
        measurementUnitCode: VetPreferences.currentBigMeasurementUnit,
      },
      idealBodyWeight: {
        measure: petInfo.IBW,
        measurementUnitCode: VetPreferences.currentBigMeasurementUnit,
      },
    },
    wetProduct,
    dryProduct,
    dryQuantityPerDay,
    wetQuantityPerDay,
    kCalAllocation:
      (manualRation?.value || manualRation?.value === 0) && automaticCalculation
        ? currentRecommendation?.diagnostic?.recommendedAllocation
        : undefined,
    visit: getRationingApiBodyVisit(lastConsultation, followUpAction, currentProgram, currentConsultationVisitDate),
  };
}

function getActivityRationingFromBcs(bcs: number): ActivityCode {
  if (bcs < 4) {
    return ActivityCode.High;
  } else if (bcs > 5) {
    return ActivityCode.Low;
  }
  return ActivityCode.Moderate;
}

function getRationingApiBodyVisit(
  lastConsultation: Consultation,
  followUpAction: FollowUpAction,
  currentProgram: Programs,
  currentConsultationVisitDate: Date | null
): RationingApiBodyVisit | undefined {
  const withVisit =
    lastConsultation &&
    [Programs.WEIGHT_LOSS, Programs.WEIGHT_STABILISATION_STEP_1, Programs.WEIGHT_STABILISATION_STEP_2].includes(currentProgram) &&
    followUpAction?.actionCode !== ActionCode.restartWL;

  const visit = lastConsultation?.visit;

  return withVisit
    ? {
        lastKcalPerDay: visit?.recommendation?.diagnostic?.kCalAllocation,
        firstVisitDate: visit?.firstVisitDate,
        currentVisitDate: currentConsultationVisitDate || new Date(),
        lastVisitDate: visit?.visitDateTime,
        lastWeight: {
          measure: visit?.weight?.measure,
          measurementUnitCode: visit?.weight?.measureUnit,
        },
        lastProgram: visit?.program,
      }
    : undefined;
}

function getManualDryQuantityPerDay(value: number, currentRecommendation: NutritionRecommendation | null): number | null {
  if (!value) return value;
  const currentRation = currentRecommendation?.basicDryRation || null;

  const convertedWeight = MeasureHelper.convertWeight(
    value,
    VetPreferences.currentSmallMeasurementUnit,
    currentRation?.unit || MeasurementCodeType.Gram
  );
  return convertedWeight?.measure || null;
}

function getRationingApiBodyProduct(
  products: Product[],
  productType: ProductType,
  selectedPacks: { productId: string; packId: string }[]
): RationingApiBodyProduct | undefined {
  const product = products.find((item) => item.productType === productType);
  const pack = getPackFromProductAndSelectedPacks(product, selectedPacks);
  const density = product?.density && parseFloat(product.density);
  return (
    product &&
    pack && {
      energyCategory: product.energyCategory,
      referenceEnergyValue: product.energy,
      density: density || undefined,
      pack: {
        weight: pack.weight,
        measurementUnitCode: pack.measurementUnitCode,
        price: pack.productPrice?.clinicPrice === -1 ? undefined : pack.productPrice?.clinicPrice,
      },
    }
  );
}

export function getBasicDryRation(rations: NutritionRecommendationRation[]): NutritionRecommendationRation | null {
  return rations.find((ration) => ![MeasurementCodeType.Cups].includes(ration.unit)) || null;
}

export function getCupDryRation(rations: NutritionRecommendationRation[]): NutritionRecommendationRation | null {
  return rations.find((ration) => ration.unit === MeasurementCodeType.Cups) || null;
}

export function getBasicWetRation(rations: NutritionRecommendationRation[]): NutritionRecommendationRation | null {
  return rations?.length ? rations[0] : null;
}

export function formatNutritionRecommendation({ diagnostic, dryRation, wetRation }: any = {}): NutritionRecommendation {
  const dryRations = formatNutritionRations(dryRation);
  const wetRations = formatNutritionRations(wetRation);

  return {
    diagnostic: {
      recommendedAllocation: diagnostic?.recommendedAllocation ? Math.round(diagnostic?.recommendedAllocation * 100) / 100 : 0,
      kCalAllocation: diagnostic?.kCalAllocation ? Math.round(diagnostic?.kCalAllocation * 100) / 100 : 0,
    },
    dryRations,
    wetRations,
    basicDryRation: getBasicDryRation(dryRations),
    cupDryRation: getCupDryRation(dryRations),
    basicWetRation: getBasicWetRation(wetRations),
  };
}

export function formatNutritionRations(rations: any[] = []): NutritionRecommendationRation[] {
  return rations.map((item) => ({
    kcalPerDay: item.kcalPerDay || 0,
    quantityPerDay: item.quantityPerDay || 0,
    unit: item.unit,
    nbDaysPerPack: item.nbDaysPerPack || 0,
    costPerDay: item.costPerDay || null,
  }));
}

export function getWMWarning(allowanceParams: AllowanceParams, diagnostic: Diagnostic, previousDiagnostic: Diagnostic): string | null {
  if (allowanceParams?.followUpAction?.targetProgram !== Programs.WEIGHT_LOSS) return null;

  if (diagnostic.kCalAllocation === previousDiagnostic.kCalAllocation) return translateKey('messages_calculator_goal-reached');
  if (diagnostic.kCalAllocation < previousDiagnostic.kCalAllocation) return translateKey('messages_calculator_weight-loss-ok');
  if (diagnostic.kCalAllocation > previousDiagnostic.kCalAllocation) return translateKey('messages_calculator_weight-loss-too-quick');
}
