import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '@app/core/models';
import { ProductType } from '@app/shared/utils';
import { LocaleContentEnum } from '@app/shared/data';
import { IconName } from '@app/shared/utils/icon/icons';

@Component({
  selector: 'app-product-footer',
  templateUrl: './product-footer.component.html',
  styleUrls: ['product-footer.component.scss'],
})
export class ProductFooterComponent {
  ProductType = ProductType;
  LocaleContentEnum = LocaleContentEnum;
  IconNameEnum = IconName;

  @Input() withMobileFilters?: boolean = false;
  @Input() selectedProducts?: Product[];
  @Input() selectedProductType: ProductType = ProductType.Unknown;
  productCartBarIsExpanded = false;

  //depend of starter kit check this rule
  @Input() useCoupleOfDryWet = true;

  @Output() actionEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeProduct: EventEmitter<any> = new EventEmitter<any>();
  @Output() openMoreProductsPopin?: EventEmitter<Product[]> = new EventEmitter<Product[]>();
  @Output() searchAction: EventEmitter<any> = new EventEmitter<any>();

  toggleExpandProductCart() {
    this.productCartBarIsExpanded = !this.productCartBarIsExpanded;
  }

  actionHandler() {
    this.actionEvent.emit();
  }

  removeItem(event: Event, product: Product) {
    this.removeProduct.emit(product);
  }

  onOpenStarterKitPopin() {
    if (!this.useCoupleOfDryWet) {
      this.openMoreProductsPopin.emit(this.selectedProducts);
    }
  }
}
