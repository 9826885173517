<div class="startTour">
  <div class="icon">
    <app-icon [name]="IconNameEnum.tool_sr_active"></app-icon>
  </div>
  <h3 class="title" i18n="@@sr-tutorial_welcometosr">Welcome to Smart Reco</h3>
  <p class="information" i18n="@@sr-tutorial_quicktour">Do you want to take a quick tour with us?</p>
  <div class="rc-button-group">
    <button id="sr-discard-tour" class="rc-button rc-button--primary" type="button" (click)="leave()">
      <span class="rc-button__title rc-button__title--link" i18n="@@sr-tutorial_discard">discard</span>
    </button>
    <span class="rc-button__separator" i18n="@@action_separator">or</span>
    <button id="sr-go-on-tour" class="rc-button rc-button--filled-small" (click)="startTour()">
      <span class="rc-button__title" i18n="@@sr-tutorial_go-on-tour">Go on tour</span>
    </button>
  </div>
  <div class="checkbox">
    <app-checkbox
      id="dontshowthismessage"
      (checked)="dontShowMessage()"
      [label]="'sr-tutorial_dontshowthismessageagain' | translateKey"
      [multiLine]="true"
    ></app-checkbox>
  </div>
</div>
