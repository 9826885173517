import { Consultation } from '@app/core/models';
import { ChartConsultation } from '@app/core/models/chart';

export interface PetState {
  chartConsultationWeights: ChartConsultation[];
  consultationsWithoutVisit: Consultation[];
}

export const initialPetState: PetState = {
  chartConsultationWeights: [],
  consultationsWithoutVisit: [],
};
