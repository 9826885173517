<!-- Catalogue -->
<ng-container *appLet="hasScrolledToMarsFooter$ | async as hasScrolledToMarsFooter">
  <div
    class="page-wrapper"
    [ngClass]="{
      'page-wrapper--withAside': withAside,
      'page-wrapper--withPanel': withPanel,
      'page-wrapper--withPanelOpened': panelOpened,
      'page-wrapper--withFooter': withFooter,
      'page-wrapper--medium': size === contentWrapperSizes.MEDIUM,
      'page-wrapper--large': size === contentWrapperSizes.LARGE,
      'page-wrapper--withMarsFooter': hasScrolledToMarsFooter,
      'page-wrapper--standalone': isStandalone
    }"
  >
    <div *ngIf="withPanel" class="page-wrapper__panel">
      <div class="wrapper">
        <button class="rc-button header" (click)="onToggle()">
          <app-icon class="page-wrapper__toggle-logo" [name]="iconNames.arrow_panel"></app-icon>
          <p
            class="rc-button__title rc-button__title--link label"
            [ngClass]="{
              'label--verticalTopToBottomLanguage': isVerticalTopToBottomLanguage$ | async
            }"
          >
            {{ panelLabel }}
          </p>
        </button>

        <div class="page-wrapper__panel__content">
          <ng-content select="[page-panel-content]"></ng-content>
        </div>
      </div>
    </div>

    <!-- ------------------------------------------------------------------
      Div use for animation,
      _
      The page-wrapper__panel block is fixed so we need a relative width div
      to work for the flex flow and animation width.
    ------------------------------------------------------------------- -->
    <div class="panel-animation__block" *ngIf="withPanel"></div>

    <div class="page-wrapper__content">
      <div class="page-wrapper__content-inner">
        <ng-content select="[page-content]"></ng-content>
      </div>
    </div>
    <!-- </div> -->

    <div
      class="page-wrapper__footer"
      [ngClass]="{
        'page-wrapper__footer--withPanel': withPanel,
        'page-wrapper__footer--withPanelOpened': panelOpened,
        'page-wrapper__footer--withMarsFooter': hasScrolledToMarsFooter,
        'page-wrapper__footer--withPanelAndMarsFooter': hasScrolledToMarsFooter && withPanel,
        'page-wrapper__footer--withPanelOpenedAndMarsFooter': hasScrolledToMarsFooter && panelOpened
      }"
    >
      <ng-content select="[page-footer-content]"></ng-content>
    </div>
  </div>
</ng-container>
