import { Programs } from '@app/shared/utils/enums/programs.enum';
import { getBasicDryRation, getCupDryRation, getBasicWetRation } from '@app/shared/utils/static-helpers/rationing-helper';
import { Consultation, NutritionDataProduct, NutritionRecommendation, Product, Recommendation, Prediction, Vet } from '@app/core/models';
import { ProductType, Tool } from '../enums';
import { getPackFromProductAndSelectedPacks } from './product-helper';
import { formatNutritionRations } from './rationing-helper';
import { VetPreferences } from '..';
import { AllowanceStep } from '@app/pages/allowance/allowance';
import { ConsultationState } from '@app/store/consultation';
import { AllowanceState } from '@app/pages/allowance/store/allowance.state';

export function buildRecommendation(
  products: Product[],
  selectedPacks: { productId: string; packId: string }[],
  nutritionalRecommendation: NutritionRecommendation
): Recommendation {
  return {
    diagnostic: nutritionalRecommendation.diagnostic,
    nutritionData: products
      .filter((product) =>
        product.productType === ProductType.Dry
          ? nutritionalRecommendation.basicDryRation?.quantityPerDay > 0
          : nutritionalRecommendation.basicWetRation?.quantityPerDay > 0
      )
      .map((product) => {
        const pack = getPackFromProductAndSelectedPacks(product, selectedPacks);
        return {
          product: {
            mainItemId: product.mainItemId,
            productType: product.productType,
            energyCategory: product.energyCategory,
            productDetail: {
              id: product.id,
              energy: product.energy,
              details: {
                name: product.name,
                publicUrl: product.image,
              },
              package: pack && {
                sCode: pack.sCode,
                ean: pack.ean,
                type: pack.type,
                text: pack.text,
                weight: pack.weight,
                measurementUnitCode: pack.measurementUnitCode,
                productPrice: pack.productPrice,
              },
            },
          },
          rations: product.productType === ProductType.Dry ? nutritionalRecommendation.dryRations : nutritionalRecommendation.wetRations,
          basicRation:
            product.productType === ProductType.Dry
              ? getBasicDryRation(nutritionalRecommendation.dryRations)
              : getBasicWetRation(nutritionalRecommendation.wetRations),
          cupRation: product.productType === ProductType.Dry ? getCupDryRation(nutritionalRecommendation.dryRations) : null,
        };
      }),
  };
}

// TODO create a ConsultationApiBody once the API has evolved and provides us with a precise interface
export function buildConsultationApiBody(consultation: Partial<Consultation> = {}, isRenewal = false): any {
  const { visit = {} } = consultation;
  const { weight, firstWeight } = visit;
  const weightToCreate = {
    bcs: weight?.bcs,
    measure: weight?.measure,
    measureUnit: weight?.measureUnit,
    weightDate: isRenewal ? new Date() : weight?.weightDate,
    type: weight?.type,
  };
  const firstWeightToCreate = {
    bcs: firstWeight?.bcs,
    measure: firstWeight?.measure,
    measureUnit: firstWeight?.measureUnit,
    weightDate: firstWeight?.weightDate,
    type: firstWeight?.type,
  };
  return {
    // ...consultation,
    date: isRenewal ? new Date() : consultation.date,
    tool: consultation.tool,
    comments: consultation.comments,
    renalDetect: consultation.prediction || undefined,
    pathologies: consultation.pathologies,
    sensitivities: consultation.sensitivities,
    // ASK CHRISTINE
    targetVisitId: isRenewal ? undefined : consultation.targetVisitId,
    visit: {
      // ASK CHRISTINE
      id: isRenewal ? undefined : consultation.id,
      weight: weightToCreate,
      firstWeight: firstWeightToCreate,
      firstVisitDate: visit.firstVisitDate,
      visitDateTime: isRenewal ? new Date() : visit.visitDateTime,
      program: visit.program,
      recommendation: {
        diagnostic: consultation?.visit?.recommendation?.diagnostic,
        nutritionData: consultation?.visit?.recommendation?.nutritionData.map((item) => ({
          product: item.product,
          rations: item.rations,
        })),
      },
    },
  };
}

export function buildConsultationData(
  consultationState: ConsultationState,
  allowanceState: AllowanceState,
  prediction: Prediction | null,
  products: Product[],
  program: Programs,
  vet: Vet
): Consultation {
  const { allowanceParams: params, lastConsultation, currentConsultationVisitDate } = consultationState;
  const { nutritionRecommendation, rationObservation, currentRecommendationPacks, steps } = allowanceState;
  if (!params?.deprecatedPetInfo || !nutritionRecommendation) return;
  const visitDate = currentConsultationVisitDate || new Date();
  const weight =
    (params.deprecatedPetInfo.weight && {
      weightDate: visitDate,
      bcs: params.deprecatedPetInfo.bcs,
      measure: params.deprecatedPetInfo.weight,
      measureUnit: VetPreferences.currentBigMeasurementUnit,
      type: 0,
    }) ||
    null;

  return <Consultation>{
    date: visitDate,
    tool: steps.includes(AllowanceStep.WeightManagement) ? Tool.WeightManagement : params.tool,
    comments: rationObservation,
    vetId: vet?.id,
    visit: {
      /**
       * this visit id is defined by the previous consulation
       */
      id: lastConsultation?.nextVisitId,
      /**
       * If there is already a program started and it matches the current one,
       * we pass the first visit data to the new consultation.
       * It firstVisitDate does not exist in lastConsultation.visit, then it was the first.
       */
      ...(lastConsultation?.visit.program === program
        ? {
            firstVisitDate: lastConsultation.visit.firstVisitDate || lastConsultation.visit.visitDateTime,
            firstWeight: lastConsultation.visit.firstWeight || lastConsultation.visit.weight,
          }
        : /**
           * If the program is different or lastConsultation non existent, this visit becomes the first one
           */
          {
            firstVisitDate: visitDate,
            firstWeight: weight,
          }),
      recommendation: buildRecommendation(products, currentRecommendationPacks, nutritionRecommendation),
      /**
       * API requires to force the program name if we're not in a weight manangement program
       */
      program,
      visitDateTime: visitDate,
      weight,
    },
    pathologies: params.deprecatedPetInfo.pathologies,
    sensitivities: params.deprecatedPetInfo.sensitivities,
    prediction,
    /**
     * id of the target visit, passed from lastConsultation
     */
    targetVisitId: lastConsultation?.targetVisitId,
  };
}

export function buildConsultationDataFromExistingConsultation(
  lastConsultation: Partial<Consultation>,
  products: Product[],
  selectedPackIds: string[]
): Partial<Consultation> {
  return {
    ...lastConsultation,
    visit: {
      ...lastConsultation.visit,
      recommendation: {
        ...lastConsultation.visit.recommendation,
        nutritionData: lastConsultation.visit.recommendation.nutritionData
          .filter((item) => !!products.find((product) => product.mainItemId === item.product.mainItemId))
          .map((item) => {
            const matchingProduct = products.find((product) => product.mainItemId === item.product.mainItemId);
            const selectedPack = matchingProduct.packages.find((pack) => selectedPackIds.includes(pack.sCode));
            return {
              rations: item.rations,
              basicRation: item.basicRation,
              cupRation: item.cupRation,
              product: {
                mainItemId: matchingProduct.mainItemId,
                productType: matchingProduct.productType,
                energyCategory: matchingProduct.energyCategory,
                productDetail: {
                  id: matchingProduct.id,
                  energy: matchingProduct.energy,
                  details: {
                    name: matchingProduct.name,
                    publicUrl: matchingProduct.image,
                  },
                  package: selectedPack && {
                    sCode: selectedPack.sCode,
                    ean: selectedPack.ean,
                    type: selectedPack.type,
                    text: selectedPack.text,
                    weight: selectedPack.weight,
                    measurementUnitCode: selectedPack.measurementUnitCode,
                    productPrice: selectedPack.productPrice,
                  },
                },
              },
            };
          }),
      },
    },
  };
}

export function formatConsultation(consultation: any): Consultation {
  const visit = consultation?.visit;
  const recommendation = visit?.recommendation;

  // TODO recommendation is now correctly formatted, we need to do the same with the rest of consultation
  return {
    ...consultation,
    prediction: consultation?.renalDetect,
    visit: {
      ...(visit || {}),
      recommendation: formatRecommendation(recommendation),
    },
  };
}

export function formatRecommendation(data: any = {}): Recommendation {
  const nutritionData = data?.nutritionData;
  return {
    diagnostic: data?.diagnostic || {
      kCalAllocation: 0,
      recommendedAllocation: 0,
    },
    nutritionData:
      nutritionData && nutritionData.length
        ? nutritionData.map((item) => {
            const product = formatNutritionProduct(item?.product);
            const rations = formatNutritionRations(item?.rations);
            return {
              ...item,
              product,
              rations,
              basicRation: product.productType === ProductType.Dry ? getBasicDryRation(rations) : getBasicWetRation(rations),
              cupRation: product.productType === ProductType.Dry ? getCupDryRation(rations) : null,
            };
          })
        : [],
  };
}

export function formatNutritionProduct(data: any = {}): NutritionDataProduct {
  const productDetail = data?.productDetail;
  const productDetailPackage = productDetail?.package;
  return {
    mainItemId: data.mainItemId || 0,
    productType: data.productType || ProductType.Unknown,
    energyCategory: data.energyCategory || 0,
    productDetail: {
      id: productDetail?.id || '',
      energy: productDetail?.energy || 0,
      details: {
        name: productDetail?.details?.name || '',
        publicUrl: productDetail?.details?.publicUrl || '',
      },
      package: {
        sCode: productDetailPackage?.sCode || '',
        ean: productDetailPackage?.ean || '',
        type: productDetailPackage?.type || '',
        text: productDetailPackage?.text || '',
        weight: productDetailPackage?.weight || 0,
        measurementUnitCode: productDetailPackage?.measurementUnitCode,
        productPrice: {
          clinicPrice: productDetailPackage?.clinicProductPrice,
          price: -1,
        },
      },
    },
  };
}
