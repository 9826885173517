import { Pipe, PipeTransform } from '@angular/core';
import { translateKey } from '../utils/static-helpers/translate';

@Pipe({
  name: 'translateKey',
})
export class TranslatePipe implements PipeTransform {
  transform(value: string, params?: { [key: string]: unknown }): string {
    return translateKey(value, params);
  }
}
