<rc-dialog>
  <rc-dialog-content>
    <rc-dialog-wrapper>
      <div class="rc-dialog-left rc-dialog-left--vertical-align">
        <div class="rc-dialog-left__content">
          <h3 class="rc-title-gamma rc-title-gamma--thin">{{ title }}</h3>
          <p class="rc-paragraph" i18n="@@create-patient_discover-benefits">Discover the benefits:</p>
          <ul class="benefits-list">
            <li class="benefits-list__item">
              <span class="rc-paragraph" i18n="@@create-patient_individual-nutrition-plan">Individual nutritional plan</span>
            </li>
            <li class="benefits-list__item">
              <span class="rc-paragraph" i18n="@@create-patient_calculated-daily-ration">Calculated daily ration</span>
            </li>
            <li class="benefits-list__item">
              <span class="rc-paragraph" i18n="@@create-patient_patient-follow-up">Patient follow up</span>
            </li>
          </ul>
        </div>
      </div>

      <ng-container *appLet="state$ | async as state">
        <ng-container *appLet="scanningMails$ | async as scanningMails">
          <div
            class="rc-dialog-right"
            [ngClass]="{ 'rc-dialog-right--vertical-align': state === CreatePatientStates.Initial || state === CreatePatientStates.Error }"
          >
            <div class="rc-dialog-form">
              <form [formGroup]="form" (submit)="submit()">
                <div class="rc-dialog-form__input">
                  <rc-field
                    id="create-patient-popin-field-email"
                    formControlName="email"
                    [placeholder]="'form-attribute_owner-email' | translateKey"
                    [error]="emailErrorMessage()"
                  >
                  </rc-field>
                  <div *ngIf="scanningMails" class="rc-input--info-message">
                    <span i18n="@@create-patient_looking-for-pet-owner">We are looking for an existing pet owner...</span>
                  </div>
                  <div *ngIf="!scanningMails && state === CreatePatientStates.MailExists" class="rc-input--warning-message">
                    <span i18n="@@create-patient_address-used">This email address is already associated to an account</span>
                  </div>
                  <div *ngIf="!scanningMails && state === CreatePatientStates.Error" class="rc-input--error-message">
                    <span i18n="@@error_general_text">An error occurred</span>
                  </div>
                </div>
                <div *ngIf="state === CreatePatientStates.MailFree" class="rc-dialog-form__input">
                  <rc-field
                    id="create-patient-popin-field-givenName"
                    formControlName="givenName"
                    [placeholder]="'form-attribute_owner-first-name' | translateKey"
                  >
                  </rc-field>
                </div>
                <div *ngIf="state === CreatePatientStates.MailFree" class="rc-dialog-form__input">
                  <rc-field
                    id="create-patient-popin-field-familyName"
                    formControlName="familyName"
                    [placeholder]="'form-attribute_owner-last-name' | translateKey"
                  >
                  </rc-field>
                </div>
                <div
                  *ngIf="state === CreatePatientStates.MailFree"
                  class="field-wrapper phone-wrapper field-wrapper-right"
                  id="create-patient-popin-field-telephone"
                >
                  <app-rc-input-phone
                    formControlName="telephone"
                    [label]="'form-attribute_phone' | translateKey"
                    [placeholder]="'form-attribute_phone' | translateKey"
                    [errorState]="phoneErrorMessage()"
                    [error]="phoneErrorMessage()"
                  >
                  </app-rc-input-phone>
                </div>
                <div class="consent">
                  <app-checkbox
                    formControlName="noEmail"
                    id="pohasnoemail"
                    [label]="'create-patient_petowner-hasno-email-adress' | translateKey"
                    [multiLine]="true"
                  ></app-checkbox>
                  <app-checkbox
                    *ngIf="state !== CreatePatientStates.Initial && state !== CreatePatientStates.Error"
                    id="confirmContent"
                    formControlName="consent"
                    [multiLine]="true"
                    [label]="'create-patient_legal' | translateKey"
                  ></app-checkbox>
                </div>
                <div class="rc-dialog-form__action">
                  <div class="rc-button-group">
                    <button class="rc-button" type="button" app-rc-dialog-close>
                      <span class="rc-button__title--link" i18n="@@action_cancel">Cancel</span>
                    </button>
                    <ng-container *ngIf="state !== CreatePatientStates.Initial && state !== CreatePatientStates.Error">
                      <span class="rc-button__separator" i18n="@@or-key">or</span>
                      <button class="rc-button rc-button--filled-small" id="createPatient" [disabled]="form.invalid" type="submit">
                        <span class="rc-button__title" i18n="@@action_create-profile">Create profile</span>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </rc-dialog-wrapper>
  </rc-dialog-content>
</rc-dialog>
