<div class="segmented-control__wrapper" [class.disabled]="disabled" [class.is-error]="error">
  <div class="segmented-control__label" *ngIf="label">
    {{ label }}
  </div>
  <div class="segmented-control__items">
    <button
      type="button"
      class="segmented-control__item"
      *ngFor="let item of items"
      [disabled]="item.disabled || disabled"
      [class.is-disabled]="item.disabled"
      [class.is-active]="item.value === this.value"
      [class.is-moreThanTwoItem]="items.length > 2"
      id="rc-segmented-control-{{ item.value }}"
      (click)="didSelect($event, item)"
    >
      <ng-container *ngIf="item.icon">
        <app-icon [class]="'segmented-control__item__icon' + ' segmented-control__item__icon-' + item.icon" [name]="item.icon"></app-icon>
      </ng-container>
      <span *ngIf="item.label" class="segmented-control__item__label">{{ item.label }}</span>
    </button>
  </div>
  <span class="segmented-control__error" *ngIf="error">{{ error }}</span>
</div>
