import { Invitation, Vet } from '@app/core/models';
import { Market } from '@app/core/models/market';
import { UserClaims } from '@okta/okta-auth-js';

export interface VetState {
  vet: Vet | undefined;
  oktaUser: UserClaims | undefined;
  market: Market | undefined;
  isVetValid: boolean;
  currentClinicId: string | undefined;
  isLoading: boolean;
  invitation: Invitation | undefined;
}

export const initialVetState: VetState = {
  vet: null,
  oktaUser: undefined,
  market: undefined,
  isVetValid: false,
  currentClinicId: undefined,
  isLoading: false,
  invitation: undefined,
};
