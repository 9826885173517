import { PatientSearchFilters } from '@app/core/models';
import { SpeciesCode } from '@app/shared/utils';
import { SearchChoice } from '@app/shared/utils/enums/search-choice.enum';

/**
 * When searching for patients with pathUrl.patients API it is possible to specify filters
 * Thoses filters are on the front-end right now but they will be moved to the backend
 */

/**
 * Filter used in the home page for the search of patients using the autocomplete input
 */
export function patientsAutocompleteFilter(search: string): PatientSearchFilters {
  return {
    term: search,
  };
}

/**
 * Filter used when searching for patients with the full pet object needed in each item
 * It is used for example in the pet owner detail page
 */
export function patientsFullPetFilter(contactId: string) {
  return {
    include: ['pet'],
    where: {
      contactId,
    },
    term: '',
  };
}

/**
 * Filters used in the pet record page, it also handles pagination
 */
export function patientsPetRecordFilter(
  speciesCode?: SpeciesCode,
  search?: string,
  loadLastConsultation?: boolean,
  searchBy?: SearchChoice
): PatientSearchFilters {
  const paramsFilterApi = {
    term: search && search.length > 0 ? search : '',
  };

  if (speciesCode && speciesCode !== SpeciesCode.Unknown && speciesCode.toString().length > 0) paramsFilterApi['speciesCode'] = speciesCode;
  if (loadLastConsultation) paramsFilterApi['loadLastConsultation'] = loadLastConsultation;
  if (searchBy) paramsFilterApi['searchBy'] = searchBy;

  return paramsFilterApi;
}
