import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { Consultation, Patient, SummaryWeightManagement } from '@app/core/models';
import { ActionCommunicationService, LoaderService, ModalService, PetCommunicationService, VetService } from '@app/core/services';
import { Logger } from '@app/core/services/utils/logger';
import ERRORS from '@app/core/strings/errors.json';
import { ChartData } from 'chart.js';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { UpdateDatePopinComponent, UpdateDatePopinComponentData } from './../../components/update-date-popin/update-date-popin.component';
import { futureDateValidator, minDateValidator, pastDateValidator } from './../../directives';
import {
  EvolutionIndicatorEnum,
  GraphType,
  Helper,
  IconType,
  MeasureHelper,
  Programs,
  VetPreferences,
  VisitsColorEnum,
} from './../../utils';
import { IUpdateDatePopinOutput } from './../update-date-popin/update-date-popin.component';
import { translateKey } from '@app/shared/utils/static-helpers/translate';

@Component({
  selector: 'app-weight-loss-summary',
  templateUrl: './weight-loss-summary.component.html',
  styleUrls: ['./weight-loss-summary.component.scss'],
  providers: [DatePipe],
})
export class WeightLossSummaryComponent implements OnDestroy, AfterViewInit {
  readonly weightTodayColor = '#87888A';
  readonly weightExpectedVisitColor = '#3697C6';
  readonly weightGoalColor = '#47B800';

  IconType = IconType;
  countTabs = 0;
  nextVisitColor = 'blue';
  programTitle = '';
  programDesc = '';
  @Input() lastConsultation?: Consultation;
  @Input() setNextVisitDate?: boolean;
  @Input() patient?: Patient | null;
  /** Tabs **/
  weightsTabs = [
    {
      id: 'today',
      enabled: true,
      short: $localize`:@@tab_last-visit:`,
      long: $localize`:@@tab_last-visit:`,
    },
    {
      id: 'next',
      enabled: true,
      short: $localize`:@@tab_next_visit:`,
      long: $localize`:@@tab_next_visit:`,
    },
    {
      id: 'goal',
      enabled: true,
      short: $localize`:@@tab_goal:`,
      long: $localize`:@@tab_goal:`,
    },
  ];
  weightsTab = 'today';
  chartType: GraphType = GraphType.Bar;
  chartData: ChartData;
  weightDifferencePercentage: string;
  weightDifferenceIndicator: EvolutionIndicatorEnum;
  /** Variables & Properties **/
  private _destroyed$ = new Subject();
  private _data = new BehaviorSubject<SummaryWeightManagement>(null);

  constructor(
    private actionCommunicationService: ActionCommunicationService,
    private petCommunicationService: PetCommunicationService,
    private loaderService: LoaderService,
    private vetService: VetService,
    private modal: ModalService,
    private logger: Logger
  ) {}

  _program: Programs;

  get program(): Programs | null {
    return this._program;
  }

  @Input()
  set program(program: Programs | null) {
    const programTexts = Helper.getProgramTexts(program);
    this._program = program;
    this.programTitle = programTexts.title;
    this.programDesc = programTexts.description;
  }

  get summaryData(): SummaryWeightManagement | null {
    return this._data.getValue();
  }

  @Input()
  set summaryData(data: SummaryWeightManagement | null) {
    if (data) {
      this._data.next(data);
      this.chartData = this._buildChartDataObject(this.summaryData);

      this._initTabs(data);
      this.weightDifferencePercentage = this._getWeightDifferencePercentage(data);
      if (data.goal && data.goal.weight) {
        this.nextVisitColor = VisitsColorEnum.blue;
      } else {
        this.nextVisitColor = VisitsColorEnum.green;
      }
    }
  }

  get weightIndicatorArrowClass(): string {
    const baseClass = 'rc-icon--arrow2';
    if (this.weightDifferenceIndicator === EvolutionIndicatorEnum.up || this.weightDifferenceIndicator === EvolutionIndicatorEnum.down) {
      return `${baseClass}-${this.weightDifferenceIndicator}--${this.nextVisitColor}`;
    }
    return `${baseClass}-${this.weightDifferenceIndicator}-equal equal-indicator`;
  }

  ngAfterViewInit() {
    if (this.setNextVisitDate) {
      this._openModal('init-date');
    }
  }

  selectedTab(tab): void {
    this.weightsTab = tab.id;
  }

  /**
   * Show current visit update modal
   * @param event - Click or touch event
   * &param Id - popin id
   */
  showModalUpdateDateVisit(event: Event, id: string): void {
    event.preventDefault();
    if (id === this.weightsTabs[0].id) {
      this._openModal('current');
    } else if (id === this.weightsTabs[1].id) {
      this._openModal('next');
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _buildGraphData(summaryData: SummaryWeightManagement): number[] {
    const chartData = [summaryData.today.weightValue, summaryData.next.weightValue];
    if (summaryData.goal && summaryData.goal.date && summaryData.goal.weight) {
      chartData.push(summaryData.goal.weightValue);
    }
    return chartData;
  }

  private _buildChartDataObject(summaryData: SummaryWeightManagement): ChartData {
    return {
      datasets: [
        {
          label: MeasureHelper.measureUnitToSuffix(VetPreferences.currentBigMeasurementUnit),
          xAxisID: 'x-axis-0',
          data: this._buildGraphData(summaryData),
          backgroundColor: this._setBackgroundColors(summaryData),
        },
      ],
      labels: this._setChartLabels(summaryData),
    };
  }

  private _setBackgroundColors(summaryData: SummaryWeightManagement) {
    const chartColors = [this.weightTodayColor, this.weightExpectedVisitColor];
    if (summaryData.goal && summaryData.goal.date && summaryData.goal.weight) {
      chartColors.push(this.weightGoalColor);
    } else {
      chartColors[1] = this.weightGoalColor;
    }
    return chartColors;
  }

  private _setChartLabels(summaryData: SummaryWeightManagement): string[] {
    const lastVisitLabel = $localize`:@@wm-summary_last-visit-label:`;
    const nextVisitLabel = $localize`:@@wm-summary_next-visit-label:`;
    const goalLabel = $localize`:@@wm-summary_goal-label:`;
    const labels = [`${lastVisitLabel}\n${summaryData.today.date}`, `${nextVisitLabel}\n${summaryData.next.date}`];
    if (summaryData.goal && summaryData.goal.date && summaryData.goal.weight) {
      labels.push(`${goalLabel}\n${summaryData.goal.date}`);
    }
    return labels;
  }

  private _initTabs(summaryData: SummaryWeightManagement) {
    if (!summaryData.goal || !summaryData.goal.date || !summaryData.goal.weight) {
      this.weightsTabs = this.weightsTabs.slice(0, 2);
    }
    if (!summaryData.next || !summaryData.next.date || !summaryData.next.weight) {
      this.weightsTabs[1].enabled = false;
    }
    if (!summaryData.today || !summaryData.today.date || !summaryData.today.weight) {
      this.weightsTabs[0].enabled = false;
    }

    this.countTabs = this.weightsTabs.length;
  }

  private _openModal(updateType: string) {
    const nextConsultationDate = this.lastConsultation?.nextVisit?.expected?.visitDateTime;
    const todaysConsultation = this.lastConsultation?.date;
    const data: UpdateDatePopinComponentData = {
      id: updateType,
      title: '',
      subtitle: '',
      footerText: '',
      fieldLabel: '',
      value: new Date(),
      validators: [],
    };
    if (updateType === 'current') {
      data.title = $localize`:@@dialog-update-date_title_last:`;
      data.footerText = $localize`:@@dialog-update-date_footer_last:`;
      data.value = todaysConsultation;
      data.maxDate = new Date();
      data.fieldLabel = $localize`:@@rc-input_last-visit-date-label:`;

      data.validators = [Validators.required, pastDateValidator];
    } else if (updateType === 'next') {
      data.title = $localize`:@@dialog-update-date_title_next:`;
      data.value = nextConsultationDate;
      data.footerText = $localize`:@@dialog-update-date_footer_next:`;
      data.fieldLabel = $localize`:@@rc-input_next-visit-date-label:`;

      if (this.lastConsultation) {
        data.minDate = this.lastConsultation.visit.visitDateTime;
        data.validators = [Validators.required, minDateValidator(this.lastConsultation.visit.visitDateTime)];
      }
    } else if (updateType === 'init-date') {
      data.title = $localize`:@@wm_plan_next_visit_pop_in-title:`;
      data.minDate = new Date();
      data.validators = [Validators.required, futureDateValidator];
      data.value = nextConsultationDate;
      data.footerText = translateKey('wm_plan_next_visit_pop_in-text', {
        value: Helper.daysToWeeks(Helper.getNumberOfDays(todaysConsultation, nextConsultationDate)),
      });
      data.subtitle = $localize`:@@wm_plan_next_visit_pop_in-subtitle:`;
      data.fieldLabel = $localize`:@@wm_plan_next_visit_pop_in-date:`;
    }
    this.modal.open(UpdateDatePopinComponent, { data }).afterClosed.subscribe((res: IUpdateDatePopinOutput) => {
      if (res) {
        this._handleVisitPopinResult(res);
      }
    });
  }

  /**
   * Get the visit update popin result
   * And handle updating visit
   */
  private _handleVisitPopinResult(data: IUpdateDatePopinOutput) {
    if (data.value?.original) {
      this.loaderService.startLoader(true);

      const { patient, lastConsultation } = this;
      if (data.id && patient?.id && lastConsultation?.id) {
        const updateData = Helper.buildVisitDataUpdate(data.id, data.value.original);

        this.vetService
          .updateConsultationVisits(updateData, patient.id, lastConsultation.id)
          .pipe(
            filter((a) => !!a),
            take(1),
            takeUntil(this._destroyed$)
          )
          .subscribe(
            (result: Consultation) => {
              // @TODO LAST_CONSULTATION_STORE this is not good, the correct way to implement this would be to have the lastConsultation
              // in the ngrx store and to subscribe to it in the ngOnInit and parse data there
              this.petCommunicationService.updateNextConsultation(result);
              this.loaderService.stopLoader();
            },
            (err) => {
              this.loaderService.stopLoader();
              this.actionCommunicationService.showError(err);
            }
          );
      } else {
        this.loaderService.stopLoader();
        this.logger.errorString(ERRORS.WEIGHT_MANAGMENT.UPDATE_VISIT_DATE_ERROR);
      }
    }
  }

  /**
   * We don't have to calculate this
   * TODO: remove this function when API returns the percentage
   */
  private _getWeightDifferencePercentage(summaryData: SummaryWeightManagement) {
    const lastWeight = summaryData.today.weightValue;
    const expectedNextWeight = summaryData.next.weightValue;

    if (expectedNextWeight > lastWeight) {
      this.weightDifferenceIndicator = EvolutionIndicatorEnum.up;
    } else if (expectedNextWeight < lastWeight) {
      this.weightDifferenceIndicator = EvolutionIndicatorEnum.down;
    } else if (lastWeight === expectedNextWeight) {
      this.weightDifferenceIndicator = EvolutionIndicatorEnum.equal;
    }

    return Math.abs(Helper.getPercentageDifference(lastWeight, expectedNextWeight)).toFixed(2) + '%';
  }
}
