import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]',
})
export class InputNumberDirective implements OnInit, OnChanges, OnDestroy {
  /**
   * Properties
   */
  @Input() public libInputNumber;
  @Input() public allowDecimals = true;
  @Input() public allowSign = false;

  public previousValue = '';

  // --------------------------------------
  //  Regular expressions
  private integerUnsigned = '^[0-9]*$';
  private integerSigned = '^-?[0-9]+$';
  private decimalUnsigned = '^[0-9]+(.[0-9]+)?$';
  private decimalSigned = '^-?[0-9]+(.[0-9]+)?$';

  private validationRegex: string;

  private change;
  private paste;
  private keydown;

  /**
   * Initializer
   * @param hostElement - ElementRef
   */
  constructor(private hostElement: ElementRef, private renderer: Renderer2) {}

  public ngOnInit() {
    if (this.libInputNumber !== false) {
      this.change = this.renderer.listen(this.hostElement.nativeElement, 'change', () => this.onChange());
      this.paste = this.renderer.listen(this.hostElement.nativeElement, 'paste', (e) => this.onPaste(e));
      this.keydown = this.renderer.listen(this.hostElement.nativeElement, 'keydown', (e) => this.onKeyDown(e));
    }
  }

  public ngOnChanges() {
    this.validationRegex =
      !this.allowDecimals && !this.allowSign
        ? this.integerUnsigned
        : !this.allowDecimals && this.allowSign
        ? this.integerSigned
        : this.allowDecimals && !this.allowSign
        ? this.decimalUnsigned
        : this.decimalSigned;
  }

  public ngOnDestroy() {
    return this.change && this.change() && this.paste && this.paste() && this.keydown && this.keydown();
  }

  /**
   * Event handler for host's change event
   */
  public onChange() {
    this.validateValue(this.hostElement.nativeElement.value);
  }

  /**
   * Event handler for host's paste event
   * @param e - Paste event
   */
  public onPaste(e) {
    // get and validate data from clipboard
    const value = e.clipboardData.getData('text/plain');
    this.validateValue(value);
    e.preventDefault();
  }

  /**
   * Event handler for host's keydown event
   * @todo: The function onKeyDown has a cyclomatic complexity of 22 which is higher than the threshold of 20
   * @param e - KeyboardEvent
   */
  /* eslint-disable-next-line complexity */
  public onKeyDown(e: KeyboardEvent) {
    const target = e.target as HTMLInputElement;
    const cursorPosition: number = target.selectionStart;
    const originalValue: string = target.value;
    const key: string = this.getName(e);
    const controlOrCommand = e.ctrlKey === true || e.metaKey === true;
    const signExists = originalValue.includes('-');
    const separatorExists = originalValue.includes('.') || originalValue.includes(',');

    // allowed keys apart from numeric characters
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab'];

    // when decimals are allowed, add
    // decimal separator to allowed codes when
    // its position is not close to the the sign (-. and .-)
    const separatorIsCloseToSign = signExists && cursorPosition <= 1;

    if (this.allowDecimals && !separatorIsCloseToSign && !separatorExists) {
      allowedKeys.push('.');
      allowedKeys.push(',');
    }

    // when minus sign is allowed, add its
    // key to allowed key only when the
    // cursor is in the first position, and
    // first character is different from
    // decimal separator
    const firstCharacterIsSeparator = originalValue.charAt(0) === '.' || originalValue.charAt(0) === ',';
    if (this.allowSign && !signExists && firstCharacterIsSeparator && cursorPosition === 0) {
      allowedKeys.push('-');
    }

    // allow some non-numeric characters
    if (
      allowedKeys.indexOf(key) !== -1 ||
      // Allow: Ctrl+A and Command+A
      (key === 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key === 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key === 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key === 'x' && controlOrCommand)
    ) {
      // let it happen, don't do anything
      return;
    }

    // save value before keydown event
    this.previousValue = originalValue;

    const isNumber = new RegExp(this.validationRegex).test(key);

    if (isNumber) {
      return;
    } else {
      e.preventDefault();
    }
  }

  /**
   * Test whether value is a valid number or not
   * @param value: String
   * @todo rework this method
   */
  public validateValue(value: string): void {
    // when a numbers begins with a decimal separator,
    // fix it adding a zero in the beginning
    const firstCharacter = value.charAt(0);
    if (firstCharacter === '.' || firstCharacter === ',') {
      // value = 0 + value;
    }

    // when a numbers ends with a decimal separator,
    // fix it adding a zero in the end
    const lastCharacter = value.charAt(value.length - 1);
    if (lastCharacter === '.' || lastCharacter === ',') {
      // value = value.slice(0, -1);
    }

    // test number with regular expression, when
    // number is invalid, replace it with a zero
    // const valid: boolean = new RegExp(this.validationRegex).test(value);
    // this.hostElement.nativeElement.value = valid ? value : this.previousValue;
  }

  /**
   * Get key's name
   * @param event: Key event
   */
  public getName(event): string {
    if (event.key) {
      return event.key;
    } else {
      // for old browsers
      if (event.keyCode && String.fromCharCode) {
        switch (event.keyCode) {
          case 8:
            return 'Backspace';
          case 9:
            return 'Tab';
          case 27:
            return 'Escape';
          case 37:
            return 'ArrowLeft';
          case 39:
            return 'ArrowRight';
          case 188:
            return ',';
          case 190:
            return '.';
          case 109:
            return '-'; // minus in numbpad
          case 173:
            return '-'; // minus in alphabet keyboard in firefox
          case 189:
            return '-'; // minus in alphabet keyboard in chrome
          default:
            return String.fromCharCode(event.keyCode);
        }
      }
    }
  }
}
