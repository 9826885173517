import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IconName } from '@app/shared/utils/icon/icons';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss'],
})
export class WarningMessageComponent {
  @Input() description: string;
  @Input() actionLabel?: string;

  @Output() actionClicked = new EventEmitter<MouseEvent>();

  public readonly IconName = IconName;
}
