import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';

import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Logger } from '../utils';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService, private logger: Logger) {}

  canActivate(): Observable<boolean> {
    /**
     * WARNING ! This is a hack, canActivate is used only for pages where we are supposed to *NOT* be logged in to access (landing page)
     */
    return this.authService.isAuthenticated().pipe(
      first(),
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.logger.log('The user is not longer authenticated and will be redirected to the login page');
          return true;
        }
        this.router.navigate(['home']);
        return false;
      })
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      first(),
      map((loggedIn) => {
        if (loggedIn) {
          return true;
        }
        this.router.navigate(['']);
        return false;
      })
    );
  }
}
