import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { RecommendationBodyEmail, SignalRecommendationBodyEmail } from './../../../core/models/body-email';
import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  BreedSize,
  EmailPopinType,
  MeasureHelper,
  ProductType,
  RenalDetectStatusEnum,
  SpeciesCode,
  Tool,
  VetPreferences,
} from '@app/shared/utils';
import {
  Clinic,
  Consultation,
  NutritionData,
  PetInfo,
  PetOwner,
  ProductRecommendation,
  Recommendation,
  SmartRecoApiBody,
  Vet,
  Weight,
} from '@app/core/models';
import { Helper } from './helper';
import { NutritionalChoice, PackType, POLetterFrom, PoLetterType } from '../enums';
import { environment, environment as env } from '@env/environment';
import { FractionCupPipe } from '@app/shared/pipes';
import { TemplateMailInterface, TemplateProduct } from '@app/pages/templates/templates-interface';
import { lifestageTypeTranslationCodes } from '@app/shared/data';
import { QRCodeToDataURLOptions, toDataURL } from 'qrcode';

export function buildRecommendationBodyEmail({
  data: { vet, clinic, petInfo, productRecommendation, currentConsultation, tool, emailPopinType, owner, chronoVetLink },
}: {
  data: {
    vet: Vet;
    clinic: Clinic;
    petInfo: PetInfo;
    productRecommendation: Recommendation;
    currentConsultation: Consultation;
    tool: Tool;
    emailPopinType: EmailPopinType;
    owner: PetOwner;
    chronoVetLink?: string;
  };
}): RecommendationBodyEmail {
  return {
    tool,
    emailPopinType,
    chronoVetLink,
    user: {
      name: `${vet.user?.firstName}${vet.user?.firstName && vet.user?.lastName ? ' ' : ''}${vet.user?.lastName}`,
      clinic_name: clinic?.legalName || '',
      clinic_logo: clinic?.logo.path || null,
      address: clinic?.companyAddress?.address1 || '',
      zip_code: `${clinic?.companyAddress?.zip || ''}`,
      city_name: clinic?.companyAddress?.city || '',
    },
    pet: {
      name: petInfo.name,
    },
    owner: {
      name: owner ? `${owner.givenName} ${owner.familyName}` : null,
    },
    eventData:
      currentConsultation?.id && currentConsultation?.visit?.recommendationId
        ? {
            consultationId: currentConsultation?.id,
            recommendationId: currentConsultation?.visit?.recommendationId,
          }
        : undefined,
    product1: formatProductsForEmail(productRecommendation?.nutritionData)[0],
    product2: formatProductsForEmail(productRecommendation?.nutritionData)[1],
    newPoLetter: true,
  };
}

function formatProductsForEmail(nutritionData: NutritionData[]) {
  return nutritionData.map((item) => ({
    name: item.product?.productDetail?.details?.name,
    url: item.product?.productDetail?.details?.publicUrl,
  }));
}
export function buildSignalRecommendationBodyEmail(
  clinic: Clinic,
  vet: Vet,
  srApiBody: SmartRecoApiBody,
  petInfo: PetInfo,
  message: string,
  tool_flow_id: string,
  product?: ProductRecommendation
): SignalRecommendationBodyEmail {
  return {
    clinic,
    vet,
    input: srApiBody,
    product: product?.product,
    coverage: product?.coverage,
    petInfo: {
      bcs: petInfo.bcs || 0,
      birthdate: petInfo.birthdate || null,
      breed: petInfo.breed || '',
      gender: petInfo.gender || null,
      IBW: petInfo.IBW || 0,
      lifestage: petInfo.lifestage || null,
      name: petInfo.name || '',
      neutered: petInfo.neutered || false,
      speciesCode: petInfo.speciesCode || null,
      weight: petInfo.weight || 0,
      reproductionStatus: petInfo.reproductionStatus || null,
      bigMeasurementUnit: VetPreferences.currentBigMeasurementUnit,
      pathologies: petInfo.pathologies
        ? petInfo.pathologies.map((value: string) => {
            return {
              label: translateKey(`pathology-${value}`),
              value,
            };
          })
        : [],
      sensitivities: petInfo.sensitivities
        ? petInfo.sensitivities.map((value: string) => {
            return {
              label: translateKey(`sensitivity-${value}`),
              value,
            };
          })
        : [],
    },
    message,
    env: environment.envName,
    api_session_id: tool_flow_id,
  };
}

export const generateQrCode = (url: string): Promise<string> => {
  const qrCodeOptions: QRCodeToDataURLOptions = {
    errorCorrectionLevel: 'M',
    width: 58,
    type: 'image/jpeg',
    color: {
      dark: '#000000',
      light: '#FFFFFF',
    },
  };

  return toDataURL(url, qrCodeOptions);
};

export function buildPOLetterData({
  datePipe,
  currencyPipe,
  fractionCupPipe,
  data: {
    vet,
    clinic,
    petInfo,
    productRecommendation,
    currentConsultation,
    idealBodyWeight,
    fleaTreatment_reminderDate,
    deworming_reminderDate,
    vaccination_reminderDate,
    isChronoVetClinic,
    qrCode,
    isRetailPriceActivated,
    chartImage,
    profilePictureLink,
    currency,
  },
}: {
  data: {
    vet: Vet;
    clinic: Clinic;
    petInfo: PetInfo;
    productRecommendation: Recommendation;
    currentConsultation: Consultation;
    fleaTreatment_reminderDate: Date;
    deworming_reminderDate: Date;
    vaccination_reminderDate: Date;
    idealBodyWeight: Weight;
    isChronoVetClinic?: boolean;
    qrCode?: string;
    isRetailPriceActivated: boolean;
    chartImage: string;
    currency: string;
    profilePictureLink: string;
  };
  datePipe: DatePipe;
  currencyPipe: CurrencyPipe;
  fractionCupPipe: FractionCupPipe;
}): TemplateMailInterface {
  const formattedWeight = MeasureHelper.convertWeight(
    currentConsultation?.visit?.weight?.measure || petInfo.weight,
    currentConsultation?.visit?.weight?.measureUnit || VetPreferences.currentBigMeasurementUnit,
    VetPreferences.currentBigMeasurementUnit,
    VetPreferences.currentSystemPreferenceCode
  );
  const formattedCurrentWeight = MeasureHelper.convertWeight(
    currentConsultation?.visit?.weight?.measure || petInfo.weight,
    currentConsultation?.visit?.weight?.measureUnit || VetPreferences.currentBigMeasurementUnit,
    VetPreferences.currentBigMeasurementUnit,
    VetPreferences.currentSystemPreferenceCode
  );
  const formattedIdealWeight = MeasureHelper.convertWeight(
    idealBodyWeight.measure,
    idealBodyWeight.measureUnit,
    VetPreferences.currentBigMeasurementUnit,
    VetPreferences.currentSystemPreferenceCode
  );
  const formattedNextVisitWeight = MeasureHelper.convertWeight(
    currentConsultation.nextVisit?.expected?.weight.measure,
    currentConsultation.nextVisit?.expected?.weight.measureUnit,
    VetPreferences.currentBigMeasurementUnit,
    VetPreferences.currentSystemPreferenceCode
  );
  const lifestageTranslation = translateKey(lifestageTypeTranslationCodes.find((item) => petInfo?.lifestage === item.code).value);
  const pathologies = petInfo?.pathologies?.map((patho) => translateKey(`pathology-${patho}`)) || null;
  const sensitivities = petInfo?.sensitivities?.map((sensitivity) => translateKey(`sensitivity-${sensitivity}`)) || null;
  const getTotalPricePerDayValue = getTotalPricePerDay(productRecommendation?.nutritionData);
  return {
    standaloneType: environment.standaloneType,
    clinic: {
      name: clinic?.legalName,
      address: clinic?.companyAddress?.address1,
      postalCode: clinic?.companyAddress?.zip,
      addressCity: clinic?.companyAddress?.city,
      logoLink: clinic?.logo?.path,
    },
    vet: {
      name: `${vet?.user?.firstName} ${vet?.user?.lastName}`,
      title: vet?.user?.title,
    },
    pet: {
      name: petInfo?.name,
      breed: petInfo?.breedObject?.localName,
      neutred: petInfo?.neutered,
      species: petInfo?.speciesCode,
      isSpeciesCat: petInfo?.speciesCode === SpeciesCode.Cat,
      isSpeciesDog: petInfo?.speciesCode === SpeciesCode.Dog,
      profilePictureLink: profilePictureLink,
      formattedAge: Helper.getPetAgeText(petInfo?.birthdate),
      lifestage: lifestageTranslation.length ? lifestageTranslation : null,
      BCSValue: petInfo?.bcs,
      BCSStatus: Helper.formatBCSText(petInfo?.bcs),
      formattedWeight: MeasureHelper.weightToString(formattedWeight, 2),
      formattedCurrentWeight: MeasureHelper.weightToString(formattedCurrentWeight, 2),
      formattedIdealWeight: MeasureHelper.weightToString(formattedIdealWeight, 2),
      formattedNextVisitWeight: MeasureHelper.weightToString(formattedNextVisitWeight, 2),
      formattedWeightDifference: formatWeightDifference(
        formattedNextVisitWeight.measure,
        formattedIdealWeight?.measure,
        formattedNextVisitWeight?.measureUnit
      ),
      formattedWeightNewVisitDifference: formatWeightDifference(
        formattedCurrentWeight.measure,
        formattedNextVisitWeight.measure,
        formattedCurrentWeight.measureUnit
      ),
    },
    consultation: {
      visitDate:
        datePipe.transform(currentConsultation?.visit?.visitDateTime, 'shortDate') ||
        datePipe.transform(currentConsultation.date, 'shortDate'),
      nextVisitDate: datePipe.transform(currentConsultation.nextVisit?.expected?.visitDateTime, 'shortDate'),
      renalDetectStatus: currentConsultation?.prediction?.status,
      observations: currentConsultation.comments,
      sensitivities,
      pathologies,
      pathologiesOrSensitivities: !!(sensitivities?.length || pathologies?.length),
      chartImage,
      idealVisitDate: datePipe.transform(currentConsultation?.targetVisit?.expected?.weight?.weightDate, 'shortDate'),
      isRenalNotAtRisk: currentConsultation?.prediction?.status === RenalDetectStatusEnum.NotAtRisk,
      isRenalAtRisk: currentConsultation?.prediction?.status === RenalDetectStatusEnum.AtRisk,
      isRenalCKD: currentConsultation?.prediction?.status === RenalDetectStatusEnum.CKD,
      reminders: {
        isNotEmpty: !!(fleaTreatment_reminderDate || deworming_reminderDate || vaccination_reminderDate),
        fleaTreatment: {
          label: translateKey('po-letter_mailOtherRemindersFlea'),
          value: datePipe.transform(fleaTreatment_reminderDate, 'shortDate'),
        },
        deworming: {
          label: translateKey('po-letter_mailOtherRemindersDeworming'),
          value: datePipe.transform(deworming_reminderDate, 'shortDate'),
        },
        vaccination: {
          label: translateKey('po-letter_mailOtherRemindersVaccin'),
          value: datePipe.transform(vaccination_reminderDate, 'shortDate'),
        },
      },
    },
    chronoVetLinkButton: isChronoVetClinic
      ? formatChronoVetLink(productRecommendation?.nutritionData, clinic?.customerId, 'pet_owner_letter', 'buy_button')
      : undefined,
    chronoVetQrCode: qrCode,
    showPrice:
      isRetailPriceActivated &&
      !!(
        productRecommendation?.nutritionData[0]?.product?.productDetail?.package?.productPrice?.clinicPrice > 0 ||
        productRecommendation?.nutritionData[1]?.product?.productDetail?.package?.productPrice?.clinicPrice > 0
      ),
    productsTotalPricePerDay:
      getTotalPricePerDayValue && getTotalPricePerDayValue > 0
        ? translateKey('po-letter_cost_day', {
            cost_value: currencyPipe.transform(getTotalPricePerDayValue, currency),
          })
        : null,
    products: productRecommendation.nutritionData.map((item) => formatNutritionDataPOLetter(item, currency, currencyPipe, fractionCupPipe)),
    translations: {
      neutredStatus: translateKey('po-letter_neutered'),
      nextVisit: translateKey('po-letter_weightlossNextVisit'),
      consultationSummaryTitle: translateKey('po-letter_consultationSummaryTitle'),
      consultationSummaryRenalDetectTitle: translateKey('po-letter_consultationSummaryTitleRenalDetect'),
      consultationPetAge: translateKey('po-letter_consultationPetAge'),
      consultationPetLifeStage: translateKey('po-letter_consultationPetLifestage'),
      consultationPetBCS: translateKey('po-letter_PetBCS'),
      consultationPetBCSValueSuffix: '/9',
      consultationPetWeight: translateKey('po-letter_consultationPetWeight'),
      consultationNotesTitle: translateKey('po-letter_consultationNotesTitle'),
      consultationActivityPlanTitle: translateKey('po-letter_consultationActivityPlanTitle'),
      consultationActivityPlanToy: translateKey('po-letter_consultationActivityRenalDetectToy2'),
      consultationActivityPlanPlay: translateKey('po-letter_consultationActivityPlanPlay'),
      consultationActivityPlanWalk: translateKey('po-letter_consultationActivityPlanWalk'),
      consultationActivityPlanToyValue: translateKey('po-letter_consultationActivityRenalDetectToy1'),
      consultationActivityPlanPlayValue: translateKey('po-letter_consultationActivityPlanPlayTime'),
      consultationActivityPlanWalkValue: translateKey('po-letter_consultationActivityPlanWalkDistance'),
      renaldetectConsultationRecoTitle: translateKey('po-letter_renaldetectConsultationRecoTitle'),
      renaldetectConsultationDescription: translateKey(
        `po-letter_renaldetectStatus${formatRenalDetectStatus(currentConsultation?.prediction?.status)}Reco1`
      ),
      consultationPathologies: translateKey('po-letter_consultationPathologies'),
      consultationPredispositions: translateKey('po-letter_consultationPredispositions'),
      consultationOtherReminders: translateKey('po-letter_mailOtherReminders'),

      renalDetectStatusTitle: translateKey(
        `po-letter_renaldetectStatus${formatRenalDetectStatus(currentConsultation?.prediction?.status)}Title`
      ),
      renalDetectStatusDescription: translateKey(
        `po-letter_renaldetectStatus${formatRenalDetectStatus(currentConsultation?.prediction?.status)}Description`
      ),

      consultationPetWeightCurve: translateKey('po-letter_consultationPetWeightCurve'),
      consultationPetWeightCurveVisits: translateKey('po-letter_consultationPetWeightCurveVisits'),
      consultationPetWeightIdeal: translateKey('po-letter_consultationPetWeightIdeal'),
      consultationPetWeightTarget: translateKey('po-letter_weightlossTarget'),
      consultationPetWeightLegend: translateKey('po-letter_consultationPetWeightLegend'),
      consultationPetWeightCurrent: translateKey('po-letter_weightlossPatientWeight'),
      weightLossTitle: translateKey('po-letter_weightlossTitle'),
      weightLossIdealWeight: translateKey('po-letter_weightlossIBW'),
      weightLossNextWeight: translateKey('po-letter_weightlossNextVisitGoal'),
      weightLossCurrentWeight: translateKey('po-letter_weightlossCurrentWeight'),
      weightLossText: translateKey('po-letter_weightlossText'),
      weightLossLegend:
        petInfo?.speciesCode === SpeciesCode.Cat
          ? translateKey('po-letter_weightlossRecoCat')
          : translateKey('po-letter_weightlossRecoDog'),

      nutriRecoTitle: translateKey('po-letter_nutriRecoTitle'),
      nutriRecoProductCostPerDay: translateKey('rc-table_cost-title'),
      nutriRecoProductRation: translateKey('po-letter_nutriRecoProductRation'),
      nutriRecoProductName: translateKey('po-letter_nutriRecoProductName'),
      nutriRecoProductPack: translateKey('po-letter_nutriRecoPacksize'),
      nutriRecoProductPrice: translateKey('po-letter_nutriRecoPrice'),
      nutriRecoProductCalories: translateKey('po-letter_nutriRecoCalories'),

      feedingAdviceTitle: translateKey('po-letter_feedingAdviceTitle'),
      feedingStep1Title: translateKey('po-letter_feedingAdvice1Subtitle'),
      feedingStep1Description: translateKey('po-letter_feedingAdvice1Text'),
      feedingStep2Title: translateKey('po-letter_feedingAdvice2Subtitle'),
      feedingStep2Description: translateKey('po-letter_feedingAdvice2Text'),
      feedingStep3Title: translateKey('po-letter_feedingAdvice3Subtitle'),
      feedingStep3Description: translateKey('po-letter_feedingAdvice3Text'),
      feedingTransitionTitleCat: translateKey('po-letter_feedingTransitionTitleCat'),
      feedingTransitionTitleDog: translateKey('po-letter_feedingTransitionTitleDog'),
      feedingTransitionStep1: translateKey('po-letter_feedingTransitionStep1'),
      feedingTransitionStep2: translateKey('po-letter_feedingTransitionStep2'),
      feedingTransitionStep3: translateKey('po-letter_feedingTransitionStep3'),
      feedingTransitionStep4: translateKey('po-letter_feedingTransitionStep4'),
      feedingTransitionLegend1: translateKey('po-letter_feedingTransitionLegend1'),
      feedingTransitionLegend2: translateKey('po-letter_feedingTransitionLegend2'),

      /*********
       * Chrono vet
       **********/

      nutriRecoProductBuyOnlineLabel: translateKey('po-letter_integrationBuy'),
      chronoVetSectionTitle: translateKey('po-letter_mailOrderChronovet'),
      chronoVetDescription: translateKey('po-letter_inetgrationBuySentence'),
      chronoVetLegend: translateKey('po-letter_inetgrationBuySentence2'),
      chronoVetBuyExplanation1: translateKey('po-letter_integrationBuyExplanation1'),
      chronoVetBuyExplanation2: translateKey('po-letter_integrationBuyExplanation2'),
      chronoVetBuyExplanation3: translateKey('po-letter_integrationBuyExplanation3'),
      chronoVetBuySentence: translateKey('po-letter_inetgrationBuySentence'),
    },
  };
}

const formatRenalDetectStatus = (status) => {
  return status?.charAt(0).toUpperCase() + status?.substring(1);
};

const formatWeightDifference = (weight1, weight2, unit) => {
  const weightDifference = weight1 && weight2 ? weight1 - weight2 : undefined;
  return `${weightDifference && weightDifference.toFixed(2)} ${unit || VetPreferences.currentBigMeasurementUnit}`;
};
export const formatPetProfilePictureLink = (size: BreedSize, url: string, species: SpeciesCode): string => {
  if (species === SpeciesCode.Dog) {
    if (size === BreedSize.XLarge || size === BreedSize.Large) {
      return `${url}/pictures/profile_${species}_maxi.png?${env.blobPDFTemplateQueryParams}`;
    }
    if (size === BreedSize.Small || size === BreedSize.XSmall) {
      return `${url}/pictures/profile_${species}_small.png?${env.blobPDFTemplateQueryParams}`;
    }
    return `${url}/pictures/profile_${species}_medium.png?${env.blobPDFTemplateQueryParams}`;
  }
  return `${url}/pictures/profile_${species}.png?${env.blobPDFTemplateQueryParams}`;
};

export function formatChronoVetLink(
  nutritionData: NutritionData[],
  customerId: string,
  utm_medium: 'summary_email' | 'pet_owner_letter',
  utmContent: 'qr_code' | 'buy_button'
): string {
  return `https://www.chronovet.fr/?rcpid1=${nutritionData[0]?.product?.productDetail?.package?.ean}&rcpid2=${nutritionData[1]?.product?.productDetail?.package?.ean}&rccid=${customerId}&utm_campaign=order_with_chronovet&utm_source=vet_services&utm_medium=${utm_medium}&utm_content=${utmContent}`;
}

const getPackTypeTranslation = (packType): string => {
  switch (packType) {
    case PackType.SmallCanStr:
      return translateKey('package-type_wet-small-can');

    case PackType.LargeCanStr:
      return translateKey('package-type_wet-large-can');

    case PackType.CanStr:
      return translateKey('package-type_wet-can');

    case PackType.PouchStr:
      return translateKey('po-letter_nutriRecoPouch');

    case PackType.BAG:
      return translateKey('package-type_dry-bag');

    case PackType.Kibbles:
      return translateKey('po-letter_nutriRecoKibbles');

    default:
      return '';
  }
};

const getRationUnit = (product, ration) => {
  return product.productType === ProductType.Wet
    ? getPackTypeTranslation(product?.productDetail?.package?.type)
    : MeasureHelper.measureUnitToSuffix(ration?.unit);
};

function formatNutritionDataPOLetter(
  nutritionData: NutritionData,
  currency: string,
  currencyPipe: CurrencyPipe,
  fractionCupPipe: FractionCupPipe
): TemplateProduct {
  const product = nutritionData.product;
  const ration = nutritionData.basicRation;
  const rationCup = nutritionData.cupRation;
  return {
    name: product?.productDetail?.details.name,
    imageLink: `${product?.productDetail?.details?.publicUrl}?w=250&auto=compress`,
    buyLink: '',
    formattedDailyRation: `${ration?.quantityPerDay} ${getRationUnit(product, ration)}`,
    formattedRationbyCup:
      product.productType === ProductType.Dry
        ? `(${fractionCupPipe.transform(rationCup?.quantityPerDay)} ${translateKey('po-letter_nutriRecoCup')})`
        : '',
    formattedPackSize: product?.productDetail?.package?.text, // '85g'
    formattedRationbyDay: translateKey('po-letter_day_bag', { number_of_days: ration?.nbDaysPerPack }), // '(53 days of food)'
    formattedPrice:
      product?.productDetail?.package?.productPrice?.clinicPrice && product?.productDetail?.package?.productPrice?.clinicPrice > 0
        ? currencyPipe.transform(product?.productDetail?.package?.productPrice?.clinicPrice, currency)
        : '--', // '24,99$'
    formattedCalories: `${Math.floor(ration?.kcalPerDay)} ${translateKey('Kcal')}/${translateKey('day')}`, // '423kCal/g'
  };
}

export function getTotalPricePerDay(nutritionData) {
  return nutritionData[1]?.basicRation?.costPerDay
    ? nutritionData[1]?.basicRation?.costPerDay + nutritionData[0]?.basicRation?.costPerDay
    : nutritionData[0]?.basicRation?.costPerDay;
}
export function getPOLetterTemplateName({
  tool,
  petInfo,
  currentConsultation,
  poLetterFrom,
}: {
  tool: Tool;
  petInfo: PetInfo;
  currentConsultation: Consultation;
  poLetterFrom: POLetterFrom;
}): PoLetterType {
  if (poLetterFrom === POLetterFrom.FlowAllowanceWeightBlock) {
    return PoLetterType.WeightLoss;
  } else {
    switch (tool) {
      case Tool.Rationing:
      case Tool.PersonalizedBag:
        return PoLetterType.DailyAllowance;
      case Tool.SmartReco:
        return PoLetterType.SmartReco;
      case Tool.RenalDetect:
        if (
          petInfo?.pathologies?.length > 0 ||
          currentConsultation?.pathologies?.length > 0 ||
          petInfo?.sensitivities?.length > 0 ||
          currentConsultation?.sensitivities?.length > 0
        ) {
          return PoLetterType.RenalSmartReco;
        } else {
          return PoLetterType.DailyRenalDetect;
        }
      case Tool.WeightManagement:
        return poLetterFrom === POLetterFrom.WeightTab ? PoLetterType.WeightLoss : PoLetterType.DailyAllowance;
      default:
        return PoLetterType.DailyAllowance;
    }
  }
}

export function getObservationMaxLength(
  tool: Tool,
  additionalInformation: { petInfo?: PetInfo; consultation?: Consultation; renalDetectNutritionalChoice?: NutritionalChoice } | null = null
): string {
  const petInfo = additionalInformation?.petInfo;
  const consultation = additionalInformation?.consultation;
  const renalDetectNutritionalChoice = additionalInformation?.renalDetectNutritionalChoice;
  const hasPathologies =
    petInfo?.pathologies?.length > 0 ||
    consultation?.pathologies?.length > 0 ||
    petInfo?.sensitivities?.length > 0 ||
    consultation?.sensitivities?.length > 0;

  switch (tool) {
    case Tool.SmartReco:
      if (!additionalInformation || (additionalInformation && hasPathologies)) {
        return '215';
      }
      return '415';
    case Tool.WeightManagement:
      return '215';
    case Tool.RenalDetect:
      if (
        !additionalInformation ||
        (additionalInformation && hasPathologies) ||
        renalDetectNutritionalChoice === NutritionalChoice.SmartReco
      ) {
        return '120';
      }
      return '204';
    default:
      return '415';
  }
}
