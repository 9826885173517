import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'publicUrl' })
export class PublicUrlPipe implements PipeTransform {
  transform(publicUrl: string, height?: number): any {
    if (!publicUrl || publicUrl === '') {
      return './assets/other/Dry.svg';
    }

    return `${publicUrl}?h=${height || 400}&auto=compress`;
  }
}
