<div [formGroup]="productForm">
  <div class="rc-table product-list">
    <div class="rc-table__row rc-table__row--header">
      <span class="rc-table__cell"></span>
      <span class="rc-table__cell price-cell rc-table__cell--title" i18n="@@rc-table_type-title">Type</span>
      <span class="rc-table__cell price-cell rc-table__cell--title" i18n="@@rc-table_product-title">Product</span>
      <span class="rc-table__cell price-cell rc-table__cell--title" i18n="@@rc-table_details-title">Details</span>
      <span class="rc-table__cell price-cell rc-table__cell--title" i18n="@@rc-table_size-title" *ngIf="displayPackages">Size</span>
      <span
        *ngIf="showRetailPrices$ | async"
        class="rc-table__cell price-cell rc-table__cell--title price-cell"
        i18n="@@rc-table_price-title"
      >
        Price
      </span>
      <span
        *ngIf="showWholesalePrices$ | async"
        class="rc-table__cell price-cell rc-table__cell--title price-cell"
        i18n="@@rc-table_b2b-price"
      >
        Wholesale price
      </span>
    </div>

    <div
      class="rc-table__row"
      *ngFor="let product of products; trackBy: trackByFn"
      [formGroupName]="product.id"
      (click)="didSelectProduct($event, product)"
      [id]="'product-id-' + product.id"
    >
      <div class="rc-table__cell price-cell product__picture">
        <img [src]="product.image | publicUrl" [alt]="product.name || ''" />
      </div>

      <p class="rc-table__cell price-cell product__category">
        {{ product.productType | translationCode: LocaleContentEnum.ProductType }}
      </p>

      <div class="rc-table__cell price-cell product-description" (click)="showDetailPopin($event, product)">
        <p class="product-description__meta">
          {{ product.productType | titlecase }}
        </p>
        <p class="product-description__text">{{ product.name || '--' }}</p>
      </div>

      <div class="rc-table__cell price-cell product__link" (click)="showDetailPopin($event, product)">
        <span class="product__link-long-text rc-link" i18n="@@action_product-detail">Product details</span>
        <span class="product__link-short-text rc-link" i18n="@@action_detail">Details</span>
      </div>

      <div class="rc-table__cell price-cell" *ngIf="displayPackages">
        <app-rc-select formControlName="packId" keyPath="sCode" labelPath="text" [items]="getPackages(product.packages)"> </app-rc-select>
      </div>

      <div class="rc-table__cell price-cell" *ngIf="showRetailPrices$ | async">
        <p class="productPrice">
          {{ (getProductPrice(product) | currency: (clinicCurrency | async)) || '--' }}
        </p>
      </div>
      <div class="rc-table__cell price-cell" *ngIf="showWholesalePrices$ | async">
        <p class="productPrice">
          {{ (getWholesalePrice$(product) | async | currency: (clinicCurrency | async)) || '--' }}
        </p>
      </div>
    </div>
  </div>
</div>
