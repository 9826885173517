<nav>
  <img class="logo" alt="logo" src="./assets/standalone/logo.svg" />

  <div class="navigation" *ngIf="standalonePage$ | async as standalonePage">
    <app-icon
      name="chevron_down"
      class="back-icon"
      [class.disabled]="standalonePage.index === 1"
      (click)="standalonePage.index > 1 && goToStep(standalonePage.index - 1)"
    ></app-icon>
    <div class="information">
      <span class="step-name">{{ standalonePage.key | translateKey }}</span>
      <br />
      <span class="step-count">{{ 'standalone_step' | translateKey }} {{ standalonePage.index }}/3</span>
    </div>
  </div>
</nav>
<app-srs-progress-bar [value]="progressValue$ | async"></app-srs-progress-bar>
