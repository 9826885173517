import { PatientSearchFilters, PatientSearchInterfaces, PetOwnerSearch } from '@app/core/models';
import { createAction, props } from '@ngrx/store';
/**
 * Get patients actions
 */
/**
 * Action to load patients
 */
export const getPatients = createAction('[Patient] Get Patients', props<{ filters: PatientSearchFilters }>());
/**
 * Action dispatched when patients have been found
 */
export const getPatientsSuccess = createAction('[Patient] Get Patients Success', props<{ patients: PatientSearchInterfaces[] }>());

/**
 * Get owners actions
 */
/**
 * Action to load owners
 */
export const getOwners = createAction('[Patient] Get Owners', props<{ contactIds: string[] }>());
/**
 * Action dispatched when owners have been found
 */
export const getOwnersSuccess = createAction('[Patient] Get Owners Success', props<{ owners: PetOwnerSearch[] }>());

export const clearPatientState = createAction('[Patient] Clear');

/**
 * Action dispatched on error
 */
export const errorPatientState = createAction('[Patient] Error', props<{ error: string }>());
