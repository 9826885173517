import { Consultation } from '@app/core/models';
import { createAction, props } from '@ngrx/store';

/**
 * Get consultations actions
 */
/**
 * Action to load consultations with renal information
 */
export const getConsultationsWithoutVisit = createAction('[Pet] Get Consultations Without Visit', props<{ patientId: string }>());
export const getConsultationsWithoutVisitSuccess = createAction(
  '[Pet] Get Consultations Without Visit Success',
  props<{ consultations: Consultation[] }>()
);

export const resetPet = createAction('[Pet] Reset');
