import { ChangeDetectorRef, Component, Input } from '@angular/core';

import { OverlayComponent } from '@app/core/components';
import { DomService } from '@app/core/services';

@Component({
  selector: 'app-rc-dropdown',
  templateUrl: './rc-dropdown.component.html',
  styleUrls: ['./rc-dropdown.component.scss'],
})
export class RcDropdownComponent {
  @Input() htmlId?: string;
  @Input() toggleMode: 'hover' | 'click' = 'click';

  dropDownOpen = false;
  private _config = {
    backgroundColor: 'transparent',
    zIndex: '0',
    hasDismiss: true,
  };

  constructor(private domService: DomService, private cdr: ChangeDetectorRef) {}

  toggleDropdown(): void {
    if (this.toggleMode === 'hover') return;

    this.dropDownOpen ? this.hideDropdown() : this.openDropdown();
  }

  private openDropdown(): void {
    this.dropDownOpen = true;
    this.domService.appendComponent(OverlayComponent, 'l-main', {
      data: this._config,
    });

    setTimeout(() => {
      const overlay = document.querySelector('.overlay');
      overlay?.addEventListener('click', () => {
        this.hideDropdown();
        this.cdr.detectChanges();
      });
    }, 5);
  }

  private hideDropdown(): void {
    const overlay = document.querySelector('.overlay');
    this.dropDownOpen = false;

    overlay?.removeEventListener('click', this.hideDropdown, false);
    this.domService.removeComponent();
  }
}
