import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { ChildActivationStart, NavigationEnd, Params, Router } from '@angular/router';
import { AuthService, ScriptService } from '@app/core/services';
import { Helper, Constants } from '@app/shared/utils';
import { IconName } from '../app/shared/utils/icon/icons/index';
import { environment } from '@env/environment';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import version from '../../package.json';
import { LocalizationService } from './core/services/utils/localization/localization.service';
import { CountryCode } from './shared/utils/enums/country-code.enum';
import { Env } from './shared/utils/enums/environment.enum';
import { StandaloneService } from './standalone/standalone.service';
import { CoreFacade } from './store/core/core.facade';
import { VetFacade } from './store/vet';
import { AlertNotification } from '@app/core/models';

const countriesWithFooter = [CountryCode.FR];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterContentInit {
  isIE = Helper.isInternetExplorer();
  isBrowserDeprecated = Helper.isBrowserDeprecated();
  version = version;
  iconName = IconName;
  isStandalone = environment.isStandalone;

  showLoader$ = this.coreFacade.showLoader$;
  loaderTransparent$ = this.coreFacade.loaderTransparent$;
  showProgressBar$ = this.coreFacade.showProgressBar$;
  alerts$ = this.coreFacade.alerts$.pipe(distinctUntilChanged((prev, curr) => prev.length === curr.length));
  showFooter$ = this.vetFacade.clinic$.pipe(
    map((clinic) => !this.isStandalone && countriesWithFooter.some((country) => clinic?.companyAddress?.country === country))
  );
  srsTermUrl$ = this.vetFacade.currentClinicCountry$.pipe(
    filter((country) => !!country),
    map((country) => `https://www.royalcanin.com/${country.toLowerCase()}/about-us/smart-reco`)
  );

  constructor(
    private router: Router,
    private scriptService: ScriptService,
    private _elementRef: ElementRef,
    private vetFacade: VetFacade,
    private coreFacade: CoreFacade,
    private authService: AuthService,
    private standaloneService: StandaloneService
  ) {}

  ngOnInit() {
    if (this.isIE || this.isBrowserDeprecated) {
      this.router.navigate(['browser-deprecated']).then();
    }
    this._removeAngularVersion();
    this._scrollToTop();
    this.scriptService.initDocumentLang();
    this.handleInitialQueryParams();
    this.coreFacade.loadLocalization();
  }

  /**
   * Load external scripts
   */
  ngAfterContentInit() {
    this.scriptService.loadOneTrustScript();
    this.scriptService.loadMarsFooter();
    if (LocalizationService.isPhraseSdkEnabled) {
      this.scriptService.loadPhraseAppEditor();
    }
    if (environment.envName === Env.STG) {
      this.scriptService.loadZESnippet();
    }
  }

  backToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  close(alert: AlertNotification): void {
    this.coreFacade.removeAlert(alert);
  }

  /**
   * If invitation is provided via query params, we save it to the store
   */
  private saveInvitation = (params: Params) => {
    const id = params.invitation;
    const email = params.email;
    const clinicId = params.clinic_id;
    id && email && clinicId && this.vetFacade.setInvitation({ id, email, clinicId });
  };

  /**
   * If SRS info is provided we save it here to the localstorage
   * We cannot instantiate RouterFlowData here because user is first redirected to okta
   * However we'll be able to save it to persistent store after SR migration
   */
  private saveSRSInfo = (params: Params) => {
    !!params.srs && (this.standaloneService.data = params.srs);
  };

  /**
   * If internal redirect info is passes, we persist it in the session storage
   * in order to start a flow once user is logged in
   */
  private saveInternalRedirectInfo = (params: Params) => {
    !!params.startFlow && sessionStorage.setItem(Constants.INTERNAL_REDIRECT_KEY, params.startFlow);
  };

  /**
   * If token or redirectToLogin is passed, we login with it
   * Happens after register and in SRS
   */
  private useAutoLogin = (params: Params) => {
    const token = params.token;
    const redirectToLogin = params.redirectToLogin;
    if (token) {
      this.coreFacade.toggleLoader(true);
      this.authService.loginWithSessionToken(token);
    } else if (redirectToLogin === 'true') {
      this.coreFacade.toggleLoader(true);
      this.authService.login();
    }
  };

  /**
   * Get initial query params and dispatch actions if needed
   */
  private handleInitialQueryParams() {
    this.router.events
      .pipe(
        filter((e) => e instanceof ChildActivationStart),
        take(1),
        map((e: ChildActivationStart) => e.snapshot.queryParams),
        tap(this.saveInvitation),
        tap(this.saveSRSInfo),
        tap(this.saveInternalRedirectInfo),
        tap(this.useAutoLogin)
      )
      .subscribe();
  }

  /**
   * Remove ng-version from the DOM
   */
  private _removeAngularVersion() {
    this._elementRef.nativeElement.removeAttribute('ng-version');
  }

  /**
   * Scroll to the top
   */
  private _scrollToTop() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
