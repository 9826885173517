import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Dialog } from '@app/core/cdk/dialog';
import { AlertNotification, Consultation, Patient, PatientSearchAutocomplete } from '@app/core/models';
import { VetService } from '@app/core/services';
import { GtmEventsService } from '@app/core/services/tracking';
import { patientsAutocompleteFilter } from '@app/core/services/utils/search-filters-helper';
import { ActiveProgramPopinComponent } from '@app/shared/components/active-program-popin/active-program-popin.component';
import { SpeciesCode } from '@app/shared/utils';
import { Tool } from '@app/shared/utils/enums/tool-type.enum';
import { IconName } from '@app/shared/utils/icon/icons';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { PatientFacade } from '@app/store/patient';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { RCAlertType } from '@rc/ui';
import { getMUFAvailabilityError, getRDAvailabilityError, getWLPAvailabilityError } from '@app/shared/utils/static-helpers/patient-helper';

@Component({
  selector: 'app-pet-identification',
  styleUrls: ['pet-identification.component.scss'],
  templateUrl: './pet-identification.component.html',
})
export class PetIdentificationComponent implements OnInit, OnDestroy {
  @Input() tool: Tool;
  @Output() consultationInfosUpdated = new EventEmitter();
  @Output() startWithNewPet = new EventEmitter();
  @Output() alertMessage = new EventEmitter<AlertNotification>();
  @Output() closeMessages = new EventEmitter<undefined>();

  title = '';
  description = '';
  imgSrc = '';
  patients$: Observable<PatientSearchAutocomplete[]> = this.patientFacade.selectPatientsAutocomplete$;
  search$ = new BehaviorSubject<string>('');

  showEmptyResult$: Observable<boolean> = combineLatest([this.patients$, this.patientFacade.patientsLoaded$]).pipe(
    map(([patients, loaded]) => loaded && patients.length === 0 && this.search$.value.length > 0)
  );
  public itemSize = 5;
  IconNameEnum = IconName;
  SpeciesCode = SpeciesCode;

  private _destroyed$ = new Subject();

  constructor(
    private patientFacade: PatientFacade,
    private vetService: VetService,
    private dialog: Dialog,
    private gtmEventsService: GtmEventsService
  ) {}

  ngOnInit(): void {
    const specificToolInfo: {
      title: string;
      description: string;
      imgSrc: string;
    } = {
      [Tool.SmartReco]: {
        title: translateKey('home-page_product-finder-title'),
        description: translateKey('home-page_product-finder-description'),
        imgSrc: './assets/other/smart_reco_dog.png',
      },
      [Tool.WeightManagement]: {
        title: translateKey('home-page_weight-title'),
        description: translateKey('home-page_weight-description'),
        imgSrc: './assets/other/weight_management_dog.png',
      },
      [Tool.RenalDetect]: {
        title: `${translateKey('home-page_renal-detect-title')} ${translateKey('renal-detect_older_7')}`,
        description: translateKey('home-page_renal-detect-description'),
        imgSrc: './assets/other/renal_detect_cat.png',
      },
      [Tool.PersonalizedBag]: {
        title: translateKey('home-page_multi-title'),
        description: translateKey('home-page_multi-description'),
        imgSrc: './assets/other/multifunction_dog.png',
      },
      [Tool.Rationing]: {
        title: translateKey('home-page_daily-title'),
        description: translateKey('home-page_daily-description'),
        imgSrc: './assets/other/daily_allowance_cat.png',
      },
    }[this.tool];
    this.title = specificToolInfo?.title;
    this.description = specificToolInfo?.description;
    this.imgSrc = specificToolInfo?.imgSrc;

    this._setupSearch();
  }

  ngOnDestroy(): void {
    this.patientFacade.clearState();
    this.search$.unsubscribe();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onNewSearch(searchStr?: string): void {
    this.search$.next(searchStr);
  }

  clearSearch() {
    this.search$.next('');
  }

  customSearchFilter() {
    return true;
  }

  /**
   * When a patient is selected, fetch the pet and last consultation information using two APIs
   * Then call a third API to calculate lifestage of pet (needed for decision making on next step)
   * Then use this data to check what is possible for this pet and eventually trigger new consultation
   * TODO the APIF will provide a unique endpoint to gather all needed data, right now it is messy and not performant
   */
  didSelectSearch(item: PatientSearchAutocomplete): void {
    if (item.value?.id) {
      this.vetService
        .fetchPatientInformationForNewConsultation(item.value.id)
        .pipe(take(1))
        .subscribe(
          ({ patient, lastConsultation }) => {
            this.handleConsultationLaunch(patient, lastConsultation);
          },
          (err) => {
            this.alertMessage.emit({ message: err, alertType: RCAlertType.ERROR });
          }
        );
    }
  }

  getAvailabilityError(patient: Patient): string | null {
    switch (this.tool) {
      case Tool.WeightManagement:
        return getWLPAvailabilityError(patient?.pet?.lifeStage);
      case Tool.PersonalizedBag:
        return getMUFAvailabilityError(patient?.pet?.lifeStage);
      case Tool.RenalDetect:
        return getRDAvailabilityError(patient?.pet?.speciesCode, patient?.pet?.birth?.date);
      default:
        break;
    }
  }

  createNewPet(): void {
    this.startWithNewPet.emit();
    this.closeMessages.emit();
    this.gtmEventsService.sendStartConsultation({
      patient: null,
      tool: this.tool,
    });
  }

  /**
   * If the consultation is not be possible for this pet, show error message (getAvailabilityError)
   * If patient selected is in a WLP and the vet is trying to create a different consultation, show popin
   * Else start consultation
   */
  handleConsultationLaunch(patient: Patient, lastConsultation: Consultation): void {
    const error = this.getAvailabilityError(patient);
    if (error) {
      this.alertMessage.emit({ message: error, alertType: RCAlertType.ERROR });
    } else if (this.tool !== Tool.WeightManagement && lastConsultation?.tool === Tool.WeightManagement) {
      this.dialog
        .open(ActiveProgramPopinComponent, {
          panelClass: 'small',
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.startConsultation(patient, lastConsultation);
        });
    } else {
      this.startConsultation(patient, lastConsultation);
    }
  }

  /**
   * consultationInfosUpdated indicates to the container component that he must refresh its local data
   */
  startConsultation(patient: Patient, lastConsultation: Consultation): void {
    this.consultationInfosUpdated.emit({ patient, lastConsultation });
    this.closeMessages.emit();

    this.gtmEventsService.sendStartConsultation({
      patient: patient,
      tool: this.tool,
      program: lastConsultation?.visit?.program,
    });
  }

  /**
   * Fetch patients list for autocomplete option values
   */
  private _setupSearch(): void {
    this.search$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((search) => {
          if (!search || search.length === 0) {
            this.patientFacade.clearState();
          } else {
            this.patientFacade.loadPatients(patientsAutocompleteFilter(search));
          }
        })
      )
      .subscribe();
  }
}
