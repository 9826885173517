<div class="rc-field">
  <ng-container *ngIf="fixedLabel; else dynamic">
    <label class="rc-field__fixed-label" [class.error-state]="inputError"
      ><span *ngIf="isLabelDisplayed">{{ fixedLabel }}</span></label
    >
    <span
      class="rc-field__placeholder rc-field__placeholder-fixed"
      *ngIf="!value"
      [class.rc-field__placeholder--focus]="isFocused"
      [class.error-state]="inputError"
      >{{ placeholder }}</span
    >
  </ng-container>
  <ng-template #dynamic>
    <!-- Placeholder {{label}} {{placeholder}}-->
    <span
      class="rc-field__placeholder"
      [class.rc-field__placeholder--active]="compositionStarted || value"
      [class.rc-field__placeholder--focus]="isFocused"
      [class.error-state]="inputError"
      [class.rc-field__placeholder--disabled]="disabled"
    >
      <ng-container *ngIf="(value && label) || !placeholder; else placeholderSpan">
        {{ label }}
      </ng-container>
      <ng-template #placeholderSpan>
        {{ placeholder }}
      </ng-template>
      <span *ngIf="!required" i18n="@@form-attribute_optional">Optional</span>
    </span>
  </ng-template>

  <!-- Input -->
  <div *ngIf="type !== InputType.Number; else number">
    <input
      [type]="type"
      [minlength]="minlength"
      [maxlength]="maxlength"
      [pattern]="pattern"
      [autocomplete]="autocomplete"
      [required]="required"
      class="rc-field__input"
      [class.rc-field__input--active]="(isFocused && !inputError) || (value && !inputError)"
      [class.error-bottom]="inputError"
      [class.success-state]="successState"
      [class.rc-field__input--disabled]="disabled"
      [name]="name"
      [(ngModel)]="value"
      [disabled]="disabled"
      [readonly]="readonly"
      (ngModelChange)="updateChanges()"
      (focus)="onFocus()"
      (focusout)="onFocusOut()"
      (compositionstart)="setCompositionStarted(true)"
      (compositionend)="setCompositionStarted(false)"
    />
  </div>
  <ng-template #number>
    <input
      [type]="type"
      [minlength]="minlength"
      [maxlength]="maxlength"
      [pattern]="pattern"
      [min]="min"
      [max]="max"
      [autocomplete]="autocomplete"
      appNumberOnly
      [step]="step"
      [required]="required"
      class="rc-field__input"
      [class.rc-field__input--active]="(isFocused && !inputError) || (value && !inputError)"
      [class.error-bottom]="inputError"
      [class.success-state]="successState"
      [class.rc-field__input--disabled]="disabled"
      [class.rc-field__fixed-label-input]="fixedLabel && isLabelDisplayed"
      [name]="name"
      [(ngModel)]="value"
      [disabled]="disabled"
      [readonly]="readonly"
      (ngModelChange)="updateChanges()"
      (focus)="onFocus()"
      (focusout)="onFocusOut()"
    />
    <div class="rc-field__suffix" *ngIf="suffix && !inputError">
      {{ suffix }}
    </div>
  </ng-template>
</div>
