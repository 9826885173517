import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  private appInsights: ApplicationInsights;

  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
      },
    });
    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    this.buildRouterSubscription();
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  /**
   * Method to manually track events, error, if needed for a more custom experience
   */

  /**
   * Method to manually track the pageView
   * @param name of the page
   * @param url of the page
   */
  monitorPage(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  /**
   * Method to manually track an Event
   * @param name of the event
   * @param properties additional properties regarding the event
   */
  monitorEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  /**
   * Method to manually track an Metric
   * @param name of the metric
   * @param average value to send
   * @param properties additional properties regarding the metric
   */
  monitorMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  /**
   * Method to manually track an Exception
   * @param exception error
   * @param severityLevel error
   */
  monitorException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  /**
   * Method to manually track an application Trace
   * @param message log
   * @param properties additional properties regarding the trace
   */
  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer((envelope) => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      envelope.tags['ai.cloud.role'] = environment.appInsights.role;
      envelope.tags['ai.cloud.roleInstance'] = environment.appInsights.roleInstance;
      item.properties['ApplicationPlatform'] = 'WEB';
      item.properties['ApplicationName'] = 'My.Application.Name';
    });
  }

  private buildRouterSubscription() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.monitorPage(null, event.urlAfterRedirects);
    });
  }
}
