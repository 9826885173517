<div class="rc-popin rc-popin--{{ size }}">
  <!-- Popin Close action -->
  <div class="rc-popin__header" [ngClass]="isCreatePatientContent ? 'rc-popin__header--patient' : ''">
    <div class="rc-popin__close" (click)="dismiss()" id="close-popin">
      <em class="rc-icon rc-icon--close"></em>
    </div>
  </div>

  <div class="rc-popin__alert">
    <ng-content select="[popin_alert-content]"></ng-content>
  </div>
  <div class="rc-popin__title">
    <ng-content select="[popin_title-content]"></ng-content>
  </div>

  <!-- Popin Content -->
  <div class="rc-popin__content" [ngClass]="isCreatePatientContent ? 'rc-popin__content--patient' : ''">
    <!-- Popin with 1 column -->
    <ng-container *ngIf="isSingleColumn; else twoColumn">
      <ng-content></ng-content>
    </ng-container>

    <!-- Popin with 2 column -->
    <ng-template #twoColumn>
      <div class="rc-popin__left-content vertical-seperator-left">
        <ng-content select="[popin_left-content]"></ng-content>
      </div>

      <ng-container *ngIf="isSeparatorDisplayed">
        <div class="vertical-seperator"></div>
      </ng-container>

      <div class="rc-popin__right-content">
        <ng-content select="[popin_right-content]"></ng-content>
      </div>
    </ng-template>
  </div>

  <!-- Popin Footer -->
  <div class="rc-popin__footer">
    <ng-content select="[popin_footer-content]"></ng-content>
  </div>
</div>
