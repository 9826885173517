import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopinRecommendationData } from '@app/core/models/popin-recommendation-data';
import { ApiService, ChartService, EmailService, POLetterService } from '@app/core/services';
import { GTMService, mapToolToModule, openQRCode, print } from '@app/core/services/tracking';
import { FractionCupPipe } from '@app/shared/pipes';
import { ChartHelper, EmailPopinType, POLetterFrom, Tool } from '@app/shared/utils';
import {
  buildPOLetterData,
  buildRecommendationBodyEmail,
  formatChronoVetLink,
  formatPetProfilePictureLink,
  generateQrCode,
  getObservationMaxLength,
  getPOLetterTemplateName,
} from '@app/shared/utils/static-helpers/emails-helper';
import { getEmailErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { VetFacade } from '@app/store/vet';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { filter, first, switchMap, take, tap } from 'rxjs/operators';
import {
  SharePopinState,
  ShareRecommendationFormValuesFirstStep,
  ShareRecommendationFormValuesSecondStep,
} from './share-recommendation-popin';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { setConsultationPetOwner } from '@app/store/consultation/consultation.actions';

@Component({
  selector: 'app-share-recommendation-popin',
  templateUrl: './share-recommendation-popin.component.html',
  styleUrls: ['./share-recommendation-popin.component.scss'],
})
export class ShareRecommendationPopinComponent implements OnInit {
  @ViewChild('myCanvas', { static: false })
  myCanvas: ElementRef<HTMLDivElement>;
  base64ChartImage = new BehaviorSubject<string>('');
  pdf = '';
  poLetterQrCode$: Observable<string> = of('');
  canShareRecoByEmail$ = this.vetFacade.canShareRecoByEmail$;
  SharePopinState = SharePopinState;
  notesPlaceHolder = translateKey('share-reco-setting_type_notes');
  sharePopinState$ = new BehaviorSubject<SharePopinState>(SharePopinState.FIRST_STEP);
  formFirstStep: IFormGroup<ShareRecommendationFormValuesFirstStep>;
  formSecondStep: IFormGroup<ShareRecommendationFormValuesSecondStep>;
  ctx: CanvasRenderingContext2D;
  EmailPopinType = EmailPopinType;
  isChronoVetClinic$ = this.vetFacade.isChronoVetClinic$;
  currentTool = this.data.tool;
  Tool = Tool;
  showQRCode = false;
  chronovetCheckboxLabel = `${translateKey('share-reco-setting_chronovet')} (${translateKey('share-reco-setting_chronovet_add_link')})`;
  textareaMaxLength = getObservationMaxLength(this.data.tool, { petInfo: this.data.petInfo, consultation: this.data.currentConsultation });

  constructor(
    public config: MatDialogConfig,
    private chartService: ChartService,
    private vetFacade: VetFacade,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<ShareRecommendationPopinComponent>,
    private currencyPipe: CurrencyPipe,
    private fractionCupPipe: FractionCupPipe,
    private poLetterService: POLetterService,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: PopinRecommendationData,
    private changeDetector: ChangeDetectorRef,
    private emailService: EmailService,
    public router: Router,
    private trackingService: GTMService,
    private store$: Store<AppState>
  ) {}

  ngOnInit(): void {
    this._createForm();
    this.getChartBase64();
  }

  renderPdf(pdfBase64: string): void {
    const pdfData = atob(pdfBase64);
    GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const loadingTask = getDocument({ data: pdfData });
    const scale = 1;
    loadingTask.promise.then((pdf) => {
      const renderPage = (pageNumber, canvas) => {
        pdf.getPage(pageNumber).then((page) => {
          const viewport = page.getViewport({ scale: scale });
          if (canvas) {
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const context = canvas.getContext('2d');

            // Render PDF page into canvas context
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            page.render(renderContext);
          }
        });
      };

      for (let page = 1; page <= pdf.numPages; page++) {
        const canvas = document.createElement('canvas');
        this.myCanvas.nativeElement.appendChild(canvas);
        canvas.className = 'pdf-page-canvas';
        renderPage(page, canvas);
      }
    });
  }

  getChronovetQRCode(): Observable<string> {
    return this.vetFacade.clinic$.pipe(
      switchMap((clinic) => {
        const showChronovet = this.formFirstStep.getRawValue();
        return showChronovet
          ? from(
              generateQrCode(
                formatChronoVetLink(this.data.productRecommendation?.nutritionData, clinic?.customerId, 'pet_owner_letter', 'qr_code')
              )
            )
          : of('');
      })
    );
  }

  getPOLetterQRCode(url: string): Observable<string> {
    return from(generateQrCode(url));
  }

  displayQRCode(): void {
    const { tool, page } = this.data;
    const { notes, showChronoVet, deworming, fleaTreatment, vaccination } = this.formFirstStep.getRawValue();
    this.trackingService.sendInteraction(
      openQRCode(
        mapToolToModule(tool),
        page?.type,
        page?.block,
        { vaccination: vaccination, deworming: deworming, flea: fleaTreatment, chronoVet: showChronoVet },
        notes?.length
      )
    );
    this.showQRCode = true;
  }

  submitFirstStep(): void {
    this.sharePopinState$.next(this.SharePopinState.LOADING);
    this.changeDetector.detectChanges();
    combineLatest([
      this.base64ChartImage,
      this.vetFacade.vet$,
      this.vetFacade.clinic$,
      this.vetFacade.currentClinicCurrency$,
      this.getChronovetQRCode(),
      this.vetFacade.isRetailPriceActivated$,
    ])
      .pipe(
        filter(([chartImage]) => !!chartImage),
        take(1),
        switchMap(([chartImage, vet, clinic, currency, chronovetQrCode, isRetailPriceActivated]) => {
          const { petInfo, currentConsultation, idealBodyWeight, tool, poLetterFrom, ...props } = this.data;
          const {
            deworming_reminderDate,
            fleaTreatment_reminderDate,
            vaccination_reminderDate,
            notes,
            showChronoVet,
          } = this.formFirstStep.getRawValue();
          const dataTemplate = buildPOLetterData({
            datePipe: this.datePipe,
            currencyPipe: this.currencyPipe,
            fractionCupPipe: this.fractionCupPipe,
            data: {
              ...props,
              vet,
              clinic,
              petInfo,
              currentConsultation: {
                ...currentConsultation,
                comments: notes,
              },
              fleaTreatment_reminderDate,
              deworming_reminderDate,
              vaccination_reminderDate,
              idealBodyWeight,
              isChronoVetClinic: showChronoVet,
              qrCode: chronovetQrCode,
              isRetailPriceActivated,
              currency,
              chartImage,
              profilePictureLink: formatPetProfilePictureLink(
                petInfo?.breedObject?.sizeCategory,
                this.apiService.pathUrl.blobPDFTemplateStorageData,
                petInfo.speciesCode
              ),
            },
          });
          return this.poLetterService.getPOLetterPdf(
            getPOLetterTemplateName({ tool, petInfo, currentConsultation, poLetterFrom }),
            dataTemplate
          );
        }),
        tap((pdf) => {
          this.pdf = pdf.data;
          this.renderPdf(pdf.data);
          this.poLetterQrCode$ = this.getPOLetterQRCode(pdf.fileUrl);
        })
      )
      .subscribe(() => {
        this.sharePopinState$.next(SharePopinState.SECOND_STEP);
        this.changeDetector.detectChanges();
      });
  }

  save(): void {
    of(this.data?.actions?.submit()).subscribe(() => this.closePopin());
  }

  closePopin(): void {
    this.dialogRef.close();
  }

  setFirstStep(): void {
    this.sharePopinState$.next(SharePopinState.FIRST_STEP);
  }

  emailErrorMessage(): string {
    return getEmailErrorMessage(this.formSecondStep.controls.email.errors, 'form-attribute_email');
  }

  /**
   * Share ration or weight management recommendation by email
   */
  shareRecommendationByEmail(): void {
    const { page, petInfo, emailPopintype, currentConsultation, defaultEmail, tool, owner, ...props } = this.data;
    const { notes, showChronoVet, deworming, fleaTreatment, vaccination } = this.formFirstStep.getRawValue();
    const { email } = this.formSecondStep.getRawValue();
    combineLatest([this.vetFacade.vet$, this.vetFacade.clinic$])
      .pipe(
        tap(([vet, clinic]) => {
          this.store$.dispatch(setConsultationPetOwner({ value: { email } }));
          this.emailService.sendByEmail(
            emailPopintype,

            email || defaultEmail,
            buildRecommendationBodyEmail({
              data: {
                ...props,
                vet,
                clinic,

                petInfo: {
                  ...petInfo,
                  name: petInfo.name,
                },
                currentConsultation: {
                  ...currentConsultation,
                  comments: notes,
                },
                tool,
                emailPopinType: emailPopintype,
                owner,
                chronoVetLink: showChronoVet
                  ? formatChronoVetLink(this.data.productRecommendation?.nutritionData, clinic?.customerId, 'summary_email', 'buy_button')
                  : undefined,
              },
            }),
            [
              {
                content: this.pdf,
                filename: `${petInfo?.name}.pdf`,
                type: 'application/pdf',
              },
            ],
            {
              page,
              notesCharacterCount: notes?.length,
              options: { vaccination: vaccination, deworming: deworming, flea: fleaTreatment, chronoVet: showChronoVet },
            }
          );
        })
      )
      .subscribe(() => this.closePopin());
  }

  /**
   * Share ration or weight management recommendation by print
   */
  shareRecommendationByPrint(): void {
    const { petInfo, tool, page } = this.data;
    const { notes, showChronoVet, deworming, fleaTreatment, vaccination } = this.formFirstStep.getRawValue();
    this.onClickDownloadPdf(this.pdf, petInfo?.name);
    this.trackingService.sendInteraction(
      print(
        mapToolToModule(tool),
        page?.type,
        page?.block,
        { vaccination: vaccination, deworming: deworming, flea: fleaTreatment, chronoVet: showChronoVet },
        notes?.length
      )
    );
  }

  downloadPdf(base64String: string, fileName: string): void {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }

  onClickDownloadPdf(pdf: string, petName: string): void {
    this.downloadPdf(pdf, petName);
    this.closePopin();
  }

  getChartBase64(): void {
    const today = new Date();
    const targetWeightData = this.data?.currentConsultation?.targetVisit?.expected?.weight;
    const isWeightLossPOLetter = [POLetterFrom.FlowAllowanceWeightBlock, POLetterFrom.WeightTab].includes(this.data.poLetterFrom);
    const currentWeightData =
      this.data.poLetterFrom === POLetterFrom.FlowAllowanceRationBlock
        ? ChartHelper.formatCurrentWeightData(
            this.data?.petInfo?.weight,
            this.data?.petInfo?.IBW,
            today,
            this.datePipe,
            isWeightLossPOLetter,
            targetWeightData
          )
        : null;
    this.chartService
      .getChartConsultationImage(this.data.patientId, isWeightLossPOLetter, currentWeightData, targetWeightData)
      .pipe(
        first(),
        tap((image) => {
          this.base64ChartImage.next(image);
        })
      )
      .subscribe();
  }

  private _createForm(): void {
    const formBuilderFirstStep: IFormBuilder = new FormBuilder();
    this.formFirstStep = formBuilderFirstStep.group<ShareRecommendationFormValuesFirstStep>({
      notes: [this.data?.currentConsultation?.comments || null],
      deworming: [false],
      vaccination: [false],
      fleaTreatment: [false],
      vaccination_reminderDate: [null],
      deworming_reminderDate: [null],
      fleaTreatment_reminderDate: [null],
      showChronoVet: [false],
    });
    const formBuilderSecondStep: IFormBuilder = new FormBuilder();

    this.formSecondStep = formBuilderSecondStep.group<ShareRecommendationFormValuesSecondStep>({
      email: this.data?.defaultEmail || null,
      consent: [false],
    });
  }
}
