import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ServicesHelper } from '@app/core/services';
import { countriesNumbersData } from '@app/shared/data/countryCode_phoneNumber';
import { CountriesDialCode, VetPreferences } from '@app/shared/utils';
import { InputStateType } from '@app/shared/utils/enums/input-state.enum';

@Component({
  selector: 'app-rc-input-phone',
  templateUrl: './rc-input-phone.component.html',
  styleUrls: ['./rc-input-phone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RcInputPhoneComponent),
      multi: true,
    },
  ],
})
export class RcInputPhoneComponent implements ControlValueAccessor {
  InputState = InputStateType;
  dialCodes = CountriesDialCode.cleanDialCodes(countriesNumbersData);
  defaultDialCode = ServicesHelper.getDialCodeByCountryCode(VetPreferences.country);

  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() error: string;

  @Input() label: string;
  @Input() autocomplete: string;
  @Input() name: string;
  @Input()
  set value(val: string) {
    if (val) {
      const indexOfDialCode: number = val.indexOf('+');

      if (indexOfDialCode > -1) {
        const indexOfPhoneValue: number = val.indexOf(' ') > -1 ? val.indexOf(' ') : this.defaultDialCode.length;
        this.dialCodeValue = val.slice(indexOfDialCode, indexOfPhoneValue);
        this.phoneValue = val.slice(indexOfPhoneValue + 1, val.length);
      } else {
        this.dialCodeValue = this.defaultDialCode;
        this.phoneValue = val;
      }
    }
  }
  get value(): string {
    if ((this.dialCodeValue || this.defaultDialCode) && this.phoneValue && this._formatPhoneValue(this.phoneValue)) {
      return `${this.dialCodeValue || this.defaultDialCode} ${this._formatPhoneValue(this.phoneValue)}`;
    }
  }

  dialCodeValue: string;
  phoneValue: string;
  inputError: boolean;

  @Input() set errorState(value: string) {
    this.inputError = value === InputStateType.Failed;
  }

  @Output() changeValue = new EventEmitter<string>();
  @Output() focusOut = new EventEmitter<string>();

  propagateChange(_: any) {
    // empty
  }
  onTouchedFn(_: any) {
    // empty
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  /**
   * Should be called when the control receives a disabled attribute
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _formatPhoneValue(phoneValue: string) {
    if (!phoneValue) {
      return '';
    }
    if (phoneValue.length > 3 && phoneValue[0] === '0') {
      return phoneValue.slice(1);
    }
    return phoneValue;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Method that is invoked on an update of a model.
   */
  onChangeDialCode(dialCode) {
    if (dialCode !== this.dialCodeValue) {
      this.dialCodeValue = dialCode;
      this.propagateUpdates();
    }
  }

  onChangePhone(eventTarget: EventTarget): void {
    const eventTargetValue = (eventTarget as HTMLInputElement).value;

    if (eventTargetValue !== this.phoneValue) {
      this.phoneValue = eventTargetValue;
      this.propagateUpdates();
    }
  }

  propagateUpdates() {
    this.propagateChange(this.value);
    this.changeValue.emit(this.value);
  }

  onTouched() {
    this.onTouchedFn(this.value);
  }
}
