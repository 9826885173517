import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertNotification, Breed, LifestageInfo, PetProfileFormValueKeys, PetProfileFormValues, Segmented } from '@app/core/models';
import { NutritionService } from '@app/core/services/network';
import { pastDateValidator } from '@app/shared/directives';
import { GenderCode, LifestageType, ReproductionStatusCode, SpeciesCode, Tool } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { getDateErrorMessage, getRequiredErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import {
  filterFormValues,
  formatAndFilterBreeds,
  genderValidator,
  getActivityItems,
  getAdditionalLifestageInfo,
  getGenderItems,
  getMessagesToEmit,
  getPlaceholders,
  getReproductionStatusItems,
  getSpeciesItems,
  isLifestageAdult,
  sortMixedBreeds,
} from '@app/shared/utils/static-helpers/pet-profile-form-helper';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { AppState } from '@app/store';
import { selectBreeds } from '@app/store/core';
import { Store } from '@ngrx/store';
import { RCAutocompleteItem, RCSelectItem } from '@rc/ui';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { asyncScheduler, BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { addYears } from 'date-fns';

@Component({
  selector: 'app-pet-profile-form',
  styleUrls: ['pet-profile-form.component.scss'],
  templateUrl: './pet-profile-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PetProfileFormComponent implements OnInit, OnDestroy {
  @Input() tool: Tool;
  @Input() initialValues?: PetProfileFormValues | null = null;
  @Input() isNewPet = true;
  @Input() submitButtonLabel = translateKey('action_continue');

  @Output() submitValues = new EventEmitter<{ values: PetProfileFormValues; selectedFullBreed: Breed }>();
  @Output() alertMessage = new EventEmitter<AlertNotification>();
  @Output() closeMessages = new EventEmitter<string[]>();

  PetProfileFormValueKeysEnum = PetProfileFormValueKeys;
  IconNameEnum = IconName;
  speciesItems: Segmented[] = getSpeciesItems();
  genderItems: Segmented[] = getGenderItems();
  activityItems: RCSelectItem[] = getActivityItems();
  reproductionStatusItems: RCSelectItem[] = getReproductionStatusItems();
  placeholders = getPlaceholders();
  form: IFormGroup<PetProfileFormValues>;

  breeds$: Observable<RCAutocompleteItem<string>[]> = of([]);
  fullBreeds: Breed[] = [];
  iconName = IconName;
  Tool = Tool;
  lifestageMessage$ = new BehaviorSubject<string | null>(null);
  lifestageError$ = new BehaviorSubject<string | null>(null);
  petProfileInvalidForTool$ = new BehaviorSubject<boolean>(false);
  initalBreedInvalid$ = this.store$
    .select(selectBreeds)
    .pipe(map((breeds) => this.initialValues?.breed && !breeds.find((i) => i.breedCode === this.initialValues?.breed)));
  maxDate = new Date();
  initialGenderInvalid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _destroyed$ = new Subject();

  constructor(private cdr: ChangeDetectorRef, private nutritionService: NutritionService, private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.initialGenderInvalid$.next(
      this.initialValues ? ![GenderCode.Female, GenderCode.Male].includes(this.initialValues?.gender) : false
    );

    // form setup
    this.setupForm();
    // set breed items and handle prefill/reset of breed field
    this.setAutocompleteBreedItemsAndControls();
    // set lifestage based on breed and date of birth
    this.setLifestage();

    // handle disabled/hidden fields when inputs and lifestage are updated
    this.setDisabledBreed();
    this.setDisabledDateOfBirth();
    this.setFieldsControlsFromLifestage();
    this.setReproductionStatusControls();
    this.handleMessagesToEmit();
  }

  customSortAutocomplete(): undefined | ((breed1: RCAutocompleteItem<string>, breed2: RCAutocompleteItem<string>) => number) {
    return this.form.controls.speciesCode.value === SpeciesCode.Dog && this.form.controls.mixed.value ? sortMixedBreeds : undefined;
  }

  setupForm(): void {
    const formBuilder: IFormBuilder = new FormBuilder();
    this.maxDate = this.tool === Tool.RenalDetect ? addYears(new Date(), -7) : new Date();
    this.form = formBuilder.group<PetProfileFormValues>({
      name: [this.initialValues?.name || null, Validators.required],
      speciesCode: [
        {
          value: this.initialValues?.speciesCode || null,
          disabled: this.tool === Tool.RenalDetect,
        },
        Validators.required,
      ],
      gender: [{ value: this.initialValues?.gender || null, disabled: false }, [Validators.required, genderValidator]],
      neutered: [{ value: this.initialValues?.neutered || false, disabled: true }, Validators.required],
      reproductionStatus: [{ value: this.initialValues?.reproductionStatus || null, disabled: true }],
      breed: [{ value: this.initialValues?.breed || null, disabled: true }, Validators.required],
      mixed: [{ value: this.initialValues?.mixed || false, disabled: true }],
      birthdate: [
        {
          value: this.initialValues?.birthdate || null,
          disabled: true,
        },
        [Validators.required, pastDateValidator],
      ],
      petActivity: [{ value: this.initialValues?.petActivity || null, disabled: true }, Validators.required],
      lifestage: [null, Validators.required],
    });

    // make all fields as touched if initial values are passed (triggers fields validation and errors for srs instance)
    if (this.initialValues) {
      Object.keys(this.initialValues).forEach((key: keyof PetProfileFormValues) => {
        if (this.initialValues[key]) {
          this.form.controls[key].markAsTouched();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  requiredErrorMessage(field: PetProfileFormValueKeys, fieldKeyTrad: string): string {
    return (this.form.controls[field].touched && this.form.controls[field].errors?.required && getRequiredErrorMessage(fieldKeyTrad)) || '';
  }

  dateOfBirthErrorMessage(): string {
    return (
      this.form.controls.birthdate.touched &&
      this.form.controls.birthdate.errors &&
      getDateErrorMessage(this.form.controls.birthdate.errors, 'form-attribute_birth-date', true)
    );
  }

  shouldDisplayReproductionStatus(): boolean {
    return this.form.controls.gender.value !== GenderCode.Male;
  }

  /*
    By default the RcSelect component will sort items alphabetically, we want to prevent this behaviour
  */
  customSelectSort(): number {
    return 1;
  }

  /*
      When we submit, we want to send two informations : the form values and the full breed information
      The full breed information is retrieved from the list of breeds with the value selected be the user
      (NB: selectedFullBreed only exists for a new pet profile, indeed for an existing pet the breed input
      is not even displayed and thus the this.breeds observable not subscribed)
  */
  submit(): void {
    if (this.form.valid) {
      const selectedFullBreed = this.fullBreeds.find((item) => item.breedCode === this.form.controls.breed.value);
      this.submitValues.emit({ values: filterFormValues(this.form.getRawValue()), selectedFullBreed });
    }
  }

  /*
    Depending on the tool used and the pet information filled (BIRTHDATE, SPECIES_CODE, REPRODUCTION_STATUS, LIFESTAGE)
    We have to emit a message to the parent component to display a warning or an error
    And in case it is a blocking error, we prevent the user from submitting (petProfileInvalidForTool$)
    Example : for renal detect, the pet must be older than 7 years old
  */
  private handleMessagesToEmit() {
    combineLatest([
      this.fieldUpdated$<Date>(PetProfileFormValueKeys.BIRTHDATE),
      this.fieldUpdated$<SpeciesCode>(PetProfileFormValueKeys.SPECIES_CODE),
      this.fieldUpdated$<ReproductionStatusCode>(PetProfileFormValueKeys.REPRODUCTION_STATUS),
      this.fieldUpdated$<LifestageType>(PetProfileFormValueKeys.LIFESTAGE),
    ])
      .pipe(
        tap(([birthDate, speciesCode, reproductionStatus, lifestage]) => {
          const { messages, isBlocking, messageIdsToRemove } = getMessagesToEmit(this.tool, {
            birthDate,
            speciesCode,
            reproductionStatus,
            lifestage,
          });
          if (messages.length) {
            messages.forEach((message) => {
              this.alertMessage.emit(message);
            });
          }
          if (messageIdsToRemove.length) {
            this.closeMessages.emit(messageIdsToRemove);
          }
          this.petProfileInvalidForTool$.next(isBlocking);
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
    BREED and MIXED fields are minked to NAME, SPECIES_CODE and GENDER
    - if BREED is not set and NAME, SPECIES_CODE and GENDER are not set, BREED and MIXED are disabled
  */
  private setDisabledBreed(): void {
    combineLatest([
      this.fieldUpdated$<string>(PetProfileFormValueKeys.NAME),
      this.fieldUpdated$<SpeciesCode>(PetProfileFormValueKeys.SPECIES_CODE),
      this.fieldUpdated$<GenderCode>(PetProfileFormValueKeys.GENDER),
    ])
      .pipe(
        tap(([name, speciesCode, gender]) => {
          const breedInput = this.form.controls.breed;
          const mixedInput = this.form.controls.mixed;
          if ((!name || !speciesCode || !gender) && breedInput.enabled && !breedInput.value) {
            breedInput.disable();
            mixedInput.disable();
          } else if (name && speciesCode && gender && breedInput.disabled) {
            breedInput.enable();
            mixedInput.enable();
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
    BIRTHDATE field is linked to BREED
    - if BREED is not set, BIRTHDATE is disabled
  */

  private setDisabledDateOfBirth(): void {
    combineLatest([this.fieldUpdated$<string>(PetProfileFormValueKeys.BREED), this.initalBreedInvalid$])
      .pipe(
        tap(([breed, initalBreedInvalid]) => {
          const dateOfBirthInput = this.form.controls.birthdate;

          if ((!breed && dateOfBirthInput.enabled) || initalBreedInvalid) {
            dateOfBirthInput.disable();
          } else if (breed && dateOfBirthInput.disabled) {
            dateOfBirthInput.enable();
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
    REPRODUCTION_STATUS field is linked to LIFESTAGE and NEUTERED
    - if NEUTERED is checked, REPRODUCTION_STATUS is cleared and disabled
    - if LIFESTAGE is too young, REPRODUCTION_STATUS is disabled
  */
  private setReproductionStatusControls(): void {
    combineLatest([
      this.fieldUpdated$<LifestageType>(PetProfileFormValueKeys.LIFESTAGE),
      this.fieldUpdated$<boolean>(PetProfileFormValueKeys.NEUTERED),
    ])
      .pipe(
        tap(([lifestage, neutered]) => {
          const reproductionStatusInput = this.form.controls.reproductionStatus;
          if (neutered) {
            reproductionStatusInput.reset();
            reproductionStatusInput.disable();
          } else if (!isLifestageAdult(lifestage) && reproductionStatusInput.enabled) {
            reproductionStatusInput.disable();
          } else if (isLifestageAdult(lifestage) && reproductionStatusInput.disabled) {
            reproductionStatusInput.enable();
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
    PET_ACTIVITY and NEUTERED fields behaviours are linked to the lifestage
    - if there is no LIFESTAGE yet, NEUTERED is disabled
    - if LIFESTAGE is too young PET_ACTIVITY are disabled
    - NB : PET_ACTIVITY is a required field by default, but with petActivityInput.disable angular considers that it is not required
    (very useful here to handle the case where PET_ACTIVITY is not required for a young pet)
  */
  private setFieldsControlsFromLifestage(): void {
    this.fieldUpdated$<LifestageType>(PetProfileFormValueKeys.LIFESTAGE)
      .pipe(
        tap((lifestage) => {
          const petActivityInput = this.form.controls.petActivity;
          const neuteredInput = this.form.controls.neutered;

          if (!lifestage && neuteredInput.enabled) {
            neuteredInput.disable();
          } else if (lifestage && neuteredInput.disabled) {
            neuteredInput.enable();
          }
          if (!isLifestageAdult(lifestage) && petActivityInput.enabled) {
            petActivityInput.disable();
          } else if (isLifestageAdult(lifestage) && petActivityInput.disabled) {
            petActivityInput.enable();
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
  First we need to fetch the breeds from the API
  Then we format and emit all the breeds received
  When users update the species and/or check the mixed checkbox
  we filter and emit the new breed list to match the requirements
*/
  private setAutocompleteBreedItemsAndControls(): void {
    this.breeds$ = this.store$.select(selectBreeds).pipe(
      filter((breeds) => !!breeds),
      concatMap((breeds) =>
        combineLatest([
          this.fieldUpdated$<SpeciesCode>(PetProfileFormValueKeys.SPECIES_CODE),
          this.fieldUpdated$<boolean>(PetProfileFormValueKeys.MIXED),
        ]).pipe(
          // Update placeholder depending on mixed checkbox ticked
          tap(([, mixed]) => {
            this.placeholders[PetProfileFormValueKeys.BREED] = mixed
              ? translateKey('form-attribute_size')
              : translateKey('form-attribute_breed');
          }),
          map(([speciesCode, mixed]) => {
            this.fullBreeds = breeds;
            return formatAndFilterBreeds(breeds, speciesCode, mixed);
          }),
          // If there is only one value, we prefill the breed field
          // else if the current selected value does not exist in the new filtered list we reset the field
          tap((breedItems) => {
            asyncScheduler.schedule(() => {
              const breedInput = this.form.controls.breed;
              const isCurrentBreedValueStillValid = !!breedItems.find((i) => i.value === breedInput.value);
              if (breedItems.length === 1) {
                breedInput.patchValue(breedItems[0].value);
              } else if (!isCurrentBreedValueStillValid) {
                breedInput.reset();
              }
            });
          })
        )
      )
    );
  }

  /*
    Whenever the breed or the birthdate updates, we need to fetch the lifestage
    The lifestage is used for several things :
    - Display error message below the date of birth input (lifestageError$)
    - Display the lifestage message information below the date of birth input (lifestageMessage$)
    - Use the lifestage value (LifestageType) when submitting the form
    - Use the lifestage value (LifestageType) to disable or not other inputs
  */
  private setLifestage(): void {
    combineLatest([this.fieldUpdated$<string>(PetProfileFormValueKeys.BREED), this.fieldUpdated$<Date>(PetProfileFormValueKeys.BIRTHDATE)])
      .pipe(
        switchMap(([breed, birthDate]) => {
          // if the users clears some fields (like the breed when selecting a different species), clear the lifestage information
          if (!breed || !birthDate) return of<LifestageInfo>({ code: null, message: null, error: null });
          // if the BREED or BIRTHDATE fetch the new lifestage information
          return this.nutritionService
            .getBreedPetProfile({
              breedCode: breed,
              dateOfBirth: birthDate,
            })
            .pipe(
              // is the API is successful, get the lifestage code but also the message to display or the error
              map(({ lifestage }) => getAdditionalLifestageInfo(lifestage)),
              catchError(() => {
                // in case the API failed, make sure to emit a value with an error by returning this observable
                // this is to prevent the observable from completing and make sure that the API can be called again
                // if the user changes the inputs BREED and BIRTHDATE again
                return of<LifestageInfo>({
                  code: null,
                  message: null,
                  error: $localize`:@@label_life-stage-msg-error:`,
                });
              })
            );
        }),
        tap((lifestageInfo) => {
          this.lifestageMessage$.next(lifestageInfo?.message);
          this.lifestageError$.next(lifestageInfo?.error);
          this.form.patchValue({ lifestage: lifestageInfo?.code });
          if (lifestageInfo?.code) this.cdr.detectChanges();
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
    Small helper to listen to field updates
    - startWith is needed to catch the initial value of the field (which is needed when opening the form for an existing pet)
    and also needed because of combineLatest behaviour (does not emit until all values have emitted once)
    - distinctUntilChanged is here to make sure we catch only new values (make sure that we don't unecessary call the lifestage API for instance)
  */
  private fieldUpdated$<T extends PetProfileFormValues[keyof PetProfileFormValues]>(key: keyof PetProfileFormValues): Observable<T> {
    const controlValueChanges = this.form.controls[key].valueChanges as Observable<T>;
    const initialValue = this.initialValues && (this.initialValues[key] as T);
    return controlValueChanges.pipe(startWith(initialValue || null), distinctUntilChanged());
  }
}
