import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OverlayModule } from '@app/core/cdk/overlay';
import { PortalModule } from '@app/core/cdk/portal';

import { Dialog } from './dialog';
import { DialogContainerComponent } from './dialog-container';

import {
  RCDialogCloseDirective,
  RCDialogDirective,
  RCDialogTitleDirective,
  RCDialogContentDirective,
  RCDialogWrapperDirective,
  RCDialogActionsDirective,
} from './dialog-content.directive';

@NgModule({
  imports: [CommonModule, OverlayModule, PortalModule],
  exports: [
    DialogContainerComponent,
    RCDialogCloseDirective,
    RCDialogDirective,
    RCDialogTitleDirective,
    RCDialogActionsDirective,
    RCDialogWrapperDirective,
    RCDialogContentDirective,
  ],
  declarations: [
    DialogContainerComponent,
    RCDialogCloseDirective,
    RCDialogDirective,
    RCDialogTitleDirective,
    RCDialogActionsDirective,
    RCDialogWrapperDirective,
    RCDialogContentDirective,
  ],
  providers: [Dialog],
})
export class DialogModule {}
