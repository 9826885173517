import { Observable, Subject } from 'rxjs';

export class ModalRef {
  private _result: any;
  private readonly _afterClose = new Subject<any>();
  private readonly _afterClosed = new Subject<any>();
  afterClose: Observable<any> = this._afterClose.asObservable();
  afterClosed: Observable<any> = this._afterClosed.asObservable();

  close(result?: any) {
    this._result = result;
    this._afterClose.next();
  }

  closed() {
    this._afterClosed.next(this._result);
  }
}
