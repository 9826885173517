import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Package, Product } from '@app/core/models';
import { ProductDetailComponent } from '@app/shared/components/product-detail/product-detail.component';
import { LocaleContentEnum } from '@app/shared/data';
import { PackType } from '@app/shared/utils/enums';
import ProductHelper from '@app/shared/utils/static-helpers/product-helper';
import { VetFacade } from '@app/store/vet';
import { MatDialog } from '@angular/material/dialog';
import { DialogHelpers } from '@app/shared/utils/static-helpers/dialog-helpers';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent {
  LocaleContentEnum = LocaleContentEnum;
  clinicCurrency = this.vetFacade.currentClinicCurrency$;

  @Input() products?: Product[];
  @Input() productForm?: FormGroup;
  @Input() selectedProductsIds: string[] = [];
  @Input() showSelectButton = false;
  @Input() showRetailPrices?: boolean = true;
  @Input() displayPackages = true;

  @Output() selectProduct = new EventEmitter<{ product: Product; packId: string }>();
  @Output() removeProduct = new EventEmitter<Product>();

  public showRetailPrices$ = this.vetFacade.isRetailPriceActivated$.pipe(
    map((isRetailPriceActivated) => this.showRetailPrices && isRetailPriceActivated)
  );
  public showWholesalePrices$ = this.vetFacade.isWholesalePriceActivated$;

  constructor(private vetFacade: VetFacade, protected modal: MatDialog) {}

  trackByFn(index, product: Product) {
    if (!product) {
      return null;
    }
    return product.id;
  }

  didSelectProduct = (event: Event, product: Product): void => {
    event.stopPropagation();

    return this.isSelected(product) ? this.removeProduct.emit(product) : this.selectProduct.emit({ product, packId: null });
  };

  isSelected = (product: Product): boolean => !!this.selectedProductsIds?.find((p: string) => p === product.id);

  getProductPrice = (product: Product): number | null =>
    ProductHelper.getPriceFromSelectedProductPack(product, this.productForm?.get(`${product.id}`)?.get('packId')?.value);

  getWholesalePrice$ = (product: Product): Observable<number | null> => {
    const currentPackId = this.productForm?.get(`${product.id}`)?.get('packId')?.value;
    const currentPack = product.packages.find((pack) => pack.sCode === currentPackId);
    return this.vetFacade.wholesalePrices$.pipe(
      first(),
      map((wholesalePrices) => wholesalePrices[currentPack?.ean] || null)
    );
  };

  getPackages(packages: Package[]): Package[] {
    return packages.filter((pack) => pack.type != PackType.StarterKit);
  }

  isSelectProduct = (product: Product, packId: string): void => this.selectProduct.emit({ product, packId });

  isRemoveProduct = (data: Product): void => this.removeProduct.emit(data);

  showDetailPopin = (event: Event, product: Product): void => {
    event.stopPropagation();
    const dialogConfig = {
      ...DialogHelpers.defaultConfig('productDetailPanelCustom'),
      data: {
        productId: product.id,
        isSelected: this.isSelected(product),
        starterKit: false,
        selectedProductsIds: this.selectedProductsIds || [],
        selectButton: this.showSelectButton
          ? {
              callback: ($event, productItem) => {
                this.selectedProductsIds?.includes(productItem.id)
                  ? this.selectProduct.emit(productItem)
                  : this.removeProduct.emit(productItem);
              },
            }
          : null,
      },
    };
    this.modal.open(ProductDetailComponent, dialogConfig);
  };
}
