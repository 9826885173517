import { Segmented } from '@app/core/models';
import { BreedSize, ProductPillar, SpeciesCode } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { RCSelectItem } from '@rc/ui';

export interface ProductCatalogFilterValues {
  pillar: ProductPillar;
  specie: SpeciesCode;
  search: string;
  dry: boolean;
  wet: boolean;
  birthAndGrowth: boolean;
  mature: boolean;
  adult: boolean;
  size: BreedSize;
}

export const getSpeciesItems = (specie_code?: SpeciesCode): Segmented[] => {
  return [
    {
      label: translateKey('specie-dog'),
      icon: IconName.specie_dog,
      value: SpeciesCode.Dog,
      disabled: specie_code === SpeciesCode.Cat,
    },
    {
      label: translateKey('specie-cat'),
      icon: IconName.specie_cat,
      value: SpeciesCode.Cat,
      disabled: specie_code === SpeciesCode.Dog,
    },
  ];
};

export const getPillarItems = (): RCSelectItem[] => [
  {
    label: translateKey('product-book_search_veterinary'),
    value: ProductPillar.VET,
  },
  {
    label: translateKey('product-book_search_health'),
    value: ProductPillar.SPTRETAIL,
  },
];

export const getSizeItems = (): RCSelectItem[] => [
  {
    label: translateKey('all'),
    value: BreedSize.All,
  },
  {
    label: translateKey('size-x-small'),
    value: BreedSize.XSmall,
  },
  {
    label: translateKey('size-mini'),
    value: BreedSize.Small,
  },
  {
    label: translateKey('size-medium'),
    value: BreedSize.Medium,
  },
  {
    label: translateKey('size-maxi'),
    value: BreedSize.Large,
  },
  {
    label: translateKey('size-giant'),
    value: BreedSize.XLarge,
  },
];
