<div class="bcs">
  <div class="bcs__image-container">
    <app-icon class="bcs__image-container-image" [name]="DetectMobile.isMobile() ? mediumBcsImageUrl() : largeBcsImageUrl()"></app-icon>
    <div class="bcs__descritption-container">
      <p class="bcs__descrition-title">{{ BCS_Title }}</p>
      <ul class="bcs__descrition-bloc">
        <li *ngIf="BCS_Text_1().length" class="bcs__descrition">{{ BCS_Text_1() }}</li>
        <li *ngIf="BCS_Text_2().length" class="bcs__descrition">{{ BCS_Text_2() }}</li>
        <li *ngIf="BCS_Text_3().length" class="bcs__descrition">{{ BCS_Text_3() }}</li>
        <li *ngIf="BCS_Text_4().length" class="bcs__descrition">{{ BCS_Text_4() }}</li>
        <li *ngIf="BCS_Text_5().length" class="bcs__descrition">{{ BCS_Text_5() }}</li>
      </ul>

      <div class="bcs-help">
        <span class="rc-icon rc-icon-pdf bcs-help-icon"></span>
        <a class="rc-button rc-button--primary" [href]="bcsPdfUrl$ | async" rel="noopener" target="_blank">
          <span class="rc-button__title rc-button__title--link" i18n="@@action_condition-score">Body condition score guide</span>
        </a>
      </div>
    </div>
  </div>

  <div class="bcs-selector">
    <span class="bcs-selector--number" *ngFor="let bcs of bcsList">
      <a
        class="bcs-selector--number-link"
        (click)="selectBCS(bcs)"
        [id]="'bcs-select-' + bcs"
        [class.active_red]="bcs === value && value >= 8"
        [class.active_orange]="bcs === value && value > 5 && value < 8"
        [class.active_yellow]="bcs === value && value < 4"
        [class.active_green]="bcs === value && value > 3 && value < 6"
        [class.disabled]="bcs < minimalBCS"
        >{{ bcs }}</a
      >
    </span>
  </div>
</div>
