import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ActionCommunicationService {
  paginationSubject = new Subject(); // Used for Pet-Page pagination
  showLoaderSubject = new Subject(); // Used to handle the loading inside health-tab contained in Pet-page
  // Used to handle errors happening in sub components for the Pet profile page and the DA page
  // right now this subject is only used by the weight loss summary component for the visit date updates
  showErrorSubject = new Subject<string>();

  skip(pageNumber: number, type?: string) {
    this.paginationSubject.next({ pageNumber, type });
  }

  showLoader(isLoading: boolean, type?: string) {
    this.showLoaderSubject.next({ isLoading, type });
  }

  showError(error: string) {
    this.showErrorSubject.next(error);
  }
}
