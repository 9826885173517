import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fractionCup',
})
export class FractionCupPipe implements PipeTransform {
  transform(cupRation?: number): string {
    if (!cupRation) return '';

    const [integer, decimals] = cupRation.toString().split('.');
    if (decimals) {
      let fraction = (cupRation - parseFloat(integer)) * 8;
      let divider = 8;
      const isMultipleOfTwo = fraction % 2 === 0;
      const isMultipleOfFour = fraction % 4 === 0;
      if (isMultipleOfFour) {
        fraction = fraction / 4;
        divider = divider / 4;
      } else if (isMultipleOfTwo) {
        fraction = fraction / 2;
        divider = divider / 2;
      }

      if (cupRation > 1) {
        return `${integer}+${fraction}/${divider}`;
      } else {
        return `${fraction}/${divider}`;
      }
    }
    return cupRation.toString();
  }
}
