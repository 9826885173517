import { Helper, MeasurementCodeType, ProductType, Programs, SpeciesCode, Tool, VisitType } from '@app/shared/utils/';
import { Patient, Product, ProductPacks } from '@app/core/models';
import { CalculatorHelper } from '@app/shared/utils/static-helpers/calculator-helper';

export class GtmHelper {
  /*
   * Function return product for GTM format
   **/
  static productForGTM(
    products: Product[],
    productPacks: ProductPacks
  ): {
    id: string;
    name: string;
    brand: string;
    category: string;
    quantity: string;
    weight: string;
    variant: string;
    territory: string;
  }[] {
    const productLayer = [];

    products.forEach((product: Product) => {
      const pack = CalculatorHelper.getInitialPack(product, productPacks);
      const petSpecies = product.speciesCode === SpeciesCode.Dog ? 'Dog' : 'Cat';
      const productType = product.productType === ProductType.Dry ? 'Dry' : 'Wet';
      let productRange = '';

      if (product.rangeCategory) productRange = product.rangeCategory;

      let packWetVariant = `${pack.weight}`;

      if (product.productType === ProductType.Wet || pack.measurementUnitCode === MeasurementCodeType.Gram)
        packWetVariant = `${pack.weight / 1000}`;

      productLayer.push({
        id: `${product.id}`,
        name: product.name,
        hasPrice: Boolean(product.packages.find((item) => !!item?.productPrice?.clinicPrice && item?.productPrice?.clinicPrice !== -1)),
        brand: 'Royal Canin',
        category: `${petSpecies}/${productRange}/${productType}`,
        quantity: 1,
        territory: product.territory,
        specificNeeds: this._joinArrays(product.specificNeeds),
        variant: packWetVariant,
      });
    });
    return productLayer;
  }

  static petForGtmOrder(
    patient: Patient
  ): {
    id: string;
    BCS: string;
    activityLevel: string;
    breed: string;
    gender: string;
    weight: string;
    age: string;
    name: string;
    neutered: boolean | string;
    specie: string;
    ownerId: string;
    pathology: string;
    sensitivities: string;
    reproductionStatus: string;
    expectedWeight: number | string;
  } {
    if (!patient) {
      return {
        id: 'undefined',
        BCS: 'undefined',
        activityLevel: 'undefined',
        breed: 'undefined',
        gender: 'undefined',
        weight: 'undefined',
        age: 'undefined',
        name: 'undefined',
        neutered: 'undefined',
        specie: 'undefined',
        ownerId: 'undefined',
        pathology: 'undefined',
        sensitivities: 'undefined',
        reproductionStatus: 'undefined',
        expectedWeight: 'undefined',
      };
    }
    const pet = patient.pet || {};
    return {
      id: pet.id || '',
      BCS: pet && pet.idealBodyWeight && pet.idealBodyWeight.bcs ? `${pet.idealBodyWeight.bcs}` : '',
      activityLevel: pet.petActivityCode || pet.petActivity || '',
      breed: pet.breedCode || 'undefined',
      gender: pet.genderCode || 'undefined',
      weight:
        patient.consultations &&
        patient.consultations[0] &&
        patient.consultations[0].visit &&
        patient.consultations[0].visit.weight &&
        patient.consultations[0].visit.weight.measure
          ? `${patient.consultations[0].visit.weight.measure}`
          : '',
      age: Helper._petAge(pet) || '',
      name: pet.name || '',
      neutered: pet.neutered || '',
      specie: pet.speciesCode || '',
      ownerId: pet.ownerId || '',
      pathology: 'undefined',
      sensitivities: 'undefined',
      reproductionStatus: pet.reproductionStatusCode || 'undefined',
      expectedWeight:
        patient.consultation &&
        patient.consultation.nextVisit &&
        patient.consultation.nextVisit.expected &&
        patient.consultation.nextVisit.expected.weight &&
        patient.consultation.nextVisit.expected.weight.measure
          ? patient.consultation.nextVisit.expected.weight.measure
          : 'undefined',
    };
  }

  /**
   * Transform array to string with pipe as separator.
   * @param value - Array of string
   * @param separator - Custom separator
   */
  private static _joinArrays(value: string[], separator = '|'): string {
    if (!value || value.length <= 0) {
      return 'None';
    }
    return value.map((data: string) => data.replace(/_/g, ' ')).join(separator);
  }
}

export const gtmWeightProgram = (program: Programs): string => {
  switch (program) {
    case Programs.WEIGHT_LOSS:
      return 'Weight loss program';
    case Programs.WEIGHT_STABILISATION_STEP_1:
      return 'After weight loss program';
    case Programs.WEIGHT_STABILISATION_STEP_2:
      return 'After weight loss program';
    default:
      return 'Weight maintenance program';
  }
};

export const gtmVisitType = (tool: Tool): VisitType => {
  return {
    [Tool.WeightManagement]: VisitType.WeightManagement,
    [Tool.SmartReco]: VisitType.SmartReco,
    [Tool.Multifunction]: VisitType.Multifunction,
    [Tool.PersonalizedBag]: VisitType.Multifunction,
    [Tool.RenalDetect]: VisitType.RenalDetect,
    [Tool.Rationing]: VisitType.RationingTool,
  }[tool];
};
