import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() value = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() label = '';
  @Input() topLabel = '';
  @Input() multiLine = false;
  @Output() checked: EventEmitter<boolean> = new EventEmitter();
  /**
   * Invoked when the model has been changed
   */
  onChange: (...args: unknown[]) => void = () => {
    // do not delete
  };
  /**
   * Invoked when the model has been touched
   */
  onTouched: (...args: unknown[]) => void = () => {
    // do not delete
  };
  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges(value?: boolean): void {
    const val = value !== undefined ? value : this.value;
    if (!this.disabled) {
      this.writeValue(val);
      this.onChange(val);
      this.checked.emit(val);
    }
  }
  /**
   * Writes a new item to the element.
   */
  writeValue(value: boolean): void {
    this.value = value;
  }
  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   */
  registerOnChange(fn: (...args: unknown[]) => void): void {
    this.onChange = fn;
  }
  /**
   * Registers a callback function that should be called when the control receives a blur event.
   */
  registerOnTouched(fn: (...args: unknown[]) => void): void {
    this.onTouched = fn;
  }
  /**
   * Should be called when the control receives a disabled attribute
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
