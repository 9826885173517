import { Injectable } from '@angular/core';
import { Clinic } from '@app/core/models';
import { Market } from '@app/core/models/market';
import { AppFeature, ClinicFeature } from '@app/shared/utils/enums/feature.enum';
import { VetFacade } from '@app/store/vet';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  constructor(private vetFacade: VetFacade) {}
  /**
   * Observable that returns feature activation status
   */
  isEnabled$(featureName: ClinicFeature | AppFeature): Observable<boolean> {
    return combineLatest([this.vetFacade.clinic$, this.vetFacade.market$]).pipe(
      map(([clinic, market]) => this.isEnabled(featureName, clinic, market))
    );
  }
  /**
   * Check feature activation status for specific market & clinic
   * To be activated, a feature must be activated for the market and for the clinic
   * It must remain private so we don't do sync checks, as country can change during navigation
   */
  private isEnabled(featureName: ClinicFeature | AppFeature, clinic: Clinic, market: Market | undefined): boolean {
    if (!clinic) return false;
    const isMarketFeatureEnabled = !!market?.features.find((f) => f === featureName);

    const isClinicFeatureEnabled =
      !Object.values(ClinicFeature).includes(featureName as ClinicFeature) ||
      !clinic.features ||
      !!clinic.features.find((f) => f.name === featureName);

    return isMarketFeatureEnabled && isClinicFeatureEnabled;
  }
}
