import { PanelSectionTypeEnum } from '@app/shared/utils';
import { createAction, props } from '@ngrx/store';
import { SmartRecoStep } from '../../smart-reco-step';
/**
 * Action dispatched to start a smart reco flow
 * It resets smart reco store data and consultation store data
 */
export const startSmartReco = createAction('[Smart Reco] Start', props<{ initFlow: boolean; withIdentification?: boolean }>());
/**
 * Set current step
 */
export const setSmartRecoStep = createAction('[Smart Reco] Set Step', props<{ value: SmartRecoStep }>());
/**
 * Set steps
 */
export const setSmartRecoSteps = createAction('[Smart Reco] Set Steps', props<{ value: SmartRecoStep[] }>());
/**
 * Reset smart reco store
 */
export const resetSmartReco = createAction('[Smart Reco] Reset');
/**
 * Navigate back to step from panel from another module (allowance) during consultation
 */
export const navigateBackToStep = createAction('[Smart Reco] Navigate back to step', props<{ value: PanelSectionTypeEnum }>());
