<div class="product-grid" [formGroup]="productForm">
  <div class="product-item" [ngClass]="showSelectButton ? 'product-item--large' : ''" *ngFor="let product of products; trackBy: trackByFn">
    <app-product-card
      [showSelectButton]="showSelectButton"
      [showRetailPrices]="showRetailPrices"
      [product]="product"
      [displayPackages]="displayPackages"
      [selectedProductsIds]="selectedProductsIds"
      (removeProduct)="isRemoveProduct($event)"
      (selectProduct)="isSelectProduct($event.product, $event.packId)"
      (showDetailPopinEvent)="showDetailPopin($event, product)"
    ></app-product-card>
  </div>
</div>
