import { Tool } from '@app/shared/utils/enums';
import { AfterViewInit, Component } from '@angular/core';
import { DialogRef } from '@app/core/cdk/dialog';
import { GtmEventsService } from '@app/core/services/tracking';
import { PopinSize } from '@app/shared/utils';

@Component({
  selector: 'app-multifunction-popin',
  templateUrl: './multifunction-popin.component.html',
  styleUrls: ['./multifunction-popin.component.scss'],
})
export class MultifunctionPopinComponent implements AfterViewInit {
  DialogSize = PopinSize;

  constructor(private _dialogRef: DialogRef<MultifunctionPopinComponent>, private _gtmEventsService: GtmEventsService) {}

  ngAfterViewInit(): void {
    this._gtmEventsService.sendMultifunctionChooseRecipientView();
  }

  navigateToStarter(): void {
    this._dialogRef.close(Tool.StarterKit);
  }
  navigateToPersonalized(): void {
    this._dialogRef.close(Tool.PersonalizedBag);
  }
}
