<header class="header l-header">
  <!-- App Name -->
  <a routerLink="/home" class="header__link_no_color">
    <h3
      class="header__title"
      [matTooltipPosition]="'below'"
      matTooltipHideDelay="1"
      matTooltip="{{ 'tool-tip_home' | translateKey }}"
      #tooltip="matTooltip"
    >
      Vet Services
    </h3>
  </a>

  <!-- Logo Royal Canin -->
  <a routerLink="/home" title="Back to home" class="main-logo">
    <svg
      class="header__logo--img"
      width="75px"
      height="40px"
      viewBox="0 0 75 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Logos/Crown-Logo" fill="#EC001A">
          <g id="Crown">
            <path
              d="M15.3420132,35.9137089 C14.6866667,35.9137089 14.0366133,35.6157812 13.6137794,35.0530374 C12.9016663,34.1018252 13.0958232,32.7535035 14.04812,32.0416429 C19.7760924,27.7687203 28.5430684,25.2159173 37.4980643,25.2159173 L37.4988314,25.2159173 C46.4207647,25.2159173 55.1654943,27.7672674 60.8908585,32.0416429 C61.8470676,32.7535035 62.0422984,34.0999134 61.3240485,35.0512021 C60.6160779,36.0024908 59.2627333,36.1963426 58.3066009,35.4834114 C54.4407239,32.599722 46.9441674,29.5178985 37.5003657,29.5178985 L37.4980643,29.5178985 C28.010537,29.5178985 20.5013231,32.599722 16.6327612,35.4849408 C16.247516,35.7748391 15.7940742,35.9137089 15.3420132,35.9137089"
              id="Fill-28"
            ></path>
            <path
              d="M17.314228,39.5883826 C16.8890928,39.5883826 16.4651849,39.4043954 16.1715332,39.0514092 C15.6581797,38.4237422 15.7486993,37.4965416 16.3779639,36.9809034 C21.3309199,32.9237031 29.2260694,30.5021229 37.5019382,30.5021229 C45.7363061,30.5021229 53.6091326,32.922556 58.5628557,36.980521 C59.1937312,37.4942475 59.286859,38.4221363 58.7692864,39.0501856 C58.2512535,39.678235 57.3211264,39.7681639 56.6918618,39.2529845 C52.2466594,35.6152994 45.0739789,33.4435472 37.5019382,33.4435472 C30.0049982,33.4435472 22.6255035,35.6692874 18.2520263,39.2537492 C17.9788566,39.4797185 17.6457752,39.5883826 17.314228,39.5883826"
              id="Fill-30"
            ></path>
            <path
              d="M5.7538941,8.63182054 C4.87600748,8.63182054 4.00801664,8.86864859 3.24749864,9.31729896 C2.11170788,9.98702454 1.3014041,11.0611243 0.971007592,12.3367228 C0.645980885,13.614692 0.830165242,14.9396903 1.50545675,16.0719073 C2.38188585,17.5483165 4.00418106,18.475823 5.74315449,18.4918817 L5.77690756,18.4938699 C6.65793936,18.4931052 7.52738772,18.2555889 8.28790572,17.8054091 C9.4252307,17.1352247 10.2310085,16.0638015 10.5594872,14.7889676 C10.8894235,13.5129867 10.697568,12.1860766 10.0245778,11.0534008 C9.14124466,9.55902111 7.50207292,8.63182054 5.7538941,8.63182054"
              id="Fill-32"
            ></path>
            <path
              d="M37.4711923,12.42591 C34.7407225,12.42591 32.518313,14.6386502 32.518313,17.3563229 C32.518313,20.0759073 34.7407225,22.2890299 37.4711923,22.2890299 C40.1985936,22.2890299 42.41571,20.0759073 42.41571,17.3563229 C42.41571,14.6386502 40.1985936,12.42591 37.4711923,12.42591"
              id="Fill-34"
            ></path>
            <path
              d="M37.4711923,0.0764242679 C34.7407225,0.0764242679 32.518313,2.289241 32.518313,5.00691366 C32.518313,7.72649808 34.7407225,9.93885599 37.4711923,9.93885599 C40.1985936,9.93885599 42.41571,7.72649808 42.41571,5.00691366 C42.41571,2.289241 40.1985936,0.0764242679 37.4711923,0.0764242679"
              id="Fill-36"
            ></path>
            <path
              d="M24.3100347,14.144026 C23.8694805,14.144026 23.4290796,14.2018374 23.0011828,14.3173074 C20.3678297,15.035668 18.8115064,17.7540289 19.5351262,20.3764962 C20.1152954,22.4909723 22.0683707,23.9831343 24.2870213,24.004546 L24.3990201,24.0069165 C24.8103472,23.9991931 25.2202169,23.9402346 25.6191935,23.831647 C26.8923746,23.4851607 27.9521442,22.6624184 28.6091017,21.518272 C29.262914,20.3731315 29.4302218,19.0458391 29.0817981,17.7759758 C28.4931906,15.636188 26.5324441,14.144026 24.3100347,14.144026"
              id="Fill-39"
            ></path>
            <path
              d="M12.0605322,19.2618826 C11.1795771,19.2618826 10.3123534,19.4983283 9.55567097,19.9477433 C8.41681176,20.6163219 7.60804221,21.6900392 7.27871966,22.9660966 C6.9533861,24.243607 7.14140604,25.5708994 7.81125102,26.7049517 C8.68852396,28.1791432 10.3108192,29.1056556 12.0528611,29.1224025 L12.0820114,29.1239319 C12.966035,29.1239319 13.828656,28.8864156 14.5945438,28.4381476 C15.7311017,27.767275 16.5391808,26.6953929 16.8645911,25.4190296 C17.1945273,24.1441958 17.0042061,22.8180504 16.3335173,21.6835393 C15.4448143,20.1902302 13.8102452,19.2618826 12.0605322,19.2618826"
              id="Fill-41"
            ></path>
            <path
              d="M51.9347196,14.317315 C51.5068995,14.2018451 51.0697973,14.1440337 50.6300101,14.1440337 C48.4083678,14.1440337 46.446931,15.6361957 45.8605481,17.7759834 C45.1407639,20.3976096 46.6936352,23.113294 49.3247636,23.8316546 C49.7528906,23.9479657 50.1949024,24.0079948 50.6339224,24.0079948 C52.850962,24.0079948 54.8163111,22.5135386 55.4026173,20.3765038 C56.1194097,17.7540365 54.5661549,15.0356756 51.9347196,14.317315"
              id="Fill-43"
            ></path>
            <path
              d="M55.2015564,2.40552151 C54.7745801,2.28959276 54.3404696,2.23025193 53.8970003,2.23025193 C51.6744374,2.23025193 49.7168362,3.72478454 49.1282287,5.8622017 C48.4083678,8.48421021 49.9670692,11.2018829 52.5946688,11.9190964 C53.0221054,12.0357134 53.462583,12.0953601 53.9000688,12.0953601 C56.1194097,12.0953601 58.0839917,10.6009804 58.6702979,8.46394562 C59.3916163,5.84071359 57.8337588,3.1238821 55.2015564,2.40552151"
              id="Fill-45"
            ></path>
            <path
              d="M65.3844122,19.947728 C64.62236,19.498313 63.7532185,19.2618673 62.8726469,19.2618673 C61.1299147,19.2618673 59.4963428,20.1902149 58.6104015,21.6836005 C57.9377949,22.8180351 57.7474736,24.1441805 58.0762592,25.4190143 C58.4017462,26.6954541 59.2105924,27.7672597 60.3463065,28.4381323 C61.1138052,28.8871651 61.9793413,29.1239931 62.8634416,29.1239931 C64.6042561,29.1239931 66.236984,28.1975572 67.1241528,26.7050129 C67.7963758,25.5709605 67.9833218,24.2435917 67.6552266,22.9660813 C67.3259808,21.6901004 66.5178249,20.6163066 65.3844122,19.947728"
              id="Fill-47"
            ></path>
            <path
              d="M73.9629157,12.3367458 C73.6341301,11.0611472 72.8260509,9.98704748 71.688726,9.3173219 C70.9259066,8.86867153 70.0587596,8.63184348 69.1777278,8.63184348 C67.4372201,8.63184348 65.8034182,9.55904405 64.9170166,11.0527355 C64.2432593,12.1871702 64.0529381,13.5140802 64.3828743,14.7889905 C64.7098188,16.0638244 65.5172075,17.1352477 66.6540722,17.8054321 C67.4172752,18.2566824 68.2860331,18.4938928 69.1685991,18.4938928 C70.9128657,18.4938928 72.5467443,17.5666923 73.4318418,16.0719303 C74.103758,14.9397132 74.2926218,13.6147149 73.9629157,12.3367458"
              id="Fill-49"
            ></path>
            <path
              d="M21.0454533,12.095383 C21.4859308,12.095383 21.9264084,12.0352775 22.3500094,11.9190429 C24.9776858,11.2019058 26.5324748,8.48415668 25.8141482,5.86214817 C25.2293763,3.72480748 23.2655614,2.23027487 21.0407739,2.23027487 C20.6010634,2.23027487 20.1598188,2.2896157 19.7346836,2.40554445 C17.1005634,3.12390504 15.5453141,5.84073653 16.2644078,8.46465679 C16.8476455,10.58028 18.8007208,12.0716773 21.0163029,12.0930889 L21.0454533,12.095383 Z"
              id="Fill-51"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </a>

  <!-- Menu -->
  <div class="header-right">
    <!-- Header menu: Product -->
    <div
      [matTooltipPosition]="'below'"
      matTooltipHideDelay="1"
      matTooltip="{{ 'tool-tip_services' | translateKey }}"
      #tooltip="matTooltip"
      class="header__menu-icon rc-icon rc-icon--product"
      data-content="product"
      (click)="showDropdownMenu($event)"
    >
      <div class="dropdown dropdown--product">
        <div class="dropdown-menu dropdown-menu--product" data-content="product">
          <div class="dropdown-menu__content dropdown-menu__content--product">
            <ng-container *ngFor="let product of productsMenu">
              <a class="dropdown-grid" (click)="externalLink(product)">
                <div class="dropdown-grid__icon">
                  <img class="dropdown-grid__product-icon" [src]="product.icon" alt="{{ product.icon }}" />
                </div>
                <div class="dropdown-grid__product-name">
                  {{ product.text }}
                </div>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Header menu: Profile -->
    <div class="header__menu-icon rc-icon rc-icon--profile" data-content="profile" (click)="showDropdownMenu($event)"></div>

    <!-- Header menu: Navigation profile -->
    <div class="header__menu-profile" data-content="profile" (click)="showDropdownMenu($event)">
      <div class="navigation-item navigation-profile-picture" [style.background-image]="'url(' + vetImage + ')'"></div>
      <p class="navigation-item navigation-username">{{ vetFullName }}</p>
      <em class="rc-icon rc-icon--arrow-down navigation-item__icon"></em>

      <div class="dropdown dropdown--profile {{ vet && vet.clinics.length > 1 ? 'dropdown--profile-3' : 'dropdown--profile-2' }}">
        <div class="dropdown-menu dropdown-menu--profile" data-content="profile">
          <div class="dropdown-menu__content dropdown-menu__content--profile">
            <ul class="dropdown-menu__links">
              <li routerLink="/account" routerLinkActive="active">
                <img class="left dropdown-menu__icon" src="./assets/icons/settings.svg" alt="account-link-icon" />
                <a class="left" i18n="@@header-link_account">Account</a>
                <div class="clear"></div>
              </li>
              <li routerLink="/register/select-clinic" routerLinkActive="active" *ngIf="vet && vet.clinics.length > 1">
                <img class="left dropdown-menu__icon" src="./assets/icons/switch.svg" alt="switch-clinic-link-icon" />
                <a class="left" i18n="@@header-link_select-clinic">Switch clinic</a>
                <div class="clear"></div>
              </li>
              <li (click)="logout($event)">
                <img class="left dropdown-menu__icon" alt="logout-link-icon" src="./assets/icons/logout.svg" />
                <a class="left" i18n="@@header-link_log-out">Log out</a>
                <div class="clear"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- Subheader -->
<div class="l-sub-header sub-header">
  <div class="left sub-header__nav">
    <p class="sub-header__nav-title" *ngFor="let link of subHeaderLinks">
      {{ link | capitalizeFirst }}
    </p>
  </div>
  <div class="sub-header__dropdown-menu" [ngClass]="!isMobile ? 'right' : ''">
    <em class="sub-header__dropdown-menu-button right rc-icon rc-icon--dots-horizontal" (click)="showSubMenu($event)"></em>
    <ul class="sub-header__hyperlinks" *ngIf="subMenuIsActive">
      <li
        class="sub-header__link"
        routerLinkActive="active"
        (click)="hideSubMenu($event)"
        routerLink="{{ menu.link }}"
        *ngFor="let menu of submenu"
      >
        <em class="left rc-icon rc-icon-sub-header rc-icon--{{ menu.icon }}"></em>
        <a class="anchor" routerLinkActive="active">{{ menu.text || '--' }}</a>
      </li>
    </ul>
  </div>
  <div class="clear"></div>
</div>

<!-- Dropdown: Mobile -->
<div class="dropdown-holder">
  <div class="dropdown-holder__header">
    <span class="dropdown-holder__close-action" (click)="closeDropdown()">
      <em class="rc-icon rc-icon--close"></em>
    </span>
  </div>

  <!-- Dropdown wrapper -->
  <div class="dropdown-holder__wrap">
    <!-- Menu : Product -->
    <div class="dropdown-menu dropdown-menu--product" data-content="product">
      <div class="dropdown-menu__content dropdown-menu__content--product">
        <ng-container *ngFor="let product of productsMenu">
          <a class="dropdown-grid" (click)="externalLink(product)">
            <div class="dropdown-grid__icon">
              <img class="dropdown-grid__product-icon" [src]="product.icon" alt="{{ product.icon }}" />
            </div>
            <div class="dropdown-grid__product-name">
              {{ product.text | titlecase }}
            </div>
          </a>
        </ng-container>
      </div>
    </div>

    <!-- Menu : Profile -->
    <div class="dropdown-menu dropdown-menu--profile" data-content="profile">
      <div class="dropdown-menu__content dropdown-menu__content--profile">
        <ul class="dropdown-menu__links">
          <li routerLink="/account" routerLinkActive="active" (click)="closeDropdown()">
            <span class="left rc-icon rc-icon--settings"></span>
            <a class="left">Account</a>
            <div class="clear"></div>
          </li>
          <li routerLink="/register/select-clinic" routerLinkActive="active" *ngIf="vet && vet.clinics.length > 1">
            <span class="left rc-icon rc-icon--settings"></span>
            <a class="left" i18n="@@header-link_select-clinic">Switch clinic</a>
            <div class="clear"></div>
          </li>
          <li (click)="logout($event)">
            <span class="left rc-icon rc-icon--logout"></span>
            <a class="left">Log out</a>
            <div class="clear"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
