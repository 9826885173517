import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-rc-input-number, rc-input-number',
  templateUrl: './rc-input-number.component.html',
  styleUrls: ['./rc-input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RcInputNumberComponent),
      multi: true,
    },
  ],
})
export class RcInputNumberComponent implements ControlValueAccessor {
  /** Properties & Variables **/

  @Input() value = 1;
  @Input() step = 1;
  @Input() min = 1;
  @Input() max = 10;

  @Output() updateValue: EventEmitter<number> = new EventEmitter();

  get disableMinus(): boolean {
    return this.value <= this.min;
  }
  get disablePlus(): boolean {
    return this.value >= this.max;
  }

  /** Initializer **/

  /** Methods **/
  increment() {
    this.setValue(Math.min(this.max, this.value + this.step));
  }

  decrement() {
    this.setValue(Math.max(this.min, this.value - this.step));
  }

  setValue(value: number) {
    if (value !== this.value) {
      this.value = value;
      this.updateValue.emit(value);
      this.propagateChange(value);
    }
  }

  /** ControlValueAccessor */
  propagateChange = (_: number) => {
    // empty
  };

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // empty
  }
}
