/**
 * Order matters
 */
export enum WeightManagementStep {
  Identification = 'weight-management_identification',
  Profile = 'weight-management_profile',
  Weight = 'weight-management_weight',
  Recommendation = 'weight-management_recommendation',
  NewWeightLoss = 'weight-management_new_weight_loss',
  Stabilization = 'weight-management_stabilization',
  FollowUp = 'weight-management_follow_up',
  Allowance = 'weight-management_allowance',
}

export const steps = Object.values(WeightManagementStep);
