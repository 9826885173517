import { deletePetOwnerAddress, petOwnerFail, updatePetOwner, updatePetOwnerSuccess } from './../consultation.actions';
import { Injectable } from '@angular/core';
import { VetService } from '@app/core/services';
import { AppState } from '@app/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RcToasterService } from '@rc/ui';
import { of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectConsultationPetOwner } from '../consultation.selectors';
import { toggleLoader } from '@app/store/core';

@Injectable()
export class PetOwnerEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private vetService: VetService,
    private toaster: RcToasterService
  ) {}

  /**
   * Update Pet Owner
   */
  updatePetOwner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePetOwner),
      switchMap(({ value }) => {
        return this.vetService.apiUpdatePetOwner(value.id, value).pipe(
          switchMap((petOwner) => {
            return [updatePetOwnerSuccess({ value: petOwner })];
          }),
          catchError((error: string) => of(petOwnerFail({ error })))
        );
      })
    )
  );

  /**
   * Delete Pet Owner addresses
   */
  deletePetOwnerAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePetOwnerAddress),
      withLatestFrom(this.store$.select(selectConsultationPetOwner)),
      switchMap(([{ id }, petOwner]) => {
        return this.vetService.apiDeletePetOwnerAddress(petOwner.id, id).pipe(
          switchMap((petOwner) => {
            return [updatePetOwnerSuccess({ value: petOwner })];
          }),
          catchError((error: string) => of(petOwnerFail({ error })))
        );
      })
    )
  );

  /**
   * Pet owner generic fail
   */
  petOwnerFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(petOwnerFail),
      tap(({ error }) => {
        return this.toaster.open({
          title: $localize`:@@error_general_title:`,
          text: error,
          type: 'error',
        });
      }),
      switchMap(() => [toggleLoader({ value: false })])
    )
  );
}
