import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rc-tooltip',
  templateUrl: './rc-tooltip.component.html',
  styleUrls: ['./rc-tooltip.component.scss'],
})
export class RcTooltipComponent {
  @Input() primaryPosition = 'bottom';
  @Input() secondaryPosition = 'center';
  @Input() size = 'small';
}
