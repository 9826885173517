import { ComponentRef, EmbeddedViewRef } from '@angular/core';

import { Portal, PortalOutlet } from './portal';

import { ComponentPortal } from './component-portal';
import { TemplatePortal } from './template-portal';

import {
  throwPortalAlreadyAttachedError,
  throwNullPortalError,
  throwPortalOutletAlreadyDisposedError,
  throwUnknownPortalTypeError,
} from './portal-errors';

export abstract class BasePortalOutlet implements PortalOutlet {
  protected _attachedPortal: Portal<any> | null;
  private _disposeFn: (() => void) | null;
  private _isDisposed = false;

  static throwContentAlreadyAttachedError() {
    throw Error('Attempting to attach dialog content after content is already attached');
  }

  abstract attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T>;
  abstract attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C>;

  hasAttached(): boolean {
    return !!this._attachedPortal;
  }

  attach(portal: Portal<any>): any {
    if (!portal) {
      throwNullPortalError();
    }

    if (this.hasAttached()) {
      throwPortalAlreadyAttachedError();
    }

    if (this._isDisposed) {
      throwPortalOutletAlreadyDisposedError();
    }

    if (portal instanceof ComponentPortal) {
      this._attachedPortal = portal;
      return this.attachComponentPortal(portal);
    } else if (portal instanceof TemplatePortal) {
      this._attachedPortal = portal;
      return this.attachTemplatePortal(portal);
    }

    throwUnknownPortalTypeError();
  }

  detach() {
    if (this._attachedPortal) {
      this._attachedPortal.setAttachedHost(null);
      this._attachedPortal = null;
    }

    this._invokeDisposeFn();
  }

  dispose(): void {
    if (this.hasAttached()) {
      this.detach();
    }

    this._invokeDisposeFn();
    this._isDisposed = true;
  }

  setDisposeFn(fn: () => void) {
    this._disposeFn = fn;
  }

  private _invokeDisposeFn() {
    if (this._disposeFn) {
      this._disposeFn();
      this._disposeFn = null;
    }
  }
}
