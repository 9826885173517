import { DOCUMENT } from '@angular/common';
import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  Output,
  ViewContainerRef,
  Inject,
} from '@angular/core';

import { Portal } from './portal';
import { BasePortalOutlet } from './base-portal-outlet';
import { ComponentPortal } from './component-portal';
import { TemplatePortal } from './template-portal';

export type PortalOutletAttachedRef = ComponentRef<any> | EmbeddedViewRef<any> | null;

@Directive({
  selector: '[appPortalOutlet], [rcPortalHost], [portalHost]',
  exportAs: 'rcPortalOutlet, rcPortalHost',
})
export class PortalOutletDirective extends BasePortalOutlet {
  @Input()
  set portal(portal: Portal<any> | null) {
    if (this.hasAttached() && !portal && !this._isInitialized) {
      return;
    }

    if (this.hasAttached()) {
      super.detach();
    }

    if (portal) {
      super.attach(portal);
    }

    this._attachedPortal = portal;
  }
  get portal(): Portal<any> | null {
    return this._attachedPortal;
  }

  @Output()
  attached: EventEmitter<PortalOutletAttachedRef> = new EventEmitter<PortalOutletAttachedRef>();

  private _document: Document;
  private _isInitialized = false;
  private _attachedRef: PortalOutletAttachedRef;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT) document?: any
  ) {
    super();
    this._document = document;
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    portal.setAttachedHost(this);

    // If the portal specifies an origin, use that as the logical location of the component
    // in the application tree. Otherwise use the location of this PortalOutlet.
    const viewContainerRef = portal.viewContainerRef != null ? portal.viewContainerRef : this._viewContainerRef;

    const resolver = portal.componentFactoryResolver || this._componentFactoryResolver;
    const componentFactory = resolver.resolveComponentFactory(portal.component);
    const ref = viewContainerRef.createComponent(componentFactory, viewContainerRef.length, portal.injector || viewContainerRef.injector);

    super.setDisposeFn(() => ref.destroy());
    this._attachedPortal = portal;
    this._attachedRef = ref;
    this.attached.emit(ref);

    return ref;
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    portal.setAttachedHost(this);
    const viewRef = this._viewContainerRef.createEmbeddedView(portal.templateRef, portal.context);
    super.setDisposeFn(() => this._viewContainerRef.clear());

    this._attachedPortal = portal;
    this._attachedRef = viewRef;
    this.attached.emit(viewRef);

    return viewRef;
  }
}
