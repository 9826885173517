<span class="rc-attribute rc-attribute--{{ attribute.type }}" [ngSwitch]="attribute.type">
  <a
    *ngSwitchCase="[AttributeType.Link, AttributeType.FbLink].includes(attribute.type) ? attribute.type : ''"
    class="rc-attribute--link"
    [href]="href"
    target="_blank"
  >
    {{ attrVal | async }}
  </a>

  <img *ngSwitchCase="AttributeType.Image" [src]="val || attribute.default" class="rc-logo" alt="" />

  <span *ngSwitchCase="AttributeType.Species">
    {{ val | translationCode: LocaleContentEnum.Species }}
  </span>
  <span *ngSwitchCase="AttributeType.Lang">
    {{ 'language_' + val | translateKey }}
  </span>
  <span *ngSwitchCase="AttributeType.Gender">
    {{ val | translationCode: LocaleContentEnum.Gender }}
  </span>
  <span *ngSwitchCase="AttributeType.MeasurementSystem">
    {{ val | translationCode: LocaleContentEnum.MeasurementSystem }}
  </span>
  <span *ngSwitchCase="AttributeType.Breed">
    {{ val | breedIcd | async }}
  </span>
  <span *ngSwitchCase="AttributeType.PetActivity">
    {{ val | translationCode: LocaleContentEnum.PetActivity }}
  </span>
  <span *ngSwitchCase="AttributeType.Weight">
    {{ val | weight: 2 }}
  </span>
  <span *ngSwitchCase="AttributeType.WeightNumber">
    {{ { measure: val, measureUnit: VetPreferences.currentBigMeasurementUnit } | weight: 2 }}
  </span>
  <span *ngSwitchCase="AttributeType.Date">
    {{ val | date: 'shortDate' }}
  </span>
  <span *ngSwitchCase="AttributeType.Neutered">
    {{ val | neutered }}
  </span>
  <span *ngSwitchDefault>
    {{ attrVal | async }}
  </span>
</span>
