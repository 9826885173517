import { Clinic } from '@app/core/models';
import { ShareRecommendationMethod } from '@app/shared/utils';
import { ClinicFeature } from '@app/shared/utils/enums/feature.enum';
import { PriceType } from '@app/shared/utils/enums/price-type.enum';
import { createSelector } from '@ngrx/store';
import { VetState } from '.';
import { AppState } from '..';

const vetState = (state: AppState) => state.vet;

export const selectVet = createSelector(vetState, (state) => state.vet);
/**
 * Select okta user
 * Raw data from Okta
 */
export const selectOktaUser = createSelector(vetState, (state) => state.oktaUser);
/**
 * Select vet validity state
 * See vet.effects for criteria
 */
export const selectIsVetValid = createSelector(vetState, (state) => state.isVetValid);

export const selectCurrentClinicId = createSelector(vetState, (state) => state.currentClinicId);

/**
 * Select current clinic
 */
export const selectClinic = createSelector(vetState, selectCurrentClinicId, (state, currentClinicId) => {
  let clinic = state.vet?.clinics?.find((c) => c.id === currentClinicId);
  /**
   * Select current clinic
   * If clinic.muf = true, add muf to clinic.features for consistency
   * Once it has been updated in DB, it can be deleted
   */
  if (clinic && clinic.muf && clinic.features && !clinic.features.find((f) => f.name === ClinicFeature.MUF)) {
    clinic = {
      ...clinic,
      features: [...clinic.features, { name: ClinicFeature.MUF, updatedAt: new Date() }],
    };
  }
  return clinic;
});

export const selectCurrentClinicCountry = createSelector(selectClinic, (clinic) => clinic?.companyAddress?.country);
/**
 * Select market data
 */
export const selectMarket = createSelector(vetState, selectCurrentClinicCountry, (state, country) => {
  if (!country || state.market?.countryCode === country) {
    return state.market;
  }
});

export const selectMarketQuicklinks = createSelector(vetState, selectMarket, (state, market) => {
  return market?.quicklinks || [];
});

/**
 * Select share recommendation by email is authorized for this market
 */
export const selectCanShareRecoByEmail = createSelector(vetState, selectMarket, (state, market) => {
  return !market?.shareRecommendationMethods ? true : market.shareRecommendationMethods.includes(ShareRecommendationMethod.EMAIL);
});
/**
 * Select current clinic currency code based on country
 */
export const selectCurrentClinicCurrency = createSelector(selectClinic, selectMarket, (clinic, market) => {
  if (market?.countryCode === clinic?.companyAddress?.country) {
    return market?.currency;
  }
});
export const selectClinics = createSelector(vetState, (state) => (state.vet && state.vet.clinics ? state.vet.clinics : []));

export const selectLanguageListener = createSelector(vetState, (state) =>
  state.vet && state.vet.user ? state.vet.user.preferredLanguage : null
);

export const selectMeasureUnitListener = createSelector(vetState, (state: VetState) => (state.vet ? state.vet.systemPreference : null));
/**
 * Select invitation
 */
export const selectInvitation = createSelector(vetState, (state) => state.invitation);

export const selectIsClinicAddressComplete = createSelector(
  selectClinic,
  ({ companyAddress }: Clinic = {}) => !!(companyAddress && companyAddress.address1 && companyAddress.zip && companyAddress.city)
);

export const selectIsChronoVet = createSelector(selectClinic, selectMarket, (clinic, market) => {
  return market?.chronovet_clinics?.includes(clinic.customerId);
});

/**
 * Select can edit prices for this market, by default true if nothing is set
 */
export const selectCanEditPrices = createSelector(vetState, selectMarket, (state, market) => market?.canEditPrices ?? true);
/**
 * Check wheter retail prices are activated, by default true if nothing is set
 */
export const selectIsRetailPriceActivated = createSelector(vetState, selectMarket, (state, market) =>
  !market?.activatedPrices ? true : market.activatedPrices.includes(PriceType.RETAIL)
);
/**
 * Check wheter wholesage prices are activated, by default false if nothing is set
 */
export const selectIsWholesalePriceActivated = createSelector(vetState, selectMarket, (state, market) =>
  !market?.activatedPrices ? false : market.activatedPrices.includes(PriceType.WHOLESALE)
);

export const selectWholesalePrices = createSelector(vetState, selectMarket, (state, market) => market?.wholesalePrices);
export const selectPricesVat = createSelector(vetState, selectMarket, (state, market) => market?.pricesVat);
export const selectIsSptRetailEnable = createSelector(vetState, selectMarket, (state, market) => market?.enableSptRetail);
