export enum Programs {
  WEIGHT_LOSS = 'weight_loss',
  WEIGHT_STABILISATION_STEP_1 = 'weight_stabilization_step1',
  WEIGHT_STABILISATION_STEP_2 = 'weight_stabilization_step2',
  GROWTH = 'growth',
  NORMAL_ADULT = 'normal_adult',
}

export enum ProductPrograms {
  WEIGHT_MAINTENANCE = 'weight maintenance',
  WEIGHT_LOSS = 'weight loss',
  AFTER_WEIGHT_LOSS = 'after weight loss',
}
