import { Component, Inject } from '@angular/core';
import { DialogRef, DIALOG_DATA } from '@app/core/cdk/dialog';
import { PopinSize } from '@app/shared/utils';

@Component({
  selector: 'app-active-program-popin',
  templateUrl: './active-program-popin.component.html',
  styleUrls: ['./active-program-popin.component.scss'],
})
export class ActiveProgramPopinComponent {
  DialogSize = PopinSize;

  constructor(private _dialogRef: DialogRef<ActiveProgramPopinComponent>, @Inject(DIALOG_DATA) public data: any) {}

  stay() {
    this._dialogRef.close({
      stay: true,
    });
  }

  startNewConsultation() {
    this._dialogRef.close({
      start: true,
    });
  }
}
