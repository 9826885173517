export enum EmailPopinType {
  Recommendation = 'Recommendation',
  Collaborators = 'Collaborators',
  Contact = 'Contact',
}

export enum POLetterFrom {
  WeightTab = 'weight_tab',
  HealthTab = 'health_tab',
  FlowAllowanceRationBlock = 'flow_allowance_ration_block',
  FlowAllowanceWeightBlock = 'flow_allowance_weight_block',
}

export enum PoLetterType {
  WeightLoss = 'weight_loss_PO_template',
  DailyAllowance = 'daily_allowance_PO_template',
  DailyRenalDetect = 'daily_renal_detect_PO_template',
  DailyWeightLoss = 'daily_weight_loss_PO_template',
  RenalSmartReco = 'renal_smart_reco_PO_template',
  SmartReco = 'smart_reco_PO_template',
}
