export enum MeasurementSubstanceTypeCodeEnum {
  mgPerL = 'mg/l',
  mgPerDl = 'mg/dl',
  microMolPerL = 'μmol/l',
  gPerL = 'g/l',
  mmolPerL = 'mmol/l',
}

export const measurementSubstanceTypeTrad = {
  [MeasurementSubstanceTypeCodeEnum.mgPerL]: $localize`:@@measurement_mg-l:`,
  [MeasurementSubstanceTypeCodeEnum.mgPerDl]: $localize`:@@measurement_mg-dl:`,
  [MeasurementSubstanceTypeCodeEnum.microMolPerL]: $localize`:@@measurement_g-l:`,
  [MeasurementSubstanceTypeCodeEnum.gPerL]: $localize`:@@measurement_g-l:`,
  [MeasurementSubstanceTypeCodeEnum.mmolPerL]: $localize`:@@measurement_milli-mol-l:`,
};
