export enum LifestageType {
  Baby = 'baby',
  Kitten = 'kitten',
  Puppy = 'puppy',
  Adult = 'adult',
  Mature = 'mature',
  Senior = 'ageing',
  Junior = 'junior',
  All = 'All',
  NotAvailable = 'not_available',
}
