<div
  class="rc-select"
  [class.rc-select--has-prefix]="showPrefix"
  [class.is-disabled]="disabled"
  [class.is-active]="!!labelValue"
  (click)="toggleSelectList($event)"
>
  <div class="rc-select__label" *ngIf="label" [class.error-state]="inputError">
    {{ label }}
    <span *ngIf="!required" i18n="@@form-attribute_optional">Optional</span>
  </div>

  <div
    class="rc-select-wrapper"
    (focus)="isFocused = true"
    (focusout)="isFocused = false"
    [class.rc-select--active]="(isFocused && !inputError) || (value && !inputError)"
    [class.error-bottom]="inputError"
  >
    <div class="rc-select__value-wrapper" [ngSwitch]="!!labelValue">
      <span
        class="rc-select__placeholder"
        id="c-select__placeholder_{{ placeholder }}"
        *ngSwitchCase="false"
        [class.error-state]="inputError"
      >
        {{ placeholder }}
      </span>
      <span class="rc-select__value-text" id="rc-select__value-text_{{ labelValue }}" *ngSwitchCase="true">{{ labelValue }}</span>
    </div>
    <div class="rc-select__arrow-wrapper" [class.active]="dropDownOpen">
      <em class="rc-icon rc-icon--arrow-down" [class.active]="dropDownOpen"></em>
    </div>
  </div>

  <ng-container *ngIf="showPrefix">
    <div class="rc-select-prefix">
      <svg class="rc-select-prefix__icon" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icons/Feedback-Warning/32" fill="#767676">
            <g id="Page-1">
              <path
                d="M16,2 C8.28,2 2,8.28 2,16 C2,23.72 8.28,30 16,30 C23.72,30 30,23.72 30,16 C30,8.28 23.72,2 16,2 M16,32 C7.178,32 0,24.822 0,16 C0,7.178 7.178,0 16,0 C24.822,0 32,7.178 32,16 C32,24.822 24.822,32 16,32"
                id="Fill-1"
              />
              <path
                d="M16,19 C15.448,19 15,18.552 15,18 L15,10 C15,9.448 15.448,9 16,9 C16.552,9 17,9.448 17,10 L17,18 C17,18.552 16.552,19 16,19"
                id="Fill-3"
              />
              <path
                d="M16,23 C15.87,23 15.74,22.97 15.62,22.92 C15.49,22.87 15.39,22.8 15.29,22.71 C15.25,22.66 15.2,22.61 15.17,22.56 C15.13,22.5 15.1,22.44 15.08,22.38 C15.05,22.32 15.03,22.26 15.02,22.2 C15.01,22.13 15,22.06 15,22 C15,21.94 15.01,21.87 15.02,21.8 C15.03,21.74 15.05,21.68 15.08,21.62 C15.1,21.56 15.13,21.5 15.17,21.44 C15.2,21.39 15.25,21.34 15.29,21.29 C15.39,21.2 15.49,21.13 15.62,21.08 C15.98,20.93 16.43,21.01 16.71,21.29 C16.75,21.34 16.8,21.39 16.83,21.44 C16.87,21.5 16.9,21.56 16.92,21.62 C16.95,21.68 16.97,21.74 16.98,21.8 C16.99,21.87 17,21.94 17,22 C17,22.06 16.99,22.13 16.98,22.2 C16.97,22.26 16.95,22.32 16.92,22.38 C16.9,22.44 16.87,22.5 16.83,22.56 C16.8,22.61 16.75,22.66 16.71,22.71 C16.52,22.89 16.26,23 16,23"
                id="Fill-5"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  </ng-container>

  <div class="rc-select-list" *ngIf="items && items.length > 0" [class.is-opened]="dropDownOpen">
    <div class="rc-option" *ngFor="let item of items" (click)="didSelectOption($event, item)">
      <span class="rc-option__text" id="rc-select-option-{{ item.__key }}" [title]="item.__label">{{ item.__label }}</span>
    </div>
  </div>
</div>
