export enum RangeType {
  BHN = 'bhn',
  FBN = 'fbn',
  FCN = 'fcn',
  FHN = 'fhn',
  CCN = 'ccn',
  SHN = 'shn',
}

export enum RangeCategoryType {
  BreedHealthNutrition = 'Breed Health Nutrition',
  FelineBreedNutrition = 'Feline Breed Nutrition',
  FelineCareNutrition = 'Feline Care Nutrition',
  FelineHealthNutrition = 'Feline Health Nutrition',
  CanineCareNutrition = 'Canine Care Nutrition',
  SizeHealthNutrition = 'Size Health Nutrition',
}
