<div class="rc-tooltip">
  <div class="rc-tooltip-main">
    <ng-content select="[main]"></ng-content>
  </div>

  <div
    class="rc-tooltip-content rc-tooltip-content--pos-prim-{{ primaryPosition }} rc-tooltip-content--pos-sec-{{
      secondaryPosition
    }} rc-tooltip-content--size-{{ size }}"
  >
    <ng-content select="[tooltip]"></ng-content>
  </div>
</div>
