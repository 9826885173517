import { createReducer, on } from '@ngrx/store';
import { getConsultationsWithoutVisitSuccess, resetPet } from './pet.actions';
import { initialPetState } from './pet.state';
import { getChartConsultationsSuccess } from '@app/store/pet/chart.actions';

export const petReducer = createReducer(
  initialPetState,
  on(getConsultationsWithoutVisitSuccess, (state, { consultations }) => ({
    ...state,
    consultationsWithoutVisit: consultations,
  })),
  on(resetPet, () => initialPetState),
  on(getChartConsultationsSuccess, (state, { chartConsultationWeights }) => ({
    ...state,
    chartConsultationWeights,
  }))
);
