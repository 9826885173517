import { ProductCategoriesFilters } from '@app/core/models/territories-filters';
import { TerritoryType } from '../utils/enums/territoryType';
import { IconName } from '../utils/icon/icons';

export const territories: ProductCategoriesFilters[] = [
  {
    code: TerritoryType.WeightManagement,
    translationCode: 'product-select-territory-weight-management',
    icon: IconName.territory_weight_management,
    colorHex: '#00966C',
    disabled: false,
  },
  {
    code: TerritoryType.Dermatology,
    translationCode: 'product-select-territory-dermatology',
    icon: IconName.territory_dermatology,
    colorHex: '#009CBD',
    disabled: false,
  },
  {
    code: TerritoryType.GastrointestinalTract,
    translationCode: 'product-select-territory-gastro-tract',
    icon: IconName.territory_gastrointestinal_tract,
    colorHex: '#703F2A',
    disabled: false,
  },
  {
    code: TerritoryType.HealthManagement,
    translationCode: 'product-select-territory-health-management',
    icon: IconName.territory_health_management,
    colorHex: '#563D82',
    disabled: false,
  },
  {
    code: TerritoryType.Urinary,
    translationCode: 'product-select-territory-urinary',
    icon: IconName.territory_urinary,
    colorHex: '#C89403',
    disabled: false,
  },
  {
    code: TerritoryType.VitalSupport,
    translationCode: 'product-select-territory-vital-support',
    icon: IconName.territory_vital_support,
    colorHex: '#A54570',
    disabled: false,
  },
];
