import { environment } from '@env/environment';
/**
 * This file describes the custom configurations we need to display our custom graphs using Chart.js
 * */
import { ChartConfiguration, ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import { GraphElementPosition, GraphEvents, GraphType, GraphTypeScale } from '@app/shared/utils';

/**
 * Chart.js Options for our Custom Weight Tracking Graph
 * Line Chart
 */
export const _lineOptions = (data: ChartData) => {
  const max = Math.max(...[].concat(...data.datasets.map((dataset: ChartDataSets) => dataset.data as number[])));
  const step = +(max / 5).toPrecision(1);
  return {
    events: [GraphEvents.Mousemove],
    title: {
      display: true,
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
    },
    layout: {
      padding: {
        left: 25,
      },
    },
    scales: {
      type: GraphTypeScale.Linear,
      xAxes: [
        {
          ticks: {
            padding: 20,
            fontFamily: '"DINProRegular" ,Arial, sans-serif',
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            padding: 20,
            maxTicksLimit: 9,
            stepSize: step,
            suggestedMax: max * 1.2,
            fontFamily: '"DINProRegular" ,Arial, sans-serif',
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0.1)',
            zeroLineColor: 'rgba(0, 0, 0, 0.1)',
            drawBorder: false,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: true,
  };
};

/**
 * Chart.js Options for our Custom Weight management Graph
 * Bar Chart
 */
const _roundedBarOptions = {
  events: [GraphEvents.Mouseout, GraphEvents.Mousemove],
  // See ChartTitleOptions interface
  title: {
    display: false,
  },
  // See ChartLegendOptions interface
  legend: {
    display: false,
    position: GraphElementPosition.Top,
    // See ChartLegendLabelOptions interface
    labels: {
      fontSize: 15,
    },
  },
  // See ChartScales interface
  scales: {
    type: GraphTypeScale.Category,
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontFamily: '"DINProRegular" ,Arial, sans-serif',
        },
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          drawBorder: false,
          color: '#F6F6F6',
          zeroLineColor: '#F6F6F6',
        },
        ticks: {
          display: false,
          drawBorder: false,
          beginAtZero: true,
          maxTicksLimit: 6,
        },
      },
    ],
  },
  // See ChartLayoutOptions
  layout: {
    padding: {
      top: 20,
      right: 20,
      left: 20,
      bottom: 10,
    },
  },
};

/**
 * Chart.js DataSet Options for our Custom Weight Tracking Graph
 * Options for Min & Max values
 */
const _minMaxCustomLinesOptions = {
  borderDash: [10, 5],
  fill: false,
  borderWidth: 1,
  borderColor: environment.graphColor || 'rgb(226, 0, 26)',
  lineTension: 0.0001,
  pointRadius: 0,
  fillBetweenSet: 1,
  fillBetweenColor: 'rgba(255, 99, 132, 0.2)',
};

/**
 * Chart.js DataSet Options for our Custom Weight Tracking Graph
 * Options for real values
 */
const _valuesCustomLineOptions = {
  backgroundColor: 'rgba(255, 99, 132, 0.2)',
  fill: -1,
  borderColor: environment.graphColor || 'rgb(226, 0, 26)',
  lineTension: 0.0001,
  pointBorderColor: environment.graphColor || 'rgb(226, 0, 26)',
  pointBackgroundColor: 'white',
  pointBorderWidth: 3,
  pointRadius: 7,
};

/**
 * Chart.js plugin for our Custom Weight Tracking Graph
 * Function to fill background only between Min & Max values
 */
export const _fillBetweenLinesPlugin = {
  afterDatasetsDraw: function (chart) {
    const ctx = chart.chart.ctx;
    const datasets = chart.data.datasets;
    ctx.save();

    for (let d = 0; d < datasets.length; d++) {
      const dataset = datasets[d];

      if (dataset.fillBetweenSet === undefined) {
        continue;
      }

      // get meta for both data sets
      const meta1 = chart.getDatasetMeta(d);
      const meta2 = chart.getDatasetMeta(dataset.fillBetweenSet);

      // do not draw fill if one of the datasets is hidden
      if (meta1.hidden || meta2.hidden) {
        continue;
      }

      // create fill areas in pairs
      for (let p = 0; p < meta1.data.length - 1; p++) {
        // if null skip
        if (dataset.data[p] == null || dataset.data[p + 1] == null) {
          continue;
        }

        ctx.beginPath();

        // trace line 1
        let curr = meta1.data[p];
        let next = meta1.data[p + 1];
        ctx.moveTo(curr._view.x, curr._view.y);
        ctx.lineTo(curr._view.x, curr._view.y);
        if (curr._view.steppedLine === true) {
          ctx.lineTo(next._view.x, curr._view.y);
          ctx.lineTo(next._view.x, next._view.y);
        } else if (next._view.tension === 0) {
          ctx.lineTo(next._view.x, next._view.y);
        } else {
          ctx.bezierCurveTo(
            curr._view.controlPointNextX,
            curr._view.controlPointNextY,
            next._view.controlPointPreviousX,
            next._view.controlPointPreviousY,
            next._view.x,
            next._view.y
          );
        }

        // connect dataset1 to dataset2
        curr = meta2.data[p + 1];
        next = meta2.data[p];
        ctx.lineTo(curr._view.x, curr._view.y);

        // trace BACKWORDS set2 to complete the box
        if (curr._view.steppedLine === true) {
          ctx.lineTo(curr._view.x, next._view.y);
          ctx.lineTo(next._view.x, next._view.y);
        } else if (next._view.tension === 0) {
          ctx.lineTo(next._view.x, next._view.y);
        } else {
          // reverse bezier
          ctx.bezierCurveTo(
            curr._view.controlPointPreviousX,
            curr._view.controlPointPreviousY,
            next._view.controlPointNextX,
            next._view.controlPointNextY,
            next._view.x,
            next._view.y
          );
        }

        // close the loop and fill with shading
        ctx.closePath();
        ctx.fillStyle = dataset.fillBetweenColor || 'rgba(0,0,0,0.1)';
        ctx.fill();
      } // end for p loop
    }
  }, // end afterDatasetsDraw
};

/**
 * Render our custom Weight Tracking Graph
 * @param chartData: including Datasets ( values, minimums, maximums)
 * It merges the Dataset input with our custom Dataset Options
 */
export const _renderLineData = (chartData: ChartData) => {
  // Min Max Lines Options
  const mergedChartData = {
    datasets: [{}, {}, {}],
  } as ChartData;

  for (let i = 0, length = chartData.datasets.length || 0; chartData && i < length && i < 2; i++) {
    const dataset = chartData.datasets[i];
    mergedChartData.datasets[i] = {
      ..._minMaxCustomLinesOptions,
      ...dataset,
    } as ChartDataSets;
  }
  // Values Options
  const valuesLineOptions = chartData.datasets[2];
  mergedChartData.datasets[2] = {
    ...valuesLineOptions,
    ..._valuesCustomLineOptions,
  } as ChartDataSets;

  mergedChartData.labels = chartData.labels;

  return mergedChartData;
};

/**
 * Build a ChartConfiguration object to display the graph
 * @param graphType = Line, RoundedBar
 * @param chartData = DataSets
 * @param chartOptions = Displaying Chart Options
 */
export const chartConfiguration = (graphType: GraphType, chartData: ChartData, chartOptions?: ChartOptions): ChartConfiguration => {
  switch (graphType) {
    case GraphType.Line:
      return {
        type: GraphType.Line,
        data: _renderLineData(chartData),
        options: chartOptions ? { ...chartOptions, ..._lineOptions(chartData) } : _lineOptions(chartData),
        plugins: [_fillBetweenLinesPlugin],
      };
    case GraphType.Bar:
      return {
        type: GraphType.Bar,
        data: chartData,
        options: chartOptions ? { ...chartOptions, ..._roundedBarOptions } : _roundedBarOptions,
      };
    default:
      return {
        type: graphType,
        options: chartOptions,
        data: chartData,
        plugins: [],
      } as ChartConfiguration;
  }
};
