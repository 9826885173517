<div class="ie-deprecation" *ngIf="isIe">
  <div class="warning">
    <img alt="" src="{{ svgLinks.atRisk }}" />
    <div class="laius">
      <div class="title" i18n="@@ie-deprecation_title">Internet Explorer will no longer be supported.</div>
      <div class="text" i18n="@@ie-deprecation_msg">
        Internet Explorer will soon reach end of support by Microsoft. For a better experience we recommend you to switch to another
        browser!
      </div>
    </div>
  </div>
  <div class="links">
    <div *ngFor="let link of links">
      <img alt="" [src]="link.icon" />
      <a target="_blank" rel="noopener noreferrer" [href]="link.href">{{ link.name }}</a>
      <app-icon [name]="IconName.external_link"></app-icon>
    </div>
  </div>
</div>
<div class="ie-deprecation" *ngIf="isBrowserDeprecated && !isIe">
  <div class="warning">
    <img alt="" src="{{ svgLinks.atRisk }}" />
    <div class="laius">
      <div class="title" i18n="@@browser-deprecation_title">Your browser version is not supported on Vet Services.</div>
      <div class="text" i18n="@@browser-deprecation_msg">
        For a better experience, we recommend updating your browser to the latest version or using an other browser !
      </div>
    </div>
  </div>
  <div class="links">
    <div *ngFor="let link of links">
      <img alt="" [src]="link.icon" />
      <a target="_blank" rel="noopener noreferrer" [href]="link.href">{{ link.name }}</a>
      <app-icon [name]="IconName.external_link"></app-icon>
    </div>
  </div>
</div>
