export enum PopinSize {
  Fullscreen = 'fullscreen',
  Big = 'big',
  Plus = 'plus',
  Medium = 'medium',
  Regular = 'regular',
  Small = 'small',
  Calendar = 'calendar',
  Auto = 'auto',
}
