import { Injectable, InjectionToken, Injector, Optional, SkipSelf, TemplateRef } from '@angular/core';

import { PortalInjector, ComponentPortal, ComponentType, TemplatePortal } from '@app/core/cdk/portal';

import { Overlay, OverlayConfig, OverlayRef } from '@app/core/cdk/overlay';

import { DialogConfig } from './dialog-config';
import { DialogRef } from './dialog-ref';
import { DialogContainerComponent as DialogContainer } from '@app/core/cdk/dialog/dialog-container';
import { Subject } from 'rxjs';

export const DIALOG_DATA = new InjectionToken<any>('DialogData');

@Injectable()
export class Dialog {
  private _openDialogsAtThisLevel: DialogRef<any>[] = [];
  private readonly _afterAllClosedAtThisLevel = new Subject<void>();
  private readonly _afterOpenedAtThisLevel = new Subject<DialogRef<any>>();
  private _ariaHiddenElements = new Map<Element, string | null>();

  /**
   * Initializer
   */
  constructor(private _overlay: Overlay, private _injector: Injector, @Optional() @SkipSelf() private _parentDialog: Dialog) {}

  private static _getOverlayConfig(dialogConfig: DialogConfig): OverlayConfig {
    const state = new OverlayConfig({
      panelClass: dialogConfig.panelClass,
      hasBackdrop: dialogConfig.hasBackdrop,
      minWidth: dialogConfig.minWidth,
      minHeight: dialogConfig.minHeight,
      maxWidth: dialogConfig.maxWidth,
      maxHeight: dialogConfig.maxHeight,
      disposeOnNavigation: dialogConfig.closeOnNavigation,
    });

    if (dialogConfig.backdropClass) {
      state.backdropClass = dialogConfig.backdropClass;
    }

    return state;
  }

  get openDialogs(): DialogRef<any>[] {
    return this._parentDialog ? this._parentDialog.openDialogs : this._openDialogsAtThisLevel;
  }

  get afterOpened(): Subject<DialogRef<any>> {
    return this._parentDialog ? this._parentDialog.afterOpened : this._afterOpenedAtThisLevel;
  }

  get _afterAllClosed(): Subject<void> {
    const parent = this._parentDialog;
    return parent ? parent._afterAllClosed : this._afterAllClosedAtThisLevel;
  }

  /**
   * Open dialog
   */
  open<T, V>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: DialogConfig): DialogRef<T, V> {
    config = this._applyDefaultConfigs(config, new DialogConfig());

    if (config.id) {
      throw Error(`Dialog with id "${config.id}" exists already. The dialog id must be unique.`);
    }

    const overlayRef = this._createOverlay(config);
    const dialogContainer = this._attachDialogContainer(overlayRef, config);
    const dialogRef = this._attachDialogContent<T, V>(componentOrTemplateRef, dialogContainer, overlayRef, config);

    this.openDialogs.push(dialogRef);
    dialogRef.afterClosed().subscribe(() => this._removeOpenDialog(dialogRef));
    this.afterOpened.next(dialogRef);

    return dialogRef;
  }

  private _createOverlay(config: DialogConfig): OverlayRef {
    const overlayConfig = Dialog._getOverlayConfig(config);
    return this._overlay.create(overlayConfig);
  }

  private _attachDialogContainer(overlay: OverlayRef, config: DialogConfig) {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
    const injector = new PortalInjector(userInjector || this._injector, new WeakMap([[DialogConfig, config]]));
    const containerPortal = new ComponentPortal(DialogContainer, config.viewContainerRef, injector, config.componentFactoryResolver);
    const containerRef = overlay.attach<DialogContainer>(containerPortal);

    return containerRef.instance;
  }

  private _attachDialogContent<T, R>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    dialogContainer: DialogContainer,
    overlayRef: OverlayRef,
    config: DialogConfig
  ) {
    const dialogRef = new DialogRef<T, R>(overlayRef, dialogContainer, config.id);

    if (componentOrTemplateRef instanceof TemplateRef) {
      dialogContainer.attachTemplatePortal(
        new TemplatePortal<T>(componentOrTemplateRef, null, <any>{
          $implicit: config.data,
          dialogRef,
        })
      );
    } else {
      const injector = this._createInjector<T>(config, dialogRef, dialogContainer);
      const contentRef = dialogContainer.attachComponentPortal<T>(new ComponentPortal(componentOrTemplateRef, undefined, injector));
      dialogRef.componentInstance = contentRef.instance;
    }

    return dialogRef;
  }

  private _createInjector<T>(config: DialogConfig, dialogRef: DialogRef<T>, dialogContainer: DialogContainer): PortalInjector {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
    const injectionTokens = new WeakMap<any, any>([
      [DialogContainer, dialogContainer],
      [DIALOG_DATA, config.data],
      [DialogRef, dialogRef],
    ]);

    return new PortalInjector(userInjector || this._injector, injectionTokens);
  }

  private _removeOpenDialog(dialogRef: DialogRef<any>) {
    const index = this.openDialogs.indexOf(dialogRef);

    if (index > -1) {
      this.openDialogs.splice(index, 1);

      // If all the dialogs were closed, remove/restore the `aria-hidden`
      // to a the siblings and emit to the `afterAllClosed` stream.
      if (!this.openDialogs.length) {
        this._ariaHiddenElements.forEach((previousValue, element) => {
          if (previousValue) {
            element.setAttribute('aria-hidden', previousValue);
          } else {
            element.removeAttribute('aria-hidden');
          }
        });

        this._ariaHiddenElements.clear();
        this._afterAllClosed.next();
      }
    }
  }

  private _applyDefaultConfigs(config?: DialogConfig, defaultOptions?: DialogConfig): DialogConfig {
    return { ...defaultOptions, ...config };
  }
}
