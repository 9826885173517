import { Component, Input } from '@angular/core';
import { SpeciesCode } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';

@Component({
  selector: 'app-activity-tooltip',
  templateUrl: './activity-tooltip.component.html',
  styleUrls: ['./activity-tooltip.component.scss'],
})
export class ActivityTooltipComponent {
  @Input() speciesCode: SpeciesCode;

  SpeciesCode = SpeciesCode;
  IconName = IconName;
}
