import { createReducer, on } from '@ngrx/store';
import {
  getAllProductsSuccess,
  setFilteredProductsSuccess,
  resetProductsPricesState,
  setPriceListToUpdate,
  updatePricesSuccess,
  setProductFilters,
  updateSelectedCategory,
  updateSelectedCatlogViewType,
  resetProductsData,
  setSpecieProductFilters,
} from './products.actions';
import { initialProductsState } from './products.state';

export const productsReducer = createReducer(
  initialProductsState,
  on(setPriceListToUpdate, (state, { priceListToUpdate }) => ({
    ...state,
    priceListToUpdate,
  })),
  on(updatePricesSuccess, (state, { priceListUpdated }) => ({
    ...state,
    priceListUpdated,
  })),
  on(resetProductsPricesState, (state) => ({
    ...state,
    priceListUpdated: initialProductsState.priceListUpdated,
    priceListToUpdate: initialProductsState.priceListToUpdate,
  })),
  on(getAllProductsSuccess, (state, { allProductList }) => ({
    ...state,
    allProductList,
  })),
  on(setProductFilters, (state, { filtersValues }) => ({
    ...state,
    filtersValues,
  })),
  on(setSpecieProductFilters, (state, { specieCode }) => ({
    ...state,
    filtersValues: {
      ...state.filtersValues,
      specie: specieCode,
    },
  })),
  on(setFilteredProductsSuccess, (state, { filteredProductList, filteredProductLength }) => ({
    ...state,
    filteredProductList,
    filteredProductLength,
  })),
  on(updateSelectedCategory, (state, { selectedCategory }) => {
    return {
      ...state,
      selectedCategory,
    };
  }),
  on(updateSelectedCatlogViewType, (state, { currentViewType }) => ({
    ...state,
    currentViewType,
  })),
  on(resetProductsData, () => initialProductsState)
);
