import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';

import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { catchError, filter, first, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DIALOG_DATA, DialogRef } from '@app/core/cdk/dialog';
import { VetService } from '@app/core/services';
import { PetInfo, PetOwner } from '@app/core/models';
import { CreatePatientStates, Helper, PopinSize, Tool } from '@app/shared/utils';
import { Logger } from '@app/core/services/utils/logger';
import { GtmEventsService } from '@app/core/services/tracking';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { CreatePatientPopinFormValues } from './create-patient-popin';
import { customEmailValidator } from '@app/shared/validators/email.validator';
import { getEmailErrorMessage, getPhoneErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { selectConsultationPetOwner } from '@app/store/consultation/consultation.selectors';

export interface CreatePatientPopinData {
  petInfo: PetInfo;
  title: string;
  tool: Tool;
}

export interface CreatePatientPopinOutput {
  petInfo: PetInfo;
  values: CreatePatientPopinFormValues;
}

@Component({
  selector: 'app-create-patient-popin',
  templateUrl: './create-patient-popin.component.html',
  styleUrls: ['./create-patient-popin.component.scss'],
})
export class CreatePatientPopinComponent implements OnInit, AfterViewInit, OnDestroy {
  CreatePatientStates = CreatePatientStates;
  PopinSize = PopinSize;

  title = '';
  state$ = new BehaviorSubject(CreatePatientStates.Initial);
  scanningMails$ = new BehaviorSubject(false);
  form: IFormGroup<CreatePatientPopinFormValues>;

  private _destroyed$ = new Subject();

  /**
   * Initializer
   */
  constructor(
    private gtmEventsService: GtmEventsService,
    private _dialogRef: DialogRef<CreatePatientPopinComponent>,
    private logger: Logger,
    private vetService: VetService,
    @Inject(DIALOG_DATA) public data: CreatePatientPopinData,
    private store$: Store<AppState>
  ) {}

  /**
   * Life Cycle
   */
  ngOnInit(): void {
    this.title = this.data.title || $localize`:@@create-patient_create-profile:`;

    const formBuilder: IFormBuilder = new FormBuilder();
    this.store$
      .select(selectConsultationPetOwner)
      .pipe(
        first(),
        tap((petOwner) => {
          this.form = formBuilder.group<CreatePatientPopinFormValues>({
            email: [petOwner.email, [Validators.required, customEmailValidator]],
            givenName: [''],
            familyName: [''],
            telephone: ['', [Validators.maxLength(18), Validators.minLength(8)]],
            noEmail: [false],
            consent: [false, Validators.requiredTrue],
            existingOwner: [null],
          });

          this.listenEmailAndDetectExistingOwner();
          this.listenNoEmailCheckbox();
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.gtmEventsService.sendCreatePatientPopinView(this.data.tool);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  submit(): void {
    if (this.form.valid) {
      const output: CreatePatientPopinOutput = {
        petInfo: this.data.petInfo,
        values: this.form.getRawValue(),
      };
      this._dialogRef.close(output);
    }
  }

  emailErrorMessage(): string {
    return getEmailErrorMessage(this.form?.controls?.email?.errors, 'form-attribute_email');
  }

  phoneErrorMessage(): string {
    return getPhoneErrorMessage(this.form?.controls?.telephone?.errors, 'form-attribute_phone');
  }

  listenNoEmailCheckbox(): void {
    this.form?.controls?.noEmail?.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        tap((checked) => {
          if (checked) {
            // when the no email checkbow is checked, we generate en email and prefill the field
            this.form.controls.email.patchValue(Helper.generatePetEmailAdress(this.data?.petInfo?.birthdate, this.data?.petInfo?.name));
            // we need to disable the email field and re-enable all the other fields
            // indeed they might have been disabled by the different use cases in listenEmailAndDetectExistingOwner
            this.form.controls.email.disable({ emitEvent: false });
          } else {
            // when the checkbox is unchecked, we must reset and re-enable the email field
            this.form.controls.email.reset();
            this.form.controls.email.enable();
            this.form.markAllAsTouched();
          }
        })
      )
      .subscribe();
  }

  listenEmailAndDetectExistingOwner(): void {
    const emitOnEmailValid = this.store$.select(selectConsultationPetOwner).pipe(
      first(),
      switchMap((petOwner) => {
        return this.form.controls.email.valueChanges.pipe(
          startWith(petOwner.email),
          tap(() => {
            this.form.controls.existingOwner.reset();
            this.form.controls.consent.reset();
            if (this.state$.value === CreatePatientStates.MailExists) {
              this.form.controls.familyName.reset();
              this.form.controls.givenName.reset();
            }
            this.state$.next(CreatePatientStates.Initial);
          }),
          filter(() => this.form.controls.email.valid),
          tap(() => this.scanningMails$.next(true))
        );
      })
    );
    emitOnEmailValid
      .pipe(
        takeUntil(this._destroyed$),
        switchMap((email) =>
          this.vetService.apiContactsByEmail(email).pipe(
            tap((owners: PetOwner[]) => {
              this.scanningMails$.next(false);

              const owner = owners[0];
              if (owner) {
                this.form.patchValue(
                  { existingOwner: owner, familyName: owner?.familyName, givenName: owner?.givenName },
                  { onlySelf: true, emitEvent: false }
                );
                this.state$.next(CreatePatientStates.MailExists);
              } else {
                this.form.controls.givenName.setValidators(Validators.required);
                this.form.controls.familyName.setValidators(Validators.required);
                this.state$.next(CreatePatientStates.MailFree);
              }
            }),
            catchError((error) => {
              this.scanningMails$.next(false);
              this.state$.next(CreatePatientStates.Error);
              this.logger.errorString('Create Patient Popin: Error while verifying mail:', error);
              return EMPTY;
            })
          )
        )
      )
      .subscribe();
  }
}
