export enum TerritoryType {
  BirthAndGrowth = 'birth and growth',
  Dermatology = 'dermatology',
  GastrointestinalTract = 'gastrointestinal tract',
  Reproduction = 'reproduction',
  HealthManagement = 'health management',
  Urinary = 'urinary',
  VitalSupport = 'vital support',
  WeightManagement = 'weight management',
  Other = '',
}
