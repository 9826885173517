import { LabelEnum } from '../types/enums';
import { Tool } from '@app/shared/utils';

export const mapToolToLabel = (link: Tool): LabelEnum =>
  ({
    [Tool.PersonalizedBag]: LabelEnum.MULTIFUNCTION,
    [Tool.StarterKit]: LabelEnum.MULTIFUNCTION,
    [Tool.Rationing]: LabelEnum.DAILY_ALLOWANCE,
    [Tool.RenalDetect]: LabelEnum.RENAL_DETECT,
    [Tool.SmartReco]: LabelEnum.PRODUCT_FINDER,
    [Tool.WeightManagement]: LabelEnum.WEIGHT_MANAGEMENT,
    [Tool.Multifunction]: LabelEnum.MULTIFUNCTION,
  }[link]);
