import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rc-dialog-wrapper',
  templateUrl: './rc-dialog-wrapper.component.html',
  styleUrls: ['./rc-dialog-wrapper.component.scss'],
})
export class RcDialogWrapperComponent {
  @Output() closePopin = new EventEmitter();
  close(event): void {
    event.preventDefault();
    this.closePopin.emit();
  }
}
