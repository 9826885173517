import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from './../api.service';
import { Observable } from 'rxjs';
import { PredictionFromApi } from '@app/core/models';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RenalDetectService extends ApiService {
  constructor(private http: HttpClient, @Inject(LOCALE_ID) protected localeId: string) {
    super();
  }

  /**
   * [POST] - Create the nutrition for the pet
   */
  createRecommendation(body: unknown): Observable<PredictionFromApi> {
    let params = new HttpParams();

    const parameters = {
      period: 12,
    };

    Object.keys(parameters).forEach(function (key) {
      params = params.append(key, parameters[key]);
    });

    return this.http
      .post<PredictionFromApi>(this.pathUrl.renalDetectRecommendation, body, { params })
      .pipe(catchError(this.handleError.bind(this)));
  }
}
