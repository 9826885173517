import { DeliveryOrderFormValues } from '@app/shared/components/delivery-order/delivery-order';
import { Injectable } from '@angular/core';
import { Dialog } from '@app/core/cdk/dialog';
import { Consultation, Order, Patient, Product } from '@app/core/models';
import { startDailyAllowance } from '@app/pages/daily-allowance/store/actions/daily-allowance-steps.actions';
import { startMufPersonalisedBag } from '@app/pages/muf-personalised-bag/store/actions/muf-personalised-bag-steps.actions';
import { startMufStarterKit } from '@app/pages/muf-starter-kit/store/actions/muf-starter-kit-steps.actions';
import { startRenalDetect } from '@app/pages/renal-detect/store/actions/renal-detect-steps.actions';
import { startSmartReco } from '@app/pages/smart-reco/store/actions/smart-reco-steps.actions';
import { TutorialService } from '@app/pages/tutorial-page/service/tutorial.service';
import { startWeightManagement } from '@app/pages/weight-management/store/actions/weight-management-steps.actions';
import { MultifunctionPopinComponent } from '@app/shared/components/multifunction-popin/multifunction-popin.component';
import { StartTourPopinComponent } from '@app/shared/components/start-tour-popin/start-tour-popin.component';
import { Helper, Tool } from '@app/shared/utils';
import { AppFeature } from '@app/shared/utils/enums/feature.enum';
import { AppState } from '@app/store';
import {
  resetConsultationData,
  setConsultationLastConsultation,
  setConsultationPatient,
  setConsultationDryProduct,
  setConsultationPetOwner,
  setConsultationDryProductPackId,
} from '@app/store/consultation/consultation.actions';
import { setTool } from '@app/store/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest } from 'rxjs';
import { first, take, tap } from 'rxjs/operators';
import { GTMService, welcomeTourClose, welcomeTourDiscard } from '../tracking';
import { MarketService } from '../utils/localization/market.service';
import { resetProductsData } from '@app/store/products';
@Injectable()
export class StartFlowService {
  constructor(
    private cookieService: CookieService,
    private _dialog: Dialog,
    private tutorialService: TutorialService,
    private trackingService: GTMService,
    private store$: Store<AppState>,
    private marketService: MarketService
  ) {}

  startFlowNewPatient(tool: Tool): void {
    switch (tool) {
      case Tool.SmartReco:
        window.screen.width >= 1024 && !this.cookieService.check('rc-smart-reco-tutorial')
          ? this._dialog
              .open(StartTourPopinComponent, {
                panelClass: 'small',
                data: {
                  leaveTour: (isDiscard: boolean) => {
                    isDiscard && this.trackingService.sendInteraction(welcomeTourDiscard);
                    this.tutorialService.startTutorialTour$.next(false);
                    this.startSmartReco();
                  },
                },
              })
              .afterClosed()
              .subscribe((isDiscarded: boolean) => !isDiscarded && this.trackingService.sendInteraction(welcomeTourClose))
          : this.startSmartReco();
        break;

      case Tool.RenalDetect:
        this.prepareDataNewPatient(Tool.RenalDetect);
        this.store$.dispatch(startRenalDetect({ initFlow: true }));
        break;
      case Tool.Rationing:
        this.prepareDataNewPatient(Tool.Rationing);
        this.store$.dispatch(startDailyAllowance({ initFlow: true }));
        break;
      case Tool.WeightManagement:
        this.prepareDataNewPatient(Tool.WeightManagement);
        this.store$.dispatch(startWeightManagement({ initFlow: true }));
        break;
      case Tool.Multifunction:
      case Tool.PersonalizedBag:
      case Tool.StarterKit:
        this.openMultifunction();
        break;
      default:
        break;
    }
  }

  startSmartReco(): void {
    this.prepareDataNewPatient(Tool.SmartReco);
    this.store$.dispatch(startSmartReco({ initFlow: true }));
  }

  openMultifunction(): void {
    combineLatest([this.marketService.isEnabled$(AppFeature.MUF_SK), this.marketService.isEnabled$(AppFeature.MUF_PB)])
      .pipe(take(1))
      .subscribe(([starterKitEnabled, personnalizedBagsEnabled]) => {
        if (starterKitEnabled && personnalizedBagsEnabled) {
          this._dialog
            .open(MultifunctionPopinComponent, {
              panelClass: 'fullscreen',
            })
            .afterClosed()
            .pipe(
              first(),
              tap((tool) => {
                if (tool === Tool.PersonalizedBag) {
                  this.startMufPersonalisedBag();
                } else {
                  this.startMufStarterKit();
                }
              })
            )
            .subscribe();
        } else if (starterKitEnabled) {
          this.startMufStarterKit();
        } else if (personnalizedBagsEnabled) {
          this.startMufPersonalisedBag();
        }
      });
  }

  startMufStarterKit(): void {
    this.prepareDataNewPatient(Tool.StarterKit);
    this.store$.dispatch(startMufStarterKit({}));
  }

  startMufStarterKitRenew(products: Product[]): void {
    this.prepareDataNewPatient(Tool.StarterKit);
    this.store$.dispatch(startMufStarterKit({ renew: true, products }));
  }

  startMufPersonalisedBag(): void {
    this.prepareDataNewPatient(Tool.PersonalizedBag);
    this.store$.dispatch(startMufPersonalisedBag({ initFlow: true }));
  }

  startMufPersonalisedBagRenew(order: Order, patient: Patient, consultation: Consultation, products: Product[]): void {
    this.prepareDataExistingPatient(Tool.PersonalizedBag, patient, consultation);
    this.store$.dispatch(setConsultationPetOwner({ value: patient.owner }));
    this.store$.dispatch(setConsultationDryProduct({ product: products[0] || null }));
    this.store$.dispatch(
      setConsultationDryProductPackId({
        value: consultation?.visit?.recommendation?.nutritionData[0]?.product?.productDetail?.package?.sCode || null,
      })
    );
    const deliveryValues: DeliveryOrderFormValues = {
      choice: order.deliverTo,
    };
    this.store$.dispatch(startMufPersonalisedBag({ initFlow: false, renew: true, deliveryValues }));
  }

  startMufPersonalisedBagExistingPatient(patient: Patient, lastConsultation: Consultation): void {
    this.prepareDataExistingPatient(Tool.PersonalizedBag, patient, lastConsultation);
    this.store$.dispatch(startMufPersonalisedBag({ initFlow: false }));
  }

  startMufPersonalisedBagNew(): void {
    this.prepareDataNewPatient(Tool.PersonalizedBag);
    this.store$.dispatch(startMufPersonalisedBag({ initFlow: false }));
  }

  private prepareDataNewPatient(tool: Tool) {
    this.store$.dispatch(resetConsultationData());
    this.store$.dispatch(resetProductsData());
    this.store$.dispatch(setTool({ tool, tool_flow_id: Helper.randomId() }));
  }

  private prepareDataExistingPatient(tool: Tool, patient: Patient, lastConsultation: Consultation) {
    this.store$.dispatch(resetConsultationData());
    this.store$.dispatch(resetProductsData());
    this.store$.dispatch(setConsultationPatient({ value: patient }));
    this.store$.dispatch(setConsultationLastConsultation({ value: lastConsultation }));
    this.store$.dispatch(setTool({ tool, tool_flow_id: Helper.randomId() }));
  }
}
