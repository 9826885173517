/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Dialog } from '@app/core/cdk/dialog';
import { VetService } from '@app/core/services';
import { AuthService } from '@app/core/services/auth/auth.service';
import { LogginInformationPopinComponent } from '@app/shared/components/loggin-information-popin/loggin-information-popin.component';
import { ActionsEnum, Constants } from '@app/shared/utils';
import {
  formatedAlertDescriptionWithDates,
  isAlertMessageWithFuturDates,
  shouldDisplayAlertInfoPopin,
  shouldDisplayInfoPopin,
} from '@app/shared/utils/static-helpers/blob-market-helper';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { setAlert, toggleLoader } from '@app/store/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RCAlertType } from '@rc/ui';
import { CookieService } from 'ngx-cookie-service';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../..';
import { displayInfoPopin, getMarket, getMarketFail, getMarketSuccess, setInfoAlert, setInfoPopin } from '../vet.actions';
import { selectClinic, selectMarket, selectVet } from '../vet.selectors';

@Injectable()
export class GetMarketEffects {
  displayInfoPopin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(displayInfoPopin),
      withLatestFrom(this.store$.select(selectMarket), this.store$.select(selectClinic), this.store$.select(selectVet)),
      switchMap(([, market, clinic, vet]) => {
        const isPopinEnabled = localStorage.getItem(Constants.POPIN_INFORMATION_KEY) === ActionsEnum.enable;
        const isDefinitelyDisabled = this.cookieService.get('popin_info_definitely_disabled') === market?.info_popin?.id;
        if (market?.info_popin && isPopinEnabled && shouldDisplayInfoPopin(market.info_popin, isDefinitelyDisabled)) {
          return [setInfoPopin({ clinic: clinic, market: market, vet: vet })];
        } else if (market?.info_popin && isPopinEnabled && shouldDisplayAlertInfoPopin(market.info_popin)) {
          return [setInfoAlert({ clinic: clinic, market: market, vet: vet })];
        } else {
          return [];
        }
      })
    )
  );
  /**
   * Get market fail effect
   */

  getMarketFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMarketFail),
      tap(() => this.authService.logout())
    )
  );

  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private authService: AuthService,
    private _dialog: Dialog,
    private cookieService: CookieService,
    private vetService: VetService
  ) {}

  /**
   * Get market effect
   */
  getMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMarket),
      switchMap(({ countryCode }) => this.vetService.getMarket(countryCode)),
      switchMap((market) => [getMarketSuccess({ market }), toggleLoader({ value: false })]),
      catchError((err) => [getMarketFail(err)])
    )
  );

  setInfoPopin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setInfoPopin),
      switchMap((action) => {
        return this._dialog
          .open(LogginInformationPopinComponent, {
            panelClass: 'small',
            data: {
              id: action.market.info_popin.id,
              title: action.market.info_popin.title,
              description: isAlertMessageWithFuturDates(action.market.info_popin)
                ? formatedAlertDescriptionWithDates(action.market.info_popin, action.vet.user?.preferredLanguage)
                : translateKey(action.market.info_popin.description),
              link: action.market.info_popin.link,
              textlink: action.market.info_popin.textlink ?? action.market.info_popin.link,
              displaySupport: action.market.info_popin.displaySupport ?? true,
              showCheckbox: action.market.info_popin.showCheckbox,
            },
          })
          .afterClosed()
          .pipe(
            tap(() => {
              localStorage.setItem(Constants.POPIN_INFORMATION_KEY, ActionsEnum.disable);
            }),
            switchMap(() => [toggleLoader({ value: false })])
          );
      })
    )
  );

  setInfoAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setInfoAlert),
      switchMap((action) => {
        return [
          setAlert({
            alert: {
              id: action.market?.info_popin?.id,
              message: isAlertMessageWithFuturDates(action.market.info_popin)
                ? formatedAlertDescriptionWithDates(action.market.info_popin, action.vet.user?.preferredLanguage)
                : translateKey(action.market.info_popin.description),
              alertType: RCAlertType.WARNING,
            },
          }),
        ];
      }),
      tap(() => localStorage.setItem(Constants.POPIN_INFORMATION_KEY, ActionsEnum.disable))
    )
  );
}
