<div class="rc-radio-image" [class.is-disabled]="disabled" [class.is-error]="error">
  <div class="rc-radio-image__label" *ngIf="label">
    {{ label }}
  </div>
  <div class="rc-radio-image__container">
    <div class="rc-radio-image__item" *ngFor="let item of items">
      <div (click)="toggle(item)" [id]="'rc-radio-image-' + item.value">
        <div class="circle" [class.active]="value === item.value">
          <app-icon class="radio-icon" [class.is-active]="value === item.value" [name]="item.icon"></app-icon>
        </div>
        <p [class.active]="value === item.value">{{ item.label }}</p>
      </div>
    </div>
  </div>
  <span class="rc-radio-image__error" *ngIf="error">{{ error }}</span>
</div>
