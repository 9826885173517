import { getCurrencySymbol } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Package, Product, ProductPriceEdit } from '@app/core/models';
import { LocaleContentEnum } from '@app/shared/data';
import { PackType, ProductType, Tool } from '@app/shared/utils/enums';
import { IconName } from '@app/shared/utils/icon/icons';
import { VetFacade } from '@app/store/vet';

@Component({
  selector: 'app-product-edit-card',
  templateUrl: './product-edit-card.component.html',
  styleUrls: ['./product-edit-card.component.scss'],
})
export class ProductEditCardComponent implements OnInit {
  @Input() product?: Product;
  @Input() tool?: Tool;
  ToolEnum = Tool;
  IconNameEnum = IconName;
  packages: Package[];

  @Output() editedPriceList = new EventEmitter();
  @Output() showDetailPopinEvent = new EventEmitter<undefined>();

  LocaleContentEnum = LocaleContentEnum;
  clinicCurrency$ = this.vetFacade.currentClinicCurrency$;

  constructor(private vetFacade: VetFacade) {}

  ngOnInit(): void {
    this.packages = this.product?.packages.filter((pack) => pack.type !== PackType.StarterKit);
  }

  getSymbol = (currency: string) => getCurrencySymbol(currency, 'wide');

  showDetailPopin() {
    this.showDetailPopinEvent.emit();
  }

  onChange(productId: Product['id'], eventPrice: EventTarget, pack: Package, productType: ProductType): void {
    const price = Number((eventPrice as HTMLInputElement).value) ?? 0;
    const newProductPrice: ProductPriceEdit = {
      productId,
      pack,
      price,
      productType,
    };
    this.editedPriceList.emit(newProductPrice);
  }
}
