import { ChartData } from 'chart.js';
import { VetPreferences } from '@app/shared/utils/vet-preferences';
import { MeasureHelper } from '@app/shared/utils/static-helpers/measure-helper';
import { ChartConsultation } from '@app/core/models/chart.js';
import { DatePipe } from '@angular/common';
import { ConsultationWeight } from '@app/core/models/consultation-weight';
import { Weight } from '@app/core/models';
import { environment } from '@env/environment';

export class ChartHelper {
  static unitSuffix = MeasureHelper.measureUnitToSuffix(VetPreferences.currentBigMeasurementUnit);
  public static minimumDatasetsOptions = {
    // Minimum
    label: ChartHelper.unitSuffix,
    data: [],
    borderDash: [10, 5],
    fill: false,
    borderWidth: 1,
    borderColor: 'red',
    lineTension: 0.0001,
    pointRadius: 0,
    pointBackgroundColor: 'white',
  };

  public static maximumDatasetsOptions = {
    // Maximum
    label: ChartHelper.unitSuffix,
    data: [],
    borderDash: [10, 5],
    fill: false,
    borderWidth: 1,
    borderColor: 'red',
    lineTension: 0.0001,
    pointRadius: 0,
  };

  public static valuesDatasetsOptions = {
    // Values
    label: ChartHelper.unitSuffix,
    data: [],
    backgroundColor: 'rgba(255, 99, 132, 0.2)',
    fill: -1,
    borderColor: 'red',
    lineTension: 0.0001,
    pointBorderColor: 'red',
    pointBackgroundColor: 'white',
    pointBorderWidth: 3,
    pointRadius: 7,
  };

  public static lineGraphTemplate = {
    datasets: [
      {
        // Minimum and maximum weight curve
        label: ChartHelper.unitSuffix,
        data: [],
        borderDash: [10, 5],
        fill: false,
        borderWidth: 1,
        borderColor: 'red',
        lineTension: 0.5,
        pointRadius: 0,
        pointHoverRadius: 0,
        pointBackgroundColor: 'white',
      },
      {
        label: ChartHelper.unitSuffix,
        data: [],
        borderDash: [10, 5],
        fill: false,
        borderWidth: 1,
        borderColor: 'red',
        lineTension: 0.0001,
        pointRadius: 7,
        pointHoverRadius: 0,
      },

      // weight data curve
      {
        label: ChartHelper.unitSuffix,
        data: [],
        borderDash: false,
        fill: false,
        borderWidth: 4,
        borderColor: 'red',
        backgroundColor: 'black',
        pointBorderColor: environment.graphColor || 'rgb(226, 0, 26)',
        pointBackgroundColor: 'white',
        lineTension: 0.0001,
        pointRadius: 7,
        pointHoverRadius: 0,
      },
      // target weight
      {
        label: ChartHelper.unitSuffix,
        data: [],
        fill: false,
        borderDash: [5, 5],
        borderColor: 'green',
        lineTension: 0.5,
        pointBorderColor: '#E2001A1F',
        pointBackgroundColor: 'white',
        pointBorderWidth: 10,
        pointRadius: 0,
        borderWidth: 4,
        pointHoverRadius: 8,
        pointHoverBorderWidth: 3,
        pointHoverBackgroundColor: 'white',
      },
    ],
    labels: [],
  } as ChartData;

  public static formatCurrentWeightData(
    weight: number,
    IBW: number,
    date: Date,
    datePipe: DatePipe,
    isWeightLossPOLetter: boolean,
    targetWeight?: Weight
  ): ChartConsultation {
    const formattedTargetWeight =
      targetWeight &&
      MeasureHelper.convertWeight(targetWeight.measure, targetWeight.measureUnit, VetPreferences.currentBigMeasurementUnit).measure;

    return {
      weight,
      ...this.getWeightMinMax(IBW, isWeightLossPOLetter),
      targetWeight: formattedTargetWeight,
      date: {
        graph: datePipe.transform(date, 'shortDate'),
        list: datePipe.transform(date, 'longDate'),
      },
    };
  }

  public static formatDataSets(
    consultationWeight: ConsultationWeight,
    datePipe: DatePipe,
    isWeightLossPOLetter: boolean,
    targetWeight?: Weight
  ): ChartConsultation {
    const weight = MeasureHelper.convertWeight(
      consultationWeight.weight.measure,
      consultationWeight.weight.measureUnit,
      VetPreferences.currentBigMeasurementUnit
    ).measure;

    const IBW = MeasureHelper.convertWeight(
      consultationWeight.idealBodyWeight.measure,
      consultationWeight.idealBodyWeight.measureUnit,
      VetPreferences.currentBigMeasurementUnit
    ).measure;

    const formattedTargetWeight =
      targetWeight &&
      MeasureHelper.convertWeight(targetWeight?.measure, targetWeight?.measureUnit, VetPreferences.currentBigMeasurementUnit).measure;

    return {
      weight,
      ...this.getWeightMinMax(IBW, isWeightLossPOLetter),
      targetWeight: formattedTargetWeight,
      date: {
        graph: datePipe.transform(consultationWeight.weight.weightDate, 'shortDate'),
        list: datePipe.transform(consultationWeight.weight.weightDate, 'longDate'),
      },
    };
  }

  public static getWeightMinMax(IBW: number, isWeightLossPOLetter: boolean) {
    return isWeightLossPOLetter
      ? null
      : {
          weightMin: IBW - (IBW * 5) / 100,
          weightMax: IBW + (IBW * 5) / 100,
        };
  }
}
