import { PatientSearchInterfaces, PetOwnerSearch } from '@app/core/models';

export interface PatientState {
  patients: { [id: string]: PatientSearchInterfaces };
  patientsLoading: boolean;
  patientsLoaded: boolean;
  owners: { [id: string]: PetOwnerSearch };
  ownersLoading: boolean;
  ownersLoaded: boolean;
  error: string | null;
}

export const initialPatientState: PatientState = {
  patients: {},
  patientsLoading: false,
  patientsLoaded: false,
  owners: {},
  ownersLoading: false,
  ownersLoaded: false,
  error: null,
};
