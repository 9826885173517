import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@app/core/cdk/dialog';
import { EmailPopinType, PopinSize, Tool } from '@app/shared/utils';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { RCAlertType } from '@rc/ui';

@Component({
  selector: 'app-email-response-poppin',
  templateUrl: './email-response-popin.component.html',
  styleUrls: ['./email-response-popin.component.scss'],
})
export class EmailResponsePopinComponent {
  isCollaboratorsEmail: boolean;
  isSuccessPopinType: boolean;
  isRetryAllowed = false;
  DialogSize = PopinSize;
  AlertType = RCAlertType;
  error: string;
  mailto = {
    recipient: 'vetservices@royalcanin.zendesk.com',
    subject: $localize`:@@email_subject_issue:`,
    body: $localize`:@@email_body_issue:`,
  };
  private readonly _tool: Tool;

  /**
   * Initializer
   */
  constructor(private _dialogRef: DialogRef<EmailResponsePopinComponent>, @Inject(DIALOG_DATA) public data: any) {
    if (data) {
      this.isRetryAllowed = this.data.isRetryAllowed;
      this.mailto.body = $localize`:@@email_body_issue:` + ' : ' + this.data.emailPopintype + ' ' + this.data.error;
      this.isSuccessPopinType = this.data.isSuccessPopinType;
      this._tool = data.tool;
      this.isCollaboratorsEmail = (data.emailPopintype || '') === EmailPopinType.Collaborators;
    }
  }

  close() {
    this._dialogRef.close();
  }

  getPoppinTypeDescription() {
    if (this.data.emailPopinType === EmailPopinType.Recommendation) return translateKey('email-success_text');
    else if (this.data.emailPopinType === EmailPopinType.Collaborators) return translateKey('invitation-success_text');
    else return translateKey('registration-contact-ok-title');
  }

  retry() {
    this.data.retryCallback();
    this.close();
  }
}
