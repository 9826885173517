import { VetFacade } from '@app/store/vet';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-wholesale-price-total',
  styleUrls: ['wholesale-price-total.component.scss'],
  templateUrl: './wholesale-price-total.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WholesalePriceTotalComponent {
  @Input() wholesalePricesTotal: number;

  vat$ = this.vetFacade.pricesVat$;
  clinicCurrency$ = this.vetFacade.currentClinicCurrency$;

  constructor(private vetFacade: VetFacade) {}

  getTVA(wholesalePricesTotal: number, vat: number): number {
    return wholesalePricesTotal * (vat / 100);
  }

  getWholesalePriceTotalWithTva(wholesalePricesTotal: number, vat: number): number {
    return wholesalePricesTotal + wholesalePricesTotal * (vat / 100);
  }
}
