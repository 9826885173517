import { Product, ProductPriceEdit } from '@app/core/models';
import { FetchProductDataFilters } from '@app/core/models/fetch-product-data-filters';
import { ProductCatalogFilterValues } from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { SpeciesCode, ViewType } from '@app/shared/utils';
import { RangeType } from '@app/shared/utils/enums/rangeType.enum';
import { TerritoryType } from '@app/shared/utils/enums/territoryType';
import { createAction, props } from '@ngrx/store';

/**
 * Price Action
 */
export const setPriceListToUpdate = createAction('[Products] Set Price List To Update', props<{ priceListToUpdate: ProductPriceEdit[] }>());

export const updatePrices = createAction('[Products] Update Prices');

export const updatePricesSuccess = createAction('[Products] Update Prices Success', props<{ priceListUpdated: ProductPriceEdit[] }>());

export const resetProductsPricesState = createAction('[Products] Reset Prices State');

/**
 * All Product Action
 */
export const getAllProducts = createAction('[Products] get all products ', props<{ filterParams?: FetchProductDataFilters }>());
export const getAllProductsSuccess = createAction('[Products] get all products Success', props<{ allProductList: Product[] }>());

export const setFilteredProducts = createAction('[Products] get filtered products');
export const setFilteredProductsSuccess = createAction(
  '[Products] get filtered products Success',
  props<{ filteredProductList: Record<TerritoryType | string, Product[]>; filteredProductLength: number }>()
);

export const updateProductInAllProductList = createAction(
  '[Products] update product by Id in allProductsList',
  props<{ product: Product }>()
);
/**
 * Products Filters Action
 */
export const setProductFilters = createAction(
  '[Products Filters] update products filters',
  props<{ filtersValues: ProductCatalogFilterValues }>()
);

export const updateSelectedCategory = createAction(
  '[Products Filters] update products catalogue selected category',
  props<{ selectedCategory: TerritoryType | RangeType | null }>()
);

export const setSpecieProductFilters = createAction(
  '[Products Filters] update products filters specie',
  props<{ specieCode: SpeciesCode }>()
);

/**
 * Products Catalog Action
 */
export const updateSelectedCatlogViewType = createAction(
  '[Products Catalog] update selected catalog view type',
  props<{ currentViewType: ViewType }>()
);

export const resetProductsData = createAction('[Consultation] Reset Data');
