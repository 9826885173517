import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { Subject } from 'rxjs';

import { ConfigInjector } from '@app/core/services/utils/injector';
import { PopinSize } from '@app/shared/utils';
import { ModalService } from '@app/core/services';
import { CoreFacade } from '@app/store/core/core.facade';
import { RCAlertType } from '@rc/ui';
import { IconName } from '@app/shared/utils/icon/icons';
import { getDateErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';

export interface UpdateDatePopinComponentData {
  id: string;
  title: string;
  fieldLabel: string;
  subtitle: string;
  footerText: string;
  value: Date;
  validators: ValidatorFn[];
  minDate?: Date;
  maxDate?: Date;
}
export interface IUpdateDatePopinOutput {
  value?: VisitDate;
  id: string;
}

interface VisitDate {
  converted: string | null;
  original: Date;
}

@Component({
  selector: 'app-update-date-popin',
  templateUrl: './update-date-popin.component.html',
  styleUrls: ['./update-date-popin.component.scss'],
  providers: [DatePipe],
})
export class UpdateDatePopinComponent implements OnInit, OnDestroy {
  public readonly IconName = IconName;
  public readonly PopinSize = PopinSize;
  public readonly AlertType = RCAlertType;

  form: FormGroup;
  visitDate?: VisitDate;
  submitted = false;
  data: any;
  title: string;
  subtitle: string;
  footerText: string;

  private _destroyed$ = new Subject();

  constructor(
    private modal: ModalService,
    private config: ConfigInjector,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private coreFacade: CoreFacade
  ) {
    this.data = config.data;
  }

  ngOnInit() {
    this._setup();
  }

  onSubmitForm(event) {
    event.preventDefault();

    try {
      this.visitDate = {
        converted: this.datePipe.transform(this.form.value.visitDate, 'shortDate'),
        original: this.form.value.visitDate,
      };

      this.dismiss();
      this.submitted = true;
    } catch (e) {
      this.dismiss();
      this.submitted = false;
      this.coreFacade.setAlert({ message: e, alertType: RCAlertType.ERROR });
    }
  }

  dismiss() {
    const output: IUpdateDatePopinOutput = {
      value: this.visitDate,
      id: this.data.id,
    };
    this.modal.close(output);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  dateErrorMessage(): string {
    return (
      (this.form.controls.visitDate.touched &&
        this.form.controls.visitDate.errors &&
        getDateErrorMessage(this.form.controls.visitDate.errors, this.data.fieldLabel, true)) ||
      ''
    );
  }

  private _setup() {
    this.form = this.formBuilder.group({
      visitDate: [this.data.value, this.data.validators],
    });
    this.title = this.data.title;
    this.subtitle = this.data?.subtitle ? this.data?.subtitle : $localize`:@@dialog-update-date_desc:`;
    this.footerText = this.data.footerText;
  }
}
