<app-rc-dialog-wrapper (closePopin)="closePopin()">
  <div class="share-reco-popin__form">
    <div
      class="share-reco-popin__form-wrapper"
      *ngIf="(sharePopinState$ | async) === SharePopinState.FIRST_STEP || (sharePopinState$ | async) === SharePopinState.LOADING"
    >
      <form [formGroup]="formFirstStep" class="share-reco-popin__form-first-step">
        <div class="share-reco-popin__form__textarea-wrapper">
          <rc-textarea
            formControlName="notes"
            placeholder="{{ notesPlaceHolder }}"
            countRemainingSuffix="{{ 'share-reco-setting_characters_left' | translateKey }}"
            label="{{ 'share-reco-setting_consultation_notes' | translateKey }}"
            [maxlength]="textareaMaxLength"
          ></rc-textarea>
        </div>

        <div class="share-reco-popin__form__checkbox-wrapper">
          <div class="share-reco-popin__form__checkboxes">
            <div class="share-reco-popin__title">{{ 'share-reco-setting_reminders' | translateKey }}</div>
            <div class="share-reco-popin__fieldGroup">
              <div class="share-reco-popin__checkbox">
                <app-checkbox
                  formControlName="vaccination"
                  id="vaccination"
                  [label]="'share-reco-setting_vaccin' | translateKey"
                  [multiLine]="false"
                ></app-checkbox>
              </div>

              <div class="share-reco-popin__datepicker-wrapper" *ngIf="formFirstStep.controls.vaccination.value" id="vaccination_date">
                <div class="datepicker">
                  <input [formControlName]="'vaccination_reminderDate'" matInput [placeholder]="'yyyy-mm-dd'" [matDatepicker]="picker" />
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <div matDatepickerToggleIcon>
                      <em class="rc-icon rc-icon--calendar"></em>
                    </div>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>
            </div>

            <hr class="share-reco-popin__divider" />

            <div class="share-reco-popin__fieldGroup">
              <div class="share-reco-popin__checkbox">
                <app-checkbox
                  formControlName="deworming"
                  id="deworming"
                  [label]="'share-reco-setting_deworming' | translateKey"
                  [multiLine]="false"
                ></app-checkbox>
              </div>

              <div class="share-reco-popin__datepicker-wrapper" *ngIf="formFirstStep.controls.deworming.value" id="deworming_date">
                <div class="datepicker">
                  <input [formControlName]="'deworming_reminderDate'" matInput [placeholder]="'yyyy-mm-dd'" [matDatepicker]="picker" />
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <div matDatepickerToggleIcon>
                      <em class="rc-icon rc-icon--calendar"></em>
                    </div>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>
            </div>

            <hr class="share-reco-popin__divider" />

            <div class="share-reco-popin__fieldGroup">
              <div class="share-reco-popin__checkbox">
                <app-checkbox
                  formControlName="fleaTreatment"
                  id="fleaTreatment"
                  [label]="'share-reco-setting_flea' | translateKey"
                  [multiLine]="false"
                ></app-checkbox>
              </div>

              <div class="share-reco-popin__datepicker-wrapper" *ngIf="formFirstStep.controls.fleaTreatment.value" id="fleaTreatment_date">
                <div class="datepicker">
                  <input [formControlName]="'fleaTreatment_reminderDate'" matInput [placeholder]="'yyyy-mm-dd'" [matDatepicker]="picker" />
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <div matDatepickerToggleIcon>
                      <em class="rc-icon rc-icon--calendar"></em>
                    </div>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>
            </div>

            <div class="share-reco-popin__options" *ngIf="currentTool !== Tool.PersonalizedBag && isChronoVetClinic$ | async">
              <div class="share-reco-popin__title">{{ 'share-reco-setting_options' | translateKey }}</div>
              <div class="share-reco-popin__fieldGroup__chronovet">
                <app-checkbox
                  formControlName="showChronoVet"
                  class="share-reco-popin-checkbox"
                  id="showChronoVet"
                  [label]="chronovetCheckboxLabel"
                  [multiLine]="false"
                ></app-checkbox>
              </div>
            </div>
          </div>

          <div class="share-reco-popin__submit">
            <button
              id="share-reco-popin__submit-first-step"
              (click)="submitFirstStep()"
              type="submit"
              class="share-reco-popin__button rc-button rc-button--filled-small"
            >
              <span id="share-reco-popin__submit-first-step-title" class="rc-button__title">{{
                (sharePopinState$ | async) === SharePopinState.LOADING
                  ? ('po-letter_loading' | translateKey)
                  : ('action_continue' | translateKey)
              }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="(sharePopinState$ | async) === SharePopinState.SECOND_STEP" class="share-reco-popin__preview">
      <div class="share-reco-popin__preview__pdf" #myCanvas></div>
      <form [formGroup]="formSecondStep" class="share-reco-popin__preview__form-second-step">
        <div class="share-reco-popin__preview__form-second-step__fields">
          <div
            class="share-reco-popin__preview__form-second-step__qrCode-wrapper"
            [ngClass]="{
              'share-reco-popin__preview__form-second-step__qrCode-wrapper--withQR': showQRCode
            }"
          >
            <ng-container *appLet="poLetterQrCode$ | async as poLetterQrCode">
              <p class="share-reco-popin__preview__form-second-step__subtitle" i18n="@@po-letter_settings_qr_code">QR Code</p>
              <button
                *ngIf="!showQRCode"
                (click)="displayQRCode()"
                id="show-qr-code-button"
                type="button"
                class="share-reco-popin__button rc-button rc-button--filled-small"
              >
                <span class="rc-button__title">{{ 'po-letter_settings_qr_code_button' | translateKey }}</span>
              </button>

              <div class="share-reco-popin__preview__form-second-step__qrCode" *ngIf="showQRCode && poLetterQrCode">
                <div class="share-reco-popin__preview__form-second-step__qrCode__instructions">
                  <div class="share-reco-popin__preview__form-second-step__qrCode__instructions__title">
                    {{ 'po-letter_settings_instructions' | translateKey }}:
                  </div>
                  <div>{{ 'po-letter_settings_instructions_text' | translateKey }}</div>
                </div>
                <div class="share-reco-popin__preview__form-second-step__qrCode__image">
                  <img class="share-reco-popin__preview__form-second-step__qrCode__img" src="{{ poLetterQrCode }}" alt="" />
                </div>
              </div>
            </ng-container>
          </div>

          <hr class="share-reco-popin__divider" />

          <div class="share-reco-popin__preview__form-second-step__send-by-email" *ngIf="canShareRecoByEmail$ | async">
            <p class="share-reco-popin__preview__form-second-step__subtitle" i18n="@@dialog-email_title">Send by e-mail</p>
            <div class="share-reco-popin__preview__form-second-step__mail">
              <rc-field
                id="share-recommendation-popin-field-email"
                class="share-reco-popin__preview__form-second-step__mail__field"
                formControlName="email"
                [placeholder]="'form-attribute_email' | translateKey"
                [error]="emailErrorMessage()"
              ></rc-field>
              <button
                class="rc-button rc-button--filled-small"
                id="share-reco-popin__send-by-email"
                [disabled]="
                  formFirstStep.invalid || !formSecondStep.controls.consent.value || formSecondStep.controls.email.value?.length < 1
                "
                type="button"
                (click)="shareRecommendationByEmail()"
              >
                <span class="rc-button__title">{{ 'action_send-recommendation' | translateKey }}</span>
              </button>
            </div>

            <div class="share-reco-popin__preview__form-second-step__checkbox">
              <app-checkbox
                id="confirmContent"
                [multiLine]="false"
                formControlName="consent"
                [label]="'dialog-email_checkbox-label' | translateKey"
              ></app-checkbox>
            </div>
          </div>

          <hr class="share-reco-popin__divider" />

          <div class="share-reco-popin__preview__form-second-step__print">
            <p class="share-reco-popin__preview__form-second-step__subtitle">{{ 'label_print' | translateKey }}</p>
            <button
              id="share-reco-popin__send-by-print"
              class="share-reco-popin__button rc-button rc-button--filled-small"
              (click)="shareRecommendationByPrint()"
            >
              <span class="rc-button__title">{{ 'action_print-reco' | translateKey }}</span>
            </button>
          </div>
        </div>

        <div class="share-reco-popin__preview__form-second-step__print">
          <button
            class="rc-button share-reco-popin__button-previous"
            id="share-reco-popin__button-previous"
            (click)="setFirstStep()"
            i18n="@action_previous"
          >
            <span class="dropdown__btn__arrow">
              <em class="dropdown__btn__arrow__icon rc-icon rc-icon--arrow-left"></em>
            </span>
            {{ 'action_previous' | translateKey }}
          </button>
          <button id="share-reco-popin__save" *ngIf="data?.actions" class="rc-button rc-button--outline-small" (click)="save()">
            <span class="rc-button__title">{{ data?.actions?.label }} </span>
          </button>
          <button *ngIf="!data?.actions" class="rc-button" (click)="closePopin()" type="button">
            <span class="rc-button__title--link" i18n="@@action_cancel">{{ 'cancel' | titlecase }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</app-rc-dialog-wrapper>
