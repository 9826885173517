import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Env } from '@app/shared/utils/enums/environment.enum';
import { environment as env, environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ScriptService {
  /** Properties & Variable **/

  gtmIsLoaded$ = new BehaviorSubject<boolean>(false);

  /** Initializer **/
  constructor(@Inject(DOCUMENT) protected _document: Document, @Inject(LOCALE_ID) protected locale: string) {}

  /**
   * @method initDataLayer()
   * @description Initialize dataLayer array
   */
  private static _initDataLayer() {
    window['dataLayer'] = window['dataLayer'] || [];
  }

  initDocumentLang(): void {
    this._document.getElementsByTagName('html')[0].lang = this.locale;
  }

  /**
   * @method loadOneTrustScript()
   * @description Load mars script for cookie banner
   * 1 - Add `test` attribute after OneTrust id on dev to stg.
   * 2 - Init GTM (Google Tag Manager)).
   */
  loadOneTrustScript(): void {
    /*1*/
    const oneTrustEnv = env.envName === Env.PRD ? '' : '-test';

    const script = this._document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.setAttribute('data-domain-script', `${environment.oneTrustId}${oneTrustEnv}`);
    script.setAttribute('data-document-language', 'true');

    this._document.head.appendChild(script);

    /*2*/
    ScriptService._initDataLayer();
  }
  /**
   * Load phrase app editor
   */
  loadPhraseAppEditor(): void {
    window['PHRASEAPP_CONFIG'] = {
      projectId: '4d8906558ee909282c6da4f59dd78f4e',
      prefix: '[[__',
      suffix: '__]]',
    };
    const script = this._document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = ['https://', 'phraseapp.com/assets/in-context-editor/2.0/app.js?', new Date().getTime()].join('');
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
  }
  /**
   * Load ZE Snippet
   */
  loadZESnippet(): void {
    const script = this._document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=ccf85f05-781a-4ce2-bee9-a48afc1acccc';
    this._document.body.appendChild(script);
  }
  /**
   * Load Mars footer
   */
  loadMarsFooter(): void {
    const footer = document.createElement('script');
    footer.src = environment.marsFooterScript || `https://footer.mars.com/js/footer.js.aspx?vet-royalcanin-com-${this.locale}`;
    footer.type = 'text/javascript';
    footer.async = true;
    this._document.body.appendChild(footer);
  }
}
