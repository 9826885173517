import { Component, Input, OnInit } from '@angular/core';
import { Product, ProductPriceEdit } from '@app/core/models';
import { Tool } from '@app/shared/utils';
import { replaceInArray } from '@app/shared/utils/static-helpers/array.utils';
import { AppState } from '@app/store';
import { resetProductsPricesState, setPriceListToUpdate } from '@app/store/products';
import { Store } from '@ngrx/store';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogHelpers } from '@app/shared/utils/static-helpers/dialog-helpers';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
})
export class ProductEditComponent implements OnInit {
  @Input() tool?: Tool;
  @Input() products?: Product[];

  priceList: ProductPriceEdit[] = [];

  constructor(protected modal: MatDialog, private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.store$.dispatch(resetProductsPricesState());
  }

  onPriceListChange(packagePrice: ProductPriceEdit): void {
    this.priceList = replaceInArray(this.priceList, packagePrice, (value) => value.pack.sCode, true);
    this.store$.dispatch(setPriceListToUpdate({ priceListToUpdate: this.priceList }));
  }

  showDetailPopin(product: Product): void {
    const dialogConfig = {
      ...DialogHelpers.defaultConfig('productDetailPanelCustom'),
      data: {
        productId: product.id,
        showSelectButton: false,
      },
    };
    this.modal.open(ProductDetailComponent, dialogConfig);
  }
}
