export enum SharePopinState {
  FIRST_STEP = 'FIRST_STEP',
  SECOND_STEP = 'SECOND_STEP',
  LOADING = 'LOADING',
}

export interface ShareRecommendationFormValuesFirstStep {
  notes: string;
  deworming: boolean;
  vaccination: boolean;
  fleaTreatment: boolean;
  vaccination_reminderDate: Date;
  deworming_reminderDate: Date;
  fleaTreatment_reminderDate: Date;
  showChronoVet: boolean;
}

export interface ShareRecommendationFormValuesSecondStep {
  email: string;
  consent: boolean;
}
