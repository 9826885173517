import { Observable, Subject } from 'rxjs';

import { filter, take } from 'rxjs/operators';

import { OverlayRef } from '@app/core/cdk/overlay';
import { DialogContainerComponent as DialogContainer } from './dialog-container';

let uniqueId = 0;

export class DialogRef<T, R = any> {
  componentInstance: T;

  private readonly _afterOpened = new Subject<void>();
  private readonly _afterClosed = new Subject<R | undefined>();
  private readonly _beforeClosed = new Subject<R | undefined>();
  private _result: R | undefined;
  private _closeFallbackTimeout;

  constructor(
    private _overlayRef: OverlayRef,
    private _containerInstance: DialogContainer,
    readonly id: string = `rc-dialog-${uniqueId++}`
  ) {
    _containerInstance.id = id;

    _containerInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'done' && event.toState === 'enter'),
        take(1)
      )
      .subscribe(() => {
        this._afterOpened.next();
        this._afterOpened.complete();
      });

    _containerInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'done' && event.toState === 'exit'),
        take(1)
      )
      .subscribe(() => {
        clearTimeout(this._closeFallbackTimeout);
        this._overlayRef.dispose();
      });

    _overlayRef.detachments().subscribe(() => {
      this._beforeClosed.next(this._result);
      this._beforeClosed.complete();
      this._afterClosed.next(this._result);
      this._afterClosed.complete();
      this.componentInstance = null;
      this._overlayRef.dispose();
    });
  }

  beforeClosed(): Observable<R | undefined> {
    return this._beforeClosed.asObservable();
  }

  afterOpened(): Observable<void> {
    return this._afterOpened.asObservable();
  }

  afterClosed(): Observable<R | undefined> {
    return this._afterClosed.asObservable();
  }

  close(dialogResult?: R): void {
    this._result = dialogResult;

    this._containerInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'start'),
        take(1)
      )
      .subscribe((event) => {
        this._beforeClosed.next(dialogResult);
        this._beforeClosed.complete();
        this._overlayRef.detachBackdrop();

        this._closeFallbackTimeout = setTimeout(() => {
          this._overlayRef.dispose();
        }, event.totalTime + 100);
      });

    this._containerInstance.startExitAnimation();
  }
}
