import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Segmented } from '@app/core/models';

@Component({
  selector: 'app-rc-radio-image',
  templateUrl: './rc-radio-image.component.html',
  styleUrls: ['./rc-radio-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RcRadioImageComponent),
      multi: true,
    },
  ],
})
export class RcRadioImageComponent implements ControlValueAccessor {
  value: string;

  @Input() disabled = false;
  @Input() items: Segmented[];
  @Input() label: string;
  @Input() error?: string;

  private onChangeFn: (value: string) => void = () => undefined;
  private onTouchedFn: () => void = () => undefined;

  registerOnChange(fn: (...args: unknown[]) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  /**
   * Should be called when the control receives a disabled attribute
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  toggle(item: Segmented): void {
    if (!this.disabled) {
      this.writeValue(item.value);
      this.onChangeFn(item.value);
      this.onTouchedFn();
    }
  }
}
