import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { Location } from '@angular/common';

import { ComponentInjector, ConfigInjector } from '@app/core/services/utils/injector';
import { ModalRef } from './modal-ref';

import { WindowService } from '@app/core/services/utils/window/window.service';

import { OverlayComponent } from '@app/core/components/overlay/overlay.component';
import { take } from 'rxjs/operators';

@Injectable()
export class ModalService {
  /**
   * Variables & Properties
   */
  private _window: any;
  private overlayComponentRef: any;
  private childComponentRef: any;
  private _modalRef = new ModalRef();

  /**
   * Initializer
   * @param window - Window
   * @param componentFactoryResolver - Too
   * @param appRef - ApplicationRef
   * @param injector - Injector
   * @param location - PlatformLocation
   */
  constructor(
    private window: WindowService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private location: Location
  ) {
    this._window = window.nativeWindow;
    this.location.subscribe(() => {
      this.close();
    });
  }

  open(component: any, config?: ConfigInjector) {
    return this.appendComponentToBody(component, config);
  }

  close(obj?: any) {
    this._modalRef.close(obj);
  }

  /**
   * Append component to the body
   * @param component - Component to attach to the body
   * @param config - Configuration of the modal
   */
  private appendComponentToBody(component: any, config: ConfigInjector) {
    const overlayRefmap = new WeakMap();
    const childRefmap = new WeakMap();
    const modalRef = new ModalRef();
    this._modalRef = modalRef;

    overlayRefmap.set(ConfigInjector, { data: { backgroundColor: 'black' } });
    childRefmap.set(ConfigInjector, config);
    childRefmap.set(ModalRef, this._modalRef);

    // Create a component reference from the component
    const overlayRef = this.componentFactoryResolver
      .resolveComponentFactory(OverlayComponent)
      .create(new ComponentInjector(this.injector, overlayRefmap));

    const childRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(new ComponentInjector(this.injector, childRefmap));

    this._modalRef.afterClose.pipe(take(1)).subscribe(() => {
      this.removeComponent();
    });

    this.childComponentRef = childRef;
    this.overlayComponentRef = overlayRef;

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(childRef.hostView);
    this.appRef.attachView(overlayRef.hostView);

    // Get DOM element from component
    const overlayElm = (overlayRef.hostView as EmbeddedViewRef<any>).rootNodes[0];
    const domElem = (childRef.hostView as EmbeddedViewRef<any>).rootNodes[0];

    // Append DOM element to the body
    document.body.appendChild(overlayElm);
    document.body.appendChild(domElem);

    this._window.requestAnimationFrame(() => {
      document.body.classList.add('no-scroll');
      document.querySelector('.overlay').classList.add('active');
      document.querySelector('.rc-popin').classList.add('active');
    });

    return this._modalRef;
  }

  private removeComponent() {
    document.querySelector('.overlay').classList.remove('active');
    document.querySelector('.rc-popin').classList.remove('active');

    setTimeout(() => {
      document.body.classList.remove('no-scroll');
      this.appRef.detachView(this.overlayComponentRef.hostView);
      this.appRef.detachView(this.childComponentRef.hostView);

      this.overlayComponentRef.destroy();
      this.childComponentRef.destroy();
      this._modalRef.closed();
    }, 300);
  }
}
