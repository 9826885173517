<div class="rc-card" [ngClass]="'card-' + tool">
  <div class="details">
    <img class="picture" [src]="product.image | publicUrl" alt="{{ product.name || '' }}" />
    <div class="description">
      <p class="category">
        {{ product.productType | translationCode: LocaleContentEnum.ProductType }}
      </p>
      <h3 class="rc-title-beta title">
        {{ product.name || '--' }}
      </h3>
    </div>
    <button *ngIf="tool && tool === ToolEnum.SmartReco" class="rc-button" (click)="showDetailPopin()">
      <app-icon class="icon-food" [name]="IconNameEnum.food_2"></app-icon>
      <span class="rc-button__title rc-button__title--link" i18n="@@action_product-detail">Product details</span>
    </button>
  </div>

  <div class="prices" [class.prices--overflow]="packages.length > 3">
    <table class="table" *ngIf="product.packages" aria-describedby="pricestable">
      <tr>
        <th id="packsize" i18n="@@price-packsize">Packsize</th>
        <th id="price" i18n="@@price_display-price">Price</th>
      </tr>
      <tr *ngFor="let pack of packages">
        <td>
          {{ pack.convertedWeight.measure.toFixed(2) }}
          <span class="pack-unit">{{ pack.convertedWeight.measureUnit }}</span>
        </td>
        <td>
          <!-- use rc-input field shared  -->
          <div class="rc-field">
            <span class="rc-field__prefix prefix">{{ getSymbol(clinicCurrency$ | async) }}</span>
            <input
              id="input-price-{{ pack.sCode }}"
              value="{{ pack.productPrice.clinicPrice > 0 ? pack.productPrice.clinicPrice : undefined }}"
              type="number"
              class="rc-field__input input"
              placeholder="00.00"
              [allowDecimals]="true"
              appNumberOnly
              (change)="onChange(product.id, $event.target, pack, product.productType)"
            />
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
