import { PopinInformation } from '@app/core/models';
import { translateKey } from './translate';

export enum RCPopinInformationType {
  POPIN = 'popin',
  ALERT = 'alert',
}

export function shouldDisplayInfoPopin(info_popin: PopinInformation, isDefinitelyDisabled: boolean): boolean {
  const popinInformationType = RCPopinInformationType;
  const isFuturStartingDate = info_popin.startingDate?.length > 0 ? isAlertMessageWithFuturDates(info_popin) : true;
  return (
    !isDefinitelyDisabled &&
    info_popin.type !== popinInformationType.ALERT &&
    isFuturStartingDate &&
    (info_popin.description?.length > 0 || info_popin.title?.length > 0)
  );
}

export function shouldDisplayAlertInfoPopin(info_popin: PopinInformation): boolean {
  const isFuturStartingDate = info_popin.startingDate?.length > 0 ? isAlertMessageWithFuturDates(info_popin) : true;
  return info_popin.type && isFuturStartingDate && info_popin.type === RCPopinInformationType.ALERT && info_popin.description?.length > 0;
}

export function isAlertMessageWithFuturDates(info_popin: PopinInformation): boolean {
  const now = new Date();
  return info_popin.startingDate?.length > 0 && info_popin.endingDate?.length > 0 && now < new Date(info_popin.startingDate);
}

export function formatedAlertDescriptionWithDates(info_popin: PopinInformation, local: string): string {
  const formattedStartingDate = new Intl.DateTimeFormat(local, {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
  }).format(new Date(info_popin?.startingDate));
  const formattedEndingDate = new Intl.DateTimeFormat(local, {
    hour: 'numeric',
  }).format(new Date(info_popin?.endingDate));
  return translateKey(info_popin?.description, {
    startingDate: formattedStartingDate,
    endingDate: formattedEndingDate,
  });
}
