import { throwNoPortalAttachedError, throwNullPortalOutletError, throwPortalAlreadyAttachedError } from './portal-errors';

export interface PortalOutlet {
  /** Attaches a portal to this outlet. */
  attach(portal: Portal<any>): any;

  /** Detaches the currently attached portal from this outlet. */
  detach(): any;

  /** Performs cleanup before the outlet is destroyed. */
  dispose(): void;

  /** Whether there is currently a portal attached to this outlet. */
  hasAttached(): boolean;
}

export abstract class Portal<T> {
  private _attachedHost: PortalOutlet | null;

  /** Whether this portal is attached to a host. */
  get isAttached(): boolean {
    return this._attachedHost != null;
  }

  /**
   * Attach this portal to a host.
   * @param host - PortalOutlet
   */
  attach(host: PortalOutlet): T {
    if (host == null) {
      throwNullPortalOutletError();
    }

    if (host.hasAttached()) {
      throwPortalAlreadyAttachedError();
    }

    this._attachedHost = host;
    return <T>host.attach(this);
  }

  /**
   * Detach this portal from its host
   */
  detach(): void {
    const host = this._attachedHost;

    if (host == null) {
      throwNoPortalAttachedError();
    } else {
      this._attachedHost = null;
      host.detach();
    }
  }

  /**
   * Sets the PortalOutlet reference without performing `attach()`. This is used directly by
   * the PortalOutlet when it is performing an `attach()` or `detach()`.
   */
  setAttachedHost(host: PortalOutlet | null) {
    this._attachedHost = host;
  }
}
