import { createReducer, on } from '@ngrx/store';
import {
  createCurrentConsultation,
  createCurrentConsultationFail,
  createCurrentConsultationSuccess,
  resetConsultationData,
  resetCurrentConsultation,
  setConsultationAllowanceParams,
  setConsultationDryProduct,
  setConsultationDryProductPackId,
  setConsultationLastConsultation,
  setConsultationPatient,
  setConsultationPetOwner,
  setConsultationRenalDetectNutritionalChoice,
  setConsultationRenalDetectRecommendation,
  setConsultationWetProduct,
  setConsultationWetProductPackId,
  setCreatingCurrentConsultation,
  setCurrentConsultationVisitDate,
  updatePetOwnerSuccess,
} from './consultation.actions';
import { initialConsultationState } from './consultation.state';

/**
 * Reducer for all data shared between tools during a consultation
 */
export const consultationReducer = createReducer(
  initialConsultationState,
  on(setConsultationAllowanceParams, (state, action) => ({
    ...state,
    allowanceParams: action.value,
  })),
  on(setConsultationPatient, (state, action) => ({
    ...state,
    patient: action.value,
  })),
  on(setConsultationPetOwner, (state, action) => ({
    ...state,
    petOwner: action.value,
  })),
  on(updatePetOwnerSuccess, (state, action) => ({
    ...state,
    petOwner: action.value,
  })),
  on(setConsultationRenalDetectRecommendation, (state, action) => ({
    ...state,
    renalDetectRecommendation: action.value,
  })),
  on(setConsultationRenalDetectNutritionalChoice, (state, action) => ({
    ...state,
    renalDetectNutritionalChoice: action.value,
  })),
  on(setConsultationLastConsultation, (state, action) => ({
    ...state,
    lastConsultation: action.value,
  })),
  on(setConsultationDryProduct, (state, action) => ({
    ...state,
    selectedDryProduct: action.product,
    selectedDryPackId: action.packId || action.product?.packages[0]?.sCode || null,
  })),
  on(setConsultationDryProductPackId, (state, action) => ({
    ...state,
    selectedDryPackId: action.value,
  })),
  on(setConsultationWetProduct, (state, action) => ({
    ...state,
    selectedWetProduct: action.product,
    selectedWetPackId: action.packId || action.product?.packages[0]?.sCode || null,
  })),
  on(setConsultationWetProductPackId, (state, action) => ({
    ...state,
    selectedWetPackId: action.value,
  })),
  on(createCurrentConsultation, (state) => ({
    ...state,
    creatingCurrentConsultation: true,
  })),
  on(createCurrentConsultationSuccess, (state, action) => ({
    ...state,
    currentConsultation: action.consultation,
    creatingCurrentConsultation: false,
  })),
  on(resetCurrentConsultation, (state, action) => ({
    ...state,
    currentConsultation: null,
  })),
  on(createCurrentConsultationFail, (state) => ({
    ...state,
    creatingCurrentConsultation: false,
  })),
  on(setCreatingCurrentConsultation, (state, action) => ({
    ...state,
    creatingCurrentConsultation: action.value,
  })),
  on(setCurrentConsultationVisitDate, (state, action) => ({
    ...state,
    currentConsultationVisitDate: action.value,
  })),
  on(resetConsultationData, () => initialConsultationState)
);
