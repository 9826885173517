import { ActivityCode, GenderCode, ReproductionStatusCode, SpeciesCode } from '@app/shared/utils';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';

/**
 * Data that will be used to fill the pet information form
 */
export interface StandalonePet {
  name: string;
  species: SpeciesCode;
  gender: GenderCode;
  neutered: boolean;
  reproductionStatus: ReproductionStatusCode;
  breed: string;
  mixed: boolean;
  birthdate: Date;
  activity: ActivityCode;
  bcs: number;
  weight: number;
  pathologies: string[];
  sensitivities: string[];
}
/**
 * Data that will be used for setting up the app
 */
export interface StandaloneCompany {
  email: string;
  name: string;
  address1: string;
  address2?: string;
  city: string;
  zip: number;
  phone: string;
  country: CountryCode;
  measurement: 'metric' | 'imperial';
  pims_id: string;
  clinic_id: string;
  language?: LanguageCode;
  logo?: string;
}
/**
 * JSON object that will be decoded from queryParams
 */
export interface StandaloneData {
  pet: StandalonePet;
  company: StandaloneCompany;
}
/**
 * Enum used for localStorage
 */
export enum StandaloneDataKey {
  PET = 'srs:pet',
  COMPANY = 'srs:company',
}
