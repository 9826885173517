/**
 * Order matters
 */
export enum DailyAllowanceStep {
  Identification = 'daily-allowance_identification',
  Profile = 'daily-allowance_profile',
  Weight = 'daily-allowance_weight',
  Recommendation = 'daily-allowance_recommendation',
  Allowance = 'daily-allowance_allowance',
}

export const steps = Object.values(DailyAllowanceStep);
export const stepsWithoutIdentification = steps.slice(1, steps.length);
