import { Component, Inject } from '@angular/core';
import { DialogRef, DIALOG_DATA } from '@app/core/cdk/dialog';
import { ExitPopinInfo } from '@app/core/models';
import { ActionsEnum, PopinSize } from '@app/shared/utils';

@Component({
  selector: 'app-rc-exit-popin',
  templateUrl: './rc-exit-popin.component.html',
  styleUrls: ['./rc-exit-popin.component.scss'],
})
export class RcExitPopinComponent {
  DialogSize = PopinSize;
  ActionEnum = ActionsEnum;

  popinInfo: ExitPopinInfo;

  constructor(private _dialogRef: DialogRef<RcExitPopinComponent>, @Inject(DIALOG_DATA) public data: any) {
    if (data && data.popinInfo) {
      this.popinInfo = data.popinInfo;
    }
  }

  onSave(event) {
    event.stopPropagation();
    this._dialogRef.close({
      button: ActionsEnum.save,
    });
  }

  onExit(event) {
    event.stopPropagation();
    this._dialogRef.close({
      button: ActionsEnum.exit,
    });
  }

  onAction(action: ActionsEnum) {
    this._dialogRef.close({
      button: action,
    });
  }
}
