import { MatDialogConfig } from '@angular/material/dialog';

const dialogConfig = new MatDialogConfig();

export class DialogHelpers {
  static defaultConfig(panelClass?: string): MatDialogConfig {
    dialogConfig.panelClass = panelClass || 'popinDefaultPanelCustom';
    dialogConfig.backdropClass = 'customMatDialogOverlay';
    dialogConfig.maxHeight = '100vh';
    dialogConfig.maxWidth = '100vw';

    return dialogConfig;
  }
}
