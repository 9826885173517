<div class="checkbox-wrapper" [class.disabled]="disabled">
  <!--
      Top label if exists
     -->
  <span *ngIf="topLabel" class="label">{{ topLabel }}</span>
  <div
    class="checkbox"
    [attr.aria-disabled]="disabled"
    [class.checkbox--multi-line]="multiLine"
    (click)="!readonly && updateChanges(!value)"
  >
    <!--
      Checkmark
     -->
    <span class="checkmark" [class.checkmark--active]="value"></span>
    <!--
      Label
      Followed by ngContent if provided
     -->
    <div class="text">
      {{ label }}
      <ng-content></ng-content>
    </div>
  </div>
</div>
