import { VetFacade } from '@app/store/vet';
import { CoreFacade } from '@app/store/core/core.facade';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { mergeMap, take } from 'rxjs/operators';

import { Observable, combineLatest } from 'rxjs';
import { AuthService } from '@app/core/services/auth/auth.service';

@Injectable()
export class AnalyticsInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private coreFacade: CoreFacade, private vetFacade: VetFacade) {}

  // TODOEKINO: Hard type these generics when possible
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * Add some specific data for analytics and/or logs
     */
    return combineLatest([this.auth.isAuthenticated(), this.vetFacade.vet$, this.vetFacade.clinic$, this.coreFacade.toolInformation$]).pipe(
      take(1),
      mergeMap(([isAuth, vet, clinic, { tool, tool_flow_id }]) => {
        const newHeaders: Record<string, string> = {
          ...(vet?.id && { vet_id: vet?.id }),
          ...(clinic?.id && { clinic_id: clinic?.id }),
          ...(clinic?.pimsId && { pims_id: clinic?.pimsId }),
          ...(tool && { reco_type: tool, api_session_id: tool_flow_id }),
        };

        return next.handle(
          isAuth
            ? request.clone({
                setHeaders: newHeaders,
              })
            : request
        );
      })
    );
  }
}
