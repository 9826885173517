import { Measure } from '@app/core/models';
import { GenderCode } from '@app/shared/utils/enums/gender-code.enum';
import { LifestageType } from '@app/shared/utils/enums/lifestage.enum';
import { SizeCategoryEnum } from '@app/shared/utils/enums/size-category-enum';

export const defaultLifestagesPlanDuration = '12';

export interface BreedPetProfileParameters {
  breedCode: string;
  dateOfBirth?: string | Date;
  genderCode?: GenderCode;
  lifestagesPlanDuration?: number;
}

export interface BreedPetProfileModel {
  adultTargetWeight: Measure;
  lifestage: LifestageType;
  sizeCategory: SizeCategoryEnum;
  lifestagesPlan: LifestageType[];
}
