import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@app/core/cdk/dialog';
import { PopinInformation } from '@app/core/models';
import { PopinSize } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loggin-information-popin',
  templateUrl: './loggin-information-popin.component.html',
  styleUrls: ['./loggin-information-popin.component.scss'],
})
export class LogginInformationPopinComponent {
  DialogSize = PopinSize;
  IconName = IconName;
  constructor(
    @Inject(DIALOG_DATA) public data: PopinInformation,
    private cookieService: CookieService,
    public router: Router,
    public dialogRef: DialogRef<LogginInformationPopinComponent>
  ) {}

  definitelyDisabledMessage(value) {
    value
      ? this.cookieService.set('popin_info_definitely_disabled', this.data.id)
      : this.cookieService.delete('popin_info_definitely_disabled');
  }

  redirectToSupport() {
    this.dialogRef.close();
    this.router.navigateByUrl('/support');
  }
}
