<rc-dialog>
  <rc-dialog-content class="rc-dialog-content--vertical-align">
    <div class="dialog_container">
      <div class="dialog_container__body">
        <!-- Image -->
        <img alt="" class="dialog_container__body-thumb" [src]="'assets/other/Group.png'" />

        <!-- Text -->
        <h3 class="rc-title-gamma dialog_container__body__title">
          {{ popinInfo.title }}
        </h3>
        <p class="dialog_container__body__message">{{ popinInfo.subTitle }}</p>
      </div>

      <div class="dialog_container__footer">
        <div class="rc-button-group">
          <button id="exit-popin-first-action" class="rc-button" app-rc-dialog-close (click)="onAction(ActionEnum.one)">
            <span class="rc-button__title--link">{{ popinInfo.actionOne }}</span>
          </button>
          <span class="rc-button__separator" i18n="@@action_separator">or</span>
          <button class="rc-button rc-button--filled-small" id="exit-popin-second-action" (click)="onAction(ActionEnum.two)">
            <span class="rc-button__title">{{ popinInfo.actionTwo }}</span>
          </button>
        </div>
      </div>
    </div>
  </rc-dialog-content>
</rc-dialog>
