import { Injectable, isDevMode } from '@angular/core';
import { MonitoringService } from '@app/core/services/utils/telemetry';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

@Injectable()
export class Logger {
  constructor(private monitoringService: MonitoringService) {}

  debug(title: string, ...objects: any[]): void {
    if (isDevMode()) {
      console.log(`RcDebug: ${title}`, ...objects);
    }
  }

  log(title: string, ...objects: any[]): void {
    if (isDevMode()) {
      console.log(`RcLog: ${title}`, ...objects);
    } else {
      const message = this._constructAppInsightMessage(title, ...objects);
      this.monitoringService.logTrace(message, {
        severity: SeverityLevel.Information,
      });
    }
  }

  warn(title: string, ...objects: any[]): void {
    if (isDevMode()) {
      console.warn(`RcWarn: ${title}`, ...objects);
    } else {
      const message = this._constructAppInsightMessage(title, ...objects);
      this.monitoringService.logTrace(message, {
        severity: SeverityLevel.Warning,
      });
    }
  }

  errorString(title: string, ...objects: any[]): void {
    if (isDevMode()) {
      console.error(`RcError: ${title}`, ...objects);
    } else {
      const message = this._constructAppInsightMessage(title, ...objects);
      this.monitoringService.logTrace(message, {
        severity: SeverityLevel.Error,
      });
    }
  }

  error(error: Error): void {
    if (isDevMode()) {
      console.error(`RcError: ${error.message}`, error);
    } else {
      this.monitoringService.monitorException(error, SeverityLevel.Error);
    }
  }

  private _objectsToString(...objects: any[]): string {
    if (!objects) {
      return '';
    }
    return objects.map((obj) => obj.toString()).join(', ');
  }

  private _constructAppInsightMessage(title: string, ...objects: any[]) {
    let message = title;
    if (objects) {
      message += `: ${this._objectsToString(objects)}`;
    }
    return message;
  }
}
