import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ArianeTranslation',
})
export class ArianeTranslationPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    switch (value) {
      case 'home':
        return $localize`:@@path_home:`;
      case 'product':
        return $localize`:@@path_product:`;
      case 'multifunction':
        return $localize`:@@path_multifunction:`;
      case 'order':
        return $localize`:@@path_order:`;
      case 'order-record':
        return $localize`:@@path_order-record:`;
      case 'patient':
        return $localize`:@@path_patient:`;
      case 'personalized-bag':
        return $localize`:@@path_personalized-bag:`;
      case 'pet-details':
        return $localize`:@@path_pet-details:`;
      case 'pet-owner':
        return $localize`:@@path_pet-owner:`;
      case 'pet-profile':
        return $localize`:@@path_pet-profile:`;
      case 'pet-record':
        return $localize`:@@path_pet-record:`;
      case 'place-order':
        return $localize`:@@path_place-order:`;
      case 'product-catalogue':
        return $localize`:@@path_product-catalogue:`;
      case 'products-recommendation':
        return $localize`:@@path_products-recommendation:`;
      case 'starter':
        return $localize`:@@path_starter:`;
      case 'starter-kit':
        return $localize`:@@path_starter-kit:`;
      case 'success-order':
        return $localize`:@@path_success-order:`;
      case 'support':
        return $localize`:@@path_support:`;
      case 'weight-management':
        return $localize`:@@path_weight-management:`;
      case 'follow-up':
        return $localize`:@@path_follow-up:`;
      case 'account':
        return $localize`:@@path_account:`;
      case 'bcs':
        return $localize`:@@path_bcs:`;
      case 'daily-allowance':
        return $localize`:@@path_daily-allowance:`;
      case 'health':
        return $localize`:@@path_health:`;
      case 'renal-detect':
        return $localize`:@@path_renal-detect:`;
      case 'order-summary':
        return $localize`:@@path_order-summary:`;

      // PRODUCTS-PAGE: custom header links translations
      case '/products/catalogue':
        return [$localize`:@@path_product:`];
      case '/products/weight-loss':
        return [$localize`:@@path_pet-details:`, $localize`:@@path_product-catalogue:`];
      case '/products/starter-kit':
        return [$localize`:@@path_multifunction:`, $localize`:@@path_starter-kit:`];
      case '/pet-products/daily-allowance':
        return [$localize`:@@path_product-catalogue:`, $localize`:@@path_daily-allowance:`];
      case '/pet-products/personalized-bag':
        return [$localize`:@@path_product-catalogue:`, $localize`:@@path_personalized-bag:`];
      case '/pet-products/renal-detect':
        return [$localize`:@@path_renal-detect:`, $localize`:@@path_product-catalogue:`];

      // Renal detect dailyAllowance
      case '/renal-detect/daily-allowance':
        return [$localize`:@@path_renal-detect:`, $localize`:@@path_product-catalogue:`, $localize`:@@path_daily-allowance:`];

      /**
       * New Smart reco & allowance
       * Temporary until ariane data is in store
       */
      case '/smart-reco/patient':
        return [$localize`:@@path_patient:`];
      case '/smart-reco/recommendation':
        return [$localize`:@@path_products-recommendation:`];
      case '/allowance':
        return [$localize`:@@path_daily-allowance:`];
      default:
        return '--';
    }
  }
}
