<div [id]="field.id" class="rc-input-component">
  <ng-container [ngSwitch]="field.inputType">
    <div *ngSwitchCase="InputType.Date" [class.is-disabled]="field.disabled" class="datepicker-wrapper">
      <label *ngIf="formControl.value || formControl.errors?.matDatepickerParse" class="datepicker-label">
        <span>{{ field.label }}</span>
      </label>
      <div class="datepicker" [class.error-state]="fieldInError">
        <input
          matInput
          [formControl]="formControl"
          [placeholder]="field.label"
          [disabled]="field.disabled"
          [matDatepicker]="picker"
          id="datepicker-input"
        />
        <app-icon
          [class.error]="fieldInError"
          [name]="IconName.calendar_small"
          class="datepicker-icon"
          (click)="picker.opened ? picker.close() : picker.open()"
        ></app-icon>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>

    <app-rc-select
      (didSelectValue)="onTouched() && onChange($event)"
      *ngSwitchCase="InputType.Select"
      [class.rc-input--error]="fieldInError"
      [disabled]="field.disabled"
      [errorState]="fieldInError ? InputSate.Failed : InputSate.Success"
      [items]="field.items | async"
      [keyPath]="field.keyPath"
      [labelPath]="field.labelPath"
      [label]="field.label"
      [placeholder]="field.placeholder"
      [required]="field.required"
      [value]="value"
    ></app-rc-select>

    <app-rc-autocomplete
      (didSelectValue)="onTouched() && onChange($event)"
      *ngSwitchCase="InputType.Autocomplete"
      [class.rc-input--error]="fieldInError"
      [disabled]="field.disabled"
      [errorState]="fieldInError ? InputSate.Failed : InputSate.Success"
      [items]="field.items | async"
      [keyPath]="field.keyPath"
      [labelPath]="field.labelPath"
      [label]="field.label"
      [placeholder]="field.placeholder"
      [required]="field.required"
      [value]="value"
    ></app-rc-autocomplete>

    <ng-container *ngSwitchCase="InputType.Phone">
      <app-rc-input-phone
        (changeValue)="onTouched() && onChange($event)"
        [autocomplete]="field.disableAutocomplete ? 'disabled' : ''"
        [disabled]="field.disabled"
        [errorState]="fieldInError ? 'error' : 'valid'"
        [label]="field.label || field.placeholder || ''"
        [name]="field.id || field.path"
        [placeholder]="field.placeholder || ''"
        [required]="field.required"
        [value]="value"
      >
      </app-rc-input-phone>
    </ng-container>

    <ng-container *ngSwitchCase="InputType.Weight">
      <div [class.rc-input__wrapper]="field.type === AttributeType.Weight">
        <app-rc-field
          (change)="onTouched()"
          (keyup)="onChange($any($event.target))"
          [autocomplete]="field.disableAutocomplete ? 'disabled' : ''"
          [disabled]="field.disabled"
          [errorState]="fieldInError ? InputSate.Failed : InputSate.Success"
          [label]="field.label || field.placeholder || ''"
          [maxlength]="10"
          [name]="field.id || field.path"
          [placeholder]="field.placeholder || ''"
          [required]="field.required"
          [suffix]="weightSuffix"
          [value]="value"
          class="rc-input"
          type="number"
        >
        </app-rc-field>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="InputType.Number">
      <div [class.rc-input__wrapper]="field.type === AttributeType.Weight">
        <app-rc-field
          (change)="onTouched()"
          (keyup)="onChange($any($event.target))"
          [autocomplete]="field.disableAutocomplete ? 'disabled' : ''"
          [disabled]="field.disabled"
          [errorState]="fieldInError ? 'error' : 'valid'"
          [fixedLabel]="field.fixedLabel"
          [isLabelDisplayed]="field.isLabelDisplayed"
          [label]="field.label || field.placeholder || ''"
          [maxlength]="10"
          [name]="field.id || field.path"
          [placeholder]="field.placeholder || ''"
          [required]="field.required"
          [value]="value"
          class="rc-input"
          type="number"
        >
        </app-rc-field>
      </div>
    </ng-container>

    <div *ngSwitchCase="InputType.Radio">
      <label *ngFor="let item of field.items | async" class="rc-input rc-input-radio_container">
        {{ objectPath.get(item, field.labelPath) }}
        <input
          (change)="onTouched() && onChange(objectPath.get(item, field.keyPath))"
          [checked]="value === objectPath.get(item, field.keyPath)"
          [class.rc-input--error]="fieldInError"
          [disabled]="field.disabled"
          [id]="field.id + '.' + objectPath.get(item, field.keyPath)"
          [name]="field.id || field.path"
          [required]="field.required"
          [value]="objectPath.get(item, field.keyPath)"
          type="radio"
        />
        <span class="rc-input-radio_checkmark"></span>
      </label>
    </div>

    <div *ngSwitchCase="InputType.Textarea">
      <div *ngIf="field.label" [class.error-state]="fieldInError && !field.disabled" class="rc-input-component__field-label">
        {{ field.label }}
      </div>
      <textarea
        #textareaElem
        (keyup)="onTouched() && onChange(textareaElem)"
        [class.rc-input--error]="fieldInError"
        [disabled]="field.disabled"
        [name]="field.id || field.path"
        [placeholder]="field.placeholder || ''"
        [value]="value"
        class="rc-input-textarea"
      ></textarea>
    </div>

    <app-rc-field
      (changeValue)="onChange($event)"
      (focusOut)="onTouched()"
      *ngSwitchDefault
      [autocomplete]="field.disableAutocomplete ? 'disabled' : ''"
      [disabled]="field.disabled"
      [errorState]="fieldInError ? InputSate.Failed : InputSate.Success"
      [label]="field.label || field.placeholder || ''"
      [maxlength]="field.maxLength"
      [name]="field.id || field.path"
      [placeholder]="field.placeholder || ''"
      [required]="field.required"
      [type]="field.inputType || 'text'"
      [value]="value"
      class="rc-input"
    >
    </app-rc-field>
  </ng-container>
  <div *ngIf="fieldInError && !field.disabled" class="rc-input--error-message">
    <div [id]="'error-msg--' + field.id" class="rc-input__error-msg">
      <span *ngIf="!formControl.errors?.matDatepickerParse"
        >{{ formControl.getError('message') || field.label || field.fixedLabel || field.placeholder }}
      </span>
      <ng-container *ngIf="field.errorMessage; else errors_content">
        <span>
          {{ field.errorMessage }}
        </span>
      </ng-container>
      <ng-template #errors_content>
        <span *ngIf="formControl.errors?.matDatepickerParse">
          <ng-container i18n="@@rc-input_wrong_format">The format is invalid</ng-container>
        </span>
        <span *ngIf="!formControl.errors?.matDatepickerParse && formControl.errors.required">
          <ng-container i18n="@@required-key">is required</ng-container>
        </span>
        <span *ngIf="formControl.errors.minlength">
          <ng-container i18n="@@rc-input_must-be-longer">must be longer</ng-container>
        </span>
        <span *ngIf="formControl.errors.maxlength">
          <ng-container i18n="@@rc-input_must-be-shorter">must be shorter</ng-container>
        </span>
        <span *ngIf="formControl.errors.email">
          <ng-container i18n="@@rc-input_email-format">must be have format name@provider.countrycode</ng-container>
        </span>
        <span *ngIf="formControl.errors.invalidEmail">
          <ng-container i18n="@@rc-input_email-format">must be have format name@provider.countrycode</ng-container>
        </span>
        <span *ngIf="formControl.errors.pastDate">
          <ng-container i18n="@@rc-input_must-be-past">must be set in the past</ng-container>
        </span>
        <span *ngIf="formControl.errors.futureDate">
          <ng-container i18n="@@rc-input_must-be-future">must be set in the future</ng-container>
        </span>
        <span *ngIf="formControl.errors.minDate && !formControl.errors.futureDate">
          <ng-container i18n="@@rc-input_must-be-later">must be set later</ng-container>
        </span>
        <span *ngIf="formControl.errors.maxDate">
          <ng-container i18n="@@rc-input_must-be-before">must be set before</ng-container>
        </span>
        <span *ngIf="formControl.errors.min">
          <ng-container i18n="@@rc-input_should-bigger">should be bigger than</ng-container>
          {{ formControl.errors.min.min }}
        </span>
        <span *ngIf="formControl.errors.max">
          <ng-container i18n="@@rc-input_should-not-exceed">should not exceed</ng-container>
          {{ formControl.errors.max.max }} {{ weightSuffix }}
        </span>
      </ng-template>
    </div>
  </div>
</div>
