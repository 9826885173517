import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, CurrencyPipe, LowerCasePipe, TitleCasePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { DialogModule } from '@app/core/cdk/dialog';
import { OverlayModule as AppOverlayModule } from '@app/core/cdk/overlay';
import { LoaderComponent } from '@app/core/components';
import { NewProductComponent } from '@app/shared/components/new-product/new-product.component';
import { ViewTypeFilterComponent } from '@app/shared/components/view-type-filter/view-type-filter.component';
import {
  RCAccordionModule,
  RcAlertModule,
  RCAutocompleteModule,
  RcChipModule,
  RCFieldModule,
  RCFooterModule,
  RcMultiSelectModule,
  RCSelectModule,
  RCSelectorsModule,
  RcTagModule,
  RCTextareaModule,
  RcTourModule,
} from '@rc/ui';
import { CookieService } from 'ngx-cookie-service';
import { CategoryNavigationComponent } from '../shared/components/category-navigation/category-navigation.component';
import {
  ActiveProgramPopinComponent,
  AddAddressPopinComponent,
  AddressComponent,
  BcsComponent,
  CircleCharComponent,
  ClinicIdentifiedPopinComponent,
  CombineProductsPopinComponent,
  ContactPopinComponent,
  CreatePatientPopinComponent,
  CriteriaDetailsPopinComponent,
  DailyAllowanceViewComponent,
  DeletePatientPopinComponent,
  DeliveryOrderComponent,
  DropdownListComponent,
  EmailPoppinComponent,
  EmailResponsePopinComponent,
  FollowUpComponent,
  FollowUpSelectorComponent,
  FollowUpSelectorPopinComponent,
  LogginInformationPopinComponent,
  MedicalHistoryDetailPopinComponent,
  MultifunctionPopinComponent,
  PageWrapperComponent,
  PetIdentificationComponent,
  PetOwnerFormComponent,
  PetProfileFormComponent,
  PetProfileHeaderComponent,
  ProductCatalogFiltersComponent,
  ProductCatalogFiltersPopinComponent,
  ProductComponent,
  ProductDetailComponent,
  ProductGridComponent,
  ProductListComponent,
  ProductRecommendationCardComponent,
  ProductsQuantityFormComponent,
  ProfileHeaderComponent,
  RcAddressComponent,
  RcAttributeComponent,
  RcAutocompleteComponent,
  RcBlockComponent,
  RcChartComponent,
  RcDialogWrapperComponent,
  RcDropdownComponent,
  RcExitPopinComponent,
  RcFormComponent,
  RcGaugeComponent,
  RcInputComponent,
  RcInputNumberComponent,
  RcInputPhoneComponent,
  RcListComponent,
  RcNoContentComponent,
  RcOrderStatusComponent,
  RcPaginationComponent,
  RcPetSummaryPanelComponent,
  RcPopinContentComponent,
  RcPopinUploadLogoComponent,
  RcRadioImageComponent,
  RcSegmentedControlComponent,
  RcSelectComponent,
  RcSpinnerComponent,
  RcStepperComponent,
  RcTableComponent,
  RcTabsComponent,
  RcTooltipComponent,
  RenalGraphComponent,
  RenalValuesComponent,
  SelectedProductsPopinComponent,
  ShareRecommendationPopinComponent,
  SignalPopinSendComponent,
  SignalProductPopinComponent,
  SlideComponent,
  TableCellComponent,
  TableComponent,
  TableRowComponent,
  UpdateDatePopinComponent,
  WarningMessageComponent,
  WeightLossPlanComponent,
  WeightLossSummaryComponent,
  WeightManagementPopinComponent,
  WholesalePriceTotalComponent,
} from './components';
import { ActivityTooltipComponent } from './components/activity-tooltip/activity-tooltip.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ExpansionPanelStepHeaderComponent } from './components/expansion-panel-step-header/expansion-panel-step-header.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { HyperlinkComponent } from './components/hyperlink/hyperlink.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { PathologyComponent } from './components/pathology/pathology.component';
import { ProductCardGridComponent } from './components/product-card-grid/product-card-grid.component';
import { ProductCardListComponent } from './components/product-card-list/product-card-list.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductEditCardComponent } from './components/product-edit-card/product-edit-card.component';
import { ProductEditComponent } from './components/product-edit/product-edit.component';
import { ProductFooterComponent } from './components/product-footer/product-footer.component';
import { ProductResetPriceComponent } from './components/product-reset-price/product-reset-price.component';
import { RcFieldComponent } from './components/rc-field/rc-field.component';
import { RCSelectDirective } from './components/rc-select/rc-select.directive';
import { StartTourPopinComponent } from './components/start-tour-popin/start-tour-popin.component';
import { TagsSelectionContainerComponent } from './components/tags-selection-containers/tags-selection-container.component';
import { WeightFormComponent } from './components/weight-form/weight-form.component';
import { AppLetDirective, InputNumberDirective, MarketGuardDirective } from './directives';
import {
  ArianeTranslationPipe,
  BreedIcdPipe,
  BreedPipe,
  CapitalizeFirstPipe,
  ConverterHtmlToText,
  FractionCupPipe,
  NeuteredPipe,
  PossessivePipe,
  PublicUrlPipe,
  RoundPipe,
  TextToLinkPipe,
  TranslatePipe,
  TranslationCodePipe,
  TruncatePipe,
  WeightPipe,
} from './pipes';
import { WeightPrecisionPipe } from './pipes/weight-precision.pipe';
import { IconRegistryService } from './utils/icon/icon-registry.service';
import { IconModule } from './utils/icon/icon.module';
import {
  iconActions,
  iconAddAddress,
  iconAlert,
  iconArrowPanel,
  iconBloodCreatinine,
  iconBloodUrea,
  iconBoth,
  iconCalculator,
  iconCalendar,
  iconCalendarSmall,
  iconCat,
  iconCatBcs1Large,
  iconCatBcs1Medium,
  iconCatBcs2Large,
  iconCatBcs2Medium,
  iconCatBcs3Large,
  iconCatBcs3Medium,
  iconCatBcs4Large,
  iconCatBcs4Medium,
  iconCatBcs5Large,
  iconCatBcs5Medium,
  iconCatBcs6Large,
  iconCatBcs6Medium,
  iconCatBcs7Large,
  iconCatBcs7Medium,
  iconCatBcs8Large,
  iconCatBcs8Medium,
  iconCatBcs9Large,
  iconCatBcs9Medium,
  iconChevronDown,
  iconClose,
  iconDelete,
  iconDocuments,
  iconDog,
  iconDogBcs1Large,
  iconDogBcs1Medium,
  iconDogBcs2Large,
  iconDogBcs2Medium,
  iconDogBcs3Large,
  iconDogBcs3Medium,
  iconDogBcs4Large,
  iconDogBcs4Medium,
  iconDogBcs5Large,
  iconDogBcs5Medium,
  iconDogBcs6Large,
  iconDogBcs6Medium,
  iconDogBcs7Large,
  iconDogBcs7Medium,
  iconDogBcs8Large,
  iconDogBcs8Medium,
  iconDogBcs9Large,
  iconDogBcs9Medium,
  iconEdit,
  iconEmptyStateDesktop,
  iconEmptyStateMobile,
  iconExclamation,
  iconExternalLink,
  iconFemale,
  iconFood2,
  iconFood3,
  iconGrid,
  iconIndoor,
  iconLightBulb,
  iconLink,
  iconList,
  iconLoading,
  iconMale,
  iconOutdoor,
  iconRangeBhn,
  iconRangeCcn,
  iconRangeFbn,
  iconRangeShn,
  iconRefresh,
  iconSearch,
  iconShop,
  iconSpecieCat,
  iconSpecieDog,
  iconSpecificGravity,
  iconToolDaActive,
  iconToolMufActive,
  iconToolRdActive,
  iconToolSrActive,
  iconToolWmActive,
  iconTrash,
  iconUnlink,
  iconWarning,
  iconWeightLossProgram,
  iconWetProducts,
} from './utils/icon/icons';
import { iconTerritoryBirthAndGrowth } from './utils/icon/icons/icons/icon-territory-birth_and_growth.icon';
import { iconTerritoryDermatology } from './utils/icon/icons/icons/icon-territory-dermatology.icon';
import { iconTerritoryGastrointestinalTract } from './utils/icon/icons/icons/icon-territory-gastrointestinal_tract.icon';
import { iconTerritoryHealthManagement } from './utils/icon/icons/icons/icon-territory-health_management.icon';
import { iconTerritoryUrinary } from './utils/icon/icons/icons/icon-territory-urinary.icon';
import { iconTerritoryVitalSupport } from './utils/icon/icons/icons/icon-territory-vital_support.icon';
import { iconTerritoryWeightManagement } from './utils/icon/icons/icons/icon-territory_weight_management.icon';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    /* Angular Modules */
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppOverlayModule,
    OverlayModule,
    DialogModule,
    RCAutocompleteModule,
    RCFieldModule,
    RCSelectModule,
    IconModule,
    RcChipModule,
    RCFooterModule,
    RcTourModule,
    RCTextareaModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatDateFnsModule,
    MatTableModule,
    RcAlertModule,
    RcMultiSelectModule,
    RcTagModule,
    MatDialogModule,
    RCAccordionModule,
    RCSelectorsModule,
    MatInputModule,
  ],
  declarations: [
    /* Directive */
    InputNumberDirective,
    MarketGuardDirective,
    AppLetDirective,
    RCSelectDirective,
    /*Pipes*/
    WeightPrecisionPipe,
    BreedPipe,
    BreedIcdPipe,
    TranslationCodePipe,
    WeightPipe,
    ConverterHtmlToText,
    FractionCupPipe,
    RoundPipe,
    PublicUrlPipe,
    CapitalizeFirstPipe,
    PossessivePipe,
    TruncatePipe,
    ArianeTranslationPipe,
    NeuteredPipe,
    TextToLinkPipe,
    TranslatePipe,
    /* Other Components */
    LoaderComponent,
    ContactPopinComponent,
    EmailPoppinComponent,
    EmailResponsePopinComponent,
    ProfileHeaderComponent,
    PetIdentificationComponent,
    PetProfileFormComponent,
    PetOwnerFormComponent,
    ProductsQuantityFormComponent,
    PetProfileHeaderComponent,
    ProductComponent,
    NewProductComponent,
    ProductCatalogFiltersComponent,
    ProductCatalogFiltersPopinComponent,
    ProductFooterComponent,
    ProductDetailComponent,
    ProductGridComponent,
    ProductListComponent,
    ProductEditComponent,
    CreatePatientPopinComponent,
    WeightManagementPopinComponent,
    WarningMessageComponent,
    WholesalePriceTotalComponent,
    BcsComponent,
    WeightLossPlanComponent,
    WeightLossSummaryComponent,
    DailyAllowanceViewComponent,
    UpdateDatePopinComponent,
    WeightLossPlanComponent,
    WeightLossSummaryComponent,
    DailyAllowanceViewComponent,
    FollowUpComponent,
    UpdateDatePopinComponent,
    FollowUpSelectorComponent,
    FollowUpSelectorPopinComponent,
    CapitalizeFirstPipe,
    WeightPrecisionPipe,
    SignalProductPopinComponent,
    ProductRecommendationCardComponent,
    MultifunctionPopinComponent,
    DeletePatientPopinComponent,
    ProductResetPriceComponent,
    ClinicIdentifiedPopinComponent,
    NoResultsComponent,
    TableComponent,
    TableCellComponent,
    TableRowComponent,
    DeliveryOrderComponent,
    AddressComponent,
    AddAddressPopinComponent,
    SelectedProductsPopinComponent,
    DropdownListComponent,
    ActiveProgramPopinComponent,
    CombineProductsPopinComponent,
    ShareRecommendationPopinComponent,
    RenalGraphComponent,
    SlideComponent,
    MedicalHistoryDetailPopinComponent,
    RenalValuesComponent,
    CriteriaDetailsPopinComponent,
    /* RC Components */
    RcPopinContentComponent,
    RcDialogWrapperComponent,
    RcDropdownComponent,
    RcTooltipComponent,
    RcSelectComponent,
    RcInputComponent,
    RcTabsComponent,
    RcAttributeComponent,
    RcBlockComponent,
    RcFormComponent,
    RcListComponent,
    RcSegmentedControlComponent,
    RcPaginationComponent,
    RcInputComponent,
    RcAutocompleteComponent,
    RcTableComponent,
    RcSegmentedControlComponent,
    RcChartComponent,
    RcSpinnerComponent,
    RcNoContentComponent,
    RcFieldComponent,
    RcRadioImageComponent,
    RcGaugeComponent,
    RcAddressComponent,
    RcInputNumberComponent,
    RcInputPhoneComponent,
    RcOrderStatusComponent,
    AddAddressPopinComponent,
    LogginInformationPopinComponent,
    SignalPopinSendComponent,
    SelectedProductsPopinComponent,
    RcPopinUploadLogoComponent,
    RcStepperComponent,
    ActiveProgramPopinComponent,
    StartTourPopinComponent,
    RcPetSummaryPanelComponent,
    PageWrapperComponent,
    RcExitPopinComponent,
    CircleCharComponent,
    RenalGraphComponent,
    SlideComponent,
    MedicalHistoryDetailPopinComponent,
    RenalValuesComponent,
    PathologyComponent,
    ExpansionPanelComponent,
    CheckboxComponent,
    ActivityTooltipComponent,
    HyperlinkComponent,
    WeightFormComponent,
    ExpansionPanelStepHeaderComponent,
    ProductCardComponent,
    ProductEditCardComponent,
    ProductResetPriceComponent,
    CategoryNavigationComponent,
    TagsSelectionContainerComponent,
    ProductCardGridComponent,
    ProductCardListComponent,
    ViewTypeFilterComponent,
  ],
  exports: [
    MatTooltipModule,
    MatDatepickerModule,
    MatDateFnsModule,
    RCAutocompleteModule,
    RcMultiSelectModule,
    RcTagModule,
    RCFieldModule,
    RCSelectModule,
    IconModule,
    RcChipModule,
    RCFooterModule,
    RcTourModule,
    RCTextareaModule,
    RcAlertModule,
    RCSelectorsModule,
    /* Directive */
    InputNumberDirective,
    MarketGuardDirective,
    AppLetDirective,
    RCSelectDirective,
    /*Pipes*/
    ConverterHtmlToText,
    WeightPipe,
    FractionCupPipe,
    RoundPipe,
    PublicUrlPipe,
    CapitalizeFirstPipe,
    PossessivePipe,
    TranslationCodePipe,
    TruncatePipe,
    BreedPipe,
    ArianeTranslationPipe,
    NeuteredPipe,
    TextToLinkPipe,
    TranslatePipe,
    /* Other Components */
    LoaderComponent,
    EmailPoppinComponent,

    EmailResponsePopinComponent,
    WeightManagementPopinComponent,
    WarningMessageComponent,
    WholesalePriceTotalComponent,
    ProfileHeaderComponent,
    PetProfileHeaderComponent,
    PetIdentificationComponent,
    PetProfileFormComponent,
    PetOwnerFormComponent,
    ProductsQuantityFormComponent,
    ProductComponent,
    NewProductComponent,
    ProductCatalogFiltersComponent,
    CategoryNavigationComponent,
    ProductCatalogFiltersPopinComponent,
    ProductFooterComponent,
    ProductGridComponent,
    ProductEditComponent,
    ProductListComponent,
    BcsComponent,
    WeightLossPlanComponent,
    WeightLossSummaryComponent,
    DailyAllowanceViewComponent,
    UpdateDatePopinComponent,
    FollowUpSelectorComponent,
    FollowUpSelectorPopinComponent,
    WeightLossPlanComponent,
    WeightLossSummaryComponent,
    DailyAllowanceViewComponent,
    FollowUpComponent,
    SignalProductPopinComponent,
    ProductRecommendationCardComponent,
    DeletePatientPopinComponent,
    ProductResetPriceComponent,
    ClinicIdentifiedPopinComponent,
    NoResultsComponent,
    ProductRecommendationCardComponent,
    TableComponent,
    TableCellComponent,
    TableRowComponent,
    DeliveryOrderComponent,
    AddressComponent,
    AddAddressPopinComponent,
    SelectedProductsPopinComponent,
    DropdownListComponent,
    ActiveProgramPopinComponent,
    StartTourPopinComponent,
    CombineProductsPopinComponent,
    RenalGraphComponent,
    SlideComponent,
    MedicalHistoryDetailPopinComponent,
    RenalValuesComponent,
    PathologyComponent,
    ExpansionPanelComponent,
    CheckboxComponent,
    ActivityTooltipComponent,
    HyperlinkComponent,
    WeightFormComponent,
    ExpansionPanelStepHeaderComponent,
    /* RC Components */
    RcPopinContentComponent,
    RcDialogWrapperComponent,
    RcAttributeComponent,
    RcDropdownComponent,
    RcTooltipComponent,
    RcSelectComponent,
    RcAutocompleteComponent,
    RcInputComponent,
    RcTabsComponent,
    RcBlockComponent,
    RcFormComponent,
    RcListComponent,
    RcSegmentedControlComponent,
    RcTableComponent,
    RcChartComponent,
    RcGaugeComponent,
    RcPaginationComponent,
    RcSpinnerComponent,
    RcNoContentComponent,
    RcFieldComponent,
    RcRadioImageComponent,
    RcGaugeComponent,
    RcAddressComponent,
    RcInputNumberComponent,
    RcInputPhoneComponent,
    RcOrderStatusComponent,
    LogginInformationPopinComponent,
    SignalPopinSendComponent,
    RcPopinUploadLogoComponent,
    RcStepperComponent,
    RcPetSummaryPanelComponent,
    PageWrapperComponent,
    RcExitPopinComponent,
    CircleCharComponent,
    CriteriaDetailsPopinComponent,
    TagsSelectionContainerComponent,
    ViewTypeFilterComponent,
  ],
})
export class SharedModule {
  constructor(private registry: IconRegistryService) {
    this.registry.registerIcons([
      iconActions,
      iconAlert,
      iconBloodCreatinine,
      iconBloodUrea,
      iconBoth,
      iconCalculator,
      iconCat,
      iconChevronDown,
      iconClose,
      iconDelete,
      iconDocuments,
      iconDog,
      iconEmptyStateDesktop,
      iconEmptyStateMobile,
      iconFemale,
      iconGrid,
      iconIndoor,
      iconLink,
      iconList,
      iconLightBulb,
      iconMale,
      iconSpecieDog,
      iconSpecieCat,
      iconOutdoor,
      iconAddAddress,
      iconShop,
      iconSpecificGravity,
      iconUnlink,
      iconWarning,
      iconWetProducts,
      iconWeightLossProgram,
      iconRangeBhn,
      iconRangeCcn,
      iconRangeFbn,
      iconRangeShn,
      iconRefresh,
      iconEdit,
      iconFood2,
      iconFood3,
      iconCatBcs1Large,
      iconCatBcs2Large,
      iconCatBcs3Large,
      iconCatBcs4Large,
      iconCatBcs5Large,
      iconCatBcs6Large,
      iconCatBcs7Large,
      iconCatBcs8Large,
      iconCatBcs9Large,
      iconDogBcs1Large,
      iconDogBcs2Large,
      iconDogBcs3Large,
      iconDogBcs4Large,
      iconDogBcs5Large,
      iconDogBcs6Large,
      iconDogBcs7Large,
      iconDogBcs8Large,
      iconDogBcs9Large,
      iconCatBcs1Medium,
      iconCatBcs2Medium,
      iconCatBcs3Medium,
      iconCatBcs4Medium,
      iconCatBcs5Medium,
      iconCatBcs6Medium,
      iconCatBcs7Medium,
      iconCatBcs8Medium,
      iconCatBcs9Medium,
      iconDogBcs1Medium,
      iconDogBcs2Medium,
      iconDogBcs3Medium,
      iconDogBcs4Medium,
      iconDogBcs5Medium,
      iconDogBcs6Medium,
      iconDogBcs7Medium,
      iconDogBcs8Medium,
      iconDogBcs9Medium,
      iconTrash,
      iconCalendar,
      iconExclamation,
      iconSearch,
      iconArrowPanel,
      iconCalendarSmall,
      iconLoading,
      iconTerritoryWeightManagement,
      iconTerritoryBirthAndGrowth,
      iconTerritoryDermatology,
      iconTerritoryGastrointestinalTract,
      iconTerritoryHealthManagement,
      iconTerritoryUrinary,
      iconTerritoryVitalSupport,
      iconToolDaActive,
      iconToolSrActive,
      iconToolWmActive,
      iconToolRdActive,
      iconToolMufActive,
      iconExternalLink,
    ]);
  }

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CookieService,
        TranslationCodePipe,
        TitleCasePipe,
        LowerCasePipe,
        PossessivePipe,
        RoundPipe,
        WeightPipe,
        CapitalizeFirstPipe,
        TruncatePipe,
        ArianeTranslationPipe,
        NeuteredPipe,
        TextToLinkPipe,
        CurrencyPipe,
        FractionCupPipe,
        MatDatepickerToggle,
        MatDialog,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        {
          provide: MatDialogRef,
          useValue: {},
        },
        { provide: MatDialogConfig, useValue: {} },
        MatDatepickerModule,
        MatDateFnsModule,
      ],
    };
  }
}
