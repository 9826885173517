import { ProductCategoriesFilters } from '@app/core/models/territories-filters';
import { Product } from '@app/core/models';
import { FetchProductDataFilters } from '@app/core/models/fetch-product-data-filters';
import { ProductCatalogFilterValues } from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { Constants, Helper, LifestageType, PackType, RenalDetectStatusEnum } from '@app/shared/utils';
import { BreedSize, ProductPillar, ProductType, SpeciesCode } from '../enums';
import { RangeType } from '../enums/rangeType.enum';
import { TerritoryType } from '../enums/territoryType';
import { translateKey } from './translate';

export function filterCatalogProducts(products: Product[], filterData: FetchProductDataFilters): Product[] {
  const { program, retailChannel, speciesCode, productType, packType } = filterData;
  return products.filter((product) => {
    const boxPacks = product.packages.filter((pack) => {
      return pack.type === PackType.Box;
    });

    const packTypePacks = product.packages.filter((pack) => {
      return pack.type === packType;
    });
    // filter product by pack type
    if (boxPacks.length !== 0) {
      return false;
    }
    if (packType && packTypePacks.length === 0) {
      return false;
    }

    // filter product by program
    if (program && !product.programs.includes(program)) return false;
    // filter product by retail channel
    if (retailChannel && product.retailChannel !== retailChannel) return false;
    // filter product by specie
    if (speciesCode && product.speciesCode !== speciesCode) return false;
    // filter product by type
    if (productType && product.productType !== productType) return false;

    return true;
  });
}

export function filterCatalogProductsRenalDetect(
  products: Product[],
  currentRenalStatus: RenalDetectStatusEnum,
  prevRenalStatus: RenalDetectStatusEnum
): Product[] {
  if (currentRenalStatus === RenalDetectStatusEnum.NotAtRisk) {
    return prevRenalStatus === RenalDetectStatusEnum.AtRisk ? filterProductsAtRisk(products) : filterProductsNotAtRisk(products);
  } else if (currentRenalStatus === RenalDetectStatusEnum.AtRisk) {
    return filterProductsAtRisk(products);
  } else if (currentRenalStatus === RenalDetectStatusEnum.CKD) {
    return products.filter((product) => Constants.PRODUCT_CDK.includes(product.mainItemId));
  } else {
    return products;
  }
}

function filterProductsAtRisk(products: Product[]): Product[] {
  const productsAtRiskEarly = products.filter((product) => Constants.PRODUCT_AT_RISK_EARLY.includes(product.mainItemId));
  return productsAtRiskEarly.length > 0
    ? productsAtRiskEarly
    : products.filter((product) => Constants.PRODUCT_AT_RISK.includes(product.mainItemId));
}

function filterProductsNotAtRisk(products: Product[]): Product[] {
  return products
    .filter((product: Product) => {
      return Constants.PRODUCT_NOT_AT_RISK.includes(product.mainItemId);
    })
    .sort((a, b) => {
      if (!a.lifestagesCode || !b.lifestagesCode) {
        return 0;
      } else if (b.lifestagesCode.includes(LifestageType.Senior) || b.lifestagesCode.includes(LifestageType.Mature)) {
        return 1;
      } else if (a.lifestagesCode.includes(LifestageType.Senior) || a.lifestagesCode.includes(LifestageType.Mature)) {
        return -1;
      } else {
        return 0;
      }
    });
}

export function filterProductPackagesByType(products: Product[], type: PackType): Product[] {
  return products.map((product) => ({
    ...product,
    packages: product.packages.filter((pack) => pack.type === type),
  }));
}

export function filterSPTRetailByTerritory(products: Product[], territory: TerritoryType): Product[] {
  return products.filter((product) => {
    if (product.pillar === ProductPillar.SPTRETAIL) {
      return product.territory === territory;
    } else {
      return product;
    }
  });
}

export const filterSPTRetailByTerritoryAndRange = (products: Product[], territory: TerritoryType, range: RangeType[]) => {
  return products.filter((product) => {
    if (product.pillar === ProductPillar.SPTRETAIL) {
      return product.territory === territory && !range.includes(product.range);
    } else {
      return product;
    }
  });
};
export function newFilterProducts(products: Product[], filters: ProductCatalogFilterValues) {
  const productsList = products.filter((product) => {
    if (filters.pillar === ProductPillar.VET && product.pillar !== ProductPillar.VET) return false;
    if (filters.pillar === ProductPillar.SPTRETAIL && product.pillar !== ProductPillar.SPTRETAIL) return false;

    if (filters.specie === SpeciesCode.Dog && product.speciesCode !== SpeciesCode.Dog) return false;
    if (filters.specie === SpeciesCode.Cat && product.speciesCode !== SpeciesCode.Cat) return false;

    if (!filters.wet && filters.dry && product.productType !== ProductType.Dry) return false;
    if (!filters.dry && filters.wet && product.productType !== ProductType.Wet) return false;

    if (filters.size && filters.size !== BreedSize.All && !product.weightCategoriesCode?.includes(filters.size)) return false;

    if (
      filters.search &&
      filters.search.length > 2 &&
      !filters.search
        ?.toLowerCase()
        .split(' ')
        .every((searchWord) =>
          product.name
            ?.toLowerCase()
            .split(' ')
            .some((namePart) => namePart.startsWith(searchWord))
        )
    )
      return false;

    const currentLifestages = [];
    if (filters.adult) currentLifestages.push(LifestageType.Adult);
    if (filters.mature) currentLifestages.push(LifestageType.Mature, LifestageType.Senior);
    if (filters.birthAndGrowth) currentLifestages.push(LifestageType.Baby, LifestageType.Puppy, LifestageType.Junior, LifestageType.Kitten);
    if (currentLifestages.length && !product.lifestagesCode?.some((item) => currentLifestages.includes(item))) return false;

    return true;
  });
  const dry = filterByProductTypeAndSort(productsList, ProductType.Dry);
  const wet = filterByProductTypeAndSort(productsList, ProductType.Wet);
  return dry.concat(wet);
}

export function formatProductsByCategory(products: Product[], pillar: ProductPillar): Record<TerritoryType | RangeType, Product[]> {
  return products.reduce((prev, curr) => {
    const key = pillar === ProductPillar.VET ? curr?.territory : curr?.range;
    pushProductInCategory(key, prev, curr);

    if (pillar === ProductPillar.SPTRETAIL && curr?.territory === TerritoryType.BirthAndGrowth) {
      pushProductInCategory(TerritoryType.BirthAndGrowth, prev, curr);
    }

    return prev;
  }, {} as Record<TerritoryType | RangeType, Product[]>);
}

function pushProductInCategory(key: string, prev: Record<TerritoryType | RangeType, Product[]>, curr: Product) {
  if (!prev[key]) {
    prev[key] = [];
  }
  prev[key].push(curr);
}

export function filterByProductTypeAndSort(productsList: Product[], productType: ProductType): Product[] {
  return productsList
    .filter((prod) => prod.productType === productType)
    .sort((a, b) => Helper.alphabeticalOrder(a.name.toLowerCase(), b.name.toLowerCase()));
}

export function getSortedCategories(
  categories: ProductCategoriesFilters[],
  firstDisplayedCategory?: TerritoryType | RangeType
): ProductCategoriesFilters[] {
  const sortedArray = [...categories];
  sortedArray.sort((a, b) => {
    if (a.code === firstDisplayedCategory) return 1;
    return Helper.alphabeticalOrder(translateKey(a.translationCode), translateKey(b.translationCode));
  });
  return sortedArray;
}
