export interface LocaleContent {
  code: any;
  translationCode: string;
  text: string;
  localizedText?: string;
  data?: Record<string, string | boolean>;
}

export enum LocaleContentEnum {
  Species,
  ReproductionStatus,
  PetActivity,
  Gender,
  ProductType,
  PackType,
  OrderStatus,
  MeasurementSystem,
}
