import { UserClaims } from '@okta/okta-auth-js';
import { Vet, Term } from '@app/core/models';

// The api will return data inside the terms array only if there are terms to validate
export function areTermsValid(vet: Vet): boolean {
  return !vet?.purposes?.terms || vet?.purposes?.terms.length === 0;
}

// temporary code for retro-compatibility that will be deleted once back and front are in production
export function buildTermsApiBody(terms: Term[]): any {
  return {
    terms: terms.map((term) => ({
      purpose: term.Id,
      name: term.name,
    })),
  };
}

export function isOktaUserMissingInfo(user: UserClaims): boolean {
  return !user?.userinfo?.countryCode || !user?.userinfo?.lastName || !user?.userinfo?.firstName;
}
