import { throwError, timer, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, filter, mergeMap, retryWhen } from 'rxjs/operators';

export class RxjsHelper {
  static observableRetryPolicy<T>(nbRetries = 3, baseMs = 500): OperatorFunction<T, T> {
    return retryWhen((errors) =>
      errors.pipe(
        mergeMap((error, i) => {
          if (i >= nbRetries) {
            return throwError(error);
          }
          return timer(baseMs * ++i);
        })
      )
    );
  }

  static filterCollFull<T>(): OperatorFunction<T[], T[]> {
    return filter((coll: T[]) => {
      for (const e of coll) {
        if (e === null) {
          return false;
        }
      }
      return true;
    });
  }

  static distinctUntilCollElemChanged<T>(): OperatorFunction<T[], T[]> {
    return distinctUntilChanged((coll1: T[], coll2: T[]) => {
      for (const i in coll1) {
        if (coll1[i] !== coll2[i]) {
          return false;
        }
      }
      return true;
    });
  }
}
