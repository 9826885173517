import { ModuleNameEnum, ModuleTypeEnum, PageTypeEnum } from '../types/enums';
import { GTMPageView, ModuleNameArg } from '../types/interfaces';

export const welcomeTour: GTMPageView = {
  page: {
    type: PageTypeEnum.WELCOME_TOUR,
  },
  module: {
    name: ModuleNameEnum.SMART_RECOMMENDATION,
    type: ModuleTypeEnum.CONSULTATION,
  },
};

export const petDetails = ({ name }: ModuleNameArg): GTMPageView => ({
  page: {
    type: PageTypeEnum.PET_DETAILS,
  },
  module: {
    name,
    type: ModuleTypeEnum.CONSULTATION,
  },
});

export const nutritionalRecommendation = ({ name }: ModuleNameArg): GTMPageView => ({
  page: {
    type: PageTypeEnum.NUTRITIONAL_RECOMMENDATION,
  },
  module: {
    name,
    type: ModuleTypeEnum.CONSULTATION,
  },
});

export const productSelection = ({
  name,
  type = ModuleTypeEnum.CONSULTATION,
}: {
  name: ModuleNameEnum;
  type?: ModuleTypeEnum;
}): GTMPageView => ({
  page: {
    type: PageTypeEnum.PRODUCT_SELECTION,
  },
  module: {
    name,
    type,
  },
});

export const orderDetails = ({
  name,
  type = ModuleTypeEnum.CONSULTATION,
}: {
  name: ModuleNameEnum;
  type?: ModuleTypeEnum;
}): GTMPageView => ({
  page: {
    type: PageTypeEnum.CHECKOUT,
  },
  module: {
    name,
    type,
  },
});
