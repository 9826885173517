import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AttributeType, InputType } from '@app/core/models';
import { InputStateType } from '@app/shared/utils/enums/input-state.enum';

@Component({
  selector: 'app-rc-field',
  templateUrl: './rc-field.component.html',
  styleUrls: ['./rc-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RcFieldComponent),
      multi: true,
    },
  ],
})
export class RcFieldComponent implements ControlValueAccessor {
  @Input() type = 'text';

  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() suffix: string;

  @Input() minlength: number;
  @Input() maxlength: number;
  @Input() pattern: string;

  @Input() min: number;
  @Input() max: number;
  @Input() step: number;

  @Input() label: string;
  @Input() fixedLabel: string;
  @Input() isLabelDisplayed = true;
  @Input() autocomplete: string;
  @Input() name: string;
  @Input() value: string;
  @Input() successState = false;
  @Input() digitOnly = false;

  isFocused: boolean;
  inputError: boolean;
  InputType = InputType;
  AttributeType = AttributeType;
  compositionStarted = false;

  @Input() set errorState(value: string) {
    this.inputError = value === InputStateType.Failed;
  }

  @Output() changeValue = new EventEmitter<string>();
  @Output() focusOut = new EventEmitter<string>();

  propagateChange(_: any) {
    // empty
  }
  onTouchedFn(_: any) {
    // empty
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.propagateChange(this.value);
    this.changeValue.emit(this.value);
  }

  onFocus() {
    this.isFocused = true;
  }

  onFocusOut() {
    this.isFocused = false;
    this.onTouchedFn(null);
    this.focusOut.emit(this.value);
  }

  /**
   * Method that is invoked on compositionstart and compositionend inputs events, theye are called when using cyrillic alphabets only
   * we want to make sure that the input looks active (design issue) when starting to write symbol
   */
  public setCompositionStarted(isStarted: boolean) {
    this.compositionStarted = isStarted;
  }
}
