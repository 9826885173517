import { Component, Input } from '@angular/core';
import { ModalService } from '@app/core/services/modal';
import { PopinSize } from '@app/shared/utils';

@Component({
  selector: 'app-rc-popin-content',
  templateUrl: './rc-popin-content.component.html',
  styleUrls: ['./rc-popin.scss'],
})
export class RcPopinContentComponent {
  @Input() size: PopinSize = PopinSize.Auto;
  @Input() isSingleColumn = false;
  @Input() isSeparatorDisplayed = false;
  @Input() isCreatePatientContent = false;

  /**
   * Initializer
   */
  constructor(private modal: ModalService) {}

  /**
   * Dismiss Pop-in
   */
  dismiss() {
    this.modal.close();
  }
}
