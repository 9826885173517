import { Directive, ElementRef, HostBinding, HostListener, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';

import { Dialog } from './dialog';
import { DialogRef } from './dialog-ref';

/** Counter used to generate unique IDs for dialog elements. */
let dialogElementUid = 0;

@Directive({
  selector: '[app-rc-dialog], rc-dialog, [rcDialog]',
  exportAs: 'RCDialog',
})
export class RCDialogDirective {
  @HostBinding('class') class = 'rc-dialog';
}

@Directive({
  selector: `button[app-rc-dialog-close], button[rcDialogClose]`,
  exportAs: 'rcDialogClose',
})
export class RCDialogCloseDirective implements OnInit, OnChanges {
  @Input() dialogResult: any;

  @HostBinding('attr.aria-label') ariaLabel: string;
  @HostListener('click', ['$event'])
  onClick() {
    this.dialogRef.close(this.dialogResult);
  }

  constructor(@Optional() public dialogRef: DialogRef<any>, private _elementRef: ElementRef<HTMLElement>, private _dialog: Dialog) {}

  ngOnInit() {
    if (!this.dialogRef) {
      this.dialogRef = getClosestDialog(this._elementRef, this._dialog.openDialogs);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const valueChanged = changes['_rcDialogClose'];

    if (valueChanged) {
      this.dialogResult = valueChanged.currentValue;
    }
  }
}

/**
 * Title of a dialog element. Stays fixed to the top of the dialog when scrolling.
 */
@Directive({
  selector: '[app-rc-dialog-title], rc-dialog-title, [rcDialogTitle]',
  exportAs: 'RCDialogTitle',
})
export class RCDialogTitleDirective {
  @HostBinding('class') class = 'rc-dialog-title';
  @HostBinding('attr.id') id = `rc-dialog-title-${dialogElementUid++}`;
}

/**
 * Scrollable content container of a dialog.
 */
@Directive({
  selector: `[app-rc-dialog-content], rc-dialog-content, [rcDialogContent]`,
})
export class RCDialogContentDirective {
  @Input() class: string;

  @HostBinding('class')
  get hostClasses(): string {
    return ['rc-dialog-content', this.class].join(' ');
  }
}

@Directive({
  selector: `[app-rc-dialog-content], rc-dialog-wrapper, [rcDialogWrapper]`,
})
export class RCDialogWrapperDirective {
  @HostBinding('class') class = 'rc-dialog-wrapper';
}

/**
 * Container for the bottom action buttons in a dialog.
 * Stays fixed to the bottom when scrolling.
 */
@Directive({
  selector: `[app-rc-dialog-actions], rc-dialog-actions, [rcDialogActions]`,
})
export class RCDialogActionsDirective {
  @HostBinding('class') class = 'rc-dialog-actions';
}

/**
 * Finds the closest DialogRef to an element by looking at the DOM.
 * @param element Element relative to which to look for a dialog.
 * @param openDialogs References to the currently-open dialogs.
 */
function getClosestDialog(element: ElementRef<HTMLElement>, openDialogs: DialogRef<any>[]) {
  let parent: HTMLElement | null = element.nativeElement.parentElement;

  while (parent && !parent.classList.contains('RC-dialog-container')) {
    parent = parent.parentElement;
  }

  return parent ? openDialogs.find((dialog) => dialog.id === parent.id) : null;
}
