import { Pipe, PipeTransform } from '@angular/core';
import { LocaleContent, LocaleContentEnum } from '@app/shared/data';
import { ActivityCode, GenderCode, Helper, ReproductionStatusCode, SpeciesCode } from '@app/shared/utils';
import { translateKey } from '../utils/static-helpers/translate';

@Pipe({
  name: 'translationCode',
})
export class TranslationCodePipe implements PipeTransform {
  private _localizedContents: { [localeType: number]: LocaleContent[] } = {};

  transform(localeContentCode, localeType: LocaleContentEnum): string {
    const localeContent = this.getLocaleContent(localeContentCode, localeType);
    return localeContent ? localeContent.localizedText : '--';
  }

  getLocaleContent(localeContentCode, localeType: LocaleContentEnum): LocaleContent {
    return this.getLocalizedContent(localeType).find((elem: LocaleContent) => {
      return elem.code === localeContentCode || elem.translationCode === localeContentCode;
    });
  }

  getLocalizedContent(localeType: LocaleContentEnum): LocaleContent[] {
    if (!(localeType in this._localizedContents)) {
      this._localizedContents[localeType] = Helper.translateLocalizedContent(this._getLocaleContent(localeType));
    }
    return this._localizedContents[localeType];
  }

  private _getLocaleContent(localeType: LocaleContentEnum): LocaleContent[] {
    switch (localeType) {
      case LocaleContentEnum.Gender:
        return [
          {
            translationCode: 'gender-male',
            code: GenderCode.Male,
            text: 'Male',
            localizedText: translateKey('gender-male'),
          },
          {
            translationCode: 'gender-female',
            code: GenderCode.Female,
            text: 'Female',
            localizedText: translateKey('gender-female'),
          },
        ];
      case LocaleContentEnum.PetActivity:
        return [
          {
            translationCode: 'activity-low',
            code: ActivityCode.Low,
            text: 'Low',
            localizedText: translateKey('activity-low'),
          },
          {
            translationCode: 'activity-moderate',
            code: ActivityCode.Moderate,
            text: 'Moderate',
            localizedText: translateKey('activity-moderate'),
          },
          {
            translationCode: 'activity-high',
            code: ActivityCode.High,
            text: 'High',
            localizedText: translateKey('activity-high'),
          },
        ];
      case LocaleContentEnum.ReproductionStatus:
        return [
          {
            code: ReproductionStatusCode.Gestation,
            translationCode: 'status-gestation',
            text: 'Gestation',
            localizedText: translateKey('status-gestation'),
          },
          {
            translationCode: 'status-lactation',
            code: ReproductionStatusCode.Lactation,
            text: 'Lactation',
            localizedText: translateKey('status-lactation'),
          },
          {
            translationCode: 'status-none',
            code: ReproductionStatusCode.None,
            text: 'None',
            localizedText: translateKey('status-none'),
          },
        ];
      case LocaleContentEnum.Species:
        return [
          {
            translationCode: 'specie-dog',
            code: SpeciesCode.Dog,
            text: 'Dog',
            localizedText: translateKey('specie-dog'),
          },
          {
            translationCode: 'specie-cat',
            code: SpeciesCode.Cat,
            text: 'Cat',
            localizedText: translateKey('specie-cat'),
          },
        ];
      case LocaleContentEnum.ProductType:
        return [
          {
            translationCode: 'product-type_dry',
            code: 'dry',
            text: 'Dry',
            localizedText: translateKey('product-type_dry'),
          },
          {
            translationCode: 'product-type_wet',
            code: 'wet',
            text: 'Wet',
            localizedText: translateKey('product-type_wet'),
          },
          {
            translationCode: 'product-type_both',
            code: 'both',
            text: 'Both',
            localizedText: translateKey('product-type_both'),
          },
        ];
      case LocaleContentEnum.PackType:
        return [
          {
            translationCode: 'package-type_dry-bag',
            code: 'bag',
            text: 'Bag',
            localizedText: translateKey('package-type_dry-bag'),
          },
          {
            translationCode: 'package-type_wet-can',
            code: 'can',
            text: 'Can',
            localizedText: translateKey('package-type_wet-can'),
          },
          {
            translationCode: 'package-type_wet-cans',
            code: 'cans',
            text: 'Can(s)',
            localizedText: translateKey('package-type_wet-cans'),
          },
          {
            translationCode: 'package-type_wet-small-can',
            code: 'smallCan',
            text: 'Small can',
            localizedText: translateKey('package-type_wet-small-can'),
          },
          {
            translationCode: 'package-type_wet-large-can',
            code: 'bigCan',
            text: 'Large can',
            localizedText: translateKey('package-type_wet-large-can'),
          },
          {
            translationCode: 'package-type_wet-pouch',
            code: 'pouch',
            text: 'Pouch',
            localizedText: translateKey('package-type_wet-pouch'),
          },
        ];
      case LocaleContentEnum.OrderStatus:
        return [
          {
            code: 'pending',
            text: 'New',
            translationCode: 'order-status_pending',
            localizedText: translateKey('order-status_pending'),
          },
          {
            code: 'awaiting-approval',
            text: 'Awaiting Approval',
            translationCode: 'order-status_awaiting-approval',
            localizedText: translateKey('order-status_awaiting-approval'),
          },
          {
            code: 'approved',
            text: 'Approved',
            translationCode: 'order-status_approved',
            localizedText: translateKey('order-status_approved'),
          },
          {
            code: 'validated',
            text: 'Approved',
            translationCode: 'order-status_validated',
            localizedText: translateKey('order-status_validated'),
          },
          {
            code: 'personalized',
            text: 'Awaiting Sticker',
            translationCode: 'order-status_personalized',
            localizedText: translateKey('order-status_personalized'),
          },
          {
            code: 'ready-for-wms',
            text: 'Personalized',
            translationCode: 'order-status_ready-for-wms',
            localizedText: translateKey('order-status_ready-for-wms'),
          },
          {
            code: 'validated',
            text: 'Validated',
            translationCode: 'order-status_validated',
            localizedText: translateKey('order-status_validated'),
          },
          {
            code: 'processed',
            text: 'Order preparation',
            translationCode: 'order-status_processed',
            localizedText: translateKey('order-status_processed'),
          },
          {
            code: 'shipped',
            text: 'Shipped',
            translationCode: 'order-status_shipped',
            localizedText: translateKey('order-status_shipped'),
          },
          {
            code: 'awaiting-b2b-loyalty',
            text: 'Awaiting B2B loyalty',
            translationCode: 'order-status_awaiting-b2b-loyalty',
            localizedText: translateKey('order-status_awaiting-b2b-loyalty'),
          },
          {
            code: 'ready-for-vet-invoiced',
            text: 'Ready for Vet invoiced',
            translationCode: 'order-status_ready-for-vet-invoiced',
            localizedText: translateKey('order-status_ready-for-vet-invoiced'),
          },
          {
            code: 'invoiced',
            text: 'Sent for vet invoice',
            translationCode: 'order-status_invoiced',
            localizedText: translateKey('order-status_invoiced'),
          },
          {
            code: 'closed',
            text: 'Completed',
            translationCode: 'order-status_closed',
            localizedText: translateKey('order-status_closed'),
          },
          {
            code: 'canceled',
            text: 'Canceled',
            translationCode: 'order-status_canceled',
            localizedText: translateKey('order-status_canceled'),
          },
          {
            code: 'refused',
            text: 'Refused',
            translationCode: 'order-status_refused',
            localizedText: translateKey('order-status_refused'),
          },
          {
            code: 'active',
            text: 'Active',
            translationCode: 'status-active',
            localizedText: translateKey('status-active'),
          },
        ];
      case LocaleContentEnum.MeasurementSystem:
        return [
          {
            code: 1,
            text: 'Metric System',
            translationCode: 'measurement_metric-system',
            localizedText: translateKey('measurement_metric-system'),
          },
          {
            code: 2,
            text: 'Imperial System',
            translationCode: 'measurement_imperial-system',
            localizedText: translateKey('measurement_imperial-system'),
          },
        ];
      default:
        return [];
    }
  }
}
