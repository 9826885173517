import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ChartService } from '@app/core/services';
import { GraphType, GraphTypeScale } from '@app/shared/utils/enums/chart-enums';
import { ChartData, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-rc-chart',
  templateUrl: './rc-chart.component.html',
  styleUrls: ['./rc-chart.component.scss'],
})
export class RcChartComponent {
  @ViewChild('canvasElement', { static: true }) private chartRef;

  /**
   * Chart properties
   *
   * node_modules ▸ @types ▸ chart.js
   * https://www.chartjs.org/docs/latest/
   */
  private _chartType: GraphType;
  private _chartData: ChartData;
  private _globalOptions: ChartOptions;
  private _graph: ChartService;

  /**
   * Setters and Getters
   */

  @Output()
  chartLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  set chartType(value: GraphType) {
    this._chartType = value;
  }
  get chartType(): GraphType {
    return this._chartType;
  }
  @Input()
  set chartData(value: ChartData) {
    this._chartData = value;
    this._initChart();
  }
  get chartData(): ChartData {
    return this._chartData;
  }
  @Input()
  set globalOptions(value: ChartOptions) {
    this._globalOptions = value;
  }
  get globalOptions(): ChartOptions {
    return this._globalOptions;
  }

  constructor(private chartService: ChartService) {}

  private _initChart() {
    this.chartLoading.emit(true);
    this._graph = this.chartService;
    const chartElm = this.chartRef.nativeElement;
    this._graph.graphType = this._chartType || GraphType.Bar;

    switch (this._chartType) {
      case GraphType.Bar:
        this._graph.scaleType = GraphTypeScale.Category;
        this._graph.drawGraph(chartElm, this._chartData);
        break;

      case GraphType.Line:
        this._graph.scaleType = GraphTypeScale.Linear;
        this._graph.drawGraph(chartElm, this._chartData);
        break;

      default:
        this._graph.drawGraph(chartElm, this._chartData);
        break;
    }
    this.chartLoading.emit(false);
  }
}
