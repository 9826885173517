import { environment } from '@env/environment';
import { VetFacade } from '@app/store/vet';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { AfterContentInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Dialog } from '@app/core/cdk/dialog/dialog';
import { Breed, Product, ProductPriceEdit, Segmented } from '@app/core/models';
import { GTMService, productEditPrices } from '@app/core/services/tracking';
import { Logger } from '@app/core/services/utils/logger';
import { RcExitPopinComponent } from '@app/shared/components/rc-exit-popin/rc-exit-popin.component';
import { LocaleContent } from '@app/shared/data';
import { ActionsEnum, DetectMobile, Helper, LifestageType, ProductType, SpeciesCode, Tool, ViewType } from '@app/shared/utils';
import { TerritoryType } from '@app/shared/utils/enums/territoryType';
import { IconName } from '@app/shared/utils/icon/icons';
import ProductHelper from '@app/shared/utils/static-helpers/product-helper';
import { AppState } from '@app/store';
import { selectPriceListUpdated } from '@app/store/products/products.selectors';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { ProductsInterfaceConfig } from './products-interface-config';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, AfterContentInit {
  LifestageType = LifestageType;
  ViewType = ViewType;
  IconNameEnum = IconName;
  DetectMobile = DetectMobile;

  loaded = false;
  /*
  Child translations
  */
  i18n_buttonEditText = $localize`:@@action_reset:`;
  i18n_titleText = $localize`:@@sorry:`;
  /**
   * Properties
   */

  territories = [
    {
      code: undefined,
      text: 'All',
      translationCode: 'form-attribute_all-products',
      localizedText: translateKey('form-attribute_all-products'),
    },
    {
      code: TerritoryType.BirthAndGrowth,
      text: 'Birth & Growth',
      translationCode: 'product-select-territory-birth',
      localizedText: translateKey('product-select-territory-birth'),
    },
    {
      code: TerritoryType.Dermatology,
      text: 'Dermatology',
      translationCode: 'product-select-territory-dermatology',
      localizedText: translateKey('product-select-territory-dermatology'),
    },
    {
      code: TerritoryType.GastrointestinalTract,
      text: 'Gastrointestinal Tract',
      translationCode: 'product-select-territory-gastro-tract',
      localizedText: translateKey('product-select-territory-gastro-tract'),
    },
    {
      code: TerritoryType.HealthManagement,
      text: 'Health Management',
      translationCode: 'product-select-territory-health-management',
      localizedText: translateKey('product-select-territory-health-management'),
    },
    {
      code: TerritoryType.Reproduction,
      text: 'Reproduction',
      translationCode: 'product-select-territory-reproduction',
      localizedText: translateKey('product-select-territory-reproduction'),
    },
    {
      code: TerritoryType.Urinary,
      text: 'Urinary',
      translationCode: 'product-select-territory-urinary',
      localizedText: translateKey('product-select-territory-urinary'),
    },
    {
      code: TerritoryType.VitalSupport,
      text: 'Vital Support',
      translationCode: 'product-select-territory-vital-support',
      localizedText: translateKey('product-select-territory-vital-support'),
    },
    {
      code: TerritoryType.WeightManagement,
      text: 'Weight Management',
      translationCode: 'product-select-territory-weight-management',
      localizedText: translateKey('product-select-territory-weight-management'),
    },
    {
      code: TerritoryType.Other,
      text: 'Other ?',
      translationCode: 'product-select-territory-other',
      localizedText: translateKey('product-select-territory-other'),
    },
  ];
  filteredTerritories;

  @Input() pricesSaved = false;
  @Input() interfaceConfig: ProductsInterfaceConfig;
  @Input() productForm: FormGroup;
  @Input() selectedProductIds: string[];
  @Input() displayPackages = true;
  @Input() selectedProducts?: Product[];
  @Input() tool?: Tool;

  @Input()
  set products(products: Product[]) {
    this._products = products;
    this.filterProducts();
    this._filterTerritories();
    if (products && products.length > 0) {
      this.loaded = true;
    }
  }
  get products(): Product[] {
    return this._products;
  }

  @Input()
  set selectedSpecies(speciesCode: SpeciesCode) {
    this.setSelectedSpecies(speciesCode);
  }
  get selectedSpecies(): SpeciesCode {
    return this._selectedSpecies;
  }

  editPriceMode = false;
  @Input() viewType: ViewType = ViewType.Grid;

  @Input() cancelEditEvent: Observable<void>;
  @Input() resetPricesEvent: Observable<void>;

  @Input()
  set lifestageType(lifestage: LifestageType) {
    this._lifestageType = lifestage;
  }
  get lifestageType(): LifestageType {
    return this._lifestageType;
  }

  @Input()
  set selectedBreed(breed: Breed) {
    this.setSelectedBreed(breed);
  }
  get selectedBreed(): Breed {
    return this._selectedBreed;
  }

  @Input()
  set selectedProductType(productType: ProductType) {
    this.setSelectedProductType(productType);
  }
  get selectedProductType(): ProductType {
    return this._selectedProductType;
  }

  @Input() set selectedTerritory(territory: TerritoryType) {
    this.setSelectedTerritory(territory);
  }
  get selectedTerritory(): TerritoryType {
    return this._selectedTerritory;
  }

  @Output() selectedProduct: EventEmitter<{ product: Product; packId: string }> = new EventEmitter<{ product: Product; packId: string }>();
  @Output() didSelectTerritory: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeProductEvent: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() pricesUpdatedSuccess: EventEmitter<ProductPriceEdit[] | null> = new EventEmitter();
  @Output() currentViewType: EventEmitter<ViewType> = new EventEmitter<ViewType>();
  @Output() updateEditPriceMode: EventEmitter<boolean> = new EventEmitter<boolean>();

  filterExpanded = false;
  filteredProducts: Product[];
  currentSearch = '';
  currentLowercaseSearch = '';
  nbProductsDisplayed = 12;
  nbProductsMore = 9;
  productTypeSegmented: Segmented[];
  viewTypeSegmented: Segmented[];
  species: { key: string; value: SpeciesCode }[];
  dryProduct: Product;
  canEditPrices$ = this.vetFacade.canEditPrices$.pipe(
    map((canEditPrices) => !environment.isStandalone && canEditPrices && this.interfaceConfig.showRetailPrices)
  );

  private destroyed$ = new Subject();
  private _selectedProductType = ProductType.Unknown;
  private _selectedSpecies = SpeciesCode.Unknown;
  private _lifestageType: LifestageType = LifestageType.All;
  private _products: Product[];
  private _selectedBreed: Breed = null;
  private _selectedTerritory: TerritoryType;
  private _productTypes: { label: string; value: ProductType }[];

  /**
   * Initializer
   */
  constructor(
    private logger: Logger,
    private store$: Store<AppState>,
    private trackingService: GTMService,
    private dialog: Dialog,
    private vetFacade: VetFacade
  ) {}

  /**
   * Life Cycle
   */
  ngOnInit() {
    this._setAttributes();
    if (this.tool === Tool.RenalDetect) {
      this.i18n_buttonEditText = undefined;
    }

    this.cancelEditEvent?.pipe(takeUntil(this.destroyed$)).subscribe(() => this.setViewType(this.viewType));
    this.resetPricesEvent?.pipe(takeUntil(this.destroyed$)).subscribe(() => this.pricesUpdated(null));

    this.store$
      .select(selectPriceListUpdated)
      .pipe(
        takeUntil(this.destroyed$),
        filter((priceListUpdated) => !!priceListUpdated.length),
        tap((priceList) => {
          this.pricesUpdated(priceList);
        })
      )
      .subscribe();
  }

  ngAfterContentInit(): void {
    this.dryProduct && this.selectProduct(this.dryProduct, null);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private _setAttributes() {
    this.species = [
      { key: $localize`:@@all:`, value: SpeciesCode.Unknown },
      { key: $localize`:@@species-dog:`, value: SpeciesCode.Dog },
      { key: $localize`:@@species-cat:`, value: SpeciesCode.Cat },
    ];

    this._productTypes = [
      { label: $localize`:@@product-type_both:`, value: ProductType.Unknown },
      { label: $localize`:@@product-type_dry:`, value: ProductType.Dry },
      { label: $localize`:@@product-type_wet:`, value: ProductType.Wet },
    ];
    this.productTypeSegmented = this._productTypes.map((item) => Helper.mapTypeToRcSegmented(item));
    this.viewTypeSegmented = [
      {
        label: $localize`:@@product-view-type_grid:`,
        value: ViewType.Grid,
        icon: IconName.grid,
      },
      {
        label: $localize`:@@product-view-type_list:`,
        value: ViewType.List,
        icon: IconName.list,
      },
    ].map((item) => Helper.mapTypeToRcSegmented(item));
  }

  setEditMode(): void {
    this.editPriceMode = true;
    this.updateEditPriceMode.emit(this.editPriceMode);
  }

  setViewType(viewType: string): void {
    if (this.editPriceMode && !this.pricesSaved) {
      this.dialog
        .open(RcExitPopinComponent, {
          panelClass: 'small',
          data: {
            popinInfo: {
              actionOne: $localize`:@@price_display_leave_pop_in-hyperlink:`,
              actionTwo: $localize`:@@price_display_leave_pop_in-button:`,
              title: $localize`:@@price_display_leave_pop_in-title:`,
              subTitle: $localize`:@@price_display_leave_pop_in-subtitle:`,
            },
          },
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe((data: { button: ActionsEnum }) => {
          if (data.button === ActionsEnum.two) {
            this.changeView(viewType as ViewType);
          } else if (data.button === ActionsEnum.one) {
            this.viewType = viewType as ViewType;
            this.currentViewType.emit(viewType as ViewType);
          }
        });
    } else {
      this.changeView(viewType as ViewType);
    }
  }

  changeView(viewType: ViewType) {
    this.viewType = viewType;
    if (this.editPriceMode) {
      this.editPriceMode = false;
      this.trackingService.sendInteraction(productEditPrices());
    }
    this.updateEditPriceMode.emit(this.editPriceMode);
    this.currentViewType.emit(viewType);
  }

  pricesUpdated(priceList: ProductPriceEdit[] | null): void {
    this.editPriceMode = false;
    this.setViewType(this.viewType);
    this.pricesUpdatedSuccess.emit(priceList);
  }

  setSelectedSpecies(speciesCode: SpeciesCode) {
    if (this._selectedSpecies !== speciesCode) {
      this._selectedSpecies = speciesCode;
      this.filterProducts();
    }
  }

  setSelectedBreed(breed: Breed) {
    if (!breed || !this._selectedBreed || this._selectedBreed.breedCode !== breed.breedCode) {
      this._selectedBreed = breed;
      this.filterProducts();
    }
  }

  setSelectedProductType(productType?: string): void {
    const castedProductType = productType as ProductType;

    if (this._selectedProductType !== castedProductType) {
      this._selectedProductType = castedProductType;
      this.filterProducts();
    }
  }

  setSelectedTerritory(territoryCode: TerritoryType) {
    if (this._selectedTerritory !== territoryCode) {
      this._selectedTerritory = territoryCode;
      this.didSelectTerritory.emit(territoryCode);
      this.filterProducts();
    }
  }

  setSearch(eventTarget: EventTarget): void {
    const eventTargetValue = (eventTarget as HTMLInputElement).value ?? '';

    this.currentSearch = eventTargetValue;
    this.currentLowercaseSearch = eventTargetValue.trim().toLowerCase();
    this.filterProducts();
  }

  sendEvent() {
    // this.trackingService.sendInteraction(productSearchClick(this.selectedSpecies, this.selectedProductType, this.selectedTerritory));
  }

  /**
   * Filter products depending on current assigned filters
   */
  filterProducts() {
    try {
      this.filteredProducts = this.products
        .filter((product) => this._filterProductByPetInfos(product))
        .sort((p1, p2) => {
          if (this.tool && this.tool !== Tool.RenalDetect) {
            return Helper.compareProductAsc(p1, p2);
          }
        });

      if (this.filteredProducts.length > 0) {
        this.i18n_buttonEditText = $localize`:@@action_reset:`;
        this.filteredProducts = this.filteredProducts
          .filter((product) => this._filterProductByProductInfos(product))
          .sort((p1, p2) => {
            if (this.tool && this.tool !== Tool.RenalDetect) {
              return Helper.compareProductAsc(p1, p2);
            }
          });

        this.filteredProducts.some((product) => product.productType === ProductType.Wet);
      } else {
        this.i18n_buttonEditText = undefined;
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  /**
   * Filter territories for the select product
   */
  private _filterTerritories() {
    let products: Product[];
    this.filteredTerritories = this.territories.filter((content: LocaleContent) => {
      products = [];
      this.products.forEach((product: Product) => {
        if (product.territory === content.code || content.code === undefined) {
          products.push(product);
        }
      });
      return products.length > 0;
    });
  }

  toggleFilter(event: any) {
    event.stopPropagation();
    this.filterExpanded = !this.filterExpanded;
  }

  selectProduct(product: Product, packId: string): void {
    this.selectedProduct.emit({ product, packId });
    this.resetFilter();
  }

  removeProduct(product: Product): void {
    this.removeProductEvent.emit(product);
  }

  resetFilter(): void {
    this.currentSearch = '';
    this.currentLowercaseSearch = '';

    if (this.interfaceConfig.showSpeciesFilter) {
      this._selectedSpecies = SpeciesCode.Unknown;
    }
    this._selectedProductType = ProductType.Unknown;
    this._selectedTerritory = undefined;
    this.filterProducts();
  }

  /**
   * window:scroll events load data when the scroll reaches the bottom of the page
   */
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      this.showMoreProducts();
    }
  }

  showMoreProducts() {
    this.nbProductsDisplayed += this.nbProductsMore;
  }

  private _filterProductBySpecies(product: Product, speciesCode: SpeciesCode): boolean {
    return !speciesCode || speciesCode === SpeciesCode.Unknown || speciesCode === product.speciesCode;
  }
  private _filterProductByBreed(product: Product, breed: Breed): boolean {
    if (!breed) return true;

    return !breed || ProductHelper.productOkForBreedSize(product, breed.sizeCategory);
  }
  private _filterProductByTerritory(product: Product, territory: TerritoryType): boolean {
    if (territory !== TerritoryType.Other) {
      return !territory || product.territory.includes(territory);
    } else {
      const values = Object.values(TerritoryType);
      return !territory || !values.includes(product.territory as TerritoryType);
    }
  }
  private _filterProductBySearch(product: Product, search: string): boolean {
    return !search || search === '' || ProductHelper.productTitleContainsSearch(product, search);
  }
  private _filterProductByLifeStage(product: Product, lifestage: LifestageType): boolean {
    if (lifestage === LifestageType.All || product.lifestagesCode.includes(LifestageType.All)) {
      return true;
    }
    if (Helper.YOUNG_LIFESTAGES.includes(lifestage)) {
      return Helper.includesOne(product.lifestagesCode, Helper.YOUNG_LIFESTAGES);
    } else {
      return Helper.includesOne(product.lifestagesCode, Helper.OLD_LIFESTAGES);
    }
  }
  private _filterProductByPetInfos(product: Product): boolean {
    return (
      this._filterProductBySpecies(product, this.selectedSpecies) &&
      this._filterProductByBreed(product, this.selectedBreed) &&
      this._filterProductByLifeStage(product, this.lifestageType)
    );
  }

  private _filterProductByProductInfos(product: Product): boolean {
    return (
      this._filterProductByProductType(product, this.selectedProductType) &&
      this._filterProductBySearch(product, this.currentLowercaseSearch) &&
      this._filterProductByTerritory(product, this.selectedTerritory)
    );
  }

  private _filterProductByProductType = (product: Product, productType: ProductType): boolean =>
    Boolean(this.interfaceConfig.useProductTypeFilter) && [ProductType.Unknown, product.productType].includes(productType);
}
