import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Product, ProductPriceEdit } from '@app/core/models';
import { ProductType } from '@app/shared/utils';
import { DialogHelpers } from '@app/shared/utils/static-helpers/dialog-helpers';
import { ProductDetailComponent } from '../product-detail/product-detail.component';

@Component({
  selector: 'app-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductGridComponent {
  @Input() productForm?: FormGroup;
  @Input() products?: Product[];
  @Input() showSelectButton?: boolean;
  @Input() showRetailPrices?: boolean = true;
  @Input() selectedProductsIds: string[] = [];
  @Input() displayPackages = true;

  @Output() selectProduct = new EventEmitter<{ product: Product; packId: string }>();
  @Output() removeProduct = new EventEmitter<Product>();
  @Output() updatePrice = new EventEmitter();

  ProductType = ProductType;

  constructor(protected modal: MatDialog) {}

  trackByFn(index, product: Product) {
    if (!product) {
      return null;
    }
    return product.id;
  }

  isSelectProduct = (product: Product, packId: string): void => this.selectProduct.emit({ product, packId });

  isRemoveProduct = (data: Product): void => this.removeProduct.emit(data);

  isUpdatePrice = (priceList: ProductPriceEdit[] | null): void => this.updatePrice.emit(priceList);

  showDetailPopin(event: Event, product: Product): void {
    const dialogConfig = {
      ...DialogHelpers.defaultConfig('productDetailPanelCustom'),
      data: {
        productId: product.id,
        isSelected: !!this.selectedProductsIds?.find((id: string) => id === product.id),
        selectedProductsIds: this.selectedProductsIds || [],
        showSelectButton: this.showSelectButton,
        isRemoveProduct: this.isRemoveProduct,
        isSelectProduct: this.isSelectProduct,
        isUpdatePrices: this.isUpdatePrice,
      },
    };
    this.modal.open(ProductDetailComponent, dialogConfig);
  }
}
