import { IconName } from '@app/shared/utils/icon/icons';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Helper } from '@app/shared/utils';

@Component({
  selector: 'app-browser-deprecation',
  templateUrl: './browser-deprecation.component.html',
  styleUrls: ['./browser-deprecation.component.scss'],
})
export class BrowserDeprecationComponent implements OnInit {
  isIe = Helper.isInternetExplorer();
  isBrowserDeprecated = Helper.isBrowserDeprecated();
  public readonly IconName = IconName;

  links = [
    {
      name: 'Microsoft Edge',
      href: 'https://www.microsoft.com/edge',
      icon: './assets/icons/Edge.svg',
    },
    {
      name: 'Google Chrome',
      href: 'https://www.google.com/chrome',
      icon: './assets/icons/Chrome.svg',
    },
    {
      name: 'Mozilla Firefox',
      href: 'https://www.mozilla.org/new/firefox/',
      icon: './assets/icons/Mozilla.svg',
    },
  ];

  svgLinks = {
    atRisk: './assets/icons/atRisk.svg',
  };

  constructor(private _router: Router) {}

  ngOnInit() {
    if (!this.isIe && !this.isBrowserDeprecated) {
      this._router.navigate(['/']).then();
    }
  }
}
