<div>
  <ng-container *appLet="dateOfBirthErrorMessage() || (lifestageError$ | async) as error">
    <form [formGroup]="form" class="form">
      <div class="field-wrapper field-wrapper--name" id="pet-profile-form-field-name" *ngIf="isNewPet">
        <rc-field
          [formControlName]="PetProfileFormValueKeysEnum.NAME"
          type="text"
          [placeholder]="placeholders[PetProfileFormValueKeysEnum.NAME]"
          [error]="requiredErrorMessage(PetProfileFormValueKeysEnum.NAME, 'form-attribute_name')"
        ></rc-field>
      </div>
      <div class="fields-wrapper">
        <div class="fields-wrapper-left" *ngIf="isNewPet">
          <div class="field-wrapper">
            <app-rc-radio-image
              [formControlName]="PetProfileFormValueKeysEnum.SPECIES_CODE"
              [items]="speciesItems"
              [label]="placeholders[PetProfileFormValueKeysEnum.SPECIES_CODE]"
              [error]="requiredErrorMessage(PetProfileFormValueKeysEnum.SPECIES_CODE, 'form-attribute_species')"
            ></app-rc-radio-image>
          </div>
        </div>
        <div class="fields-wrapper-right" *ngIf="isNewPet || (initialGenderInvalid$ | async)">
          <div class="field-wrapper" id="pet-profile-form-field-gender">
            <app-rc-segmented-control
              [formControlName]="PetProfileFormValueKeysEnum.GENDER"
              [items]="genderItems"
              [label]="placeholders[PetProfileFormValueKeysEnum.GENDER]"
              [error]="requiredErrorMessage(PetProfileFormValueKeysEnum.GENDER, 'form-attribute_gender')"
            >
            </app-rc-segmented-control>
          </div>
        </div>
      </div>
      <div class="fields-wrapper" *ngIf="isNewPet || (initalBreedInvalid$ | async)">
        <div class="fields-wrapper-left">
          <div class="field-wrapper field-wrapper--breed" id="pet-profile-form-field-breed">
            <rc-autocomplete
              *ngIf="breeds$ | async as breeds"
              [formControlName]="PetProfileFormValueKeysEnum.BREED"
              [placeholder]="placeholders[PetProfileFormValueKeysEnum.BREED]"
              [error]="requiredErrorMessage(PetProfileFormValueKeysEnum.BREED, 'form-attribute_breed')"
              [items]="breeds"
              [customSort]="customSortAutocomplete()"
            ></rc-autocomplete>
          </div>
          <div class="field-wrapper" id="pet-profile-form-field-mixed">
            <app-checkbox
              [formControlName]="PetProfileFormValueKeysEnum.MIXED"
              [label]="placeholders[PetProfileFormValueKeysEnum.MIXED]"
            ></app-checkbox>
          </div>
        </div>
        <div [class.error-state]="error" class="fields-wrapper-right datepicker-wrapper" *ngIf="isNewPet || (initalBreedInvalid$ | async)">
          <div class="field-wrapper" id="pet-profile-form-field-birthdate" [class.is-disabled]="form.controls.birthdate.disabled">
            <div class="datepicker" [class.error-state]="error">
              <input
                [formControlName]="PetProfileFormValueKeysEnum.BIRTHDATE"
                matInput
                [disabled]="form.controls.birthdate.disabled"
                [max]="maxDate"
                [matDatepicker]="picker"
                id="datepicker-input"
                class="error-input"
                [placeholder]="placeholders[PetProfileFormValueKeysEnum.BIRTHDATE]"
              />
              <app-icon
                class="datepicker-icon"
                [class.error]="error"
                [name]="iconName.calendar_small"
                (click)="picker.opened ? picker.close() : picker.open()"
              ></app-icon>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
          <span *ngIf="error" class="datepicker-error">{{ error }}</span>

          <p *ngIf="tool === Tool.RenalDetect" [class.is-disabled]="form.controls.birthdate.disabled" class="datepicker-info">
            {{ 'rd_warning_pet-too-young' | translateKey }}
          </p>
          <div class="lifestage" *ngIf="lifestageMessage$ | async as message">
            <app-icon class="lifestage-icon" [name]="IconNameEnum.alert"></app-icon>
            <span class="lifestage-info">
              {{ message }}
            </span>
          </div>
        </div>
      </div>

      <div class="fields-wrapper">
        <div class="fields-wrapper-left">
          <div class="field-wrapper" id="pet-profile-form-field-neutered">
            <app-checkbox
              [formControlName]="PetProfileFormValueKeysEnum.NEUTERED"
              [label]="placeholders[PetProfileFormValueKeysEnum.NEUTERED]"
              [topLabel]="placeholders[PetProfileFormValueKeysEnum.NEUTERED]"
            ></app-checkbox>
          </div>
        </div>
        <div class="fields-wrapper-right">
          <div
            class="field-wrapper field-wrapper--marginTop"
            id="pet-profile-form-field-reproduction"
            *ngIf="shouldDisplayReproductionStatus()"
          >
            <rc-select
              [formControlName]="PetProfileFormValueKeysEnum.REPRODUCTION_STATUS"
              [label]="placeholders[PetProfileFormValueKeysEnum.REPRODUCTION_STATUS]"
              [placeholder]="placeholders[PetProfileFormValueKeysEnum.REPRODUCTION_STATUS]"
              [items]="reproductionStatusItems"
              [customSort]="customSelectSort"
            ></rc-select>
          </div>
        </div>
      </div>
      <div class="fields-wrapper">
        <div class="fields-wrapper-left">
          <div class="field-wrapper field-wrapper--marginTop field-wrapper--pet-activity" id="pet-profile-form-field-activity">
            <rc-select
              class="pet-activity-field"
              [formControlName]="PetProfileFormValueKeysEnum.PET_ACTIVITY"
              [label]="placeholders[PetProfileFormValueKeysEnum.PET_ACTIVITY]"
              [placeholder]="placeholders[PetProfileFormValueKeysEnum.PET_ACTIVITY]"
              [error]="requiredErrorMessage(PetProfileFormValueKeysEnum.PET_ACTIVITY, 'form-attribute_select-activity')"
              [items]="activityItems"
              [customSort]="customSelectSort"
            ></rc-select>
            <app-activity-tooltip [speciesCode]="form.controls.speciesCode.value"></app-activity-tooltip>
          </div>
        </div>
      </div>
    </form>
    <div class="footer">
      <button
        class="rc-button rc-button--filled-small"
        id="validatePetInformation"
        type="button"
        [disabled]="(petProfileInvalidForTool$ | async) || form.invalid"
        (click)="submit()"
      >
        <span class="rc-button__title">{{ submitButtonLabel }}</span>
      </button>
    </div>
  </ng-container>
</div>
