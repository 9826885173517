import { Component, OnInit } from '@angular/core';
import { Helper, Tool } from '@app/shared/utils';
import { AppState } from '@app/store';
import { setTool } from '@app/store/core';
import { Store } from '@ngrx/store';
import { startDailyAllowance } from '@app/pages/daily-allowance/store/actions/daily-allowance-steps.actions';

@Component({
  selector: 'app-das-init',
  template: '',
})
export class DasInitComponent implements OnInit {
  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.store$.dispatch(startDailyAllowance({ initFlow: true, withIdentification: false }));
    this.store$.dispatch(setTool({ tool: Tool.Rationing, tool_flow_id: Helper.randomId() }));
  }
}
