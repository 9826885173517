import { renalDetectFeatureKey } from './../pages/renal-detect/store/renal-detect-feature-key';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { appReducers, appEffects, appFacades, appServices } from '.';
import { smartRecoFeatureKey } from '@app/pages/smart-reco/store/smart-reco-feature-key';
import { consultationFeatureKey } from './consultation';
import { allowanceFeatureKey } from '@app/pages/allowance/store/allowance-feature-key';
import { dailyAllowanceFeatureKey } from '@app/pages/daily-allowance/store/daily-allowance-feature-key';
import { weightManagementFeatureKey } from '@app/pages/weight-management/store/weight-management-feature-key';
import { mufPersonalisedBagFeatureKey } from '@app/pages/muf-personalised-bag/store/muf-personalised-bag-feature-key';
import { newPetFeatureKey } from '@app/pages/new-pet/store/new-pet-feature-key';
import { mufStarterKitFeatureKey } from '@app/pages/muf-starter-kit/store/muf-starter-kit-feature-key';

/**
 * Save account data into local storage & rehydrate on app launch
 */
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { core: ['localization', 'tool', 'tool_flow_id'] },
      { vet: ['currentClinicId', 'invitation'] },
      consultationFeatureKey,
      smartRecoFeatureKey,
      renalDetectFeatureKey,
      allowanceFeatureKey,
      dailyAllowanceFeatureKey,
      weightManagementFeatureKey,
      mufPersonalisedBagFeatureKey,
      mufStarterKitFeatureKey,
      newPetFeatureKey,
      { products: ['allProductList', 'filteredProductList', 'filteredProductLength', 'filtersValues', 'currentViewType', 'currentPillar'] },
    ],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(appReducers, { metaReducers }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [...appFacades, ...appServices],
})
export class AppStoreModule {}
