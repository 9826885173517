<!-- Pet page: Header profile -->
<ng-container *ngIf="object">
  <app-profile-header
    [imageUrl]="petImageUrl"
    [headerTitle]="petName"
    [placeholderImageUrl]="Constants.placeholderImgDog"
    [object]="object"
    [attributes]="petTitleAttributes"
  >
    <ng-content select="[right]" right></ng-content>
  </app-profile-header>
</ng-container>
