<app-loader [hidden]="(showLoader$ | async) === false" [transparent]="(loaderTransparent$ | async) === true"></app-loader>
<app-progress-bar [hidden]="(showProgressBar$ | async) === false"></app-progress-bar>

<div class="app-alert">
  <div *ngFor="let alert of (alerts$ | async).slice().reverse()" class="app-alert_item">
    <rc-alert (closeAlert)="close(alert)" [id]="alert?.keyTrad" [type]="alert.alertType"
      ><p>{{ alert.message | capitalizeFirst }}</p></rc-alert
    >
  </div>
</div>
<div [attr.data-version]="version" class="app-body" [ngClass]="{ 'app-body--withAppFooter': showFooter$ | async }">
  <router-outlet></router-outlet>
</div>
<rc-footer *ngIf="showFooter$ | async" class="app-footer">
  <ng-container top-right>
    <button (click)="backToTop()" class="app-button">
      <app-icon [name]="iconName.chevron_down" class="button-icon"></app-icon>
      <span i18n="@@footer_legal_notice_vs_back">Back to top</span>
    </button>
  </ng-container>
  <ng-container bottom-left>
    <p class="app-version">© Vet Services: {{ version?.version }}</p>
    <a class="app-link" href="https://www.royalcanin.com/fr/mentions-legales/vet" rel="noopener" target="_blank"
      ><span i18n="@@footer_legal_notice_vs_legal">Vet Services legal notice</span></a
    >
  </ng-container>
</rc-footer>
<div *ngIf="!isStandalone" class="cookie-consent" id="cookie-consent">
  <button class="ot-sdk-show-settings" id="ot-sdk-btn">Cookie settings</button>
</div>
<a *ngIf="isStandalone" [href]="srsTermUrl$ | async" class="rc-link terms" i18n="@@terms_and_conditions" rel="noopener" target="_blank"
  >terms and conditions</a
>
