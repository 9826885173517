import { Action } from '@ngrx/store';
import { Vet, VetUser } from '@app/core/models';
/**
 * Ngrx old syntax, do not add actions here
 */
export enum VetActionTypes {
  /**
   * Set vet actioon,
   * Should only be used in RegisterConnectComponent
   * Deprecated, will be removed
   */
  SetVet = '[Vet] Set Vet',

  // Update vet
  UpdateVet = '[Vet] Update Vet',
  UpdateVetSuccess = '[Vet] Update Vet Success',
  UpdateVetFailed = '[Vet] Update Vet Failed',

  // Update vet user
  UpdateVetUser = '[Vet] Update VetUser',
  UpdateVetUserSuccess = '[Vet] Update VetUser Success',
  UpdateVetUserFailed = '[Vet] Update VetUser Failed',

  SetCurrentClinicId = '[Vet] Set Current Clinic Id',
}

/*** SET VET ***/

export class SetVet implements Action {
  public readonly type = VetActionTypes.SetVet;
  constructor(public vet: Vet) {}
}

/*** UPDATE VET ***/

export class UpdateVet implements Action {
  public readonly type = VetActionTypes.UpdateVet;
  constructor(public vet: Vet) {}
}

export class UpdateVetSuccess implements Action {
  public readonly type = VetActionTypes.UpdateVetSuccess;
  constructor(public vet: Vet) {}
}

export class UpdateVetFailed implements Action {
  readonly type = VetActionTypes.UpdateVetFailed;
  constructor(public errorMessage: string) {}
}

/*** UPDATE VET USER ***/

export class UpdateVetUser implements Action {
  public readonly type = VetActionTypes.UpdateVetUser;
  constructor(public oktaId: string, public vetUser: VetUser) {}
}

export class UpdateVetUserSuccess implements Action {
  public readonly type = VetActionTypes.UpdateVetUserSuccess;
  constructor(public vetUser: VetUser) {}
}

export class UpdateVetUserFailed implements Action {
  readonly type = VetActionTypes.UpdateVetUserFailed;
  constructor(public errorMessage: string) {}
}
/**
 * Set current clinic id
 */
export class SetCurrentClinicId implements Action {
  readonly type = VetActionTypes.SetCurrentClinicId;
  constructor(public clinicId: string) {}
}

export type VetActions =
  | SetVet
  | UpdateVet
  | UpdateVetSuccess
  | UpdateVetFailed
  | UpdateVetUser
  | UpdateVetUserSuccess
  | UpdateVetUserFailed
  | SetCurrentClinicId;
