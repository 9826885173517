import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { environment as env } from '@env/environment';
import { WindowService } from '@app/core/services/utils/window';
import { ScriptService } from '@app/core/services/script/script.service';
import { Constants, Helper } from '@app/shared/utils';
import { Logger } from '@app/core/services/utils/logger';
import { VetFacade } from '@app/store/vet/vet.facade';
import { Clinic, Vet } from '@app/core/models';
import { takeUntil, tap } from 'rxjs/operators';
import { EventEnum } from '../types/enums';
import { IOkta, LegacyInboundLayer, LegacyOutboundLayer, GTMInteraction, GTMPageView, GTMEvent } from '../types/interfaces';

@Injectable()
export class GTMService implements OnDestroy {
  // TODOEKINO: Hard type this property when possible
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private window?: any;
  private gtm?: Record<string, { dataLayer: { reset(): void } }>;
  private gtmGlobal?: { dataLayer: { reset(): void } };
  private gtmVet?: { dataLayer: { reset(): void } };
  private destroyed$ = new Subject();
  private vet?: Vet;
  private clinic?: Clinic;
  user: { id: string; country: string; locale: string; accountType: string; type: string };
  customer: { id: string; name: string };

  constructor(
    private windowService: WindowService,
    private scriptService: ScriptService,
    private logger: Logger,
    private _vetFacade: VetFacade
  ) {
    this.window = this.windowService.nativeWindow;

    this.window.dataLayer = this.window.dataLayer || [];

    this.window.dataLayer.push({
      event: 'dataLayerLoaded',
      site: {
        id: `${Constants.googleTagSiteId}`,
        environment: env.envName,
        country: 'Global',
        version: '3',
      },
    });

    combineLatest([this._vetFacade.vet$, this._vetFacade.clinic$])
      .pipe(
        takeUntil(this.destroyed$),
        tap(([vet, clinic]) => {
          this.vet = vet;
          this.clinic = clinic;
          vet && clinic && this.init();
        })
      )
      .subscribe();
  }

  init(): void {
    const okta: IOkta = JSON.parse(Helper.getValueFromLocalStorage('okta-token-storage') || '');
    const user = this.vet?.user;

    if (!user || !okta) return console.error(`Couldn't retrieve either user (got ${user} or okta (got ${okta})))`);

    const accountType = okta.idToken.claims.userinfo ? okta.idToken.claims.userinfo.accountType : 'undefined';
    const clinic = this.clinic;
    const country = user.countryCode ? user.countryCode : 'undefined';
    const userAccountType = accountType;

    this.user = {
      id: okta.idToken.claims.sub,
      country,
      locale: user.preferredLanguage || 'undefined',
      accountType: userAccountType,
      type: 'Veterinarian',
    };

    clinic &&
      (this.customer = {
        id: clinic['customerId'],
        name: clinic['legalName'],
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  deprecatedSendPageView(layer: LegacyInboundLayer): void {
    if (this.scriptService.gtmIsLoaded$.getValue() && this.window.google_tag_manager && !this.gtm) {
      this.gtm = this.window?.google_tag_manager;

      if (this.gtm) {
        this.gtmGlobal = this.gtm[Constants.globalContainerId];
        this.gtmVet = this.gtm[Constants.vetContainerId];
      }
    }

    this._pushLayer(layer);
  }

  deprecatedSendEvent(eventLayer: LegacyInboundLayer): void {
    if (this.window?.dataLayer === undefined) return;

    const dataLayer: LegacyOutboundLayer = {
      event: `${Constants.googleTagSiteId}${eventLayer.name}`,
    };

    if (eventLayer.interaction) {
      const interaction = eventLayer.interaction;

      dataLayer['interaction'] = {
        ...interaction,
      };
    }

    if (eventLayer.rationing) {
      const rationing = eventLayer.rationing;
      dataLayer['pet'] = rationing.pet;
      dataLayer['ecommerce'] = rationing.ecommerce;
    } else if (eventLayer.multifunction) {
      const multifunction = eventLayer.multifunction;
      dataLayer['pet'] = multifunction.pet;
      dataLayer['ecommerce'] = multifunction.ecommerce;
    } else if (eventLayer.consultation) {
      const consultation = eventLayer.consultation;
      dataLayer['pet'] = eventLayer.pet;
      dataLayer['consultation'] = consultation;
    }

    this.window?.dataLayer?.push(dataLayer);
  }

  sendInteraction = ({ event, ...rest }: GTMInteraction): void =>
    this.window?.dataLayer?.push({
      event: `${Constants.googleTagSiteId}${event}`,
      ...rest,
    });

  sendPageView = (pageView: GTMPageView): void =>
    this.window?.dataLayer?.push({
      event: EventEnum.SCREEN_DISPLAYED,
      ...pageView,
      page: {
        ...pageView.page,
        path: window.location.pathname,
      },
      user: this.user,
      customer: this.customer,
    });

  sendEvent = ({ event, ...rest }: GTMEvent): void =>
    this.window?.dataLayer?.push({
      event: `${Constants.googleTagSiteId}${event}`,
      ...rest,
    });

  private _pushLayer(layer: LegacyInboundLayer) {
    const dataLayer: LegacyOutboundLayer = {
      event: 'screenDisplayed',
      page: {
        path: layer.virtualPageUrlPopin || window.location.pathname,
      },
      user: this.user,
      customer: this.customer,
    };

    if (this.gtmGlobal && this.gtmVet) {
      this.gtmGlobal.dataLayer.reset();
      this.gtmVet.dataLayer.reset();
    }

    if (layer.type && dataLayer.page) {
      dataLayer.page['type'] = layer.type;
    }

    if (layer.module) {
      dataLayer['module'] = {
        name: layer.module,
        type: layer.moduleType ? layer.moduleType : '',
      };
    }

    this.window?.dataLayer?.push(dataLayer);
  }
}
