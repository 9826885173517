export enum EventEnum {
  CONTACT_US = 'contactUs',
  CONTINUE_TO_PRODUCTS = 'continueToProducts',
  CONTINUE_TO_RATION = 'continueToRation',
  HOME_SEARCH_CLICK = 'homeSearchClick',
  LAUNCH_SMART_RECO = 'launchSmartReco',
  MODULE_CLICK = 'moduleClick',
  QUICK_LINK_CLICK = 'quickLinkClick',
  MOSAIC_CLICK = 'mosaicClick',
  NUTRITIONAL_RECOMMENDATION = 'nutritionalRecommendation',
  PRINT = 'print',
  PRODUCT_EDIT_PRICES = 'productEditPrices',
  PRODUCT_RESET_PRICES = 'productResetPrices',
  PRODUCT_SAVE_PRICES = 'productSavePrices',
  SCREEN_DISPLAYED = 'screenDisplayed',
  SEND_BY_EMAIL = 'sendByEmail',
  OPEN_QR_CODE = 'openQrCode',
  START_DETECTION = 'startDetection',
  WELCOME_TOUR_CLOSE = 'welcomeTourClose',
  WELCOME_TOUR_DISCARD = 'welcomeTourDiscard',
  WELCOME_TOUR_DONT_SHOW_AGAIN = 'welcomeTourDontShowAgain',
  WELCOME_TOUR_FEEDBACK_SURVEY = 'welcomeTourFeedbackSurvey',
  WELCOME_TOUR_LEAVE = 'welcomeTourLeave',
  WELCOME_TOUR_POPIN = 'welcomeTourPopin',
  WELCOME_TOUR_START = 'welcomeTourStart',
  WELCOME_TOUR_START_USING = 'welcomeTourStartUsing',
  WELCOME_TOUR_STEP_POPIN = 'welcomeTourStepPopin',
  PRODUCT_ADD_CLICK = 'productAddClick',
  CRITERIA_MODAL_DISPLAYED = 'criteriaModalDisplayed',
  PET_RECORD_SEARCH_CLICK = 'petRecordSearchClick',
  PRODUCT_SEARCH_CLICK = 'productSearchClick',
  WEIGHT_MANAGEMENT_FROM_TOOL = 'weightLossPlanRedirectionClick',
  TERRITORY_NAVIGATION_CLICK = 'territoryNavigationClick',
}

export enum LabelEnum {
  B2B_WEBSHOP = 'B2B Webshop',
  DAILY_ALLOWANCE = 'Daily Allowance',
  MULTIFUNCTION = 'Multifunction',
  PRODUCT_FINDER = 'Product Finder',
  SMART_RECOMMENDATION = 'Smart Recommendation',
  RENAL_DETECT = 'Renal detect',
  VET_FOCUS = 'Vet Focus',
  VET_PORTAL = 'Vet Portal',
  WEIGHT_MANAGEMENT = 'Weight Management',
  INDIVIDUALIS = 'Individualis',
}

export enum ModuleNameEnum {
  DAILY_ALLOWANCE = 'Daily Allowance',
  MULTIFUNCTION = 'Multifunction',
  RENAL_DETECT = 'Renal Detect',
  SMART_RECOMMENDATION = 'Smart Recommendation',
  UNDEFINED = 'undefined',
  WEIGHT_MANAGEMENT = 'Weight Management',
}

export enum ModuleTypeEnum {
  CONSULTATION = 'Consultation',
  ORDER = 'Order',
}

export enum PageBlockEnum {
  CALCULATOR = 'Calculator',
  CURRENT_WEIGHT_PROGRAM = 'Current Weight Program',
  LAST_VISIT = 'Last Visit',
  MEDICAL_RECORD = 'Medical Record',
  ORDER_SUMMARY = 'Order Summary',
  WEIGHT_MANAGEMENT_SUMMARY = 'Weight Management Summary',
}

export enum PageTypeEnum {
  NUTRITIONAL_RECOMMENDATION = 'Nutritional Recommendation',
  ORDER_CONFIRMATION = ' Order Confirmation',
  PET_DETAILS = 'Pet Details',
  PET_HEALTH_TRACKING = 'Pet Health Tracking',
  PET_WEIGHT_MANAGEMENT = 'Pet Weight Management',
  PRODUCT_SELECTION = 'Product Selection',
  WELCOME_TOUR = 'Welcome Tour',
  CHECKOUT = 'Checkout',
}
export enum StatusEnum {
  ERROR = 'Error',
  SUCCESS = 'Success',
}
