<ng-container *appLet="moreFiltersOpened$ | async as moreFiltersOpened">
  <div
    class="catalog-filters"
    [ngClass]="{
      'catalog-filters--panel-opened': panelOpen,
      'catalog-filters--opened': moreFiltersOpened,
      'catalog-filters--fixed': isFixed,
      'page-wrapper__content-header-deep': isFixed
    }"
  >
    <form
      [formGroup]="form"
      class="catalog-filters-form"
      [ngClass]="{
        'catalog-filters-form--fixed': isFixed
      }"
      (ngSubmit)="submit()"
    >
      <div class="catalog-wrapper-search">
        <!-- Pillar radio buttons, not displayed when the component is fixed -->
        <div *ngIf="!isFixed && (shouldShowPillar$ | async)" class="pillar-choice-radios-wrapper">
          <h4 class="pillar-choice-title" i18n="@@product-book_search_in">Search in</h4>
          <div class="pillar-choice-radios">
            <div class="pillar-choice-radio-container">
              <input id="pillar_vet" class="pillar-choice-radio" type="radio" formControlName="pillar" [value]="ProductPillar.VET" />
              <label for="pillar_vet" i18n="@@product-book_search_veterinary">Veterinary</label>
            </div>
            <div class="pillar-choice-radio-container">
              <input id="pillar_spt" class="pillar-choice-radio" type="radio" formControlName="pillar" [value]="ProductPillar.SPTRETAIL" />
              <label for="pillar_spt" i18n="@@product-book_search_health">Health Nutrition</label>
            </div>
          </div>
        </div>
        <h4 *ngIf="!isFixed && (shouldShowPillar$ | async) === false" class="catalog-filters-title">
          {{ 'product_search-placeholder' | translateKey }}
        </h4>
        <!-- Fixed search bar, it contains the pillar choice as a select, the specie selection, the search and an arrow to display more filters -->
        <div *ngIf="isFixed" class="catalog-filters-fixed">
          <div *ngIf="shouldShowPillar$ | async" class="pillar-choice-select">
            <rc-select
              formControlName="pillar"
              label="{{ 'product-book_search_in' | translateKey }}"
              [items]="pillarItems"
              [customSort]="customSelectSort"
            ></rc-select>
          </div>
          <div class="gender">
            <app-rc-segmented-control formControlName="specie" [items]="specieItemsWithoutLabels"></app-rc-segmented-control>
          </div>
          <div class="rc-input-container rc-input-container--search">
            <app-icon [name]="IconName.search"></app-icon>
            <input
              type="text"
              formControlName="search"
              class="rc-input rc-input--underlined"
              placeholder="{{ 'product-book_search_bar_product' | translateKey }}"
            />
            <button type="button" (click)="resetSearchByProductName()" *ngIf="form.controls.search.value?.length > 0">
              <app-icon [name]="IconName.close"></app-icon>
            </button>
          </div>
          <button
            *ngIf="!moreFiltersOpened"
            [disabled]="isSubmitDisabled()"
            id="submit-filters"
            type="submit"
            class="search-button-header rc-button rc-button--filled-small"
          >
            <span class="rc-button__title">{{ 'search' | translateKey }}</span>
          </button>
          <button type="button" class="dropdown-button" (click)="toggleMoreFilters()">
            <app-icon class="expand-icon" [class.reversed]="moreFiltersOpened" [name]="IconName.chevron_down"></app-icon>
          </button>
        </div>
        <!-- Filters displayed within a grey background -->
        <div class="catalog-filters-inner--wrapper">
          <div class="catalog-filters-inner">
            <!-- The specie radio buttons, the search bar, a more filters button when the component is not fixed -->
            <div class="catalog-filters-main" *ngIf="!isFixed">
              <div class="gender">
                <app-rc-segmented-control formControlName="specie" [items]="specieItems"> </app-rc-segmented-control>
              </div>
              <div class="rc-input-container rc-input-container--search">
                <app-icon [name]="IconName.search"></app-icon>
                <input
                  id="filters-search"
                  type="text"
                  formControlName="search"
                  class="rc-input rc-input--underlined"
                  placeholder="{{ 'product-book_search_bar_product' | translateKey }}"
                />
                <button (click)="resetSearchByProductName()" type="button" type="button" *ngIf="form.controls.search.value?.length > 0">
                  <app-icon [name]="IconName.close"></app-icon>
                </button>
              </div>
              <button
                *ngIf="!moreFiltersOpened && !insidePopin"
                type="submit"
                [disabled]="isSubmitDisabled()"
                id="submit-filters"
                class="search-button-header rc-button rc-button--filled-small"
              >
                <span class="rc-button__title">{{ 'search' | translateKey }}</span>
              </button>
              <!-- In mobile, button is hidden when advances filters are opened -->
              <button
                id="dropdown-button-more-filters-top"
                class="dropdown-button"
                type="button"
                [ngClass]="{
                  'dropdown-button--hide': moreFiltersOpened
                }"
                (click)="toggleMoreFilters()"
              >
                <span i18n="@@product-book_search_advanced">advanced search</span>
                <app-icon class="expand-icon" [class.reversed]="moreFiltersOpened" [name]="IconName.chevron_down"></app-icon>
              </button>
            </div>
            <!-- The advanced filters -->
            <div class="catalog-filters-advanced" [class.hidden]="!moreFiltersOpened">
              <div class="catalog-filters-advanced-type">
                <label class="checkboxes-label" for="product_type" i18n="@@product-book_advanced_searched_products_type"
                  >Type of product</label
                >
                <div class="checkboxes-container">
                  <app-checkbox
                    class="checkbox-item"
                    id="product_type_dry"
                    formControlName="dry"
                    label="{{ 'product-type_dry' | translateKey }}"
                  ></app-checkbox>
                  <app-checkbox
                    class="checkbox-item"
                    id="product_type_wet"
                    formControlName="wet"
                    label="{{ 'product-type_wet' | translateKey }}"
                  ></app-checkbox>
                </div>
              </div>
              <div class="catalog-filters-advanced-lifestage">
                <label class="checkboxes-label" for="lifestage" i18n="@@product-book_advanced_search_lifestage">Lifestages</label>
                <div class="checkboxes-container">
                  <app-checkbox
                    class="checkbox-item"
                    id="lifestage_birthAndGrowth"
                    formControlName="birthAndGrowth"
                    label="{{ 'product-book_lifestage_b_g' | translateKey }}"
                  ></app-checkbox>
                  <app-checkbox
                    class="checkbox-item"
                    id="lifestage_mature"
                    formControlName="mature"
                    label="{{ 'product-book_lifestage_mature' | translateKey }}"
                  ></app-checkbox>
                  <app-checkbox
                    class="checkbox-item"
                    id="lifestage_adult"
                    formControlName="adult"
                    label="{{ 'product-book_lifestage_adult' | translateKey }}"
                  ></app-checkbox>
                </div>
              </div>
              <div class="catalog-filters-advanced-size">
                <rc-select
                  formControlName="size"
                  label="{{ 'product-book_advanced_searched_products_size' | translateKey }}"
                  placeholder="{{ 'product-book_advanced_searched_products_size' | translateKey }}"
                  [items]="sizeItems"
                  [customSort]="customSelectSort"
                ></rc-select>
              </div>
            </div>
            <div class="catalog-filters-advanced-actions" [class.hidden]="!moreFiltersOpened">
              <button id="reset-filters" type="button" class="reset-button" (click)="resetFilters()">
                <app-icon [name]="IconName.loading"></app-icon>
                <span i18n="@@action_reset">Reset</span>
              </button>
              <button
                *ngIf="!insidePopin"
                [disabled]="isSubmitDisabled()"
                id="submit-filters"
                type="submit"
                class="search-button rc-button rc-button--filled-small"
              >
                <span class="rc-button__title">{{ 'search' | translateKey }}</span>
              </button>
              <button
                type="button"
                id="dropdown-button-more-filters-bottom"
                class="dropdown-button dropdown-button--mobile"
                (click)="toggleMoreFilters()"
              >
                <span i18n="@@product-book_search_advanced">advanced search</span>
                <app-icon class="expand-icon" [class.reversed]="moreFiltersOpened" [name]="IconName.chevron_down"></app-icon>
              </button>
            </div>
          </div>
          <div *ngIf="insidePopin" class="action-submit">
            <button id="submit-filters" type="submit" [disabled]="isSubmitDisabled()" class="rc-button rc-button--filled-small">
              <span class="rc-button__title">
                {{ 'search' | translateKey }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
