import { Actions, createEffect, ofType } from '@ngrx/effects';
/* eslint-disable @typescript-eslint/member-ordering */
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Clinic, Image, Vet, VetUser } from '@app/core/models';
import { AuthService } from '@app/core/services/auth/auth.service';
import { VetService } from '@app/core/services/network/vet/vet.service';
import { LocalizationService } from '@app/core/services/utils/localization/localization.service';
import { Constants } from '@app/shared/utils';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import { StandaloneCompany } from '@app/standalone/standalone-data';
import { StandaloneService } from '@app/standalone/standalone.service';
import { setAlert, toggleLoader } from '@app/store/core';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { getMarket, getVet, getVetFail, getVetSuccess, setVetValidity } from '../vet.actions';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { RCAlertType } from '@rc/ui';

const defaultCompany: StandaloneCompany = {
  measurement: 'metric',
  email: '',
  name: '',
  country: CountryCode.GB,
  address1: '',
  address2: '',
  city: '',
  zip: 0,
  phone: '',
  logo: '',
  clinic_id: '',
  pims_id: '',
};

@Injectable()
export class GetVetEffects {
  constructor(
    private actions$: Actions,
    private srsService: StandaloneService,
    private vetService: VetService,
    private authService: AuthService,
    @Inject(LOCALE_ID) protected localeId: string
  ) {}

  /**
   * Set user language with an app supported language
   */
  private setCompanyLanguage = (company: StandaloneCompany): StandaloneCompany => {
    company.language = LocalizationService.completeLanguageCode(company.language || this.localeId);
    return company;
  };

  /**
   * Set the app language to match the user preferred language
   */
  private setAppLanguage = (company: StandaloneCompany): void => {
    company.language !== this.localeId && LocalizationService.setLanguageAndReload(company.language);
  };

  /**
   * Check if user and app use the same language
   * Used to block any further action until the app is reloaded if needed
   */
  private areCompanyAndAppUsingSameLanguage = (company: StandaloneCompany): boolean => company.language === this.localeId;

  /**
   * Build Vet User
   * Old data model for retrocompatibility
   */
  private buildVetUser = (company: StandaloneCompany): VetUser => {
    return {
      uuid: undefined,
      oktaId: undefined,
      countryCode: company.country,
      email: company.email,
      firstName: '',
      lastName: company?.name || translateKey('standalone-ru-fallback-vet'),
      title: '',
      state: undefined,
      primaryPhone: company.phone,
      preferredLanguage: company.language,
    };
  };

  /**
   * Build vet object
   * Old data model for retro compatibility
   */
  private buildVet = (company: StandaloneCompany, vetData: Vet): Vet => {
    return {
      ...vetData,
      user: this.buildVetUser(company),
    };
  };

  /**
   * Get vet effect
   * Retrieve vet data
   * Set languages
   * Set vet & Current Clinic
   */

  getVet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVet),
      /**
       * We keep calling AuthService.getUser$ to instantiate the service
       */
      switchMap(() => this.authService.getUser$()),
      /**
       * But what we really use is the company data provided in the url
       */
      map(() => this.srsService.company || defaultCompany),
      map(this.setCompanyLanguage),
      tap(this.setAppLanguage),
      filter(this.areCompanyAndAppUsingSameLanguage),
      switchMap((company) => {
        const currentClinic: Clinic = {
          id: company.clinic_id,
          pimsId: company.pims_id,
          logo: {
            path: company.logo,
          } as Image,
          legalName: company.name,
          companyAddress: {
            address1: company.address1,
            address2: company.address2,
            city: company.city,
            zip: company.zip,
            country: company.country,
          },
        };
        const vetData: Vet = {
          id: 'srs',
          systemPreference: Constants.metricSystemCode,
          purposes: {
            terms: [],
          },
          user: this.buildVetUser(company),
          clinics: [currentClinic],
        };
        const vet = this.buildVet(company, vetData);
        return [getVetSuccess({ vet, currentClinic, oktaUser: undefined })];
      }),
      catchError(() => [getVetFail()])
    )
  );
  /**
   * Get vet success effect
   */

  getVetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVetSuccess),
      tap(({ vet, currentClinic }) => this.vetService.deprecatedSetVetPreference(vet, currentClinic)),
      switchMap(({ currentClinic }) => {
        const countryCode = currentClinic?.companyAddress?.country as CountryCode;
        return [getMarket({ countryCode }), toggleLoader({ value: false }), setVetValidity({ value: true })];
      }),
      catchError(() => [getVetFail()])
    )
  );
  /**
   * Get vet fail
   */
  getVetFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVetFail),
      switchMap(() => {
        return [
          setAlert({
            alert: {
              message: translateKey('error_general_text'),
              alertType: RCAlertType.ERROR,
            },
          }),
        ];
      })
    )
  );
}
