import { selectBreeds } from '@app/store/core';
import { Injectable } from '@angular/core';
import { Breed } from '@app/core/models';
import { Tool } from '@app/shared/utils';
import { select, Store } from '@ngrx/store';
import { AppState } from '..';
import {
  getLocalization,
  removeAlert,
  resetAlerts,
  setAlert,
  setBreeds,
  setDeprecatedLoaderState,
  setTool,
  toggleLoader,
  toggleProgressBar,
} from './core.actions';
import {
  selectAlerts,
  selectBCSLanguages,
  selectCountries,
  selectCurrentRoute,
  selectLoaderTransparent,
  selectShowLoader,
  selectShowProgressBar,
  selectToolInfo,
} from './core.selectors';
import { RCAlertType } from '@rc/ui';

@Injectable({
  providedIn: 'root',
})
export class CoreFacade {
  showLoader$ = this.store.pipe(select(selectShowLoader));
  loaderTransparent$ = this.store.pipe(select(selectLoaderTransparent));
  showProgressBar$ = this.store.pipe(select(selectShowProgressBar));
  countries$ = this.store.pipe(select(selectCountries));
  bcsLanguages$ = this.store.pipe(select(selectBCSLanguages));
  toolInformation$ = this.store.pipe(select(selectToolInfo));
  breeds$ = this.store.pipe(select(selectBreeds));
  alerts$ = this.store.pipe(select(selectAlerts));
  currentRoute$ = this.store.pipe(select(selectCurrentRoute));

  constructor(private store: Store<AppState>) {}

  /**
   * Toggle loader
   */
  toggleLoader(value: boolean): void {
    this.store.dispatch(toggleLoader({ value }));
  }

  /**
   * Toggle progress bar
   */
  toggleProgressBar(value: boolean): void {
    this.store.dispatch(toggleProgressBar({ value }));
  }

  /**
   * Set deprecated loader state
   * NB: it does not control the display, it just set the state
   */
  setDeprecatedLoaderState(value: boolean): void {
    this.store.dispatch(setDeprecatedLoaderState({ value }));
  }

  /**
   * Load localization
   */
  loadLocalization(): void {
    this.store.dispatch(getLocalization());
  }

  /**
   * Set tool
   */
  setTool(tool: Tool, tool_flow_id: string): void {
    this.store.dispatch(setTool({ tool, tool_flow_id }));
  }

  /**
   * Set breeds
   */
  setBreeds(breeds: Breed[]): void {
    this.store.dispatch(setBreeds({ breeds }));
  }

  /**
   * Set alert
   */
  setAlert(alert: { alertType: RCAlertType; message: string; keyTrad?: string }): void {
    this.store.dispatch(setAlert({ alert }));
  }

  /**
   * Remove alert
   */
  removeAlert(alert: { alertType: RCAlertType; message: string }): void {
    this.store.dispatch(removeAlert({ alert }));
  }

  /**
   * Remove all alerts
   */
  resetAlerts(ids: string[] = []): void {
    this.store.dispatch(resetAlerts({ ids }));
  }
}
