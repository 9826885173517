import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { Dialog } from '@app/core/cdk/dialog';
import { InvitationTemplateData, Vet, RecommendationBodyEmail } from '@app/core/models';
import { Attachments, Contact } from '@app/core/models/contact';
import { Market } from '@app/core/models/market';
import { Logger } from '@app/core/services/utils/logger';
import { EmailResponsePopinComponent } from '@app/shared/components/email-response-popin/email-response-popin.component';
import { Constants, EmailPopinType } from '@app/shared/utils';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';
import { VetFacade } from '@app/store/vet';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { Page, sendByEmail, mapToolToModule, StatusEnum, GtmEventsService, GTMService, EmailOptions } from '@app/core/services/tracking';
import { ApiService } from '../api.service';

@Injectable()
export class EmailService extends ApiService implements OnDestroy {
  private destroyed$ = new Subject();
  private vet: Vet;
  private currentClinicCountry: string;
  private market: Market | undefined = undefined;
  /**
   * Get user email
   */
  get userEmail(): string {
    return this.vet?.user.email;
  }
  /**
   * Get user full name
   */
  get userFullName(): string {
    return `${this.vet?.user.firstName} ${this.vet?.user.lastName}`.trim();
  }

  constructor(
    private http: HttpClient,
    private gtmEventsService: GtmEventsService,
    private logger: Logger,
    private vetFacade: VetFacade,
    @Inject(LOCALE_ID) protected localeId: LanguageCode,
    private trackingService: GTMService,
    private _dialog: Dialog
  ) {
    super();
    this.vetFacade.vet$
      .pipe(
        takeUntil(this.destroyed$),
        tap((vet) => (this.vet = vet))
      )
      .subscribe();
    this.vetFacade.currentClinicCountry$
      .pipe(
        takeUntil(this.destroyed$),
        tap((currentClinicCountry) => (this.currentClinicCountry = currentClinicCountry))
      )
      .subscribe();
    this.vetFacade.market$
      .pipe(
        takeUntil(this.destroyed$),
        tap((market) => (this.market = market))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  sendEmail(payload: Contact): Observable<any> {
    const url = this.pathUrl.contactUs;
    return this.http.post(url, payload).pipe(
      map((data) => data),
      catchError(this.handleError.bind(this))
    );
  }

  signalRecommendationEmail(body): Observable<any> {
    return this.http.post(this.pathUrl.signalRecommendation, body).pipe(catchError(this.handleError.bind(this)));
  }

  sendRecommendationByEmail(ownerEmail: string, emailBody: RecommendationBodyEmail, attachments: Attachments[]): Observable<void> {
    const { tool, eventData, ...data } = emailBody;
    const payload = {
      ...this.getEmailLocalizationData(),
      from: { email: Constants.NO_REPLY_MAIL, name: this.userFullName },
      subject: 'Recommendation',
      to: ownerEmail,
      data,
      tool,
      eventData,
      attachments,
    };
    return this.http.post(this.pathUrl.mailRecommendation, payload).pipe(
      map((data) => data),
      catchError(this.handleError.bind(this))
    );
  }

  sendInvitation(clinicId: string, data: { email: string; data: InvitationTemplateData }): Observable<any> {
    const body = {
      ...this.getEmailLocalizationData(),
      ...data,
    };
    return this.http.post(this.pathUrl.invitations(clinicId), body).pipe(
      catchError((err) => {
        this.logger.error(err);
        return this.handleError.bind(this);
      })
    );
  }

  sendByEmail(
    emailPopinType: EmailPopinType,
    email: string,
    body?,
    attachments?: Attachments[],
    analytics?: {
      page?: Page;
      notesCharacterCount?: number;
      options?: EmailOptions;
    },
    isRetry = false
  ): Subscription | void {
    const retryCallback = () => this.sendByEmail(emailPopinType, email, body, attachments, analytics, true);
    const sendTrackingEvent = (status: StatusEnum) =>
      this.trackingService.sendInteraction(
        sendByEmail(
          mapToolToModule(body.tool),
          analytics?.page?.type,
          analytics?.page?.block,
          status,
          analytics?.options,
          analytics?.notesCharacterCount
        )
      );

    switch (emailPopinType) {
      case EmailPopinType.Recommendation:
        return this.sendRecommendationByEmail(email, body, attachments).subscribe(
          () => {
            sendTrackingEvent(StatusEnum.SUCCESS);
            this.openResponseEmailPopin(emailPopinType, true, isRetry);
          },

          (err) => {
            sendTrackingEvent(StatusEnum.ERROR);
            this.openResponseEmailPopin(emailPopinType, false, isRetry, err, retryCallback);
          }
        );

      case EmailPopinType.Collaborators:
        return this.sendInvitation(body.clinicId, body.data).subscribe(
          () => {
            this.openResponseEmailPopin(emailPopinType, true, isRetry);
          },
          (err) => {
            this.openResponseEmailPopin(emailPopinType, false, isRetry, err, retryCallback);
          }
        );

      case EmailPopinType.Contact:
        return this.sendEmail(body).subscribe(
          () => {
            // gtm event is deprecated ?
            this.gtmEventsService.sendPopinResult('contactUs', 'Contact Us', true);
            this.openResponseEmailPopin(emailPopinType, true, isRetry);
          },
          (err) => {
            // gtm event is deprecated ?
            this.gtmEventsService.sendPopinResult('contactUs', 'Contact Us', true);
            this.openResponseEmailPopin(emailPopinType, false, isRetry, err, retryCallback);
          }
        );

      default:
        return;
    }
  }

  openResponseEmailPopin(emailPopinType, isSuccessPopinType, isRetry, error?, retryCallback?): void {
    this._dialog.open(EmailResponsePopinComponent, {
      data: {
        emailPopinType,
        isSuccessPopinType,
        isRetryAllowed: !isRetry,
        error,
        retryCallback,
      },
    });
  }

  /**
   * Get email localization data
   * Method to get existing combination of countryCode & locale to use in sendgrid
   * Use user language if available, first language declared if not and en-GB if templateLanguages are not found
   */
  private getEmailLocalizationData(): { locale: string; countryCode: string } {
    const templateLanguages = this.market?.mailLanguages;

    let locale = LanguageCode.enGB;
    if (templateLanguages?.length) {
      locale = templateLanguages.includes(this.localeId) ? this.localeId : templateLanguages[0];
    }

    return {
      countryCode: this.currentClinicCountry,
      locale,
    };
  }
}
