import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { VetService } from '@app/core/services/network';
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { getConsultationsWithoutVisit, getConsultationsWithoutVisitSuccess } from '../pet.actions';
import { setAlert } from '@app/store/core';
import { RCAlertType } from '@rc/ui';

@Injectable()
export class GetConsultationsEffects {
  constructor(private actions$: Actions, private vetService: VetService) {}

  /**
   * Get all consultations with renal detect prediction informations only
   */
  getConsultationsWithRenal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsultationsWithoutVisit),
      switchMap((action) => {
        return this.vetService.consultationsByPatientWithoutVisit(action.patientId).pipe(
          switchMap((consultations) => {
            return [getConsultationsWithoutVisitSuccess({ consultations })];
          }),
          catchError(() => [
            setAlert({
              alert: { message: translateKey('error_general_text'), alertType: RCAlertType.ERROR },
            }),
          ])
        );
      })
    )
  );
}
