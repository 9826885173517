<div class="header-tabs" data-content="tabs">
  <div class="header-tabs__wrapper">
    <div class="rc-tabs">
      <ul class="rc-tabs__tabs">
        <li
          *ngFor="let tab of tabs"
          [routerLink]="tab.enabled ? tab.link : null"
          class="rc-tabs__tabs__item"
          [ngClass]="tabs.length === 2 ? 'rc-tabs__tabs__item two_items_width' : 'rc-tabs__tabs__item'"
          [class.rc-tabs__tabs__item--disabled]="!tab.enabled"
          [class.rc-tabs__tabs__item--active]="currentTab.id === tab.id"
          [id]="'rc-tab-' + tab.id"
          (click)="onTabClick($event, tab.id)"
        >
          <a class="rc-tabs__tabs__item__short">{{ tab.short }}</a>
          <a class="rc-tabs__tabs__item__long">{{ tab.long }}</a>
        </li>
      </ul>
      <div #underline class="rc-tabs__underline"></div>
    </div>
  </div>
</div>
