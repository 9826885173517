<div class="header-profile" data-content="profile">
  <div class="header-profile-content">
    <div class="header-profile-profile-picture" [style.background-image]="'url(' + (imageUrl || placeholderImageUrl) + ')'"></div>

    <div class="profile-informations">
      <h1 class="profile-informations-name rc-title-alpha">{{ headerTitle }}</h1>

      <ng-container *ngIf="attributes">
        <ul class="profile-informations-list">
          <li *ngFor="let attr of attributes" class="profile-informations-list-item">
            <p class="profile-informations-list-item-title">
              {{ attr.label }}
            </p>

            <p class="profile-informations-list-item-value">
              <app-rc-attribute [object]="object" [attribute]="attr"></app-rc-attribute>
            </p>
          </li>
        </ul>
      </ng-container>
    </div>

    <ng-content select="[right]"></ng-content>
  </div>
</div>
