import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconName } from '@app/shared/utils/icon/icons';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
})
export class NoResultsComponent {
  @Input() iconName?: IconName;
  @Input() title: string;
  @Input() description?: string;
  @Input() actionLabel?: string;

  @Output() actionOutput = new EventEmitter<undefined>();

  emitAction(): void {
    this.actionOutput.emit();
  }
}
