/**
 * Replace <newElem> into <coll> by comparing them with <accessorFn>. If not found, do nothing,
 * or if <pushIfNotFound> is true, add it at queue postion
 */
export function replaceInArray<T, R>(coll: T[], newElem: T, accessorFn: (elem: T) => R, pushIfNotFound?: boolean): T[] {
  const key = accessorFn(newElem);
  const index = coll.findIndex((elem) => accessorFn(elem) === key);
  const collCopy = coll.slice();
  if (index > -1) {
    collCopy[index] = newElem;
  } else if (pushIfNotFound) {
    collCopy.push(newElem);
  }
  return collCopy;
}

export function transformArrayToEntities<T extends { id: string }>(
  prevEntities: { [index: string]: T },
  items: T[]
): { [index: string]: T } {
  return items.reduce(
    (entities, item) => ({
      ...entities,
      [item.id]: item,
    }),
    {
      ...prevEntities,
    }
  );
}
