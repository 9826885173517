<div class="product">
  <div class="header" *ngIf="interfaceConfig.showContainerHeader">
    <!-- Product Title -->
    <div class="title">
      <app-icon class="icon" [name]="IconNameEnum.shop"></app-icon>
      <span class="label">{{ interfaceConfig.titlePage }}</span>
    </div>

    <!-- Segmented between Grid & List View -->
    <div class="switcher view-type-segmented">
      <app-rc-segmented-control
        [items]="viewTypeSegmented"
        [value]="ViewType.Grid"
        (didSelectControl)="setViewType($event)"
      ></app-rc-segmented-control>
    </div>
  </div>

  <div class="filters" [class.filters--expanded]="filterExpanded">
    <!-- Filter search on mobile -->
    <div class="filter__search">
      <div class="left rc-input-container">
        <em class="rc-icon rc-icon--search"></em>
        <input
          id="search_field"
          name="search_field"
          type="text"
          placeholder="Search"
          class="rc-input rc-input--search rc-input--underlined"
          [value]="currentSearch"
          (keyup)="setSearch($event.target)"
        />
      </div>
      <div class="left filter__button" (click)="toggleFilter($event)">
        <svg class="filter__icon" [class.active]="filterExpanded" viewBox="0 0 32 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icons/Filter/32" fill="#767676">
              <g id="Page-1">
                <path
                  class="filter__icon-top"
                  d="M9,16 C7.897,16 7,15.103 7,14 C7,12.897 7.897,12 9,12 C10.103,12 11,12.897 11,14 C11,15.103 10.103,16 9,16 M31,13 L12.858,13 C12.411,11.28 10.858,10 9,10 C7.142,10 5.589,11.28 5.142,13 L1,13 C0.448,13 0,13.448 0,14 C0,14.552 0.448,15 1,15 L5.142,15 C5.589,16.72 7.142,18 9,18 C10.858,18 12.411,16.72 12.858,15 L31,15 C31.552,15 32,14.552 32,14 C32,13.448 31.552,13 31,13"
                  id="Fill-1"
                />
                <path
                  class="filter__icon-middle"
                  d="M25,6 C23.897,6 23,5.103 23,4 C23,2.897 23.897,2 25,2 C26.103,2 27,2.897 27,4 C27,5.103 26.103,6 25,6 M31,3 L29,3 C28.953,3 28.912,3.021 28.866,3.027 C28.429,1.293 26.868,0 25,0 C23.142,0 21.589,1.28 21.142,3 L1,3 C0.448,3 0,3.448 0,4 C0,4.552 0.448,5 1,5 L21.142,5 C21.589,6.72 23.142,8 25,8 C26.868,8 28.429,6.707 28.866,4.973 C28.912,4.979 28.953,5 29,5 L31,5 C31.552,5 32,4.552 32,4 C32,3.448 31.552,3 31,3"
                  id="Fill-3"
                />
                <path
                  class="filter__icon-bottom"
                  d="M19,26 C17.897,26 17,25.103 17,24 C17,22.897 17.897,22 19,22 C20.103,22 21,22.897 21,24 C21,25.103 20.103,26 19,26 M31,23 L22.858,23 C22.411,21.28 20.858,20 19,20 C17.142,20 15.589,21.28 15.142,23 L1,23 C0.448,23 0,23.448 0,24 C0,24.552 0.448,25 1,25 L15.142,25 C15.589,26.72 17.142,28 19,28 C20.858,28 22.411,26.72 22.858,25 L31,25 C31.552,25 32,24.552 32,24 C32,23.448 31.552,23 31,23"
                  id="Fill-5"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>

    <!-- Form to filter product -->
    <div class="product-filter__form">
      <div class="form-wrapper">
        <!-- Product Segment Controls : All / Wet / Dry  -->
        <div *ngIf="interfaceConfig.useProductTypeFilter" class="product-filter__form-item product-type-segmented">
          <app-rc-segmented-control
            [items]="productTypeSegmented"
            [value]="selectedProductType"
            (didSelectControl)="setSelectedProductType($event)"
          ></app-rc-segmented-control>
        </div>

        <!-- Product Selector : Species & Categories  -->
        <div class="product-filter__form-item product__selector">
          <!-- Species Selector -->
          <div *ngIf="interfaceConfig.showSpeciesFilter" class="rc-select-group__select rc-select-group__select--specie">
            <app-rc-select
              [value]="selectedSpecies"
              placeholder="All"
              i18n-placeholder="@@all"
              [items]="species"
              [keyPath]="'value'"
              [labelPath]="'key'"
              (didSelectValue)="setSelectedSpecies($event)"
            ></app-rc-select>
          </div>

          <!-- Category Selector -->
          <ng-container *ngIf="interfaceConfig.useCategoryFilter">
            <div class="rc-select-group__select rc-select-group__select--category">
              <app-rc-select
                [value]="'' + (selectedTerritory || '')"
                placeholder="All products"
                i18n-placeholder="@@form-attribute_all-products"
                [items]="filteredTerritories"
                [keyPath]="'code'"
                [labelPath]="'localizedText'"
                (didSelectValue)="setSelectedTerritory($event)"
              ></app-rc-select>
            </div>
          </ng-container>
        </div>

        <!-- Product Selector : Search  -->
        <div class="product-filter__form-item">
          <div class="rc-input-container rc-input-container--search">
            <em class="rc-icon rc-icon--search"></em>
            <input
              (click)="sendEvent()"
              type="text"
              placeholder="Search"
              i18n-placeholder="@@product_search-placeholder"
              class="rc-input rc-input--search rc-input--underlined"
              [value]="currentSearch"
              (keyup)="setSearch($event.target)"
            />
          </div>
        </div>
      </div>
      <!-- Product edit button -->
      <div *ngIf="canEditPrices$ | async" class="product-edit-button">
        <ng-container *ngIf="editPriceMode; else editButton">
          <button id="cancel-edit-button" class="rc-button edit-button" (click)="setViewType(viewType)">
            <app-icon class="icon-edit" [name]="IconNameEnum.close"></app-icon>
            <span class="rc-button__title rc-button__title--link" i18n="@@action_cancel">Cancel</span>
          </button>
        </ng-container>
        <ng-template #editButton>
          <button id="edit-prices-button" class="edit-button rc-button" (click)="setEditMode()">
            <app-icon class="icon-edit" [name]="IconNameEnum.edit"></app-icon>
            <span class="rc-button__title rc-button__title--link" i18n="@@price_display-edit">Edit prices</span>
          </button>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- Product content: Grid or List view -->
  <div class="content" [ngClass]="{ 'content--list': viewType === ViewType.List && !editPriceMode }">
    <ng-container [ngSwitch]="viewType" *ngIf="filteredProducts && filteredProducts.length > 0; else noContent">
      <div [formGroup]="productForm">
        <!-- Product Grid View -->
        <ng-container *ngIf="!editPriceMode">
          <ng-container *ngSwitchCase="ViewType.Grid">
            <app-product-grid
              [productForm]="productForm"
              [products]="filteredProducts.slice(0, nbProductsDisplayed)"
              [showSelectButton]="interfaceConfig.showProductSelectBtn"
              [showRetailPrices]="interfaceConfig.showRetailPrices"
              [selectedProductsIds]="selectedProductIds"
              [displayPackages]="displayPackages"
              (removeProduct)="removeProduct($event)"
              (selectProduct)="selectProduct($event.product, $event.packId)"
              (updatePrice)="pricesUpdated($event)"
            >
            </app-product-grid>
          </ng-container>

          <!-- Product List View -->
          <ng-container *ngSwitchCase="ViewType.List">
            <app-product-list
              [productForm]="productForm"
              [showSelectButton]="interfaceConfig.showProductSelectBtn"
              [showRetailPrices]="interfaceConfig.showRetailPrices"
              [products]="filteredProducts.slice(0, nbProductsDisplayed)"
              [selectedProductsIds]="selectedProductIds"
              [displayPackages]="displayPackages"
              (removeProduct)="removeProduct($event)"
              (selectProduct)="selectProduct($event.product, $event.packId)"
            >
            </app-product-list>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="editPriceMode">
          <app-product-edit [products]="filteredProducts.slice(0, nbProductsDisplayed)" [tool]="tool"></app-product-edit>
        </ng-container>
      </div>

      <div class="clear"></div>

      <div class="product__show-more" (click)="showMoreProducts()" *ngIf="filteredProducts.length > nbProductsDisplayed">
        <em class="rc-icon rc-icon--dots-horizontal"></em>
      </div>
    </ng-container>

    <!-- No content view -->
    <ng-template #noContent>
      <app-no-results
        [iconName]="DetectMobile.isMobile() ? IconNameEnum.empty_state_mobile : IconNameEnum.empty_state_desktop"
        [title]="'product-catalogue-empty_title_no_result' | translateKey"
        [description]="'product-catalogue-empty_text_adjust' | translateKey"
        [actionLabel]="'product-catalogue-empty_button_clear' | translateKey"
        (actionOutput)="resetFilter()"
      ></app-no-results>
    </ng-template>
  </div>
</div>
