<div class="rc-input-number">
  <div class="rc-input-number__action">
    <button type="button" class="rc-input-number__action-button rc-input-number__minus" (click)="decrement()" [disabled]="disableMinus">
      <span class="rc-input-number__action-icon rc-input-number__action-icon--minus">
        <svg
          width="16px"
          height="2px"
          viewBox="0 0 16 2"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Vet_VF_SP1.25_05" transform="translate(-976.000000, -795.000000)" [style.fill]="disableMinus ? '#EAEAEA' : '#E2001A'">
              <g id="Products" transform="translate(260.000000, 591.000000)">
                <g transform="translate(40.000000, 134.000000)" id="size/weight">
                  <g transform="translate(0.000000, 39.000000)">
                    <g id="Dry">
                      <g id="Group-2" transform="translate(676.000000, 11.000000)">
                        <g id="Icons/Minus/16" transform="translate(0.000000, 20.000000)">
                          <path
                            d="M1,2 C0.447,2 0,1.553 0,1 C0,0.447 0.447,0 1,0 L15,0 C15.553,0 16,0.447 16,1 C16,1.553 15.553,2 15,2 L1,2 Z"
                            id="Page-1"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </span>
    </button>
  </div>
  <div class="rc-input-number__container">
    <p>{{ value }}</p>
  </div>
  <div class="rc-input-number__action">
    <button type="button" class="rc-input-number__action-button rc-input-number__plus" (click)="increment()" [disabled]="disablePlus">
      <span class="rc-input-number__action-icon rc-input-number__action-icon--plus">
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Vet_VF_SP1.25_05" transform="translate(-1052.000000, -787.000000)" [style.fill]="disablePlus ? '#EAEAEA' : '#E2001A'">
              <g id="Products" transform="translate(260.000000, 591.000000)">
                <g transform="translate(40.000000, 134.000000)" id="size/weight">
                  <g transform="translate(0.000000, 39.000000)">
                    <g id="Dry">
                      <g id="Group-2" transform="translate(676.000000, 11.000000)">
                        <g id="Icons/Plus/16" transform="translate(76.000000, 12.000000)">
                          <path
                            d="M15,7 L9,7 L9,1 C9,0.447 8.552,0 8,0 C7.448,0 7,0.447 7,1 L7,7 L1,7 C0.448,7 0,7.447 0,8 C0,8.553 0.448,9 1,9 L7,9 L7,15 C7,15.553 7.448,16 8,16 C8.552,16 9,15.553 9,15 L9,9 L15,9 C15.552,9 16,8.553 16,8 C16,7.447 15.552,7 15,7"
                            id="Page-1"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </span>
    </button>
  </div>
</div>
