import { Component, OnInit } from '@angular/core';

import { ConfigInjector } from '@app/core/services/utils/injector';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit {
  /**
   * Properties
   */
  data: any;

  /**
   * Initializer
   */
  constructor(public config: ConfigInjector) {}

  /**
   * Life Cycle
   */

  ngOnInit() {
    this.data = this.config.data;
  }
}
