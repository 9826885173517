import { DasInitComponent } from './standalone/das-init.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BrowserDeprecationComponent, MainPageComponent } from '@app/pages';
import { OktaCallbackComponent } from '@app/pages/okta/callback.component';
import { PetPageComponent } from '@app/pages/pet-page/container/pet-page.component';
import { StandaloneGuard } from './standalone/standalone-guard';
import { DasMainPageComponent } from './standalone/das-main-page/das-main-page.component';
import { DasNavComponent } from './standalone/standalone-nav/das-nav.component';
import { StandaloneAccessDeniedComponent } from './standalone/standalone-access-denied/standalone-access-denied.component';
import { StandaloneProgressBarComponent } from './standalone/standalone-progress-bar/standalone-progress-bar.component';

const routes: Routes = [
  {
    path: 'callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'browser-deprecated',
    component: BrowserDeprecationComponent,
  },
  {
    path: 'access-denied',
    component: StandaloneAccessDeniedComponent,
  },
  {
    path: '',
    component: DasMainPageComponent,
    canActivate: [StandaloneGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        component: DasInitComponent,
      },
      {
        path: 'daily-allowance',
        loadChildren: () => import('app/pages/daily-allowance/daily-allowance.module').then((m) => m.DailyAllowanceModule),
      },
      {
        path: 'allowance',
        loadChildren: () => import('app/pages/allowance/allowance.module').then((m) => m.AllowanceModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  declarations: [],
  providers: [StandaloneGuard],
})
export class AppRoutingModule {}
/**
 * These components are imported directly into AppModule for retrocompatibility
 */
export const routingComponents: any[] = [
  BrowserDeprecationComponent,
  OktaCallbackComponent,
  /**
   * Das component
   */
  DasInitComponent,
  DasMainPageComponent,
  StandaloneProgressBarComponent,
  DasNavComponent,
  StandaloneAccessDeniedComponent,
  /**
   * Needs to be imported because PetCommunicationService
   */
  PetPageComponent,
  MainPageComponent,
];
