import { formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Clinic, FollowUpSelector, NutritionRecommendation, ProductRecommendationBack, SmartRecoApiBody } from '@app/core/models';
import { Market } from '@app/core/models/market';
import { RecommendationProgramApiBody } from '@app/core/models/program';
import { Helper, Programs, VetPreferences } from '@app/shared/utils';
import { formatNutritionRecommendation } from '@app/shared/utils/static-helpers/rationing-helper';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BreedPetProfileModel, BreedPetProfileParameters, defaultLifestagesPlanDuration } from '../models/BreedController';
import { ApiService } from './../api.service';
import { environment } from '@env/environment';

@Injectable()
export class NutritionService extends ApiService {
  constructor(private http: HttpClient, @Inject(LOCALE_ID) protected localeId: string) {
    super();
  }

  createRecommendation(program: Programs, body: unknown, withCups: boolean): Observable<NutritionRecommendation> {
    let params = new HttpParams();

    const parameters = {
      withCups,
      countryCode: VetPreferences.country.toLowerCase(),
      program: program,
    };

    Object.keys(parameters).forEach((key) => {
      params = params.append(key, parameters[key]);
    });

    return this.http.post(this.pathUrl.rationing, body, { params }).pipe(
      map((data) => formatNutritionRecommendation(data)),
      catchError(this.handleError.bind(this))
    );
  }

  getIBW(currentWeight: number, bcs: number): Observable<number> {
    let params = new HttpParams();
    params = params.set('bodyWeight', JSON.stringify(currentWeight));
    params = params.set('bcs', JSON.stringify(bcs));

    return this.http
      .get<{ idealBodyWeight: number }>(this.pathUrl.ibw, { params })
      .pipe(
        map((res) => res.idealBodyWeight),
        catchError(this.handleError.bind(this))
      );
  }

  recommendationPrograms(body: RecommendationProgramApiBody): Observable<FollowUpSelector> {
    return this.http.post<FollowUpSelector>(this.pathUrl.recommendationPrograms, body).pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * [GET]
   */
  getSmartRecoProducts(
    body: SmartRecoApiBody,
    clinic: Clinic,
    vetId: string,
    market: Market,
    filterApi?: any,
    limit?: number
  ): Observable<any> {
    const parameters = {
      locale: Helper.catalogLang(this.localeId, market),
      countryCode: clinic?.companyAddress?.country.toLowerCase(),
    };
    if (filterApi) {
      parameters['filter'] = filterApi;
    }
    if (limit) {
      parameters['limit'] = limit;
    }
    const params: HttpParams = this.params(parameters);
    const path = environment.isStandalone ? this.pathUrl.smartRecoProductsSrs() : this.pathUrl.smartRecoProducts(clinic?.id);
    return this.http
      .post<any>(path, body, { params })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * [GET]
   */
  getSmartRecoNotRecommendedProduct(id: string, body: SmartRecoApiBody, clinic: Clinic): Observable<ProductRecommendationBack> {
    const parameters = {
      countryCode: clinic?.companyAddress?.country.toLowerCase(),
    };

    const params: HttpParams = this.params(parameters);
    return this.http
      .post<any>(this.pathUrl.smartRecoNotRecommendedProduct(id), body, { params })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getBreedPetProfile = ({
    breedCode,
    dateOfBirth,
    genderCode,
    lifestagesPlanDuration,
  }: BreedPetProfileParameters): Observable<BreedPetProfileModel> =>
    this.http.get<BreedPetProfileModel>(this.pathUrl.petProfile(breedCode), {
      params: {
        ...(dateOfBirth && {
          dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd', this.localeId),
        }),
        ...(genderCode && { genderCode }),
        lifestagesPlanDuration: lifestagesPlanDuration?.toString() || defaultLifestagesPlanDuration,
      },
    });
}
