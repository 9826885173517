import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-srs-progress-bar',
  templateUrl: './standalone-progress-bar.component.html',
  styleUrls: ['./standalone-progress-bar.component.scss'],
})
export class StandaloneProgressBarComponent {
  @Input() value = 0;
}
