import { Inject, Injectable } from '@angular/core';
import Chart, { ChartConfiguration, ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import { VetService, WindowService } from '@app/core/services';
import { GraphType, GraphTypeScale } from '@app/shared/utils/enums/chart-enums';
import { _lineOptions, _renderLineData, chartConfiguration } from '@app/core/services/utils/chart/chart-configuration';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ChartHelper, VetPreferences } from '@app/shared/utils';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { ChartConsultation } from '@app/core/models/chart';
import { ConsultationWeight } from '@app/core/models/consultation-weight';
import { Weight } from '@app/core/models';

function _chart(window: WindowService): any {
  return window.nativeWindow.Chart as Chart;
}

@Injectable()
export class ChartService {
  /**
   * Properties & Variables
   */
  static _chart: any;
  public chartType = GraphType.Line;
  public canvasContainer: HTMLCanvasElement;
  private _graphType: GraphType = GraphType.Line;
  private _scaleType: GraphTypeScale;
  private _config: ChartConfiguration;
  private isWeightLossPOLetter: boolean;

  /**
   * Initializer
   * @param windowService - Window
   * @param vetService
   * @param datePipe
   * @param document
   */
  constructor(
    private vetService: VetService,
    private windowService: WindowService,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: HTMLDocument // private coreFacade: CoreFacade, // private windowService: WindowService, // private vetService: VetService // private loaderService: LoaderService, // @Inject(DOCUMENT) private document: HTMLDocument, // private datePipe: DatePipe
  ) {
    this.initGraphView();
  }

  /**
   * Draw rounded bar
   * This function is used to draw rounded top bar
   * @param ctx = Canvas context
   */
  private static drawRoundedTopRectangle(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    // top right corner
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    // bottom right	corner
    ctx.lineTo(x + width, y + height);
    // bottom left corner
    ctx.lineTo(x, y + height);
    // top left
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  }

  set graphType(value: GraphType) {
    this._graphType = value;
    this.initGraphView();
  }

  get graphType(): GraphType {
    return this._graphType;
  }

  set scaleType(value: GraphTypeScale) {
    this._scaleType = value;
  }

  get scaleType(): GraphTypeScale {
    return this._scaleType;
  }

  set chart(value) {
    ChartService._chart = value;
  }

  get chart() {
    return _chart(this.windowService);
  }

  afterDatasetsDrawPlugin = {
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      chart.config.data.datasets.forEach((dataset, i) => {
        const meta = chart && dataset && chart.getDatasetMeta(i);
        if (!meta.hidden) {
          meta.data.forEach((element, index) => {
            ctx.fillStyle = 'rgb(0, 0, 0)';
            const fontSize = 35;
            const fontStyle = 'normal';
            const fontFamily = '"DINProRegular" ,Arial, sans-serif';
            ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
            /**
             * Writing point values only for the 3rd dataset built by the _buildGraphData function (which is the weight curve, the first two datasets of the array are the weightMin and weightMax curves)
             */
            const dataString = (datasetIndex) => {
              if (!this.isWeightLossPOLetter) {
                return datasetIndex === 0 || datasetIndex === 1
                  ? ''
                  : `${dataset.data[index].toString()}${VetPreferences.currentBigMeasurementUnit}`;
              }
              return datasetIndex === 0 ? `${dataset.data[index].toString()}${VetPreferences.currentBigMeasurementUnit}` : '';
            };

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const padding = 30;
            const position = element.tooltipPosition();

            ctx.fillText(`${dataString(i)}`, position.x, position.y - fontSize / 2 - padding);
          });
        }
      });
    },
  };

  drawGraphForBase64(ctx, data: ChartData, isWeightLossPOLetter: boolean, options?: ChartOptions) {
    this._config = isWeightLossPOLetter
      ? {
          type: GraphType.Line,
          data: _renderLineData(data),
          options: _lineOptions(data),
        }
      : chartConfiguration(GraphType.Line, data, options);
    const max = Math.max(...[].concat(...data.datasets.map((dataset: ChartDataSets) => dataset.data as number[])));
    const step = +(max / 5).toPrecision(1);
    this._config.options = {
      events: [],
      title: {
        display: true,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
      },
      layout: {
        padding: {
          left: 25,
        },
      },
      scales: {
        type: GraphTypeScale.Linear,
        xAxes: [
          {
            ticks: {
              padding: 45,
              fontSize: 35,
              fontFamily: '"DINProRegular" ,Arial, sans-serif',
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              padding: 45,
              fontSize: 35,
              maxTicksLimit: 9,
              stepSize: step,
              suggestedMax: max * 1.2,
              fontFamily: '"DINProRegular" ,Arial, sans-serif',
            },
            gridLines: {
              color: 'rgba(0, 0, 0, 0.1)',
              zeroLineColor: 'rgba(0, 0, 0, 0.1)',
              drawBorder: false,
            },
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: true,
    };

    this._config.options.responsive = false;
    this._config.options.maintainAspectRatio = false;
    this.chart = new Chart(ctx, this._config);
    if (this.chart && this._config.type === GraphType.Line) {
      Chart.plugins.register(this.afterDatasetsDrawPlugin);
    }
    return ChartService._chart;
  }

  /**
   * public function called to draw Chart object
   * @param ctx : Canvas context
   * @param data : Chart data according to Chart.js Type
   * @param options : Chart.js Option. This is an optional parameter
   * Line Chart and RoundedBar Chart dont need ChartOptions
   */
  drawGraph(ctx, data: ChartData, options?: ChartOptions) {
    this._config = chartConfiguration(this._graphType, data, options);
    Chart.plugins.unregister([this.afterDatasetsDrawPlugin]);
    switch (this._graphType) {
      case GraphType.Bar:
        // Customize Tooltip
        this._config.options.tooltips = {
          enabled: false,
          mode: 'index',
          position: 'nearest',
          custom: this.customizeTooltipsBar,
          callbacks: {
            label: (tooltipItem) => {
              return `${tooltipItem.yLabel.toFixed(2)} ${this._config.data.datasets[0].label}`;
            },
          },
        };
        // Customize Labels
        this._config.plugins = [];
        this._config.plugins.push({
          beforeInit: function (chart) {
            chart.data.labels.forEach(function (e, i, a) {
              if (/\n/.test(e as string)) {
                a[i] = (e as string).split(/\n/);
              }
            });
          },
        });
        this.chart = new Chart(ctx, this._config);
        break;

      case GraphType.Line:
        // Todo : Customize Tooltip
        this._config.options.tooltips = {
          enabled: false,
        };
        this._config.options.events = [];

        this.chart = new Chart(ctx, this._config);
        break;

      default:
        break;
    }

    return ChartService._chart;
  }

  /**
   * Initialize the chart view with overriding some
   * Chart.js functions
   */
  private initGraphView() {
    if (this._graphType === GraphType.Bar) {
      this.roundedRectangle();
      this.chart.controllers.bar.extend({
        dataElementType: this.chart.elements.RoundedTopRectangle,
      });
    }
  }

  /**
   * Customize chart popIn
   * @param tooltipModel: Chart.js popIn
   */
  private customizeTooltipsBar(tooltipModel?: any) {
    const elementName = 'rc-chart-tooltip';
    let tooltipEl: HTMLElement = this.document?.getElementById(elementName);

    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    if (!tooltipEl) {
      tooltipEl = this.document.createElement('div');
      tooltipEl.id = elementName;
      tooltipEl.innerHTML = '<div></div>';
      this.document.body.appendChild(tooltipEl);
    }

    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }
    let tooltipFooterColors;

    if (tooltipModel.body) {
      const bodyLines = tooltipModel.body.map(getBody);
      let innerHtml = '';

      for (let index = 0, length = bodyLines.length; index < length; index++) {
        const body = bodyLines[index];
        const labelColor = tooltipModel.labelColors[index];
        tooltipEl.style.border = `1px solid ${labelColor.backgroundColor}`;
        tooltipEl.style.color = `${labelColor.backgroundColor}`;
        tooltipFooterColors = labelColor.backgroundColor;
        innerHtml += body;
      }

      innerHtml += `<div class="rc-chart-tooltip_arrow" style="border-color: ${tooltipFooterColors}"></div>`;
      innerHtml += `<div class="rc-chart-tooltip_circle" style="border-color: ${tooltipFooterColors}"></div>`;

      const rootDiv = tooltipEl.querySelector('div');
      rootDiv.innerHTML = innerHtml;
    }

    const canvasPosition = ChartService._chart.canvas.getBoundingClientRect();
    const caretSize = tooltipModel.caretSize + tooltipModel.caretPadding;
    const textContent = tooltipModel.body[0].lines[0] || '';
    const textSize = textContent.length <= 8 ? 23 : 30;
    const tooltipPadding = 6;
    const tooltipHeight = 40;
    const tooltipCircleHeight = 10;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = `${window.pageXOffset + canvasPosition.left + tooltipModel.caretX - (caretSize + tooltipPadding + textSize)}px`;
    tooltipEl.style.top = `${
      window.pageYOffset + canvasPosition.top + tooltipModel.caretY - (tooltipHeight + tooltipCircleHeight + tooltipPadding / 2)
    }px`;
    tooltipEl.style.padding = `${tooltipPadding}px ${tooltipPadding}px`;

    tooltipEl.style.pointerEvents = 'none';
  }

  /**
   * Draw rounded Bar
   * Overriding Chart.js draw bar function
   */
  private roundedRectangle() {
    this.chart.elements.RoundedTopRectangle = this.chart.elements.Rectangle.extend({
      draw: function () {
        const ctx = ChartService._chart.ctx;
        const vm = this._view;
        let left, right, top;
        let borderWidth = vm.borderWidth;

        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        const bottom = vm.base;
        const signX = 1;
        const borderSkipped = vm.borderSkipped || 'bottom';

        // Canvas doesn't allow us to stroke inside the width so we can
        // adjust the sizes to fit if we're setting a stroke on the line
        if (borderWidth) {
          // borderWidth shold be less than bar width and bar height.
          const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
          borderWidth = borderWidth > barSize ? barSize : borderWidth;
          const halfStroke = borderWidth / 2;
          // Adjust borderWidth when bar top position is near vm.base(zero).
          const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
          const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);

          left = borderLeft;
          right = borderRight;
        }

        // calculate the bar width and roundess
        const barWidth = Math.abs(left - right);
        const radius = 4;

        // keep track of the original top of the bar
        const prevTop = top;

        // move the top down so there is room to draw the rounded top
        top = prevTop + radius;
        const barRadius = top - prevTop;

        ctx.beginPath();
        ctx.fillStyle = vm.backgroundColor;
        ctx.strokeStyle = vm.borderColor;
        ctx.lineWidth = borderWidth;

        // draw the rounded top rectangle
        ChartService.drawRoundedTopRectangle(ctx, left, top - barRadius + 1, barWidth, bottom - prevTop, barRadius);

        ctx.fill();
        if (borderWidth) {
          ctx.stroke();
        }
      },
    });
  }

  /**
   * Creates a virtual DOM canvas
   * Used to get Weight Tracking data
   * Used for health track graph data and list data
   */
  createCanvasContainer() {
    const canvasContainer = this.document.createElement('canvas');
    canvasContainer.style.display = 'none';
    canvasContainer.setAttribute('id', 'myCanvas');
    canvasContainer.width = 1000;
    // Append the created canvas to the body element
    this.document.body.appendChild(canvasContainer);
    return canvasContainer;
  }

  /**
   * Fetches consultations,
   * generates a base64 image of a chart
   */
  getChartConsultationImage(
    patientId: string | null,
    isWeightLossPOLetter: boolean,
    data?: ChartConsultation | null,
    targetWeightData?: Weight | null
  ) {
    this.isWeightLossPOLetter = isWeightLossPOLetter;
    if (patientId) {
      return this.vetService.consultationsWeights(patientId).pipe(
        switchMap((weights: ConsultationWeight[]) => {
          const chartConsultationWeights = weights
            .slice(-3)
            .map((item) => ChartHelper.formatDataSets(item, this.datePipe, isWeightLossPOLetter, targetWeightData));
          // if the data parameter exists, we are in a daily allowance and we add the current weight info to the last 2 consultations weight info
          if (data) {
            const extendedChartConsultationWeights = [...chartConsultationWeights.slice(-2), data];
            return this.buildChartImage(extendedChartConsultationWeights, isWeightLossPOLetter);
          }
          // if there's no data parameter, we use the weight data from the last 3 consultations
          return this.buildChartImage(chartConsultationWeights, isWeightLossPOLetter);
        })
      );
    }
    // if there's no patientId, it's a new patient, we therefore use only the current consultation weight info
    if (data && !patientId) {
      return this.buildChartImage([data], isWeightLossPOLetter);
    }
  }

  buildChartImage(chartConsultationWeights: ChartConsultation[], isWeightLossPOLetter: boolean): Observable<string> {
    const chartDataSet = this._buildGraphData(chartConsultationWeights, isWeightLossPOLetter);
    return this.createChartImage(chartDataSet, isWeightLossPOLetter);
  }

  /**
   * Fetches consultations and returns an observable of a
   * formatted ready-to-use dataSet for the chart component
   */
  getChartConsultationData(patientId: string) {
    return this.vetService
      .consultationsWeights(patientId)
      .pipe(map((weights: ConsultationWeight[]) => weights.map((item) => ChartHelper.formatDataSets(item, this.datePipe, null))));
  }

  /**
   * Creates a virtual DOM canvas
   */
  createChartImage(chartData, isWeightLossPOLetter: boolean) {
    const canvasContainer = this.createCanvasContainer();
    this.drawGraphForBase64(canvasContainer, chartData, isWeightLossPOLetter);
    canvasContainer.width = 1000;
    canvasContainer.height = 500;
    this.canvasContainer = canvasContainer;
    return of(canvasContainer).pipe(
      delay(1000),
      map((canvas) => {
        return canvas.toDataURL('image/png', 1.0);
      }),
      tap(() => {
        this.document.body.removeChild(canvasContainer);
      })
    );
  }

  /**
   * Map weight tracking data
   * to use them in the graph
   */
  public _buildGraphData(consultations: ChartConsultation[], isWeightLossPOLetter: boolean): ChartData {
    const graphData: ChartData = ChartHelper.lineGraphTemplate;
    const weightData = [];
    const weightMinData = [];
    const weightMaxData = [];
    const labels = [];
    const targetWeightData = [];
    const newGraphData = {
      ...graphData,
      datasets: isWeightLossPOLetter
        ? [
            { ...graphData.datasets[2], data: weightData },
            { ...graphData.datasets[3], data: targetWeightData },
          ]
        : [
            { ...graphData.datasets[0], data: weightMaxData },
            { ...graphData.datasets[0], data: weightMinData },
            { ...graphData.datasets[2], data: weightData },
          ],
      labels,
    };
    consultations.forEach((item) => {
      if (item) {
        targetWeightData.push(item.targetWeight || null);
        labels.push(item.date.graph);
        weightData.push(item.weight);
        weightMinData.push(item.weightMin || null);
        weightMaxData.push(item.weightMax || null);
      }
    });

    return newGraphData;
  }
}
