import { ProductCatalogFilterValues } from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { LifestageType, ProductType, SpeciesCode } from '@app/shared/utils/enums';
import { RangeType } from '@app/shared/utils/enums/rangeType.enum';
import { SearchChoice } from '@app/shared/utils/enums/search-choice.enum';
import { TerritoryType } from '@app/shared/utils/enums/territoryType';
import { EventEnum, LabelEnum, ModuleNameEnum, PageBlockEnum, PageTypeEnum, StatusEnum } from '../types/enums';
import { EmailOptions, GTMInteraction, Tour } from '../types/interfaces';

export const continueToProducts = (name: ModuleNameEnum, pathology?: string): GTMInteraction => ({
  event: EventEnum.CONTINUE_TO_PRODUCTS,
  module: { name },
  pet: { pathology: pathology || 'undefined' },
});

export const continueToRation = (name: ModuleNameEnum): GTMInteraction => ({ event: EventEnum.CONTINUE_TO_RATION, module: { name } });

export const print = (
  name: ModuleNameEnum,
  type: PageTypeEnum,
  block: PageBlockEnum,
  options?: EmailOptions,
  notesCharacterCount = 0
): GTMInteraction => ({
  event: EventEnum.PRINT,
  module: { name },
  page: { type, block },
  notesCharacterCount,
  options,
});

export const productEditPrices = (): GTMInteraction => ({
  event: EventEnum.PRODUCT_EDIT_PRICES,
});
export const productResetPrices = (): GTMInteraction => ({
  event: EventEnum.PRODUCT_RESET_PRICES,
});

export const productSavePrices = (): GTMInteraction => ({
  event: EventEnum.PRODUCT_SAVE_PRICES,
});

export const homeCardInteraction = (label: LabelEnum): GTMInteraction => ({
  event: EventEnum.MODULE_CLICK,
  interaction: {
    label,
  },
});

export const quickLinksInteraction = (label: LabelEnum): GTMInteraction => ({
  event: EventEnum.QUICK_LINK_CLICK,
  interaction: {
    label,
  },
});

export const homeSearchInteraction = (): GTMInteraction => ({
  event: EventEnum.HOME_SEARCH_CLICK,
});

export const sendByEmail = (
  name: ModuleNameEnum,
  type: PageTypeEnum,
  block: PageBlockEnum,
  status: StatusEnum,
  options: EmailOptions,
  notesCharacterCount = 0
): GTMInteraction => ({
  event: EventEnum.SEND_BY_EMAIL,
  interaction: { status },
  module: { name: name },
  page: { type, block },
  notesCharacterCount,
  options,
});

export const openQRCode = (
  name: ModuleNameEnum,
  type: PageTypeEnum,
  block: PageBlockEnum,
  options: EmailOptions,
  notesCharacterCount = 0
): GTMInteraction => ({
  event: EventEnum.OPEN_QR_CODE,
  module: { name: name },
  page: { type, block },
  notesCharacterCount,
  options,
});

export const startDetection: GTMInteraction = {
  event: EventEnum.START_DETECTION,
  module: { name: ModuleNameEnum.RENAL_DETECT },
};

export const welcomeTourClose: GTMInteraction = {
  event: EventEnum.WELCOME_TOUR_CLOSE,
};

export const welcomeTourDiscard: GTMInteraction = {
  event: EventEnum.WELCOME_TOUR_DISCARD,
};

export const welcomeTourDontShowAgain: GTMInteraction = {
  event: EventEnum.WELCOME_TOUR_DONT_SHOW_AGAIN,
};

export const criteriaModalDisplayed = ({ name, relatedProduct }): GTMInteraction => ({
  event: EventEnum.CRITERIA_MODAL_DISPLAYED,
  module: {
    name: ModuleNameEnum.SMART_RECOMMENDATION,
  },
  criteria: {
    name,
    relatedProduct,
  },
});

export const welcomeTourFeedbackSurvey = ({ isHelpful, step }: Tour): GTMInteraction => ({
  event: EventEnum.WELCOME_TOUR_FEEDBACK_SURVEY,
  module: {
    name: ModuleNameEnum.SMART_RECOMMENDATION,
  },
  tour: {
    isHelpful,
    step,
  },
});

export const welcomeTourLeave = ({ step }: Tour): GTMInteraction => ({
  event: EventEnum.WELCOME_TOUR_LEAVE,
  module: {
    name: ModuleNameEnum.SMART_RECOMMENDATION,
  },
  tour: {
    step,
  },
});

export const welcomeTourPopin: GTMInteraction = {
  event: EventEnum.WELCOME_TOUR_POPIN,
};

export const welcomeTourStart: GTMInteraction = {
  event: EventEnum.WELCOME_TOUR_START,
};

export const welcomeTourStartUsing = ({ step }: Tour): GTMInteraction => ({
  event: EventEnum.WELCOME_TOUR_START_USING,
  module: {
    name: ModuleNameEnum.SMART_RECOMMENDATION,
  },
  tour: {
    step,
  },
});

export const welcomeTourStepPopin = ({ step }: Tour): GTMInteraction => ({
  event: EventEnum.WELCOME_TOUR_STEP_POPIN,
  module: {
    name: ModuleNameEnum.SMART_RECOMMENDATION,
  },
  tour: {
    step,
  },
});

export const petRecordSearchClick = (filter_by: SearchChoice, filter_species: SpeciesCode): GTMInteraction => ({
  event: EventEnum.PET_RECORD_SEARCH_CLICK,
  search: {
    activeFilters: [
      {
        By: filter_by,
        Species: filter_species === SpeciesCode.Unknown ? 'All' : filter_species,
      },
    ],
  },
});

export const smartRecoSearchAddProduct: GTMInteraction = {
  event: EventEnum.PRODUCT_ADD_CLICK,
};

export const productSearchClick = (currentFilters?: ProductCatalogFilterValues): GTMInteraction => {
  const technologyFilter = [];
  if (currentFilters.dry) technologyFilter.push(ProductType.Dry);
  if (currentFilters.wet) technologyFilter.push(ProductType.Wet);
  if (!currentFilters.wet && !currentFilters.dry) technologyFilter.push(ProductType.Unknown);

  const currLifestage = [];
  if (currentFilters.adult) {
    currLifestage.push(LifestageType.Adult);
  }
  if (currentFilters.birthAndGrowth) {
    currLifestage.push('Birth & Growth');
  }
  if (currentFilters.mature) {
    currLifestage.push(LifestageType.Mature);
  }
  return {
    event: EventEnum.PRODUCT_SEARCH_CLICK,
    search: {
      query: currentFilters?.search?.length > 2 ? currentFilters?.search : '',
      activeFilters: [
        {
          Technology: technologyFilter,
          Lifestage: currLifestage,
          Size: currentFilters.size,
          Species: currentFilters.specie === SpeciesCode.Unknown ? 'All' : currentFilters.specie,
        },
      ],
    },
  };
};

export const weightManagementFromTool = (): GTMInteraction => ({
  event: EventEnum.WEIGHT_MANAGEMENT_FROM_TOOL,
});

export const territoryNavigationClick = (selectedTerritoy: TerritoryType | RangeType): GTMInteraction => ({
  event: EventEnum.TERRITORY_NAVIGATION_CLICK,
  territory: selectedTerritoy,
});
