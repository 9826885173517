import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '@app/shared/utils';
import { CoreFacade } from '@app/store/core/core.facade';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private requestCount = 0;

  constructor(public auth: AuthService, private coreFacade: CoreFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * Show progress bar
     */
    if (!this.requestCount) this.coreFacade.toggleProgressBar(true);
    this.requestCount++;
    /**
     * Add the privategroups access token to each request going to backend
     */
    return this.auth.getToken$().pipe(
      mergeMap((token) => {
        return next.handle(
          token && request.url.indexOf(Constants.blobStorageUrlId) === -1
            ? request.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`,
                  'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey,
                },
              })
            : request
        );
      }),
      finalize(() => this.handleLoader())
    );
  }

  /**
   * Handle loader display
   */
  private handleLoader(): void {
    this.requestCount--;
    if (!this.requestCount) {
      this.coreFacade.toggleProgressBar(false);
    }
  }
}
