export enum ProductEnergyCategory {
  Unknown = 'all',
  AdultMature = 'adult_mature',
  AdultMaturePro = 'adult_mature_pro',
  LabradorPro = 'labrador_pro',
  BreedingLabrador = 'breeding_labrador',
  SterilizedLight = 'sterilized_light',
  BreedLowStdEnergyNeeds = 'breed_low_std_energy_needs',
  BreedLowStdEnergyNeedsPro = 'breed_low_std_energy_needs_pro',
  BreedHighStdEnergyNeeds = 'breed_high_energy_needs',
  BreedHighStdEnergyNeedsPro = 'breed_high_energy_needs_pro',
  GreatDanePosPro = 'great_dane_pos_pro',
  GestationPos = 'gestation_pos',
  GestationPro = 'gestation_pro',
  VetDietStd = 'vet_diet_std',
  VetDietModerateMobilityWeightCtrl = 'vet_diet_moderate_mobility_weight-ctrl',
  VcnNeutered = 'vcn_neutered',
  VcnIntact = 'vcn_intact',
  WeightLoss = 'weight_loss',
  Marathon = 'marathon',
  Sphynx = 'sphynx',
  NormalOutdoorFcnBreed = 'normal_outdoor_fcn_breed',
  LowLightSterlized = 'low_light_sterilized',
  Energy4300 = '4300',
  Energy4800 = '4800',
  Others = 'others',
}
