import { Injectable } from '@angular/core';
import { Product } from '@app/core/models';
import { FetchProductDataFilters } from '@app/core/models/fetch-product-data-filters';
import { ProductService } from '@app/core/services';
import { GTMService, productSavePrices } from '@app/core/services/tracking';
import { ProductCatalogFilterValues } from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { ProductPillar } from '@app/shared/utils/enums/pillar.enum';
import { TerritoryType } from '@app/shared/utils/enums/territoryType';
import {
  filterCatalogProducts,
  filterCatalogProductsRenalDetect,
  filterProductPackagesByType,
  formatProductsByCategory,
  newFilterProducts,
} from '@app/shared/utils/static-helpers/product-filters-helper';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { AppState } from '@app/store';
import { setAlert, toggleLoader } from '@app/store/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RCAlertType } from '@rc/ui';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { getAllProducts, getAllProductsSuccess } from '..';
import {
  setFilteredProducts,
  setFilteredProductsSuccess,
  setProductFilters,
  updatePrices,
  updatePricesSuccess,
  updateProductInAllProductList,
} from '../products.actions';
import { selectAllProductsSuccess, selectFiltersValues, selectPriceListToUpdate } from '../products.selectors';

@Injectable()
export class ProductsEffects {
  UpdateProductsPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePrices),
      withLatestFrom(this.store$.select(selectPriceListToUpdate)),
      switchMap(([action, priceListToUpdate]) => {
        return this.productService.updateClinicPrices(priceListToUpdate).pipe(
          switchMap(() => {
            this.trackingService.sendInteraction(productSavePrices());
            return [updatePricesSuccess({ priceListUpdated: priceListToUpdate })];
          }),
          catchError(() => [
            setAlert({
              alert: { message: translateKey('error_try-again-later'), alertType: RCAlertType.ERROR },
            }),
          ])
        );
      })
    )
  );

  GetAllProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllProducts),
      switchMap((action) => {
        return this.productService.fetchProducts([ProductPillar.VET, ProductPillar.SPTRETAIL]).pipe(
          map((products): [Product[], FetchProductDataFilters] => {
            return [filterCatalogProducts(products, action.filterParams), action.filterParams];
          }),
          map(([products, filterParams]): [Product[], FetchProductDataFilters] => {
            return filterParams?.packType
              ? [filterProductPackagesByType(products, filterParams?.packType), filterParams]
              : [products, filterParams];
          }),
          map(([products, filterParams]) => {
            return filterParams?.renalDetectRecommendationPredictionStatus
              ? filterCatalogProductsRenalDetect(
                  products,
                  filterParams?.renalDetectRecommendationPredictionStatus,
                  filterParams?.lastConsultationPredictionStatus
                )
              : products;
          }),
          switchMap((products) => {
            return [getAllProductsSuccess({ allProductList: products }), setFilteredProducts(), toggleLoader({ value: false })];
          }),

          catchError(() => [
            setAlert({
              alert: { message: translateKey('error_general_text'), alertType: RCAlertType.ERROR },
            }),
            toggleLoader({ value: false }),
          ])
        );
      })
    )
  );

  UpdateProductInProductList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProductInAllProductList),
      withLatestFrom(this.store$.select(selectAllProductsSuccess)),
      switchMap(([action, selectAllProductsSuccessList]) => {
        const productIndex = selectAllProductsSuccessList.findIndex((item) => item.id === action.product.id);
        const updatedProductList = [...selectAllProductsSuccessList];
        updatedProductList.splice(productIndex, 1, action.product);
        return [getAllProductsSuccess({ allProductList: updatedProductList }), setFilteredProducts()];
      })
    )
  );

  SetProductFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setProductFilters),
      map(() => setFilteredProducts()),
      catchError(() => [
        setAlert({
          alert: { message: translateKey('error_general_text'), alertType: RCAlertType.ERROR },
        }),
      ])
    )
  );

  SetFilteredProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFilteredProducts),
      withLatestFrom(this.store$.select(selectFiltersValues), this.store$.select(selectAllProductsSuccess)),
      map(([action, filters, allProducts]): [Product[], ProductCatalogFilterValues] => [newFilterProducts(allProducts, filters), filters]),
      map(([products, filters]): [Record<TerritoryType | string, Product[]>, number] => [
        formatProductsByCategory(products, filters.pillar),
        products.length,
      ]),
      switchMap(([products, filteredProductLength]) => {
        return [setFilteredProductsSuccess({ filteredProductList: products, filteredProductLength })];
      })
    )
  );

  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private store$: Store<AppState>,
    private trackingService: GTMService
  ) {}
}
