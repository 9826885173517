import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { Product, ProductBack, ProductPriceEdit, ProductPriceEditApi } from '@app/core/models';
import { Market } from '@app/core/models/market';
import { ServicesHelper } from '@app/core/services/utils/services-helper';
import { Constants, Helper } from '@app/shared/utils';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import { ProductPillar } from '@app/shared/utils/enums/pillar.enum';
import { VetFacade } from '@app/store/vet';
import { environment } from '@env/environment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ApiService } from './../api.service';

@Injectable()
export class ProductService extends ApiService implements OnDestroy {
  private destroyed$ = new Subject();
  private _allProducts: Observable<Product[]>;

  constructor(private http: HttpClient, private vetFacade: VetFacade, @Inject(LOCALE_ID) protected locale: string) {
    super();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  fetchProducts(pillars: ProductPillar[], lang: string = this.locale): Observable<Product[]> {
    this._allProducts = this._fetchProducts(lang, pillars).pipe(
      map((products) => Array.isArray(products) && ServicesHelper.filterMapProducts(products)),
      shareReplay(1)
    );
    return this._allProducts;
  }

  fetchProductsByIds(productIds: any[], pillars: ProductPillar[], lang: string = this.locale): Observable<Product[]> {
    return this.fetchProducts(pillars, lang).pipe(
      map((products: Product[]) => products.filter((product) => productIds.includes(product.id)))
    );
  }

  deleteClinicPrices = (): Observable<null> =>
    this.vetFacade.currentClinicId$.pipe(
      switchMap((currentClinicId) => {
        return this.http.delete(this.pathUrl.products(currentClinicId));
      }),
      catchError(this.handleError.bind(this))
    );

  updateClinicPrices = (newPricesList: ProductPriceEdit[] = []): Observable<string | unknown> => {
    const data: ProductPriceEditApi[] = newPricesList.map((item) => ({
      productId: item.productId,
      sCode: item.pack.sCode,
      price: `${item.price}`,
      id: item.pack.id,
    }));
    return this.vetFacade.currentClinicId$.pipe(
      switchMap((currentClinicId) => this.http.put(this.pathUrl.products(currentClinicId), data)),
      catchError(this.handleError.bind(this))
    );
  };

  fetchProductById(productId: string): Observable<any> {
    const parameters = {
      fields: [Constants.ORIGINAL_PRODUCT, '_default_'],
    };
    const params: HttpParams = this.params(parameters);
    return this.vetFacade.currentClinicId$.pipe(
      switchMap((currentClinicId) => {
        return this.http
          .get<ProductBack>(
            environment.isStandalone ? this.pathUrl.productDetailSrs(productId) : this.pathUrl.productDetail(currentClinicId, productId),
            { params }
          )
          .pipe(
            map((productBack) => ServicesHelper.mapProductBackToFront(productBack)),
            catchError(this.handleError.bind(this))
          );
      })
    );
  }

  private _fetchProducts = (lang: string, pillars: ProductPillar[]): Observable<string | ProductBack[]> =>
    combineLatest([this.vetFacade.currentClinicCountry$, this.vetFacade.market$]).pipe(
      filter(([country, market]) => !!country && !!market),
      take(1),
      map(([country, market]) => {
        const catalogCountry = this.getProductCalalogCountry(country, market);
        const parameters = {
          country: catalogCountry.toLowerCase(),
          pillar: pillars,
          language: Helper.catalogLang(lang, market),
        };
        const params = new HttpParams();
        return params.set('where', JSON.stringify(parameters));
      }),
      withLatestFrom(this.vetFacade.currentClinicId$),
      switchMap(([params, clinicId]) =>
        this.http
          .get<ProductBack[]>(environment.isStandalone ? this.pathUrl.productsSrs() : this.pathUrl.products(clinicId), {
            params,
          })
          .pipe(shareReplay(1), catchError(this.handleError.bind(this)))
      )
    );

  /**
   * Get product catalog country
   */
  private getProductCalalogCountry(country: CountryCode | string, market: Market): string {
    const catalogCountry = market.forceCatalogCountry;
    return catalogCountry || country;
  }
}
