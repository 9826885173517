export enum MeasureConversionEnum {
  gramToKilogram = 'gramToKilogram',
  gramToPound = 'gramToPound',
  gramToOunce = 'gramToOunce',
  kilogramToGram = 'kilogramToGram',
  ounceToPound = 'ounceToPound',
  ounceToGram = 'ounceToGram',
  poundToOunce = 'poundToOunce',
  poundToGram = 'poundToGram',
}
