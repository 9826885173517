import { ComponentRef, EmbeddedViewRef, NgZone } from '@angular/core';

import { Observable, Subject, SubscriptionLike, Subscription } from 'rxjs';

import { Portal, PortalOutlet, ComponentPortal, TemplatePortal } from '@app/core/cdk/portal';

import { OverlayConfig } from './overlay-config';

export interface OverlayReference {
  attach: (portal: Portal<any>) => any;
  detach: () => any;
  dispose: () => void;
  overlayElement: HTMLElement;
  hostElement: HTMLElement;
  getConfig: () => any;
  hasAttached: () => boolean;
  updateSize: (config: any) => void;
  updatePosition: () => void;
}

export interface OverlaySizeConfig {
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
}

export type ImmutableObject<T> = {
  readonly [P in keyof T]: T[P];
};

export class OverlayRef implements PortalOutlet, OverlayReference {
  private _previousHostParent: HTMLElement;
  private _backdropElement: HTMLElement | null = null;
  private _backdropClick: Subject<MouseEvent> = new Subject();
  private _attachments = new Subject<void>();
  private _detachments = new Subject<void>();
  private _locationChanges: SubscriptionLike = Subscription.EMPTY;
  private _backdropClickHandler = (event: MouseEvent) => this._backdropClick.next(event);

  constructor(
    private _portalOutlet: PortalOutlet,
    private _host: HTMLElement,
    private _pane: HTMLElement,
    private _config: ImmutableObject<OverlayConfig>,
    private _ngZone: NgZone,
    private _document: Document
  ) {}

  get overlayElement(): HTMLElement {
    return this._pane;
  }

  get hostElement(): HTMLElement {
    return this._host;
  }

  attach<T>(portal: ComponentPortal<T>): ComponentRef<T>;
  attach<T>(portal: TemplatePortal<T>): EmbeddedViewRef<T>;
  attach(portal: any): any;

  attach(portal: Portal<any>): any {
    const attachResult = this._portalOutlet.attach(portal);
    this.hostElement.classList.add('rc-overlay-wrapper');

    if (!this._host.parentElement && this._previousHostParent) {
      this._previousHostParent.appendChild(this._host);
    }

    if (this._config.hasBackdrop) {
      document.body.classList.add('no-scroll');
      this._attachBackdrop();
    }

    if (this._config.panelClass) {
      this._toggleClasses(this._pane, this._config.panelClass, true);
    }

    return attachResult;
  }

  detach(): any {
    // empty
  }

  detachBackdrop(): void {
    const backdropToDetach = this._backdropElement;

    if (!backdropToDetach) {
      return;
    }

    let timeoutId;

    const finishDetach = () => {
      if (backdropToDetach) {
        document.body.classList.remove('no-scroll');
        backdropToDetach.removeEventListener('click', this._backdropClickHandler);
        backdropToDetach.removeEventListener('transitionend', finishDetach);

        if (backdropToDetach.parentNode) {
          backdropToDetach.parentNode.removeChild(backdropToDetach);
        }
      }

      if (this._backdropElement === backdropToDetach) {
        this._backdropElement = null;
      }

      if (this._config.backdropClass) {
        this._toggleClasses(backdropToDetach, this._config.backdropClass, false);
      }

      clearTimeout(timeoutId);
    };

    backdropToDetach.classList.remove('rc-overlay-backdrop--showing');

    this._ngZone.runOutsideAngular(() => {
      backdropToDetach.addEventListener('transitionend', finishDetach);
    });

    backdropToDetach.style.pointerEvents = 'none';

    this._ngZone.runOutsideAngular(() => {
      timeoutId = setTimeout(finishDetach, 500);
    });
  }

  dispose(): void {
    const isAttached = this.hasAttached();

    this.detachBackdrop();
    this._locationChanges.unsubscribe();
    this._portalOutlet.dispose();
    this._attachments.complete();
    this._backdropClick.complete();

    if (this._host && this._host.parentNode) {
      this._host.parentNode.removeChild(this._host);
      this._host = null;
    }

    this._previousHostParent = this._pane = null;

    if (isAttached) {
      this._detachments.next();
    }

    this._detachments.complete();
  }

  getConfig(): OverlayConfig {
    return this._config;
  }

  hasAttached(): boolean {
    return this._portalOutlet.hasAttached();
  }

  updateSize(sizeConfig: OverlaySizeConfig): void {
    this._config = { ...this._config, ...sizeConfig };
  }

  updatePosition(): void {
    // empty
  }

  detachments(): Observable<void> {
    return this._detachments.asObservable();
  }

  private _attachBackdrop() {
    const showingClass = 'rc-overlay-backdrop--showing';
    this._backdropElement = this._document.createElement('div');
    this._backdropElement.classList.add('rc-overlay-backdrop');

    if (this._config.backdropClass) {
      this._toggleClasses(this._backdropElement, this._config.backdropClass, true);
    }

    this._host.parentElement.insertBefore(this._backdropElement, this._host);
    this._backdropElement.addEventListener('click', this._backdropClickHandler);

    if (typeof requestAnimationFrame !== 'undefined') {
      this._ngZone.runOutsideAngular(() => {
        requestAnimationFrame(() => {
          if (this._backdropElement) {
            this._backdropElement.classList.add(showingClass);
          }
        });
      });
    } else {
      this._backdropElement.classList.add(showingClass);
    }
  }

  private _toggleClasses(element: HTMLElement, cssClass: string, isAdd: boolean) {
    const classList = element.classList;
    isAdd ? classList.add(cssClass) : classList.remove(cssClass);
  }
}
