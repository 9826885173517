import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TextToLinkPipe',
})
export class TextToLinkPipe implements PipeTransform {
  transform(value: string, arg: string, link: string): string {
    const _value = value;
    const _arg = arg.replace(/(?=[()])/g, '\\');
    const regex = new RegExp(_arg, 'i');
    value = _value.replace(regex, `<a class="rc-link" href="${link}" rel="noopener" target="_blank">${arg}</a>`);
    return value;
  }
}
