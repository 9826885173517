<ng-container [ngSwitch]="!!editMode">
  <div class="rc-form rc-form--read" *ngSwitchCase="false">
    <ng-container *ngFor="let field of fields">
      <div *ngIf="!field.hiddenRead" class="rc-form-attribute">
        <span class="left rc-form-attribute__key">{{ field.label }}</span>
        <span class="right rc-form-attribute__value" id="rc-form__{{ field.id }}">
          <app-rc-attribute [object]="object" [attribute]="field"> </app-rc-attribute>
        </span>
        <div class="clear"></div>
      </div>
    </ng-container>
  </div>
  <div class="rc-form rc-form--edit" *ngSwitchCase="true">
    <form [formGroup]="formGroup" (submit)="onSubmit($event)">
      <ng-container *ngFor="let field of fields">
        <div class="rc-form-attribute" *ngIf="!field.hiddenEdit">
          <div class="rc-form-attribute__value" [ngSwitch]="field.type">
            <app-rc-input *ngSwitchDefault [formControlName]="field.id" [field]="field" [formControl]="$any(getFormControl(field))">
            </app-rc-input>
          </div>
        </div>
      </ng-container>

      <div class="rc-form__buttons">
        <button id="rc-form-cancel-{{ mode }}" class="rc-button rc-button--primary" (click)="onCancel($event)" type="button">
          <span class="rc-button__title rc-button__title--link" i18n="@@action_cancel">Cancel</span>
        </button>
        <span class="rc-button__separator" i18n="@@action_separator">or</span>
        <button class="rc-button rc-button--filled-small" [disabled]="!formGroup.valid" type="submit">
          <span class="rc-button__title" i18n="@@action_save-changes" id="rc-form__save-changes-button">Save changes</span>
        </button>
      </div>
    </form>
  </div>
</ng-container>
