import { CoreFacade } from '@app/store/core/core.facade';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class StandaloneGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private coreFacade: CoreFacade) {}
  /**
   * Redirects to access denied page if no token can be found
   */
  canActivate(): Observable<boolean> {
    return combineLatest([this.authService.getToken$(), this.authService.loginWithSessionTokenPending$]).pipe(
      filter(([_, pending]) => !pending),
      map(([token]) => {
        if (!token) {
          this.router.navigateByUrl('/access-denied');
          this.coreFacade.toggleLoader(false);
          return false;
        }
        return true;
      })
    );
  }
}
