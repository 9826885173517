<div class="product-list">
  <ng-container *ngFor="let product of products">
    <app-product-edit-card
      class="product-card"
      [ngClass]="'card-' + tool"
      [tool]="tool"
      [product]="product"
      (editedPriceList)="onPriceListChange($event)"
      (showDetailPopinEvent)="showDetailPopin(product)"
    ></app-product-edit-card>
  </ng-container>
</div>
