import { ApiService } from './../api.service';
import { HttpClient } from '@angular/common/http';
import { catchError, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';

@Injectable()
export class BlobService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Function getting blob storage depends in country
   */
  getBlobStorageData(countryCode: string) {
    return this.http
      .get(this.pathUrl.blobStorageData(countryCode || CountryCode.GB))
      .pipe(shareReplay(1), catchError(this.handleError.bind(this)));
  }
}
