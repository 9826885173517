import { NutritionData, Product, ProductPacks, Recommendation, Package } from '@app/core/models';
import { Injectable } from '@angular/core';

@Injectable()
export class CalculatorHelper {
  static getInitialPack(product: Product, productPacks: ProductPacks): Package {
    if (productPacks && productPacks[product.id]) return CalculatorHelper.getProductPack(product, productPacks[product.id].packId);
    else return product.packages[0];
  }

  static getProductPack(product: Product, packId: string): Package {
    return product.packages.find((pack) => packId === pack.sCode);
  }

  static getProductPacksFromRecommendation(recommendation: Recommendation): ProductPacks {
    const productPacks = {};

    recommendation.nutritionData
      .map((nD: NutritionData) => nD.product)
      .forEach((product) => {
        productPacks[product.productDetail.id] = {
          packId: product.productDetail.package.sCode,
        };
      });

    return productPacks;
  }
}
