import { ExitPopinInfo, PetInfo, FollowUpAction, AlertNotification } from '@app/core/models';
import { Tool } from '@app/shared/utils';

/**
 * Order matters
 */
export enum AllowanceStep {
  Ration = 'allowance_ration',
  WeightManagement = 'allowance_weight_management',
  Redirect = 'allowance_redirect',
}

export interface AllowanceParams {
  /**
   * Tool that initiated the allowance module
   * Used for analytics purpose only, not handling scenarios
   */
  tool: Tool;
  /**
   * PetInfo object used accross the app
   */
  deprecatedPetInfo: PetInfo;
  /**
   * followUpAction is only set in case we initiated allowance from a weight management program (follow up);
   * It will be used to override automatically calculated currentProgram.
   */
  followUpAction?: FollowUpAction;
  /**
   * Wether the diagnosis information should be displayed inside the pet summary panel
   */
  displayDiagnosis: boolean;
  /**
   * Wether visit can be saved on exit
   */
  canSaveOnExit: boolean;
  /**
   * Reset when allowance is completed or exited
   */
  resetAfterRedirectStep: boolean;
  /**
   * Create consultation when allowance is completed before redirection
   */
  createConsultationBeforeRedirectStep: boolean;
  /**
   * Where to go when we click on edit patient in the pet summary panel (pet or weight)
   */
  editPatientRoute: string;
  /**
   * Where to go when we click on edit patient in the pet summary panel (pathologies)
   */
  editDiagnosticRoute: string;
  /**
   * Where to go when we click on edit selection in the ration allowance page
   */
  editSelectionRoute: string;
  /**
   * Label for ration primary button
   */
  rationPrimaryActionLabel: string;
  /**
   * Label for ration secondary button
   */
  rationSecondaryActionLabel: string;
  /**
   *
   * Steps to display in the allowance page
   */
  rationSteps: AllowanceStep[];
  /**
   * Route to redirect to at the end of the allowance tool;
   * Default to patient page (tab health or weight management)
   */
  forcedRedirectRoute?: string;
  /**
   * Prefilled ration observation
   */
  rationObservation?: string;
  /**
   * Exit popin labels
   */
  exitPopinLabels: ExitPopinInfo;
  /**
   * Show wet product selection incentive
   */
  showWetProductSelectionIncentive: boolean;
}

export interface AllowanceFormProduct {
  productId: string | null;
  packId: string | null;
  qtyPerDay: number | null;
}

export interface AllowanceFormValue {
  observation: string;
  dry: AllowanceFormProduct;
  wet: AllowanceFormProduct;
}

export interface AllowanceError extends AlertNotification {
  blocking: boolean;
  keyTrad: string;
}
