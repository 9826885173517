import { Injectable } from '@angular/core';
import { Consultation, OrderApiOutput, Patient, Product, ProductPacks, ProductQuantities } from '@app/core/models';
import { ConsultationApiData } from '@app/core/models/consultation-api-data';
import { NutritionService, OrderService, ProductService, VetService } from '@app/core/services/network';
import { Helper, LifestageType, PackType, RxjsHelper, SpeciesCode } from '@app/shared/utils';
import ProductHelper from '@app/shared/utils/static-helpers/product-helper';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { VetFacade } from '@app/store/vet';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ServicesHelper } from './utils/services-helper';

@Injectable()
export class ObservablesLayerService {
  constructor(
    private nutritionService: NutritionService,
    private vetService: VetService,
    private _orderService: OrderService,
    private _vetFacade: VetFacade,
    private productService: ProductService
  ) {}

  lifestage$(
    speciesCode$: Observable<SpeciesCode>,
    breedCode$: Observable<string>,
    dateOfBirth$: Observable<Date>
  ): {
    lifestage$: Observable<LifestageType>;
    lifestageUpdating$: BehaviorSubject<boolean>;
    lifestageErrors$: Subject<string>;
  } {
    const lifestageUpdating$ = new BehaviorSubject<boolean>(false);

    const lifestageErrors$ = new Subject<string>();

    const lifestage$ = combineLatest([speciesCode$, breedCode$, dateOfBirth$]).pipe(
      RxjsHelper.filterCollFull(),
      RxjsHelper.distinctUntilCollElemChanged(),
      tap(() => {
        lifestageUpdating$.next(true);
      }),
      switchMap(([_speciesCode, breedCode, dateOfBirth]) =>
        this.nutritionService.getBreedPetProfile({ breedCode: breedCode as string, dateOfBirth }).pipe(
          map(({ lifestage }) => lifestage),
          catchError((err) => {
            lifestageErrors$.next(err);

            return of(null);
          })
        )
      ),
      tap(() => {
        lifestageUpdating$.next(false);
      }),
      shareReplay(1)
    );

    return { lifestageUpdating$, lifestage$, lifestageErrors$ };
  }

  isOld$(lifestage$: Observable<LifestageType>): Observable<boolean> {
    return lifestage$.pipe(
      filter(Boolean),
      distinctUntilChanged(),
      map((lifestage: LifestageType) => Helper.lifestageOld(lifestage)),
      shareReplay(1)
    );
  }

  fetchPatientAndConsultations$(
    patientId: string
  ): {
    patient$: Observable<Patient>;
    patientLoading$: BehaviorSubject<boolean>;
    patientError$: Subject<string>;
    lastConsultation$: Observable<Consultation>;
    lastConsultationLoading$: BehaviorSubject<boolean>;
    lastConsultationError$: Subject<string>;
    nextConsultation$: Observable<Consultation>;
    nextConsultationLoading$: BehaviorSubject<boolean>;
    nextConsultationError$: Subject<string>;
    consultations$: Observable<ConsultationApiData>;
    consultationsLoading$: BehaviorSubject<boolean>;
    consultationsError$: Subject<string>;
  } {
    const { patient$, patientLoading$, patientError$ } = this._fetchPatient$(patientId);
    const { consultations$, consultationsError$, consultationsLoading$ } = this._fetchConsultations$(patientId);
    const { lastConsultation$, lastConsultationError$, lastConsultationLoading$ } = this._fetchLastConsultations$(consultations$);
    const { nextConsultation$, nextConsultationError$, nextConsultationLoading$ } = this._fetchNextConsultation$(lastConsultation$);

    return {
      patient$,
      patientError$,
      patientLoading$,
      consultations$,
      consultationsError$,
      consultationsLoading$,
      lastConsultation$,
      lastConsultationError$,
      lastConsultationLoading$,
      nextConsultation$,
      nextConsultationError$,
      nextConsultationLoading$,
    };
  }

  // If patient is null, fetch it from vet service
  private _fetchPatient$(
    patientId: string
  ): {
    patient$: Observable<Patient>;
    patientLoading$: BehaviorSubject<boolean>;
    patientError$: Subject<string>;
  } {
    const patientError$ = new Subject<string>();
    const patientLoading$ = new BehaviorSubject(false);
    patientLoading$.next(true);
    const patient$ = this.vetService.fetchPatient(patientId).pipe(
      catchError((error) => {
        patientError$.next(error);
        return of(null);
      }),
      tap(() => {
        patientLoading$.next(false);
      }),
      shareReplay(1)
    );
    return {
      patient$,
      patientLoading$,
      patientError$,
    };
  }

  // Fetch consultations
  private _fetchConsultations$(
    patientId: string
  ): {
    consultations$: Observable<ConsultationApiData>;
    consultationsLoading$: BehaviorSubject<boolean>;
    consultationsError$: Subject<string>;
  } {
    const consultationsError$ = new Subject<string>();
    const consultationsLoading$ = new BehaviorSubject(true);

    const consultations$ = this.vetService.consultationsByPatient(patientId).pipe(
      catchError((error) => {
        consultationsError$.next(error);
        return of(null);
      }),
      tap(() => {
        consultationsLoading$.next(false);
      }),
      shareReplay(1)
    );

    return {
      consultations$,
      consultationsError$,
      consultationsLoading$,
    };
  }

  // Fetch consultations
  private _fetchLastConsultations$(
    consultations$: Observable<ConsultationApiData>
  ): {
    lastConsultation$: Observable<Consultation>;
    lastConsultationLoading$: BehaviorSubject<boolean>;
    lastConsultationError$: Subject<string>;
    lastConsultationEnd$: Subject<boolean>;
  } {
    const lastConsultationError$ = new Subject<string>();
    const lastConsultationLoading$ = new BehaviorSubject(false);
    const lastConsultationEnd$ = new Subject<boolean>();

    lastConsultationLoading$.next(true);
    const lastConsultation$ = consultations$.pipe(
      map((data) => {
        if (data && data.result && data.result.length > 0) {
          return data.result[0];
        }
        lastConsultationError$.next('_fetchLastConsultations$: Impossible to get lastConsultation from consultations$');
        return null;
      }),
      catchError((error) => {
        lastConsultationError$.next(error);
        return of(null);
      }),
      tap(() => {
        lastConsultationLoading$.next(false);
        lastConsultationEnd$.next(true);
        lastConsultationEnd$.complete();
      }),
      shareReplay(1)
    );

    return {
      lastConsultation$,
      lastConsultationError$,
      lastConsultationLoading$,
      lastConsultationEnd$,
    };
  }

  // Get called with the lastConsultation, which may or may not be complete with "next consultation" information.
  // If not, fetch them
  private _fetchNextConsultation$(
    lastConsultation$: Observable<Consultation>
  ): {
    nextConsultation$: Observable<Consultation>;
    nextConsultationLoading$: BehaviorSubject<boolean>;
    nextConsultationError$: Subject<string>;
    nextConsultationEnd$: Subject<boolean>;
  } {
    const nextConsultationError$ = new Subject<string>();
    const nextConsultationLoading$ = new BehaviorSubject(false);
    const nextConsultationEnd$ = new Subject<boolean>();

    const nextConsultation$ = lastConsultation$.pipe(
      mergeMap((lastConsultation) => {
        if (!lastConsultation) {
          nextConsultationError$.next('_fetchNextConsultation$: lastConsultation is not supposed to be null.');
          nextConsultationEnd$.next(true);
          nextConsultationEnd$.complete();
          return of(null);
        }
        if (lastConsultation.nextVisit) {
          nextConsultationEnd$.next(true);
          nextConsultationEnd$.complete();
          return of(lastConsultation);
        } else {
          nextConsultationLoading$.next(true);
          return this.vetService.fetchConsultationById(lastConsultation.patientId, lastConsultation.id).pipe(
            catchError((error) => {
              nextConsultationError$.next(error);
              return of(null);
            }),
            tap(() => {
              nextConsultationLoading$.next(false);
              nextConsultationEnd$.next(true);
              nextConsultationEnd$.complete();
            })
          );
        }
      }),
      shareReplay(1)
    );

    return {
      nextConsultation$,
      nextConsultationError$,
      nextConsultationLoading$,
      nextConsultationEnd$,
    };
  }

  fetchOrder(
    orderId: string,
    isRenewal: boolean
  ): {
    order$: Observable<OrderApiOutput>;
    products$: Observable<Product[]>;
    productQuantities$: Observable<ProductQuantities>;
    productPacks$: Observable<ProductPacks>;
    patient$: Observable<Patient>;
    consultation$: Observable<Consultation>;
    errors$: Subject<string>;
    orderLoading$: BehaviorSubject<boolean>;
    productsLoading$: BehaviorSubject<boolean>;
    patientLoading$: BehaviorSubject<boolean>;
    consultationLoading$: BehaviorSubject<boolean>;
  } {
    const errors$ = new Subject<string>();
    const orderLoading$ = new BehaviorSubject(true);
    const productsLoading$ = new BehaviorSubject(true);
    const patientLoading$ = new BehaviorSubject(false);
    const consultationLoading$ = new BehaviorSubject(false);

    const order$: Observable<OrderApiOutput> = this._vetFacade.currentClinicId$.pipe(
      mergeMap((clinicId) => this._orderService.getOrderById(clinicId, orderId)),
      tap((_) => {
        orderLoading$.next(false);
      }),
      catchError(() => {
        errors$.next(translateKey('error_impossible_load_order'));
        return of(null);
      }),
      shareReplay(1)
    );

    let starterKit = false;

    const patient$: Observable<Patient> = order$.pipe(
      tap(() => {
        patientLoading$.next(true);
      }),
      mergeMap((order) => {
        if (!order.contactId) {
          return throwError('No contactId in the order');
        }
        return this.vetService.fetchPatient(order.contactId);
      }),
      tap((patient: Patient) => {
        if (!patient.owner) {
          return of(null);
        }
      }),
      catchError(() => {
        errors$.next(translateKey('error_try-again-later'));
        return of(null);
      }),
      tap(() => {
        patientLoading$.next(false);
      }),
      shareReplay(1)
    );

    const consultation$: Observable<Consultation> = order$.pipe(
      tap(() => {
        consultationLoading$.next(true);
      }),
      mergeMap((order) => {
        if (!order?.petOwner) {
          return of(null);
        } else {
          if (!order.contactId || !order.consultationId) {
            return throwError('No contactId or consultationId in the order');
          }
          return this.vetService.fetchConsultationById(order.contactId, order.consultationId);
        }
      }),
      catchError(() => {
        errors$.next(translateKey('error_try-again-later'));
        return of(null);
      }),
      tap(() => {
        consultationLoading$.next(false);
      }),
      shareReplay(1)
    );

    const products$ = combineLatest([order$, consultation$]).pipe(
      take(1),
      switchMap(([order, consultation]) => {
        starterKit = !order?.petOwner;
        if (starterKit) {
          return order ? of(order.orderLines.map((orderLine) => ServicesHelper.mapProductBackToFront(orderLine.product))) : of([]);
        } else {
          const nutritionData = consultation?.visit?.recommendation?.nutritionData;
          if (nutritionData.length && !nutritionData[0].product?.energyCategory) {
            return throwError('No energy category in the recommendation');
          }
          // In case of a renewal, we need to make sure to fetch product from API
          // to collect up-to-date clinic prices
          const products =
            nutritionData.map((nutriProduct) => ProductHelper.formatNutritionDataProductIntoProduct(nutriProduct.product)) || [];
          return isRenewal && products[0]?.id
            ? this.productService.fetchProductById(products[0].id).pipe(map((product) => [product]))
            : of(products);
        }
      }),
      map((products) => {
        return this._mapFilterProductStarterKit(products, starterKit);
      }),
      tap((_) => {
        productsLoading$.next(false);
      }),
      catchError((err) => {
        errors$.next(err);
        return of(null);
      }),
      shareReplay(1)
    );

    const productQuantities$: Observable<ProductQuantities> = combineLatest([order$, products$]).pipe(
      map(([order, products]) => {
        if (!order || !products) {
          return throwError('No order or products');
        }
        return order.orderLines.reduce((productQuantities, orderLine) => {
          const product = products.find((p) => !!p.packages.find((pack) => pack.ean === orderLine.ean));
          if (product) {
            productQuantities[product.id] = orderLine.units;
          }
          return productQuantities;
        }, {});
      }),
      catchError((err) => {
        console.error(err);
        return of({});
      }),
      shareReplay(1)
    );

    const productPacks$: Observable<ProductPacks> = combineLatest([order$, products$]).pipe(
      map(
        ([order, products]): ProductPacks => {
          return order.orderLines.reduce((productPacks: ProductPacks, orderLine): ProductPacks => {
            const product = products?.find((p) => !!p.packages.find((pack) => pack.ean === orderLine.ean));
            if (product) {
              const productPack = product.packages.find((pack) => pack.ean === orderLine.ean);
              productPacks[product.id] = { packId: productPack.sCode };
            }
            return productPacks;
          }, {});
        }
      ),
      shareReplay(1)
    );

    return {
      order$,
      products$,
      patient$,
      consultation$,
      productQuantities$,
      productPacks$,
      errors$,
      orderLoading$,
      productsLoading$,
      patientLoading$,
      consultationLoading$,
    };
  }

  private _mapFilterProductStarterKit(products: Product[], starterKit: boolean): Product[] {
    return products
      .map((product) => {
        return {
          ...product,
          packages: product.packages?.filter((pack) => {
            return starterKit ? pack.type === PackType.StarterKit : pack.type !== PackType.StarterKit;
          }),
        };
      })
      .filter((product: Product) => {
        return product.packages.length > 0;
      });
  }
}
