export enum Tool {
  WeightManagement = 'weight_management',
  Rationing = 'rationing',
  SmartReco = 'smart_reco',
  Multifunction = 'multi_function',
  StarterKit = 'starter-kit',
  PersonalizedBag = 'personalized-bag',
  RenalDetect = 'renal_detect',
  CreatePet = 'create-pet',
}
