<div class="container">
  <ng-container *appLet="vat$ | async as vat">
    <div class="vat-excl">
      <p class="label">
        {{ 'rc-table_b2b-price-total' | translateKey }} <span>{{ 'rc-table_b2b-price-vat-excl' | translateKey }}</span>
      </p>
      <p class="total">{{ wholesalePricesTotal | currency: (clinicCurrency$ | async) }}</p>
    </div>
    <div class="vat">
      <p class="label">{{ 'rc-table_b2b-price-vat' | translateKey }} {{ vat }}%</p>
      <p class="total">{{ getTVA(wholesalePricesTotal, vat) | currency: (clinicCurrency$ | async) }}</p>
    </div>
    <div class="vat-incl">
      <p class="label">
        {{ 'rc-table_b2b-price-total' | translateKey }} <span>{{ 'rc-table_b2b-price-vat-incl' | translateKey }}</span>
      </p>
      <p class="total">
        {{ getWholesalePriceTotalWithTva(wholesalePricesTotal, vat) | currency: (clinicCurrency$ | async) }}
      </p>
    </div>
  </ng-container>
</div>
