import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GTMService, productSearchClick } from '@app/core/services/tracking';
import { rangeCategory } from '@app/shared/data/range-filters-content';
import { territories } from '@app/shared/data/territories-filters-content';
import { BreedSize, ProductPillar, SpeciesCode } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { AppState } from '@app/store';
import { setProductFilters } from '@app/store/products';
import { ProductsFacade } from '@app/store/products/products.facade';
import { selectFiltersValues } from '@app/store/products/products.selectors';
import { VetFacade } from '@app/store/vet';
import { Store } from '@ngrx/store';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { getPillarItems, getSizeItems, getSpeciesItems, ProductCatalogFilterValues } from './product-catalog-filters';

@Component({
  selector: 'app-product-catalog-filters',
  styleUrls: ['product-catalog-filters.component.scss'],
  templateUrl: './product-catalog-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCatalogFiltersComponent implements OnInit, OnDestroy {
  @Input() initialValues: ProductCatalogFilterValues | null = null;
  @Input() insidePopin = false;
  @Input() panelOpen = false;
  @Input() isFixed = false;
  @Input() specificSpeciesCode: SpeciesCode = SpeciesCode.Unknown;
  @Input() enablePillarSPT = false;

  @Output() submitted = new EventEmitter<{ openFirstCategory: boolean; closeSelectedCategory: boolean }>();

  public ProductPillar = ProductPillar;
  public SpeciesCode = SpeciesCode;
  public IconName = IconName;
  public pillarItems = getPillarItems();
  public sizeItems = getSizeItems();
  public specieItems = [];
  public specieItemsWithoutLabels = [];

  public moreFiltersOpened$ = new BehaviorSubject(false);
  public form: IFormGroup<ProductCatalogFilterValues>;

  territories = territories;
  rangeCategory = rangeCategory;

  public currentPillar$ = this.productsFacade.currentPillar$;
  public shouldShowPillar$ = this.vetFacade.enableSptRetail$.pipe(map((enableSptRetail) => !!(enableSptRetail && this.enablePillarSPT)));

  private _destroyed$ = new Subject();

  constructor(
    private store$: Store<AppState>,
    private trackingService: GTMService,
    private productsFacade: ProductsFacade,
    private vetFacade: VetFacade
  ) {}

  ngOnInit(): void {
    this.setupForm();
    this.specieItems = getSpeciesItems(this.specificSpeciesCode);
    this.specieItemsWithoutLabels = this.specieItems.map((item) => {
      return {
        icon: item.icon,
        value: item.value,
        disabled: item.disabled,
      };
    });
    this.setDisableSizeField();
    if (!this.insidePopin) this.triggerSearchOnPillarUpdate();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  setupForm(): void {
    const formBuilder: IFormBuilder = new FormBuilder();
    this.store$
      .select(selectFiltersValues)
      .pipe(
        first(),
        map((filtersValues) => {
          this.form = formBuilder.group<ProductCatalogFilterValues>({
            pillar: [filtersValues?.pillar],
            specie: [filtersValues?.specie],
            search: [filtersValues?.search],
            dry: [filtersValues?.dry],
            wet: [filtersValues?.wet],
            birthAndGrowth: [filtersValues?.birthAndGrowth],
            mature: [filtersValues?.mature],
            adult: [filtersValues?.adult],
            size: [filtersValues?.size],
          });
        })
      )
      .subscribe();
  }

  toggleMoreFilters(): void {
    this.moreFiltersOpened$.next(!this.moreFiltersOpened$.value);
  }

  resetFilters(): void {
    this.form.reset({
      pillar: ProductPillar.VET,
      specie: this.specificSpeciesCode || SpeciesCode.Dog,
      size: BreedSize.All,
      search: '',
      dry: false,
      wet: false,
      birthAndGrowth: false,
      mature: false,
      adult: false,
    });
    this.submit();
  }

  /*
    Disable submit button
    in desktop if is all form values are pristine but ignore pillar
    in popin if all form values are pristine, including pillar (= form is pristine)
  */
  isSubmitDisabled(): boolean {
    return this.insidePopin
      ? this.form.pristine
      : this.form.controls.adult.pristine &&
          this.form.controls.birthAndGrowth.pristine &&
          this.form.controls.dry.pristine &&
          this.form.controls.search.pristine &&
          this.form.controls.mature.pristine &&
          this.form.controls.adult.pristine &&
          this.form.controls.specie.pristine &&
          this.form.controls.size.pristine;
  }

  /*
    By default the RcSelect component will sort items alphabetically, we want to prevent this behaviour
  */
  customSelectSort(): number {
    return 1;
  }

  submit(shouldOpenFirstCategory = true, shouldCloseAllCategories = false): void {
    if (this.form.valid) {
      const values = this.form.getRawValue();
      this.moreFiltersOpened$.next(false);
      this.trackingService.sendInteraction(productSearchClick(values));
      this.productsFacade.setProductFilters(values);
      this.submitted.emit({ openFirstCategory: shouldOpenFirstCategory, closeSelectedCategory: shouldCloseAllCategories });
    }
  }

  resetSearchByProductName(): void {
    this.form.patchValue({ search: '' });
    this.submit();
  }

  private setDisableSizeField(): void {
    this.store$
      .select(selectFiltersValues)
      .pipe(
        first(),
        switchMap((filtersValues) =>
          this.form?.controls?.specie?.valueChanges.pipe(
            startWith(filtersValues?.specie),
            tap((specieField) => {
              specieField === SpeciesCode.Cat ? this.form.controls.size.disable() : this.form.controls.size.enable();
            })
          )
        ),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  private triggerSearchOnPillarUpdate(): void {
    this.form?.controls?.pillar?.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        tap(() => {
          this.submit(false, true);
        })
      )
      .subscribe();
  }
}
