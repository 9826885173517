<ng-container *ngIf="summaryData && summaryData.next.weight">
  <div *ngIf="program" class="wm-summary-progam">
    <h3>
      {{ programTitle }}
    </h3>
    <p class="wm-summary-progam__description">
      {{ programDesc }}
    </p>
  </div>
  <app-rc-tabs [tabs]="weightsTabs" [tabId]="weightsTab" class="wm-summary__tabs" (selectedTab)="selectedTab($event)"> </app-rc-tabs>
  <div class="wm-summary-weights">
    <div class="wm-summary-weight wm-summary-weight__today" [class.active]="weightsTab === 'today'">
      <div class="wm-summary-weight__weight wm-summary-weight__weight--today">
        {{ summaryData.today.weight }}
      </div>
      <div class="wm-summary-weight__evolution"></div>
      <div class="wm-summary-weight__desc" i18n="@@wm-summary_last-visit-label">Last Visit</div>
      <div class="wm-summary-weight__date clickable">
        <div class="wm-summary-weight__date-link" (click)="showModalUpdateDateVisit($event, weightsTabs[0].id)">
          <em class="rc-icon rc-icon--calendar date_icon"></em>
          <a class="rc-link-footer rc-link--footer">{{ summaryData.today.date }}</a>
        </div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="wm-summary-weight wm-summary-weight__next" [class.active]="weightsTab === 'next'">
      <div class="wm-summary-weight__weight wm-summary-weight__weight--next wm-summary-weight__{{ nextVisitColor }}">
        {{ summaryData.next.weight }}
      </div>
      <div class="wm-summary-weight__evolution">
        <ng-container *ngIf="weightIndicatorArrowClass">
          <span class="wm-summary-weight__{{ nextVisitColor }}">
            <div class="rc-icon {{ weightIndicatorArrowClass }}"></div>
            {{ weightDifferencePercentage }}
          </span>
        </ng-container>
        <em class="rc-icon rc-icon--alert wm-summary-weight__icon">
          <app-rc-tooltip secondaryPosition="right" size="medium">
            <div main>
              <div class="rc-icon rc-icon--alert"></div>
            </div>
            <div tooltip class="wm-summary-weight__card-bubble">
              <ng-container i18n="@@wm-summary_info-reco">Our recommendation for safe weight loss is:</ng-container>
              <br /><br />

              <strong>
                <ng-container i18n="@@wm-summary_info-dog">For dogs:</ng-container>
              </strong>
              <br /><br />
              <ng-container i18n="@@wm-summary_info-dog-body-weight">Between 1 and 3% of bodyweight per week</ng-container>
              <br /><br />

              <strong>
                <ng-container i18n="@@wm-summary_info-cat">For cats:</ng-container>
              </strong>
              <br /><br />
              <ng-container i18n="@@wm-summary_info-cat-body-weight">Between 0.5% and 2% of bodyweight per week</ng-container>
              <br /><br />

              <strong>
                <ng-container i18n="@@wm-summary_info-goal">Goal weight at next visit is calculated using an average value.</ng-container>
              </strong>
            </div>
          </app-rc-tooltip>
        </em>
      </div>
      <div class="wm-summary-weight__desc" i18n="@@wm-summary_next-visit-label">Next visit</div>
      <div class="wm-summary-weight__date clickable">
        <div class="wm-summary-weight__date-link" (click)="showModalUpdateDateVisit($event, weightsTabs[1].id)">
          <em class="rc-icon rc-icon--calendar date_icon"> </em>
          <a class="rc-link-footer rc-link--footer">{{ summaryData.next.date }}</a>
        </div>
      </div>
      <div class="clear"></div>
    </div>
    <ng-container *ngIf="summaryData.goal && summaryData.goal.weight">
      <div class="wm-summary-weight wm-summary-weight__goal" [class.active]="weightsTab === 'goal'">
        <div class="wm-summary-weight__weight wm-summary-weight__weight--goal wm-summary-weight__green">
          {{ summaryData.goal.weight }}
        </div>
        <div class="wm-summary-weight__evolution"></div>
        <div class="wm-summary-weight__desc" i18n="@@wm-summary_goal-label">Goal</div>
        <div class="wm-summary-weight__date">
          <em class="rc-icon rc-icon--calendar date_icon"></em>
          <a class="rc-link-footer rc-link--footer noPointer font-regular">{{ summaryData.goal.date }}</a>
        </div>
        <div class="clear"></div>
      </div>
    </ng-container>
  </div>
</ng-container>

<div class="wm-summary-chart">
  <app-rc-chart *ngIf="chartData && chartType" [chartType]="chartType" [chartData]="chartData"></app-rc-chart>
</div>
