import { NutritionService } from '@app/core/services/network';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CiamUser,
  Clinic,
  Consultation,
  Market,
  Patient,
  PatientSearch,
  PatientSearchFilters,
  Pet,
  PetOwner,
  PetOwnerSearch,
  Term,
  Vet,
  VetUser,
  VetWithInvitation,
  VetWithoutUser,
} from '@app/core/models';
import { ConsultationApiData } from '@app/core/models/consultation-api-data';
import { Constants, LifestageType, MeasureHelper, SpeciesCode, VetPreferences } from '@app/shared/utils';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';
import { formatConsultation } from '@app/shared/utils/static-helpers/consultation-helper';
import { Helper } from '@app/shared/utils/static-helpers/helper';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { VetFacade } from '@app/store/vet/vet.facade';
import { Observable, of, zip } from 'rxjs';
import { catchError, concatMap, map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { patientsFullPetFilter } from '../../utils/search-filters-helper';
import { ApiService } from './../api.service';
import { CoreFacade } from '@app/store/core/core.facade';
import { RCAlertType } from '@rc/ui';
import { buildPatientApiBody, formatPatientSearch } from '@app/shared/utils/static-helpers/patient-helper';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import { buildTermsApiBody } from '@app/shared/utils/static-helpers/vet-helper';
import { ConsultationWeight } from '@app/core/models/consultation-weight.js';

@Injectable()
export class VetService extends ApiService {
  constructor(
    private http: HttpClient,
    private _vetFacade: VetFacade,
    private nutritionService: NutritionService,
    private coreFacade: CoreFacade
  ) {
    super();
  }

  /**
   * Set deprecated vet preferences until we get rid of it
   */
  deprecatedSetVetPreference(vet: Vet, currentClinic: Clinic) {
    if (vet && typeof vet !== 'string') {
      VetPreferences.id = vet.id;
      VetPreferences.updateUnitSystemPreference(vet.systemPreference);
      VetPreferences.loaded = true;
      if (vet.user) {
        VetPreferences.lang = vet.user.preferredLanguage as LanguageCode;
      }
      if (vet.clinics && vet.clinics.length > 0) {
        VetPreferences.country = currentClinic.companyAddress.country;
      }
      VetPreferences.maxPetWeight = MeasureHelper.weightToLocalizedValue(Constants.LIMIT_INPUT_WEIGHT);
    }
  }

  /*** PATIENT METHODS ***/

  /**
   * [POST] - Create patient
   * Used in CreatePatientPopin
   * @param pet: Pet
   * @param owner: PetOwner
   * @param consultation: Consultation
   */
  createPatient(pet: Pet, owner: PetOwner, consultation: Partial<Consultation>): Observable<Patient> {
    const newPatient: Patient = buildPatientApiBody(pet, owner, this._vetFacade.clinicId, this.userUuid());
    const body = {
      ...newPatient,
      consultation: {
        /**
         * With new allowance tool vetId is already set inside consultation object, this is for compatibility with legacy flows
         */
        ...consultation,
        vetId: this.userUuid(),
      },
    };
    // if pet-owner exist
    if (owner.id) {
      return this.http.post<Patient>(this.pathUrl.bff_patient, body).pipe(
        map((patient) => ({
          ...patient,
          consultation: formatConsultation(patient.consultation),
          owner, // TODO : remove when API is returning a Pet Owner as contact
        })),
        catchError(this.handleError.bind(this))
      );
    } else {
      // if pet-owner not exist create it first
      owner.organizationId = this._vetFacade.clinicId;
      return this.http.post<{ _id: string }>(this.pathUrl.contacts(this._vetFacade.clinicId), owner).pipe(
        mergeMap(({ _id }) => this.apiGetPetOwner(_id)),
        mergeMap((petOwner: PetOwner) => {
          const patientInputApi = {
            ...body,
            contactId: petOwner.id,
          };
          return this.http.post<Patient>(this.pathUrl.bff_patient, patientInputApi).pipe(
            map((patient) => ({
              ...patient,
              consultation: formatConsultation(patient.consultation),
              owner: petOwner, // TODO : remove when API is returning a Pet Owner as contact
            })),
            catchError(this.handleError.bind(this))
          );
        }),
        catchError(this.handleError.bind(this))
      );
    }
  }

  createPatientWithoutConsultation(pet: Pet, owner: PetOwner): Observable<Patient> {
    const newPatient: Patient = buildPatientApiBody(pet, owner, this._vetFacade.clinicId, this.userUuid());
    // if pet-owner exist
    if (owner.id) {
      return this.http.post<Patient>(this.pathUrl.patients(this._vetFacade.clinicId), newPatient).pipe(
        map((patient) => ({
          ...patient,
          owner, // TODO : remove when API is returning a Pet Owner as contact
        })),
        catchError(this.handleError.bind(this))
      );
    } else {
      // if pet-owner not exist create it first
      owner.organizationId = this._vetFacade.clinicId;
      return this.http.post<{ _id: string }>(this.pathUrl.contacts(this._vetFacade.clinicId), owner).pipe(
        mergeMap(({ _id }) => this.apiGetPetOwner(_id)),
        mergeMap((petOwner: PetOwner) => {
          const patientInputApi = {
            ...newPatient,
            contactId: petOwner.id,
          };
          return this.http.post<Patient>(this.pathUrl.patients(this._vetFacade.clinicId), patientInputApi).pipe(
            map((patient) => ({
              ...patient,
              owner: petOwner, // TODO : remove when API is returning a Pet Owner as contact
            })),
            catchError(this.handleError.bind(this))
          );
        }),
        catchError(this.handleError.bind(this))
      );
    }
  }

  createOwner(owner: PetOwner): Observable<PetOwner> {
    return this.http
      .post<{ _id: string }>(this.pathUrl.contacts(this._vetFacade.clinicId), this.unFormatPetOwner(owner, this._vetFacade.clinicId))
      .pipe(
        mergeMap(({ _id }) => this.apiGetPetOwner(_id)),
        catchError(this.handleError.bind(this))
      );
  }

  deletePatient(patient: Patient): Observable<any> {
    return this.http.delete(this.pathUrl.patient(this._vetFacade.clinicId, patient.id)).pipe(catchError(this.handleError.bind(this)));
  }

  deletePetOwner(clinicId: string, petOwnerId: string): Observable<any> {
    return this.http.delete(this.pathUrl.bff_petOwner(clinicId, petOwnerId)).pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * [GET] - One patient
   * TODO use pathUrl.patient API to call a specific patient instead of using search API
   */
  fetchPatient(patientId: string): Observable<Patient> {
    const filterApi = {
      include: ['pet'],
      where: { id: patientId },
    };

    return this.deprecatedClinicPatients(filterApi, true).pipe(
      map((patients) => {
        if (patients && patients.length === 1) {
          return patients[0];
        }
        return null;
      })
    );
  }

  fetchPatientInformationForNewConsultation(patientId: string): Observable<{ patient: Patient; lastConsultation: Consultation }> {
    return zip(this.fetchPatient(patientId), this.lastConsultationByPatient(patientId)).pipe(
      concatMap(([patient, lastConsultation]) =>
        this.nutritionService
          .getBreedPetProfile({
            breedCode: patient?.pet?.breedCode,
            dateOfBirth: patient?.pet?.birth?.date,
          })
          .pipe(
            map(({ lifestage }: { lifestage: LifestageType }) => {
              return {
                patient: {
                  ...patient,
                  pet: { ...patient.pet, lifeStage: lifestage },
                  owner: patient?.owner,
                },
                lastConsultation,
              };
            })
          )
      )
    );
  }

  /**
   * [PUT] - Update one Pet
   * @param petId: pet - unique ID
   * @param data: Pet - pet's data to update
   */
  updatePet(petId: string, pet: Pet): Observable<Pet> {
    const formattedData = buildPatientApiBody(pet);
    return this.http
      .put<Pet>(this.pathUrl.pet(this._vetFacade.clinicId, petId), formattedData.pet)
      .pipe(catchError(this.handleError.bind(this)));
  }

  searchPatients<P extends PatientSearch>(filters: PatientSearchFilters): Observable<P[]> {
    let params = new HttpParams();
    if (filters.term && filters.term.length > 0) params = params.set('term', filters.term);
    if (filters.loadLastConsultation) params = params.set('loadLastConsultation', JSON.stringify(filters.loadLastConsultation));
    if (filters.searchBy) params = params.set('searchBy', filters.searchBy);
    if (filters.speciesCode && filters.speciesCode != SpeciesCode.Unknown) params = params.set('speciesCode', filters.speciesCode);

    return this.http
      .get(this.pathUrl.search(this._vetFacade.clinicId), {
        params,
      })
      .pipe(
        map((patients: any[]) => {
          return patients.map((patient) => formatPatientSearch(patient));
        }),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * [GET] - All clinic's patients
   */
  deprecatedClinicPatients<P extends Patient>(filterApi, includeOwners: false): Observable<P[]>;
  deprecatedClinicPatients<P extends Patient>(filterApi, includeOwners: true): Observable<(P & { owner: PetOwner })[]>;
  deprecatedClinicPatients<P extends Patient>(filterApi, includeOwners = false): Observable<P | (P & { owner: PetOwner })[]> {
    const params = new HttpParams().set(
      'filter',
      JSON.stringify({
        ...filterApi,
      })
    );

    return this.http
      .get<P[]>(this.pathUrl.patients(this._vetFacade.clinicId), {
        params,
      })
      .pipe(
        map((patients) => patients.filter((patient) => patient.contactId)),
        mergeMap((patients) => {
          if (!includeOwners || patients.length === 0) {
            return of(patients);
          }
          const contactIds = Helper.arrayRemoveDoubles(patients.map((p) => p.contactId));
          return this.searchPetOwners(contactIds).pipe(
            map((contacts) =>
              patients
                .map((patient) => ({
                  ...patient,
                  owner: contacts.filter((c) => !!c).find((c) => c.id === patient.contactId),
                }))
                .filter((patient: P & { owner: PetOwner }) => patient.owner)
            )
          );
        }),
        catchError(this.handleError.bind(this))
      );
  }

  apiContactsByEmail(email: string): Observable<PetOwner[]> {
    const params = new HttpParams().set(
      'q',
      JSON.stringify({
        email,
      })
    );

    return this.http.get(this.pathUrl.contacts(this._vetFacade.clinicId), { params }).pipe(
      map((owners: any[]) => owners.map((owner) => this.formatPetOwner(owner))),
      catchError(this.handleError.bind(this))
    );
  }

  /**
   * Get all pets from specific owner
   * TODO : backend should create a dedicated API
   */
  patientsByContactId(contactId: string): Observable<Patient[]> {
    return this.deprecatedClinicPatients(patientsFullPetFilter(contactId), false);
  }

  /*** CONSULTATION METHODS ***/

  /**
   * [POST] - Create consultation
   * @param patient - Patient object
   * @param consultation - Consultation object
   */
  createConsultation(patient: Patient, consultation: Partial<Consultation>): Observable<Consultation> {
    const url = this.pathUrl.consultations(this._vetFacade.clinicId, patient.id);
    /**
     * vetId added for compatibility with legacy tools, can be deleted after full ngrx migration
     */
    return this.http
      .post<any>(url, { ...consultation, vetId: Helper.userUuid() })
      .pipe(
        map((consultation) => formatConsultation(consultation)),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * [PUT] - Update consultation
   * @param patientId - Patient id attribute
   * @param consultationId - Consultation id attribute
   * @param data - Consultation object data to update
   */
  updateConsultationVisits(data: any, patientId: string, consultationId: string): Observable<Consultation> {
    const url = this.pathUrl.updateConsultationVisits(this._vetFacade.clinicId, patientId, consultationId);
    return this.http.put<Consultation>(url, data).pipe(
      map((consultation) => formatConsultation(consultation)),
      catchError(this.handleError.bind(this))
    );
  }

  /**
   * [GET] - Fetch all consultation from the patient id, with limit
   * @param patientId - Patient unique ID
   * @param apiFilters - Loopback API filters
   */
  consultationsWithLimit(patientId: string, apiFilters?: any): Observable<ConsultationApiData> {
    let params = new HttpParams();
    for (const key in apiFilters) {
      const value = Array.isArray(apiFilters[key]) ? JSON.stringify(apiFilters[key]) : apiFilters[key];
      params = params.set(key, value);
    }
    return this.http
      .get<ConsultationApiData[]>(this.pathUrl.consultations(this._vetFacade.clinicId, patientId), { params, observe: 'response' })
      .pipe(
        map((data) => ({
          count: data.headers.get('totalitems'),
          result: data.body.map((consultation) => formatConsultation(consultation)),
        })),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * [GET] - Fetch all consultation from the patient id
   * @param patientId - Patient unique ID
   * @param apiFilters - Loopback API filters
   */
  consultationsWithoutLimit(patientId: string, apiFilters?: any): Observable<Consultation[]> {
    let params = new HttpParams();
    for (const key in apiFilters) {
      const value = Array.isArray(apiFilters[key]) ? JSON.stringify(apiFilters[key]) : apiFilters[key];
      params = params.set(key, value);
    }

    return this.http
      .get<Consultation[]>(this.pathUrl.consultations(this._vetFacade.clinicId, patientId), { params })
      .pipe(
        map((consultations) => consultations.map((consultation) => formatConsultation(consultation))),
        catchError(this.handleError.bind(this))
      );
  }

  consultationsWeights(patiendId: string): Observable<ConsultationWeight[]> {
    return this.http
      .get<ConsultationWeight[]>(this.pathUrl.weight(this._vetFacade.clinicId, patiendId))
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * [GET]
   * Fetch consultations including Visit which includes Recommendation + Weight
   * Used in Pet-Page
   * @param patientId: string
   * @param skip: number
   */
  consultationsByPatient(patientId: string, skip = 0): Observable<ConsultationApiData> {
    const paramFilters = {
      populate: ['visit', 'visit.recommendation', 'visit.weight'],
      skip: skip,
      limit: Constants.LIMIT_CONSULTATIONS_PAGE,
      order: 'date DESC',
    };

    return this.consultationsWithLimit(patientId, paramFilters);
  }

  consultationsByPatientWithoutVisit(patientId: string): Observable<Consultation[]> {
    const paramFilters = {
      order: 'date DESC',
    };
    return this.consultationsWithoutLimit(patientId, paramFilters);
  }

  /**
   * [GET] - Fetch consultations including Visit which includes Recommendation + Weight
   * Used in Pet-Page
   * @param patientId: string
   */
  lastConsultationByPatient(patientId: string): Observable<Consultation> {
    const paramFilters = {
      populate: ['visit', 'visit.recommendation', 'visit.weight'],
      skip: 0,
      limit: 1,
      order: 'date DESC',
    };

    return this.consultationsWithLimit(patientId, paramFilters).pipe(map((consultationApiData) => consultationApiData.result[0]));
  }

  /**
   * [GET] - Fetch all consultations with the patient id and filter by period
   * Used in Weight-Management Health Tracking Graph
   * @param patientId - Patient unique ID
   */
  patientConsultationsByPeriod(patientId: string): Observable<Consultation[]> {
    const paramFilters = {
      populate: ['visit', 'visit.weight'],
      skip: 0,
      order: 'date ASC',
    };

    return this.consultationsWithoutLimit(patientId, paramFilters);
  }

  /**
   * [GET] - Fetch One consultation
   * @param patientId - Patient unique ID
   * @param consultationId - Consultation unique ID
   * @param apiFilters - Loopback API filters
   */
  fetchConsultationById(patientId: string, consultationId: string): Observable<Consultation> {
    const url = this.pathUrl.consultation(this._vetFacade.clinicId, patientId, consultationId);
    let params = new HttpParams();
    const apiFilters = {
      populate: ['nextVisit', 'targetVisit', 'visit.weight', 'visit.recommendation'],
      skip: 0,
      order: 'date ASC',
    };
    for (const key in apiFilters) {
      const value = Array.isArray(apiFilters[key]) ? JSON.stringify(apiFilters[key]) : apiFilters[key];
      params = params.set(key, value);
    }

    return this.http
      .get<Consultation>(url, { params })
      .pipe(
        map((consultation) => formatConsultation(consultation)),
        catchError(this.handleError.bind(this))
      );
  }

  apiGetPetOwner(contactId: string): Observable<PetOwner> {
    return this.http.get<any>(this.pathUrl.contactById(this._vetFacade.clinicId, contactId)).pipe(
      map((owner) => this.formatPetOwner(owner)),
      catchError((error) => {
        if (error.status === 404) {
          return of(undefined);
        } else {
          this.handleError(error);
        }
      })
    );
  }

  // TO DO : ask api to send us back the modify address list on succes
  apiUpdatePetOwner(petOwnerId: string, petOwner: PetOwner): Observable<PetOwner> {
    return this.http.put<any>(this.pathUrl.contactById(this._vetFacade.clinicId, petOwnerId), this.unFormatPetOwner(petOwner)).pipe(
      mergeMap(() => this.apiGetPetOwner(petOwnerId)),
      catchError(this.handleError.bind(this))
    );
  }

  searchPetOwners(petOwnerIds: string[]): Observable<PetOwnerSearch[]> {
    return this.http
      .post<any[]>(this.pathUrl.contactsSearch(this._vetFacade.clinicId), {
        filter: {
          _id: {
            $in: petOwnerIds,
          },
        },
      })
      .pipe(
        map((owners) => owners.map((owner) => this.formatPetOwner(owner))),
        catchError(this.handleError.bind(this))
      );
  }

  // TO DO : ask api to send us back the modify address list on succes
  apiDeletePetOwnerAddress(petOwnerId: string, addressId: string): Observable<any> {
    return this.http.delete(this.pathUrl.contactAddress(this._vetFacade.clinicId, petOwnerId, addressId)).pipe(
      mergeMap(() => this.apiGetPetOwner(petOwnerId)),
      catchError(this.handleError.bind(this))
    );
  }

  apiLookupClinic(customerId: string, countryCode: string): Observable<Clinic[]> {
    return this.http.get(this.pathUrl.lookupClinic(customerId, countryCode)).pipe(catchError(this.handleError.bind(this)));
  }

  apiFetchVets(clinicId: string): Observable<CiamUser[]> {
    return this.http.get(this.pathUrl.bff_vets(clinicId)).pipe(shareReplay(1), catchError(this.handleError.bind(this)));
  }

  /**
   * Endpoint to connect a vet to a clinic
   * If vet exists but clinic has not been created yet, invitationId is not needed
   * If vet does not exist, it will be created there, invitationId is required
   */
  apiConnectVetClinic(clinicId: string, vet: Partial<VetWithInvitation>): Observable<Vet> {
    return this.http.post(this.pathUrl.connectVetClinic(clinicId), vet).pipe(catchError(this.handleError.bind(this)));
  }

  acceptTerms(vetId: string, terms: Term[]): Observable<{ terms: Term[] } | string> {
    return this.http
      .post<{ terms: Term[] }>(this.pathUrl.terms(vetId), buildTermsApiBody(terms))
      .pipe(catchError((err) => this.handleAcceptTermsError(err, terms)));
  }

  // Remove this function
  apiUpdateVet(vetUpdates: Vet): Observable<Vet> {
    return this.http.patch<Vet>(this.pathUrl.vet(this.userUuid()), vetUpdates).pipe(catchError(this.handleError.bind(this)));
  }

  updateOktaVet(vetId, user: VetUser) {
    const body = {
      profile: user,
    };
    return this.http.post(this.pathUrl.vetUpdate(vetId), body).pipe(catchError(this.handleError.bind(this)));
  }

  apiGetClinic(clinicId: string): Observable<Clinic> {
    return this.http.get<Clinic>(this.pathUrl.clinic(clinicId)).pipe(catchError(this.handleError.bind(this)));
  }

  apiUpdateClinic(clinicId: string, clinic: Clinic): Observable<Clinic> {
    return this.http.patch<Clinic>(this.pathUrl.clinic(clinicId), clinic).pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * Get market data
   */
  getMarket = (countryCode: CountryCode): Observable<Market> => {
    return this.http.get<Market>(this.pathUrl.blobStorageData(countryCode), {
      headers: new HttpHeaders({
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control#no-cache_2
        'Cache-Control': 'no-cache',
      }),
    });
  };

  /**
   * Get user with its related clinics
   */
  getVetWithClinics = (countryCode: string): Observable<VetWithoutUser> => {
    const params = new HttpParams().set('country', countryCode);

    return this.http.get<Vet>(this.pathUrl.userVet(), { params });
  };

  /**
   * Tolerate unhandled API or network errors
   * in order not to block the UX
   */
  private handleAcceptTermsError(err: HttpErrorResponse, terms: Term[]): Observable<{ terms: Term[] } | string> {
    return err.status === 401 || err.status === 400
      ? this.handleError(err)
      : of({ terms }).pipe(
          tap(() =>
            this.coreFacade.setAlert({
              message: translateKey('error_try-again-later-tc'),
              alertType: RCAlertType.ERROR,
            })
          )
        );
  }

  private formatPetOwner(owner: any): PetOwner {
    return {
      id: owner._id || owner.id || '',
      organizationId: owner.organizationId || '',
      givenName: owner.givenName || '',
      familyName: owner.familyName || '',
      telephone: owner.telephone || '',
      email: owner.email || '',
      countryCode: owner.countryCode || '',
      addresses: owner.addresses || [],
      createdAt: owner.createdAt || null,
      updatedAt: owner.updatedAt || null,
    };
  }

  private unFormatPetOwner(owner: PetOwner, organizationId?: string): any {
    return {
      ...owner,
      organizationId: organizationId || undefined,
      _id: owner.id,
    };
  }
}
