import { IconName } from '@app/shared/utils/icon/icons';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AttributeType, FormField, InputType, PetInfo } from '@app/core/models';
import { LocaleContentEnum } from '@app/shared/data';
import { PanelSectionTypeEnum } from '@app/shared/utils/enums/PanelSectionType.enum';
import { Helper } from '@app/shared/utils';

export interface EditingPanelConfig {
  petDetails: boolean;
  petWeight: boolean;
  petDiagnosis: boolean;
}

@Component({
  selector: 'app-rc-pet-summary-panel',
  templateUrl: './rc-pet-summary-panel.component.html',
  styleUrls: ['./rc-pet-summary-panel.component.scss'],
})
export class RcPetSummaryPanelComponent implements OnInit {
  LocalContentType = LocaleContentEnum;
  PanelSection = PanelSectionTypeEnum;
  iconName = IconName;

  petDetailsFields: FormField[];
  petWeightFields: FormField[];
  private _petInfo: PetInfo;

  isPanelPetDetailsExpended = true;
  isPanelPetWeightExpended = true;
  isPanelPetDiagnosisExpended = true;

  @Input()
  set pet(value: PetInfo | null) {
    this._petInfo = value;
    this.setPetWeightFields();
  }
  get pet(): PetInfo | null {
    return this._petInfo;
  }

  @Input() displayDiagnosis = false;
  @Input() isEditing: EditingPanelConfig = {
    petDetails: true,
    petWeight: true,
    petDiagnosis: true,
  };

  @Output()
  editPet: EventEmitter<PanelSectionTypeEnum> = new EventEmitter<PanelSectionTypeEnum>();

  ngOnInit(): void {
    this.setPetDetailsFields();
    this.setPetWeightFields();
  }

  /**
   * Add pet details in card
   * */
  setPetDetailsFields(): void {
    this.petDetailsFields = [
      {
        id: 'speciesCode',
        label: $localize`:@@form-attribute_species:`,
        type: AttributeType.Species,
        path: 'speciesCode',
      },

      {
        id: 'gender',
        placeholder: '',
        label: $localize`:@@form-attribute_gender:`,
        type: AttributeType.Gender,
        path: 'gender',
      },

      {
        id: 'neutered',
        placeholder: $localize`:@@form-attribute_neutered:`,
        label: $localize`:@@form-attribute_neutered:`,
        type: AttributeType.Neutered,
        path: 'neutered',
      },

      {
        id: 'breed',
        path: 'breedObject.breedCode',
        type: AttributeType.Breed,
        label: $localize`:@@form-attribute_breed:`,
      },

      {
        id: 'birthdate',
        inputType: InputType.Date,
        label: $localize`:@@form-attribute_birth-date:`,
        type: AttributeType.Date,
        path: 'birthdate',
      },

      {
        id: 'petActivity',
        label: $localize`:@@form-attribute_activity:`,
        inputType: InputType.Select,
        keyPath: 'code',
        labelPath: 'localizedText',
        type: AttributeType.PetActivity,
        path: 'petActivity',
      },
    ];
  }

  /**
   * Add pet weight in card
   */
  setPetWeightFields(): void {
    this.petWeightFields = [];
    if (this._petInfo.bcs) {
      this.petWeightFields.push({
        id: 'bcs',
        required: true,
        type: AttributeType.BCS,
        path: 'bcs',
        label: $localize`:@@form-attribute_bcs:`,
      });
    }
    if (this._petInfo.weight && this._petInfo.weight > 0) {
      this.petWeightFields.push({
        id: 'weight',
        type: AttributeType.WeightNumber,
        label: $localize`:@@label_weight:`,
        path: 'weight',
      });
    }
    if (this._petInfo.IBW && this._petInfo.lifestage && !Helper.lifestageOld(this._petInfo.lifestage)) {
      this.petWeightFields.push({
        id: 'IBW',
        type: AttributeType.WeightNumber,
        label: $localize`:@@form-attribute_adult-target-weight:`,
        path: 'IBW',
      });
    }

    if (this._petInfo.IBW && this._petInfo.lifestage && Helper.lifestageOld(this._petInfo.lifestage)) {
      this.petWeightFields.push({
        id: 'IBW',
        type: AttributeType.WeightNumber,
        label: $localize`:@@banner_target_weight:`,
        path: 'IBW',
      });
    }
  }

  /**
   * function to handle toggle actions from panel
   */
  showPanelDetails(panelSection: PanelSectionTypeEnum): void {
    switch (panelSection) {
      case PanelSectionTypeEnum.PetDetails:
        this.isPanelPetDetailsExpended = !this.isPanelPetDetailsExpended;
        break;
      case PanelSectionTypeEnum.PetWeight:
        this.isPanelPetWeightExpended = !this.isPanelPetWeightExpended;
        break;
      case PanelSectionTypeEnum.PetDiagnosis:
        this.isPanelPetDiagnosisExpended = !this.isPanelPetDiagnosisExpended;
        break;
    }
  }

  editPetDetails(panelSection: PanelSectionTypeEnum): void {
    this.editPet.emit(panelSection);
  }
}
