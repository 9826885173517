import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { RcSelectComponent } from './../rc-select/rc-select.component';
import { Helper } from '@app/shared/utils';
import { InputStateType } from '@app/shared/utils/enums/input-state.enum';

@Component({
  selector: 'app-rc-autocomplete',
  templateUrl: './rc-autocomplete.component.html',
  styleUrls: ['./rc-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RcSelectComponent),
      multi: true,
    },
  ],
})
export class RcAutocompleteComponent extends RcSelectComponent implements OnInit, ControlValueAccessor {
  /**
   * Properties
   */
  static ENTER_key = 'Enter';
  static TAB_key = 'Tab';
  currentSearch: string;
  filteredItems: any[];
  validInput = true;
  blurTimeout = null;
  inputError: boolean;
  isFocused = false;
  isSelect = false;

  @Input() autoFilter = true;
  @Input() label;
  @Input() required: boolean;
  @Input() loading: boolean = null;
  @Input() noResultText: string;
  @Input() bigIcon = false;

  @Input() set errorState(value: string) {
    this.inputError = value === InputStateType.Failed;
  }

  @Output() didChangeInput: EventEmitter<any> = new EventEmitter();

  /**
   * Initializer
   */
  constructor(elementRef: ElementRef) {
    super(elementRef);
  }

  ngOnInit() {
    if (!this.noResultText) {
      this.noResultText = $localize`:@@form-error_search-default:`;
    }
  }

  onValueSet(prev, next, label): void {
    if (prev || next) {
      this.currentSearch = label;
    }
    this.deactivateBlur();
    super.onValueSet(prev, next, label);
  }

  ponItemsSet(items: any[]): void {
    this.filterItems();
    super.onItemsSet(items);
  }

  /**
   * Autocomplete function
   * @param search: autocomplete text field
   */
  didChangeSearch(search: string) {
    this.validInput = true;
    this._openDropdown();
    this.didChangeInput.emit(search);
    this.filterItems();
  }

  filterItems() {
    if (!this.autoFilter) {
      this.filteredItems = this.items;
      return;
    }

    const search = (this.currentSearch || '').toLowerCase().trim();
    if (search && search.length >= 0) {
      this.filteredItems = this.items.filter((item: any) => Helper.oneWordStartsWithSearch(item.__label, search));
    } else {
      this.filteredItems = this.items;
    }
  }

  /**
   * Override didSelect methods from rc-select
   * @param event - Click or touche vent
   * @param item - Item selected from the list
   */
  didSelectOption(event?, item?) {
    super.didSelectOption(event, item);

    this._hideDropdown();
  }

  /**
   * on key pressed function
   * store pressed key then check if it's Enter
   * hide dropdown menu for option selection
   * save selected value
   * @param event KeyBoardEvent
   */
  onKey(event: Event): void {
    const keyPressed = (event as KeyboardEvent).key;

    if (keyPressed === RcAutocompleteComponent.TAB_key || keyPressed === RcAutocompleteComponent.ENTER_key) {
      this.selectFirstItem();
    }
  }

  blur() {
    if (this.currentSearch) {
      this.deactivateBlur();
      this.blurTimeout = setTimeout(() => {
        this.selectFirstItem();
      }, 300);
    } else {
      this.didSelectOption();
    }
  }
  deactivateBlur() {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
      this.blurTimeout = null;
    }
  }
  selectFirstItem() {
    if (this.filteredItems.length > 0) {
      const selectedItem = this.filteredItems[0];
      this.didSelectOption({ preventDefault: () => null }, selectedItem);
    } else {
      this.currentSearch = '';
      this.validInput = false;
    }
    if (this.dropDownOpen) {
      this._hideDropdown();
    }
  }

  disableKeyDown(event: Event): void {
    event.preventDefault();
  }

  trackByFn(index, item) {
    return item.__key;
  }
}
