<div>
  <div class="rc-input-phone">
    <div class="rc-input-phone__dial-code">
      <app-rc-select
        [items]="dialCodes"
        [value]="dialCodeValue"
        keyPath="dial_code"
        labelPath="dial_code"
        [required]="true"
        [disabled]="disabled"
        [placeholder]="defaultDialCode"
        (didSelectValue)="onChangeDialCode($event)"
      ></app-rc-select>
    </div>
    <div class="rc-input-phone__phone">
      <app-rc-field
        [autocomplete]="autocomplete || 'disabled'"
        class="rc-input"
        [errorState]="inputError ? InputState.Failed : InputState.Success"
        [disabled]="disabled"
        [maxlength]="13"
        [label]="label || placeholder || ''"
        [placeholder]="placeholder || ''"
        [value]="phoneValue"
        [required]="required"
        (change)="onTouched()"
        type="number"
        [name]="name"
        (keyup)="onChangePhone($event.target)"
      >
      </app-rc-field>
    </div>
    <!-- Error message -->
  </div>
  <div *ngIf="error" class="error">
    {{ error }}
  </div>
</div>
