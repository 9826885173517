import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { DeliveryChoice } from '@app/shared/utils/enums/delivery-choice.enum';
import { ProductsQuantityPackagesFormValues } from '@app/shared/components/products-quantity-form/products-quantity-form';
import { CurrencyPipe } from '@angular/common';
import {
  Benefit,
  Clinic,
  Consultation,
  ConsultationSimple,
  OrderPersoApiInput,
  OrderStarterKitApiInput,
  Package,
  PackSize,
  Patient,
  PetInfo,
  Product,
  ProductBack,
  ProductRecommendation,
  ProductRecommendationBack,
  Vet,
} from '@app/core/models';
import { DeliveryAddress } from '@app/core/models/delivery-address';
import { Market } from '@app/core/models/market';
import { countriesNumbersData } from '@app/shared/data/countryCode_phoneNumber';
import { FractionCupPipe } from '@app/shared/pipes';
import {
  BreedSize,
  filterCoverageCriterias,
  Helper,
  MeasureHelper,
  MeasurementCodeType,
  ProductPillar,
  ProductType,
  VetPreferences,
} from '@app/shared/utils';
import * as objectPath from 'object-path';

/**
 * No instance methods
 * Only static methods
 * No constructor for this class
 */
export class ServicesHelper {
  static mapProductBackToFront(productBack: ProductBack): Product {
    return {
      id: productBack.id,
      mainItemId: productBack.mainItem,
      productType: productBack.technology as ProductType,
      speciesCode: productBack.species,
      image: productBack.image,
      name: productBack.name,
      energy: productBack.referenceEnergyValue,
      energyCategory: productBack.energyCategory,
      programs: productBack.programs,
      lifestagesCode: productBack.lifestages,
      specificNeeds: productBack.specificNeeds || [],
      packages: this.getPackages(productBack),
      composition: this.getComposition(productBack.originalProduct),
      benefits: this.getBenefits(productBack.originalProduct),
      weightCategoriesCode: this.getWeightCategoryCode(productBack),
      description: this.getDescription(productBack.originalProduct),
      rangeCategory: productBack.rangeCategory,
      range: productBack.range,
      retailChannel: productBack.retailChannel,
      recommendedFor: productBack.recommendedFors,
      notRecommendedFor: productBack.notRecommendedFors,
      territory: productBack.territory || 'undefined',
      relatedProductsCode: productBack.relatedProducts ? productBack.relatedProducts.map((id) => id.toString()) : [],
      density: productBack.density,
      pillar: productBack.pillar,
    };
  }

  static isProductComplete(productBack: ProductBack): boolean {
    const stringIsNull = (str: string) => !str || str === '';
    const vetWithTerritory = productBack.pillar === ProductPillar.VET ? !!productBack.territory : true;
    return (
      !!productBack.lifestages &&
      productBack.lifestages.length > 0 &&
      !!productBack.programs &&
      productBack.programs.length > 0 &&
      !!productBack.technology &&
      [ProductType.Dry, ProductType.Wet].includes(productBack.technology as ProductType) &&
      !!productBack.species &&
      !stringIsNull(productBack.name) &&
      !!productBack.referenceEnergyValue &&
      !!productBack.packs &&
      productBack.packs.filter((p) => !!p).length > 0 &&
      vetWithTerritory
    );
  }

  static filterMapProducts(products: ProductBack[]): Product[] {
    return products.filter(this.isProductComplete.bind(this)).map(this.mapProductBackToFront.bind(this));
  }

  static filterMapProductRecommendations(products: ProductRecommendationBack[], petInfo: PetInfo): ProductRecommendation[] {
    if (!products) {
      return [];
    }
    return products
      .filter((pr) => this.isProductComplete(pr.product))
      .map((pr) => ({
        coverage: filterCoverageCriterias(pr.coverage, petInfo),
        product: this.mapProductBackToFront(pr.product),
      }));
  }

  static isPackComplete(pack: PackSize): boolean {
    return !!pack.weight && !!pack.measurementUnitCode && !!pack.ean && !!pack.sCode;
  }

  static getPackages(productBack: ProductBack): Package[] {
    if (!productBack.packs) {
      return [];
    }

    const packages = productBack.packs.reduce((packs: Package[], pack: PackSize): Package[] => {
      if (!pack || !this.isPackComplete(pack)) {
        return packs;
      }
      const weight = +pack.weight.toString().replace(',', '.');
      const weightInG = MeasureHelper.convertWeight(weight, pack.measurementUnitCode, MeasurementCodeType.Gram).measure;
      const convertedWeightInVetPreference = MeasureHelper.convertWeightWithCustomMapping(
        +weight,
        pack.measurementUnitCode,
        null,
        VetPreferences.currentSystemPreferenceCode
      );
      const convertedWeightTxt = MeasureHelper.weightToString(convertedWeightInVetPreference);

      convertedWeightTxt &&
        packs.push({
          sCode: pack.sCode,
          ean: pack.ean,
          type: pack.type,
          weight: weight,
          weightInG,
          text: convertedWeightTxt,
          convertedWeight: convertedWeightInVetPreference,
          measurementUnitCode: pack.measurementUnitCode,
          productPrice: pack.productPrice,
        });
      return packs;
    }, []);
    return this.sortPackages(packages);
  }

  static sortPackages(packages: Package[]): Package[] {
    return packages.sort((packSize1, packSize2) => {
      return packSize1.weightInG - packSize2.weightInG;
    });
  }

  static getWeightCategoryCode(productBack: ProductBack): BreedSize[] {
    return productBack.sizes && productBack.sizes.map(Helper.cleanSizeCategory);
  }

  static getBenefits(productWithDetails: any): Benefit[] {
    const benefits: Benefit[] = [];

    // check if I called original product
    if (!productWithDetails) {
      return benefits;
    }

    for (const key of Object.keys(productWithDetails.Contents)) {
      const content = productWithDetails.Contents[key];
      if (content.Title.toLowerCase() === 'benefits') {
        for (const keyContent of Object.keys(content.Content)) {
          for (const keyComposition of Object.keys(content.Content[keyContent])) {
            benefits.push({
              title: keyComposition,
              id: content.Content[keyContent][keyComposition].asset_id,
              description: content.Content[keyContent][keyComposition].Description,
              image: content.Content[keyContent][keyComposition].Image,
            });
          }
        }
      }
    }
    return benefits;
  }

  static getComposition(productWithDetails: any): string {
    // check if I called original product
    if (!productWithDetails) {
      return '--';
    }

    for (const key of Object.keys(productWithDetails.Contents)) {
      const content = productWithDetails.Contents[key];
      if (content.Title.toLowerCase() === 'compositions') {
        for (const keyContent of Object.keys(content.Content)) {
          for (const keyComposition of Object.keys(content.Content[keyContent])) {
            if (['composition', 'ingredient', 'ingredients', 'ingredientes'].includes(keyComposition.toLowerCase())) {
              return content.Content[keyContent][keyComposition];
            }
          }
        }
      }
    }
    return '--';
  }

  static getDescription(productWithDetails: any): string {
    if (!productWithDetails) {
      return '--';
    }
    for (const key of Object.keys(productWithDetails.Contents)) {
      const content = productWithDetails.Contents[key];
      if (content.Title === 'Text') {
        for (const keyContent of Object.keys(content.Content)) {
          for (const keyComposition of Object.keys(content.Content[keyContent])) {
            if (
              keyComposition === 'Prescriber Description' ||
              keyComposition === 'EretailShort Description' ||
              keyComposition === 'EretailLong Description'
            ) {
              return content.Content[keyContent][keyComposition];
            }
          }
        }
      }
    }
    return '--';
  }

  static getDialCodeByCountryCode(countryCode: string): string {
    return countriesNumbersData.find((element) => element.code === countryCode).dial_code;
  }

  static consultationSimple(consultation: Consultation): ConsultationSimple {
    const nutritionData = objectPath.get(consultation, ['visit', 'recommendation', 'nutritionData']);
    if (!nutritionData) {
      return {
        date: consultation.date,
        productNames: [],
        ration: 0,
        weight: null,
        bcs: null,
        comments: '',
        consultation: consultation,
      };
    }

    const productNames = nutritionData.map((data) => objectPath.get(data, ['product', 'productDetail', 'details', 'name']));

    const ration = objectPath.get(consultation, ['visit', 'recommendation', 'diagnostic', 'kCalAllocation']);

    return {
      date: consultation.date,
      productNames,
      ration,
      weight: consultation.visit.weight,
      bcs: consultation.visit.weight.bcs,
      comments: consultation.comments,
      consultation: consultation,
    };
  }

  static buildPlacePersoOrderPayload(
    products: Product[],
    productsQuantityPackages: ProductsQuantityPackagesFormValues[],
    selectedAddress: DeliveryAddress,
    clinic: Clinic,
    vet: Vet,
    market: Market,
    localeId: string,
    patient: Patient,
    consultation: Consultation,
    deliveryChoice: DeliveryChoice,
    currency: string,
    currencyPipe: CurrencyPipe,
    fractionCupPipe: FractionCupPipe
  ): OrderPersoApiInput {
    try {
      const { user } = vet;
      const ownerUser = patient.owner;
      const petWeightStr = `${MeasureHelper.convertWeightObjInPlace(consultation.visit.weight, MeasurementCodeType.Kilogram).measure}`;
      const clinicCountryCode = clinic.companyAddress.country;
      const lang = Helper.countryLangToLang(Helper.catalogLang(localeId, market));
      const addressCountryCode = Helper.mufTransformCountryCode(
        selectedAddress.addressCountry || clinicCountryCode,
        selectedAddress.postalCode
      );

      const totalPrices = consultation?.visit?.recommendation?.nutritionData
        .map((nutri) => {
          const matchingProductsQuantityPackage = productsQuantityPackages.find(
            (item) => item.productId === nutri?.product?.productDetail?.id
          );
          return nutri?.product?.productDetail?.package?.productPrice?.clinicPrice * matchingProductsQuantityPackage?.quantity;
        })
        .reduce((prev, curr) => {
          return curr > 0 ? prev + curr : prev;
        }, 0);
      return {
        orderFor: 'PO',
        deliverTo: deliveryChoice,
        consultationId: consultation.id,
        contactId: patient.id,
        delivery: {
          givenName: deliveryChoice === DeliveryChoice.AtClinic ? user.firstName : selectedAddress.givenName,
          familyName: deliveryChoice === DeliveryChoice.AtClinic ? user.lastName : selectedAddress.familyName,
          email: deliveryChoice === DeliveryChoice.AtClinic ? user.email : ownerUser.email,
          authorizedCall: true,
          otherInfo: selectedAddress.additionalInformation || '',
          countryCode: addressCountryCode,
          addressCountry: addressCountryCode,
          addressCity: selectedAddress.addressCity,
          postalCode: selectedAddress.postalCode,
          streetAddress: selectedAddress.streetAddress || '',
          streetNumberAddress: '',
          telephone: Helper.cleanMufTelephoneFormat(selectedAddress.telephone, ServicesHelper.getDialCodeByCountryCode(clinicCountryCode)),
        },
        orderLines: products.map((product: Product) => {
          const matchingProductsQuantityPackage = productsQuantityPackages.find((item) => item.productId === product.id);
          const pack = this.getEnhancedSkuPack(product, market.products_sku, matchingProductsQuantityPackage?.scode);
          if (!pack) {
            throw {
              errMessage: translateKey('muf_order_error_bag_sku'),
            };
          }

          const clinicPrice = pack.productPrice?.clinicPrice;

          const nutritionData = consultation.visit.recommendation.nutritionData.find((nd) => nd.product.mainItemId === product.mainItemId);
          let dailyRation = '';
          if (nutritionData && nutritionData.basicRation && nutritionData.basicRation.quantityPerDay) {
            dailyRation = `${nutritionData.basicRation.quantityPerDay}`;
          }

          return {
            petName: patient.pet.name,
            petWeight: petWeightStr,
            dailyRation,
            dailyCupRation: fractionCupPipe.transform(nutritionData?.cupRation?.quantityPerDay),
            vetComment: consultation.comments,
            sku: pack.sku,
            isStarerKit: false,
            ean: pack.ean,
            productId: parseInt(product.id, 10),
            units: matchingProductsQuantityPackage?.quantity || 1, // if units = undefined which is the initial value
            productName: product.name,
            productImage: product.image,
            productTechnology: product.productType.charAt(0).toUpperCase() + product.productType.substring(1),
            productSize: `${pack.weight}`,
            productDays: `${Math.floor(nutritionData.basicRation.nbDaysPerPack)}`,
            costPerDay:
              clinicPrice && clinicPrice > 0
                ? currencyPipe.transform(clinicPrice / nutritionData.basicRation.nbDaysPerPack, currency)
                : null,
            clinicPrice: clinicPrice && clinicPrice > 0 ? currencyPipe.transform(clinicPrice, currency) : null,
          };
        }),
        customer: {
          givenName: user.firstName,
          familyName: user.lastName,
          email: user.email,
          lang,
        },
        petOwner: {
          givenName: ownerUser.givenName,
          familyName: ownerUser.familyName,
          email: ownerUser.email,
          telephone: Helper.cleanMufTelephoneFormat(ownerUser.telephone, ServicesHelper.getDialCodeByCountryCode(clinicCountryCode)),
        },
        forEmail: {
          priceActivated: true,
          pet: {
            name: patient.pet.name,
            weight: petWeightStr,
            age: Helper.getPetAgeText(patient.pet.birth.date),
            breed: patient.pet.breedCode,
          },
          totalPrices: totalPrices ? currencyPipe.transform(totalPrices, currency) : null,
          creationDate: consultation.date,
          formattedCreationDate: Helper.getFormattedDate(consultation.date),
          recommendation: consultation.comments,
        },
      };
    } catch (err) {
      throw Error(err.errMessage || translateKey('perso_bag_order_error'));
    }
  }

  static buildPlaceOrderPayload(
    products: Product[],
    productsQuantityPackages: ProductsQuantityPackagesFormValues[],
    selectedAddress: DeliveryAddress,
    clinic: Clinic,
    vet: Vet,
    market: Market,
    localeId: string
  ): OrderStarterKitApiInput {
    try {
      const { user } = vet;
      const countryCode = clinic.companyAddress.country;
      const lang = Helper.countryLangToLang(Helper.catalogLang(localeId, market));
      const addressCountryCode = Helper.mufTransformCountryCode(selectedAddress.addressCountry || countryCode, selectedAddress.postalCode);

      return {
        orderFor: 'VET',
        deliverTo: DeliveryChoice.AtClinic,
        delivery: {
          givenName: user.firstName,
          familyName: user.lastName,
          email: user.email,
          authorizedCall: true,
          otherInfo: selectedAddress.additionalInformation || '',
          countryCode: addressCountryCode,
          addressCountry: addressCountryCode,
          addressCity: selectedAddress.addressCity,
          postalCode: `${selectedAddress.postalCode}`,
          streetAddress: selectedAddress.streetAddress,
          streetNumberAddress: '',
          telephone: Helper.cleanMufTelephoneFormat(selectedAddress.telephone, ServicesHelper.getDialCodeByCountryCode(countryCode)),
        },
        orderLines: products.map((product: Product) => {
          const matchingProductsQuantityPackage = productsQuantityPackages.find((item) => item.productId === product.id);
          const pack = this.getEnhancedSkuPack(product, market.products_sku, matchingProductsQuantityPackage?.scode);
          if (!pack) {
            throw {
              errMessage: translateKey('muf_order_error_bag_sku'),
            };
          }
          return {
            sku: pack.sku,
            isStarerKit: true,
            ean: pack.ean,
            productId: parseInt(product.id, 10),
            units: matchingProductsQuantityPackage?.quantity,
            productName: product.name,
            productImage: product.image,
            productTechnology: product.productType.charAt(0).toUpperCase() + product.productType.substring(1),

            productSize: `${pack.weight}`,
          };
        }),
        customer: {
          givenName: user.firstName,
          familyName: user.lastName,
          lang,
          email: user.email,
        },
        forEmail: {
          creationDate: new Date(),
          formattedCreationDate: Helper.getFormattedDate(new Date()),
        },
      };
    } catch (err) {
      throw Error(err.errMessage || translateKey('starter_kit_order_error'));
    }
  }

  static getEnhancedSkuPack(product: Product, products_sku: Record<string, string>, scode: string): Package {
    return product.packages
      .map((_pack) => ({
        ..._pack,
        sku: products_sku[_pack.ean] || 'sku_to_fill_in_blob_storage"',
      }))
      .find((_pack) => _pack.sCode === scode);
  }
}
