import { PatientSearchWithConsultation } from './../../core/models/patient-search';
import { filterPatientsForPetRecord, formatPatientSearchToPatientRecord } from '@app/shared/utils/static-helpers/pet-record-helper';
import { createSelector } from '@ngrx/store';
import { AppState } from '..';

const patientState = (state: AppState) => state.patient;

export const selectPatientStateError = createSelector(patientState, (state) => state.error);

/**
 * patients selectors
 */
export const selectPatients = createSelector(patientState, (state) => Object.values(state.patients));

export const selectPatientsLoaded = createSelector(patientState, (state) => state.patientsLoaded);

export const selectPatientsAndOwnersLoading = createSelector(patientState, (state) => state.patientsLoading);

/**
 * get each patient with a label to display in autocomplete options
 * used in the home page and in the identification component in pet details page
 */
export const selectPatientsAutocomplete = createSelector(patientState, (state) =>
  Object.values(state.patients).map((patient) => {
    const owner = patient.owner;
    return {
      label: `${patient.pet.name} - ${owner.givenName} ${owner.familyName}`,
      value: patient,
    };
  })
);

/**
 * get each patient formatted for the pet record page
 */
export const selectPatientsPetRecord = createSelector(patientState, (state) => {
  return Object.values(state.patients)
    .filter((patient) => filterPatientsForPetRecord(patient))
    .map((patient) => {
      return formatPatientSearchToPatientRecord(patient);
    });
});
