export enum ActionsEnum {
  save = 'Save',
  exit = 'Exit',
  yes = 'Yes',
  no = 'No',
  enable = 'enable',
  disable = 'disable',
  success = 'Sucess',
  fail = 'Fail',
  one = 'One',
  two = 'Two',
}
