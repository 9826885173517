import { Pipe, PipeTransform } from '@angular/core';

import { Weight } from '@app/core/models';
import { MeasureHelper } from '@app/shared/utils/static-helpers/measure-helper';

@Pipe({ name: 'weight' })
export class WeightPipe implements PipeTransform {
  transform(weight: Weight, precision?: number): string {
    return MeasureHelper.weightToLocalizedString(weight, precision);
  }
}
