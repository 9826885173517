<div class="wrapper">
  <ng-container *ngIf="iconName">
    <app-icon [name]="iconName"></app-icon>
  </ng-container>
  <h2 class="title">{{ title }}</h2>
  <p class="description">{{ description }}</p>
  <ng-container *ngIf="actionLabel">
    <button (click)="emitAction()" class="rc-button rc-button--filled-small" id="no-results-action">
      <span class="rc-button__title">{{ actionLabel }}</span>
    </button>
  </ng-container>
</div>
