import { ViewContainerRef, ComponentRef, Injector, ComponentFactoryResolver } from '@angular/core';

import { Portal } from './portal';

export interface ComponentType<T> {
  new (...args: any[]): T;
}

export class ComponentPortal<T> extends Portal<ComponentRef<T>> {
  component: ComponentType<T>;
  viewContainerRef?: ViewContainerRef | null;
  injector?: Injector | null;
  componentFactoryResolver?: ComponentFactoryResolver | null;

  constructor(
    component: ComponentType<T>,
    viewContainerRef?: ViewContainerRef | null,
    injector?: Injector | null,
    componentFactoryResolver?: ComponentFactoryResolver | null
  ) {
    super();
    this.component = component;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.componentFactoryResolver = componentFactoryResolver;
  }
}
