import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'neutered',
})
export class NeuteredPipe implements PipeTransform {
  transform(value: boolean, ...args: any[]): any {
    return value ? $localize`:@@text_yes:` : $localize`:@@text_no:`;
  }
}
