// TODO
// Everything here is deprecated
// Plan to refactor it
import { Injectable } from '@angular/core';
import { Programs, Tool } from '@app/shared/utils';
import { OrderApiOutput, Patient, Product, ProductPacks, Vet } from '@app/core/models';
import { GtmHelper, gtmVisitType, gtmWeightProgram } from '@app/shared/utils/static-helpers/gtm-helper';
import { GTMService } from './gtm.service';

interface InputOrderGtmLayer {
  products: Product[];
  productPacks: ProductPacks;
  patient?: Patient;
  order: OrderApiOutput;
  vet: Vet;
  isRenewOrder: boolean;
}

interface InputStartConsultation {
  patient?: Patient;
  tool: Tool;
  program?: Programs;
}

@Injectable()
export class GtmEventsService {
  constructor(private _gtmService: GTMService) {}

  /**
   * Login
   */
  sendLoginPageView(): void {
    this._gtmService.deprecatedSendPageView({ type: 'Login', virtualPageUrl: '/login' });
  }

  /** Home Page **/
  sendHomePageView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Homepage',
      virtualPageUrl: '/home',
    });
  }

  /** Account Page **/
  sendAccountPageView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Account',
      module: 'Account',
      virtualPageUrl: '/account',
    });
  }

  /** Product Catalog Page */
  sendProductCatalogPageView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Product Catalog',
      module: 'Product Catalog',
      virtualPageUrl: '/products',
    });
  }

  /** Pet record */
  sendPetRecordPageView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Pet Listing',
      module: 'Pets & Pet Owner',
      virtualPageUrl: '/directory',
    });
  }

  /** FAQ **/
  sendFAQPageView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'FAQs',
      module: 'Help',
      virtualPageUrl: '/support',
    });
  }

  /** Contact Popin */
  sendPopinResult(formName: string, formType: string, success: boolean): void {
    this._gtmService.deprecatedSendEvent({
      name: formName,
      interaction: {
        category: formType,
        action: 'Form submit',
        label: success ? 'Success' : 'Error',
      },
    });
  }

  /** Create patient Popin */
  sendCreatePatientPopinView(tool: Tool): void {
    switch (tool) {
      case Tool.Rationing:
        this._gtmService.deprecatedSendPageView({
          type: 'Nutritional Recommendation',
          module: 'Daily Allowance',
          moduleType: 'Consultation',
          virtualPageUrlPopin: '/daily-allowance/recommendation/create-patient',
        });
        break;
      case Tool.WeightManagement:
        this._gtmService.deprecatedSendPageView({
          type: 'Nutritional Recommendation',
          module: 'Weight Management',
          moduleType: 'Consultation',
          virtualPageUrlPopin: '/weight-management/recommendation/create-patient',
        });
        break;
      case Tool.SmartReco:
        this._gtmService.deprecatedSendPageView({
          type: 'Nutritional Recommendation',
          module: 'Smart Recommendation',
          moduleType: 'Consultation',
          virtualPageUrlPopin: '/smart-recommendation/recommendation/create-patient',
        });
        break;
      case Tool.RenalDetect:
        this._gtmService.deprecatedSendPageView({
          type: 'Nutritional Recommendation',
          module: 'Renal Detect',
          moduleType: 'Consultation',
          virtualPageUrlPopin: '/renal-detect/recommendation/create-patient',
        });
        break;
    }
  }

  /*************
   * PET OWNER PAGE
   **************/
  sendPetOwnerProfileView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Pet Owner Profile',
      module: 'Pets & Pet Owner',
      virtualPageUrl: '/pet-owner/profile',
    });
  }

  /**************
   * PET PAGE
   *************/

  /**
   * Profile
   */
  sendPetPageProfileView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Pet Profile',
      module: 'Pets & Pet Owner',
      virtualPageUrl: '/pet/profile',
    });
  }

  /**
   * Health Tracking
   */
  sendHealthTrackingPageView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Pet Health Tracking',
      module: 'Pets & Pet Owner',
      virtualPageUrl: '/pet/health-tracking',
    });
  }

  /**
   * Send different page view event on GTM
   * 1 - Sent page view when it's a first visit
   * 2 - Sent page view when it's a follow up
   * 3 - Default event
   */
  sendWeightManagementPageView(): void {
    /*3*/
    this._gtmService.deprecatedSendPageView({
      type: 'Summary',
      module: 'Weight Management',
      moduleType: 'Consultation',
      virtualPageUrl: '/pet/weight-management',
    });
  }

  /**************
   * FOLLOW UP
   *************/

  /**
   * Weight Update
   */
  sendFollowUpWeightUpdatePageView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Pet Profile Update',
      module: 'Weight Management',
      moduleType: 'Consultation',
      virtualPageUrl: '/weight_management/weight_update',
    });
  }

  /**************
   * Muf
   *************/

  /**
   * Multifunction catalogue
   */
  sendMultifunctionChooseRecipientView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Multifunction',
      module: 'Multifunction',
      virtualPageUrl: '/multifunction/choose-recipient',
    });
  }

  /**
   * Multifunction personalized bag choose product
   */
  sendMultifunctionPetOwnerOrderConfirmationView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Order Confirmation',
      module: 'Multifunction',
      moduleType: 'Consultation',
      virtualPageUrl: '/multifunction/pet-owner/order-confirmation',
    });
  }

  /***** Starter kit *****/

  /**
   * Multifunction starter kit choose product
   */
  sendMultifunctionClinicOrderConfirmationView(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Order Confirmation',
      module: 'Multifunction',
      moduleType: 'Order',
      virtualPageUrl: '/multifunction/clinic/order-confirmation',
    });
  }

  /***** Orders page *****/

  /**
   * Multifunction orders page
   */
  sendMultifunctionOrders(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Orders',
      module: 'Orders',
      virtualPageUrl: '/orders',
    });
  }

  /**
   * Multifunction order details
   */
  sendMultifunctionDetails(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Orders',
      module: 'Orders',
      virtualPageUrl: '/orders/details',
    });
  }

  /**
   * Send order confirmation
   */
  sendConfirmedOrder(inputOrderGtmLayer: InputOrderGtmLayer): void {
    const dataLayer = {
      name: 'eComTransaction',
      multifunction: {
        pet: GtmHelper.petForGtmOrder(inputOrderGtmLayer.patient),
        ecommerce: {
          purchase: {
            actionField: {
              id: inputOrderGtmLayer.order.id,
              type: 'Order',
              recipient: inputOrderGtmLayer.order.petOwner ? 'Pet Owner' : 'Clinic',
              isRenewal: inputOrderGtmLayer.isRenewOrder,
            },
            products: GtmHelper.productForGTM(inputOrderGtmLayer.products, inputOrderGtmLayer.productPacks),
          },
        },
      },
    };
    if (dataLayer) {
      this._gtmService.deprecatedSendEvent(dataLayer);
    }
  }

  /**************
   * Renal Detect
   *************/

  /**
   * Renal detect make detection
   */
  sendRenalDetectValues(): void {
    this._gtmService.deprecatedSendPageView({
      type: 'Pet Profile',
      module: 'Renal Detect',
      moduleType: 'Consultation',
    });
  }

  /**************
   * Start consultation
   *************/
  sendStartConsultation(inputStartConsultation: InputStartConsultation): void {
    const datalayer = {
      name: 'consultationStarted',
      consultation: {
        number: inputStartConsultation.patient ? inputStartConsultation.patient.consultations?.length : 'undefined',
        type: gtmVisitType(inputStartConsultation.tool),
        weightProgram: inputStartConsultation.program ? gtmWeightProgram(inputStartConsultation.program) : 'undefined',
      },
      pet: GtmHelper.petForGtmOrder(inputStartConsultation.patient),
    };
    datalayer['pet']['newPet'] = !inputStartConsultation.patient;

    this._gtmService.deprecatedSendEvent(datalayer);
  }
}
