import { selectConsultationLastConsultation, selectConsultationPatient } from '@app/store/consultation/consultation.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { dailyAllowanceFeatureKey } from '../daily-allowance-feature-key';
import { DailyAllowanceState } from '../daily-allowance.state';

const selectDailyAllowance = createFeatureSelector<DailyAllowanceState>(dailyAllowanceFeatureKey);
/**
 * Select forms values
 */
export const selectDailyAllowanceProfile = createSelector(selectDailyAllowance, (state) => state.profile);
export const selectDailyAllowanceWeight = createSelector(selectDailyAllowance, (state) => state.weight);
/**
 * Select patient Ideal Body Weight
 */
export const selectDailyAllowancePatientIbw = createSelector(
  selectConsultationPatient,
  selectConsultationLastConsultation,
  (patient, lastConsultation) => {
    return patient?.pet?.idealBodyWeight || lastConsultation?.visit?.weight;
  }
);
