import { Product, ProductPriceEdit } from '@app/core/models';
import { ProductCatalogFilterValues } from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { BreedSize, ProductPillar, SpeciesCode, ViewType } from '@app/shared/utils/enums';
import { RangeType } from '@app/shared/utils/enums/rangeType.enum';
import { TerritoryType } from '@app/shared/utils/enums/territoryType';

export interface ProductsState {
  allProductList: Product[];
  filteredProductList: Record<TerritoryType | string, Product[]>;
  filteredProductLength: number;
  filtersValues: ProductCatalogFilterValues;
  selectedCategory: TerritoryType | RangeType | null;
  priceListToUpdate: ProductPriceEdit[];
  priceListUpdated: ProductPriceEdit[];
  currentViewType: ViewType;
  currentPillar: ProductPillar;
}

export const initialProductsState: ProductsState = {
  allProductList: [],
  filteredProductList: {},
  filteredProductLength: 0,
  priceListToUpdate: [],
  priceListUpdated: [],
  selectedCategory: null,
  currentViewType: ViewType.Grid,
  currentPillar: ProductPillar.VET,
  filtersValues: {
    pillar: ProductPillar.VET,
    specie: SpeciesCode.Dog,
    search: '',
    dry: false,
    wet: false,
    birthAndGrowth: false,
    mature: false,
    adult: false,
    size: BreedSize.All,
  },
};
