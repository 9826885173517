import { createSelector } from '@ngrx/store';
import { AppState } from '..';

const productsState = (state: AppState) => state.products;

export const selectPriceListToUpdate = createSelector(productsState, (state) => state.priceListToUpdate);

export const selectPriceListUpdated = createSelector(productsState, (state) => state.priceListUpdated);

export const selectAllProductsSuccess = createSelector(productsState, (state) => state.allProductList);

export const selectFilteredProduct = createSelector(productsState, (state) => state.filteredProductList);

export const selectFilteredProductLength = createSelector(productsState, (state) => state.filteredProductLength);

export const selectFiltersValues = createSelector(productsState, (state) => state.filtersValues);

export const selectSelectedCategory = createSelector(productsState, (state) => state.selectedCategory);

export const selectCurrentViewType = createSelector(productsState, (state) => state.currentViewType);

export const selectCurrentPillar = createSelector(productsState, (state) => state.filtersValues?.pillar);
