import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector, Type } from '@angular/core';
import { ComponentInjector, ConfigInjector } from '@app/core/services/utils/injector';

@Injectable()
export class DomService {
  private _componentRef: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {}

  appendComponent(component: Type<unknown>, element: string, config: ConfigInjector): void {
    const refMap = new WeakMap();
    refMap.set(ConfigInjector, config);

    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(new ComponentInjector(this.injector, refMap));

    this._componentRef = componentRef;
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    const htmlElement = element ? document.getElementsByClassName(`${element}`)[0] : null;
    if (htmlElement) {
      htmlElement.appendChild(domElem);
    } else {
      document.body.appendChild(domElem);
    }
  }

  removeComponent() {
    if (this._componentRef) {
      this.appRef.detachView(this._componentRef.hostView);
      this._componentRef.destroy();
    }
  }
}
