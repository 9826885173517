import { IconName } from '@app/shared/utils/icon/icons';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeliveryAddress } from '@app/core/models';
import { TypeDeliveryAddress } from '@app/shared/components/add-address-popin/type-delivery-address';
import { DeliveryChoice } from '@app/shared/utils/enums/delivery-choice.enum';
import { isDeliveryAddressIncomplete } from '@app/shared/utils';

@Component({
  selector: 'app-rc-address-card, rc-address-card',
  templateUrl: './rc-address.component.html',
  styleUrls: ['./rc-address.component.scss'],
})
export class RcAddressComponent {
  /**
   * Variables & Properties
   */
  public readonly LIMIT_ADDRESSES_DISPLAYED = 6;
  public readonly TOTAL_LIMIT_ADDRESSES_DISPLAYED = 50;
  public readonly IconName = IconName;

  @Input()
  set addresses(value: DeliveryAddress[]) {
    this._addresses = value.filter((deliveryAddress: DeliveryAddress) => !!deliveryAddress);
    this.displayedAddresses = this._addresses.slice(this.pageNumber, this.LIMIT_ADDRESSES_DISPLAYED);
    if (this._addresses && this._addresses.length > this.LIMIT_ADDRESSES_DISPLAYED) {
      this.onAddressesPageChange(this.pageNumber);
    }
  }
  get addresses(): DeliveryAddress[] {
    return this._addresses;
  }
  @Input() hideNewAddressBtn?: boolean = false;
  @Input() deliveryChoice?: DeliveryChoice;

  //TODO: only input deliveryChoice when MUF refacto is done and then supp this two lines
  @Input() deliveryType?: string;
  TypeDeliveryAddress = TypeDeliveryAddress;

  @Output() editEventEmitter = new EventEmitter<any>();
  @Output() deleteEventEmitter = new EventEmitter<any>();
  @Output() addNewAddressEventEmitter = new EventEmitter<any>();
  @Output() selectEventEmitter = new EventEmitter<DeliveryAddress>();

  private _addresses: DeliveryAddress[];

  pageNumber = 0;
  displayedAddresses: DeliveryAddress[];
  DeliveryChoice = DeliveryChoice;

  showWarningMessage(address: DeliveryAddress): boolean {
    return address.defaultAddress && isDeliveryAddressIncomplete(address, this.deliveryChoice);
  }

  toggleAddress(event, address: DeliveryAddress) {
    this.selectEventEmitter.emit({
      ...address,
      defaultAddress: true,
    });
  }

  editAction(event, address: DeliveryAddress) {
    event.stopPropagation();
    this.editEventEmitter.emit(address);
  }

  deleteAction(event, address: DeliveryAddress) {
    event.stopPropagation();
    this.deleteEventEmitter.emit(address);
  }

  trackByFn(index) {
    return index;
  }

  addAddress(event): void {
    event.stopPropagation();
    this.addNewAddressEventEmitter.emit();
  }

  onAddressesPageChange(index: number): void {
    this.pageNumber = index;
    this.displayedAddresses = this.addresses.slice(index * this.LIMIT_ADDRESSES_DISPLAYED, (index + 1) * this.LIMIT_ADDRESSES_DISPLAYED);
  }
}
