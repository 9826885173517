import { NutritionDataProduct, Package, Product, ProductPriceEdit } from '@app/core/models';
import { BreedSize, MeasureHelper, VetPreferences } from '@app/shared/utils';
import * as objectPath from 'object-path';
import { SpeciesCode } from '../enums';
import { ProductEnergyCategory } from '../enums/product-energy-category.enum';

export default class ProductHelper {
  static getPriceFromSelectedProductPack(product: Product, packId: string, units = 1): number | null {
    if (!product) console.error('No product was provided');
    const clinicPrice = product?.packages?.find((_pack) => _pack.sCode === packId)?.productPrice?.clinicPrice * units;
    return clinicPrice && clinicPrice > 0 ? clinicPrice : null;
  }

  static formatNutritionDataProductIntoProduct(nutritionDataProduct: NutritionDataProduct): Product {
    const currentPackage = nutritionDataProduct.productDetail.package;
    return {
      id: nutritionDataProduct.productDetail.id,
      mainItemId: nutritionDataProduct.mainItemId,
      name: nutritionDataProduct.productDetail.details.name,
      image: nutritionDataProduct.productDetail.details.publicUrl,
      productType: nutritionDataProduct.productType,
      energy: nutritionDataProduct.productDetail.energy,
      packages: [
        {
          ...currentPackage,
          convertedWeight: MeasureHelper.convertWeightWithCustomMapping(
            currentPackage.weight,
            currentPackage.measurementUnitCode,
            null,
            VetPreferences.currentSystemPreferenceCode
          ),
        },
      ],
      programs: [],
      specificNeeds: [],
      relatedProductsCode: [],
      rangeCategory: '',
      density: '',
      energyCategory: ProductEnergyCategory.Unknown,
      speciesCode: SpeciesCode.Unknown,
    };
  }

  /**
   * Functions to search a text inside all elements of a product
   */
  static containsStr(text: string, search: string): boolean {
    return text.toLowerCase().includes(search);
  }
  static objContains(obj: any, path: string, search: string) {
    const text = objectPath.get(obj, path, '');
    const words = search.split(' ');
    for (const word of words) {
      if (!this.containsStr(text, word)) {
        return false;
      }
    }
    return true;
  }

  static productTitleContainsSearch(product: Product, search: string): boolean {
    return ProductHelper.objContains(product, 'name', search);
  }

  static productOkForBreedSize(product: Product, breedSize: BreedSize): boolean {
    if (!breedSize) {
      return true;
    }
    const weightCategories = product.weightCategoriesCode;
    if (!weightCategories || weightCategories.length === 0 || weightCategories.includes(BreedSize.All)) {
      return true;
    } else {
      return weightCategories.includes(breedSize);
    }
  }
}

export function getProductsMergedWithNewPrices(products: Product[] = [], priceList: ProductPriceEdit[] = []): Product[] {
  return products.map((item) => getProductMergedWithNewPrices(item, priceList));
}

export function getProductMergedWithNewPrices(product: Product, priceList: ProductPriceEdit[] = []): Product {
  const newPrices = priceList.filter((editedPrice) => editedPrice.productId === product.id);
  if (newPrices.length) {
    return {
      ...product,
      packages: product.packages.map((pack) => {
        const newPrice = newPrices.find((newPrice) => newPrice.pack.sCode === pack.sCode);
        return newPrice
          ? {
              ...pack,
              productPrice: {
                ...pack.productPrice,
                clinicPrice: newPrice.price,
              },
            }
          : pack;
      }),
    };
  }
  return product;
}

export function getPackFromProductAndSelectedPacks(
  product: Product,
  selectedPacks: { productId: string; packId: string }[]
): Package | undefined {
  return product?.packages.find((packageItem) => {
    const productPack = selectedPacks.find((selectedPackItem) => selectedPackItem.productId === product.id);
    return productPack?.packId === packageItem.sCode;
  });
}
