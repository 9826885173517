export enum PackType {
  SmallCanStr = 'smallCan',
  LargeCanStr = 'bigCan',
  CanStr = 'can',
  PouchStr = 'pouch',
  StarterKit = 'starter_kit',
  Box = 'box',
  BAG = 'bag',
  Kibbles = 'kibbles',
}
