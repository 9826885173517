<div class="dropdown__container">
  <div class="dropdown__btn" (click)="onOpenDropdown()">
    <span class="dropdown__btn__text">
      {{ buttonTitle }}
    </span>
    <span class="dropdown__btn__arrow">
      <em class="dropdown__btn__arrow__icon rc-icon rc-icon--arrow-down--white"></em>
    </span>
  </div>
  <div class="dropdown__options" *ngIf="dropdownOpen$ | async">
    <ng-container *ngFor="let selectable of selectables">
      <span
        id="pet__dropdown-{{ selectable.id }}"
        class="dropdown__option"
        [class.dropdown__option--disabled]="selectable.disabled"
        (click)="onSelectOption(selectable)"
      >
        <span class="dropdown__option__label">
          <em class="dropdown__option__icon rc-icon rc-icon--{{ selectable.icon }}" [class.rc-icon--disabled]="selectable.disabled"></em>
          <span class="dropdown__option__label-text">{{ selectable.title }}</span>
        </span>
      </span>
    </ng-container>
  </div>
</div>
