/**
 * LanguageCode enum
 */
export enum LanguageCode {
  bgBG = 'bg-BG',
  csCZ = 'cs-CZ',
  daDK = 'da-DK',
  deAT = 'de-AT',
  deCH = 'de-CH',
  deDE = 'de-DE',
  elGR = 'el-GR',
  enAU = 'en-AU',
  enBE = 'en-BE',
  enCA = 'en-CA',
  enGB = 'en-GB',
  enHK = 'en-HK',
  enIE = 'en-IE',
  enIN = 'en-IN',
  enNZ = 'en-NZ',
  enPH = 'en-PH',
  enSG = 'en-SG',
  enUS = 'en-US',
  enZA = 'en-ZA',
  esAR = 'es-AR',
  esCL = 'es-CL',
  esCO = 'es-CO',
  esES = 'es-ES',
  esMX = 'es-MX',
  esPR = 'es-PR',
  etEE = 'et-EE',
  fiFI = 'fi-FI',
  frBE = 'fr-BE',
  frCA = 'fr-CA',
  frCH = 'fr-CH',
  frFR = 'fr-FR',
  frRE = 'fr-RE',
  heIL = 'he-IL',
  hrHR = 'hr-HR',
  huHU = 'hu-HU',
  idID = 'id-ID',
  itCH = 'it-CH',
  itIT = 'it-IT',
  koKR = 'ko-KR',
  ltLT = 'lt-LT',
  lvLV = 'lv-LV',
  msMY = 'ms-MY',
  nbNO = 'nb-NO',
  nlBE = 'nl-BE',
  nlNL = 'nl-NL',
  noNO = 'no-NO',
  plPL = 'pl-PL',
  ptBR = 'pt-BR',
  ptPT = 'pt-PT',
  roRO = 'ro-RO',
  ruRU = 'ru-RU',
  ruUA = 'ru-UA',
  skSK = 'sk-SK',
  slSL = 'sl-SL',
  svFI = 'sv-FI',
  svSE = 'sv-SE',
  thTH = 'th-TH',
  trTR = 'tr-TR',
  ukUA = 'uk-UA',
  zhCN = 'zh-CN',
  zhHK = 'zh-HK',
  zhHant = 'zh-Hant',
  zhTW = 'zh-TW',
}
