/**
 * Translate dynamically any key
 * Gentle hack of angular $localize global function
 * Params are replaced in output string
 * and are used for dynamic translation with variables.
 * Expected format in the key is {{param}}
 */
export function translateKey(id: string, params?: { [key: string]: unknown }): string {
  let translation = $localize(({
    '0': `:@@${id}:${id}`,
    raw: [':'],
  } as unknown) as TemplateStringsArray);

  params &&
    Object.entries(params).forEach(([key, value]) => {
      const regex = new RegExp(`{{[\s]{0,}${key}[\s]{0,}}}`);
      translation = translation.replace(regex, String(value));
    });

  return translation;
}

export function translationExist(key: string): boolean {
  return !translateKey(key).includes(key);
}
