import { Injectable } from '@angular/core';
import { Clinic, Invitation, Vet, VetUser } from '@app/core/models';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import {
  selectCanEditPrices,
  selectCanShareRecoByEmail,
  selectClinic,
  selectClinics,
  selectCurrentClinicCountry,
  selectCurrentClinicCurrency,
  selectCurrentClinicId,
  selectIsChronoVet,
  selectIsClinicAddressComplete,
  selectIsRetailPriceActivated,
  selectIsSptRetailEnable,
  selectIsVetValid,
  selectIsWholesalePriceActivated,
  selectLanguageListener,
  selectMarket,
  selectMarketQuicklinks,
  selectMeasureUnitListener,
  selectOktaUser,
  selectPricesVat,
  selectVet,
  selectWholesalePrices,
} from '@app/store/vet/vet.selectors';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppState } from '..';
import { SetCurrentClinicId, SetVet, UpdateVet, UpdateVetUser } from './legacy-vet.actions';
import { getMarket, getVet, setInvitation, setVetValidity, updateClinic } from './vet.actions';

@Injectable()
export class VetFacade {
  vet: Vet;
  clinicId: string;
  vet$ = this.store.pipe(select(selectVet)).pipe(
    tap((vet: Vet) => {
      if (vet) {
        this.vet = vet; // TODO: another way
      }
    })
  );
  oktaUser$ = this.store.pipe(select(selectOktaUser));
  isVetValid$ = this.store.pipe(select(selectIsVetValid));
  clinic$ = this.store.pipe(select(selectClinic)).pipe(tap((clinic: Clinic) => (this.clinicId = clinic?.id)));
  clinics$ = this.store.pipe(select(selectClinics));
  currentClinicId$ = this.store.pipe(select(selectCurrentClinicId));
  currentClinicCountry$ = this.store.pipe(select(selectCurrentClinicCountry));
  currentClinicCurrency$ = this.store.pipe(select(selectCurrentClinicCurrency));
  market$ = this.store.pipe(select(selectMarket));
  marketQuicklinks$ = this.store.pipe(select(selectMarketQuicklinks));
  canShareRecoByEmail$ = this.store.pipe(select(selectCanShareRecoByEmail));
  language$ = this.store.pipe(select(selectLanguageListener));
  preferenceSystem$ = this.store.pipe(select(selectMeasureUnitListener));
  isClinicAddressComplete$ = this.store.pipe(select(selectIsClinicAddressComplete));
  isChronoVetClinic$ = this.store.pipe(select(selectIsChronoVet));
  canEditPrices$ = this.store.pipe(select(selectCanEditPrices));
  isRetailPriceActivated$ = this.store.pipe(select(selectIsRetailPriceActivated));
  isWholesalePriceActivated$ = this.store.pipe(select(selectIsWholesalePriceActivated));
  wholesalePrices$ = this.store.pipe(select(selectWholesalePrices));
  pricesVat$ = this.store.pipe(select(selectPricesVat));
  enableSptRetail$ = this.store.pipe(select(selectIsSptRetailEnable));

  constructor(private store: Store<AppState>) {}

  loadVet() {
    this.store.dispatch(getVet());
  }

  updateVet(vet: Vet) {
    this.store.dispatch(new UpdateVet(vet));
  }
  updateVetUser(oktaId: string, vetUser: VetUser) {
    this.store.dispatch(new UpdateVetUser(oktaId, vetUser));
  }
  updateVetObject(vet: Vet) {
    this.store.dispatch(new SetVet(vet));
  }
  updateVetClinic(clinicId: string, clinic: Clinic, redirectTo?: string[]) {
    this.store.dispatch(updateClinic({ clinicId, clinic, redirectTo }));
  }
  /**
   * Set vet validity
   * See vet.effects for validity criteria
   */
  setVetValidity(value: boolean) {
    this.store.dispatch(setVetValidity({ value }));
  }
  /**
   * Set current clinic id
   */
  setCurrentClinicId(clinicId: string) {
    this.store.dispatch(new SetCurrentClinicId(clinicId));
  }
  /**
   * Set invitation
   */
  setInvitation(invitation: Invitation) {
    this.store.dispatch(setInvitation({ invitation }));
  }
  /**
   * Load market
   * Used during onboarding when user can change country
   */
  loadMarket(countryCode: CountryCode): void {
    this.store.dispatch(getMarket({ countryCode }));
  }
}
