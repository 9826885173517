import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-loader-deprecated',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class DeprecatedLoaderComponent {
  isStandalone = environment.isStandalone;
}
