import { Component, HostListener, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Vet } from '@app/core/models';
import { Market } from '@app/core/models/market';
import { AuthService } from '@app/core/services/auth/auth.service';
import { StartFlowService } from '@app/core/services/start-flow/start-flow.service';
import { GTMService, quickLinksInteraction } from '@app/core/services/tracking';
import { mapToolToLabel } from '@app/core/services/tracking/helpers/mapToolToLabel';
import { MarketService } from '@app/core/services/utils/localization/market.service';
import { ArianeTranslationPipe } from '@app/shared/pipes';
import { Constants, DataSubscriber, DetectMobile, Tool } from '@app/shared/utils';
import { AppFeature, ClinicFeature } from '@app/shared/utils/enums/feature.enum';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { VetFacade } from '@app/store/vet';
import { environment } from '@env/environment';
import { combineLatest, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * Properties
   */

  @Input()
  set vet(vet: Vet) {
    this._vet = vet;
    // init vetFullName
    if (vet && typeof vet !== 'string') {
      const title = vet.user.title ? vet.user.title : vet.user.firstName;
      this.vetFullName = `${title} ${vet.user.lastName}`;
      if (this.locale === 'ko-KR' && !vet.user.title) {
        this.vetFullName = `${vet.user.lastName} ${title}`;
      }
    }

    // init vetImage
    if (vet && typeof vet !== 'string' && vet.user && vet.user.avatarUrl) {
      this.vetImage = vet.user.avatarUrl;
    } else {
      this.vetImage = Constants.placeholderImgVet;
    }
  }
  get vet(): Vet {
    return this._vet;
  }

  menuIsActive = false;
  subMenuIsActive = false;
  isMobile = false;
  error: string; // TODO: Handle error
  subHeaderLinks: any[];

  vetFullName = '--';
  vetImage: string;
  _vet: Vet;

  // Dropdown products array
  productsMenu: {
    icon: string;
    text: string;
    module: string;
    virtualPageUrl: string;
    link?: string;
    flowType?: Tool;
  }[];

  submenu: { icon: string; link: string; text: string }[];

  private _destroyed$ = new Subject();

  /**
   * Initializer
   */
  constructor(
    private router: Router,
    private authService: AuthService,
    private marketService: MarketService,
    private vetFacade: VetFacade,
    private _arianePipe: ArianeTranslationPipe,
    private startFlowService: StartFlowService,
    private trackingService: GTMService,
    @Inject(LOCALE_ID) protected locale: string
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter((data) => !!data)
      )
      .subscribe((data) => {
        this.parsePathUrl(data['urlAfterRedirects']);
      });
    this.submenu = [
      {
        icon: 'minidirectory',
        link: '/pet-record',
        text: $localize`:@@sub-header-link-pet-record:`,
      },
      {
        icon: 'food',
        link: '/products/catalogue',
        text: $localize`:@@sub-header-link-products:`,
      },
      {
        icon: 'support',
        link: '/support',
        text: $localize`:@@sub-header-link-support:`,
      },
    ];
  }

  @HostListener('document:click', ['$event']) clickedOutside() {
    if (this.menuIsActive) {
      this.menuIsActive = !this.menuIsActive;
      this.removeActiveElementOnOutsideClick();
    }
    return;
  }

  /**
   * Life Cycle
   */
  ngOnInit() {
    this.subMenuIsActive = !DetectMobile.isMobile();
    this.isMobile = DetectMobile.isMobile();
    this._buildProductsMenu();

    // setup multifunction marketGuard
    DataSubscriber.subscribe(
      this.marketService.isEnabled$(ClinicFeature.MUF).pipe(filter(Boolean), first()),
      () => {
        this.submenu = [
          {
            icon: 'basket',
            link: '/order-record',
            text: $localize`:@@sub-header-link-orders:`,
          },
          ...this.submenu,
        ];
      },
      this._destroyed$,
      console.error
    );
  }
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Show normal size dropdown menu or full size
   * @param event - Click or Touch event
   */
  showDropdownMenu(event) {
    if (DetectMobile.isMobile()) {
      this.dropdownMenuLowres(event);
    } else {
      this.dropDownMenuHires(event);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.subMenuIsActive = event.target.innerWidth > 960;
    this.isMobile = event.target.innerWidth <= 960;
  }

  showSubMenu(event) {
    event.preventDefault();
    this.subMenuIsActive = !this.subMenuIsActive;
  }

  hideSubMenu(event) {
    event.preventDefault();
    if (DetectMobile.isMobile()) {
      this.subMenuIsActive = false;
    }
  }

  logout(event) {
    event.preventDefault();
    this.authService.logout();
  }

  /**
   * Close dropdown when is opened on fullscreen
   */
  closeDropdown() {
    const dropdownHolderElm: HTMLElement = document.querySelector('.dropdown-holder');
    const dropDownMenuElm = document.querySelectorAll('.dropdown-menu');

    if (dropdownHolderElm.classList.contains('active')) {
      document.body.classList.remove('no-scroll');
      dropdownHolderElm.classList.remove('active');
    }

    this.removeActiveClass(dropDownMenuElm);
  }

  /**
   * Show dropdown menu on fullscreen
   * @param event - Touch event on menu
   */
  private dropdownMenuLowres(event) {
    event.stopPropagation();

    const dataAttribute = event.target.getAttribute('data-content');
    const dropdownHolderElm: HTMLElement = document.querySelector('.dropdown-holder');
    const currentElm: HTMLElement = document.querySelector(`.dropdown-holder__wrap > .dropdown-menu--${dataAttribute}`);

    document.body.classList.add('no-scroll');
    dropdownHolderElm.classList.add('active');
    currentElm.classList.add('active');
  }

  /**
   * Show dropdown menu on high resolution
   * @param event - Click event on menu
   */
  private dropDownMenuHires(event) {
    event.stopPropagation();

    const currentElm = event.currentTarget;
    const dataAttribute = currentElm.getAttribute('data-content');
    const headerMenu = document.querySelectorAll('.header__menu-icon');
    const allDropdowns = document.querySelectorAll('.dropdown');
    const allDropdownMenu = document.querySelectorAll('.dropdown-menu');

    const headerProfile: HTMLElement = document.querySelector('.header__menu-profile');
    const dropDown: HTMLElement = document.querySelector(`.dropdown--${dataAttribute}`);
    const dropDownMenu: HTMLElement = document.querySelector(`.dropdown-menu--${dataAttribute}`);
    const rcIconElm: HTMLElement = document.querySelector('.rc-icon--arrow-down');

    if (currentElm.classList.contains('active')) {
      currentElm.classList.remove('active');
      dropDown.classList.remove('active');
      dropDownMenu.classList.remove('active');
      rcIconElm.classList.remove('active');
      this.menuIsActive = false;

      return;
    }

    if (headerProfile.classList.contains('active')) {
      headerProfile.classList.remove('active');
    }

    if (rcIconElm.classList.contains('active')) {
      rcIconElm.classList.remove('active');
    }

    this.removeActiveClass(headerMenu);
    this.removeActiveClass(allDropdowns);
    this.removeActiveClass(allDropdownMenu);

    currentElm.classList.add('active');
    dropDown.classList.add('active');
    dropDownMenu.classList.add('active');

    if (dataAttribute === 'profile') {
      rcIconElm.classList.add('active');
    }

    this.menuIsActive = true;
  }

  /**
   * Remove all active class when use click outside
   */
  private removeActiveElementOnOutsideClick() {
    const headerMenus = document.querySelectorAll('.header__menu-icon');
    const allDropdowns = document.querySelectorAll('.dropdown');
    const rcIconElm: HTMLElement = document.querySelector('.rc-icon--arrow-down');
    const headerProfile: HTMLElement = document.querySelector('.header__menu-profile');

    for (let index = 0; index < headerMenus.length; index++) {
      const activeMenu: HTMLElement = headerMenus[index] as HTMLElement;

      if (activeMenu.classList.contains('active')) {
        this.removeActiveClass(headerMenus);
        this.removeActiveClass(allDropdowns);
        return;
      }
    }

    if (rcIconElm.classList.contains('active')) {
      rcIconElm.classList.remove('active');
    }

    if (headerProfile.classList.contains('active')) {
      this.removeActiveClass(allDropdowns);
    }
  }

  /**
   * Remove all active class on the menu
   * @param element - Array of menu
   */
  private removeActiveClass(element) {
    for (let i = 0; i < element.length; i++) {
      const elementItem: HTMLElement = element[i] as HTMLElement;

      if (elementItem.classList.contains('active')) {
        elementItem.classList.remove('active');
      }
    }
  }

  /**
   * Parse url to get section name when user navigate through the app
   * @param url - String of path url
   */
  private parsePathUrl(url: string) {
    const parsedUrl = url.includes(';') ? url.substr(0, url.indexOf(';')) : url;
    const arianeTranslation = this._arianePipe.transform(parsedUrl);
    // in case there is a path containing '/' that we want to customize his translation.
    // ex: /products/starter-kit will return [multifunction, starter-kit]
    if (arianeTranslation !== '--' && arianeTranslation && arianeTranslation[0]) {
      this.subHeaderLinks = arianeTranslation;
    } else {
      const pathUrls = parsedUrl.split('/');
      const pathRegex = /^[a-zA-Z-]+$/;

      this.subHeaderLinks = pathUrls
        .map((path) => {
          if (path !== '' && path.includes(';')) {
            path = path.substring(0, path.indexOf(';'));
          }

          return path;
        })
        .filter((path) => path !== '' && pathRegex.test(path))
        .map((path) => {
          return this._arianePipe.transform(path);
        })
        .filter((path) => path !== '--');
    }
  }

  /**
   * Sent event on GA when user select an icon inside the menu
   * @param event - Click or Touch event
   * @param menu - Single menu object
   */
  externalLink(menu) {
    if (menu.link) {
      window.open(menu.link, '_blank');
      this.trackingService.sendInteraction(quickLinksInteraction(menu.module));
    } else {
      this.startFlowService.startFlowNewPatient(menu.flowType);
      this.trackingService.sendInteraction(quickLinksInteraction(mapToolToLabel(menu.flowType)));
    }
    this.closeDropdown();
  }

  private _buildProductsMenu() {
    combineLatest([
      this.marketService.isEnabled$(ClinicFeature.WM),
      this.marketService.isEnabled$(ClinicFeature.DA),
      this.marketService.isEnabled$(ClinicFeature.SR),
      this.marketService.isEnabled$(ClinicFeature.MUF),
      this.marketService.isEnabled$(AppFeature.MUF_SK),
      this.marketService.isEnabled$(AppFeature.MUF_PB),
      this.marketService.isEnabled$(ClinicFeature.RD),
      this.vetFacade.market$,
    ])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        ([
          weightLossPlanEnabled,
          dailyAllowanceEnabled,
          productFinderEnabled,
          mufEnabled,
          mufStarterKitsEnabled,
          mufPersBagsEnabled,
          renalEnabled,
          market,
        ]: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, Market]) => {
          this.productsMenu = [];
          if (weightLossPlanEnabled) {
            this.productsMenu.push({
              icon: './assets/icons/weightloss.svg',
              text: $localize`:@@header-link_wm:`,
              module: 'Weight management',
              virtualPageUrl: '/weight-management',
              flowType: Tool.WeightManagement,
            });
          }
          if (dailyAllowanceEnabled) {
            this.productsMenu.push({
              icon: './assets/icons/dailyallowance.svg',
              text: $localize`:@@header-link_da:`,
              module: 'Daily Allowance',
              virtualPageUrl: '/daily-allowance',
              flowType: Tool.Rationing,
            });
          }
          if (productFinderEnabled) {
            this.productsMenu.push({
              icon: './assets/icons/product_finder.svg',
              text: $localize`:@@header-link_product_finder:`,
              module: 'Product Finder',
              virtualPageUrl: '/weight-management',
              flowType: Tool.SmartReco,
            });
          }
          if (mufEnabled && mufStarterKitsEnabled) {
            this.productsMenu.push({
              icon: './assets/icons/multifunction-starter-kits.svg',
              text: $localize`:@@muf-popin_starter-kits_title:`,
              module: 'Multifunction',
              virtualPageUrl: '/multifunction/starter-kits',
              flowType: Tool.StarterKit,
            });
          }
          if (mufEnabled && mufPersBagsEnabled) {
            this.productsMenu.push({
              icon: './assets/icons/multifunction-pers-bags.svg',
              text: $localize`:@@muf-popin_personalised-bags_title:`,
              module: 'Multifunction',
              virtualPageUrl: '/multifunction/personalized-bags',
              flowType: Tool.PersonalizedBag,
            });
          }
          if (renalEnabled) {
            this.productsMenu.push({
              icon: './assets/icons/renal-detect_mini.svg',
              text: $localize`:@@header-link_renal:`,
              module: 'Renal Detect',
              virtualPageUrl: '/renal-detect',
              flowType: Tool.RenalDetect,
            });
          }
          /**
           * If some links are defined in the market data,
           * we add them to the list
           */
          if (market?.quicklinks?.length) {
            const quicklinks = market.quicklinks.map((q) => ({
              ...q,
              icon: `${environment.blobUrl}/quicklinks_icons/${q.icon}?${environment.blobQueryParams}`,
              text: translateKey(q.text),
            }));
            this.productsMenu = [...this.productsMenu, ...quicklinks];
          }
        }
      );
  }
}
