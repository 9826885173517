import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Optional,
  ViewChild,
} from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { BasePortalOutlet, ComponentPortal, TemplatePortal, PortalOutletDirective } from '@app/core/cdk/portal';

import { DialogAnimations } from './dialog-animations';

@Component({
  selector: 'app-rc-dialog-container',
  templateUrl: 'dialog-container.html',
  styleUrls: ['dialog.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [DialogAnimations.dialogContainer],
})
export class DialogContainerComponent extends BasePortalOutlet {
  @HostBinding('@dialogContainer') get dialogContainer() {
    return this._state;
  }

  constructor(
    private _elementRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(DOCUMENT) private _document: any
  ) {
    super();
    this._createCloseElm();
  }

  id: string;
  animationStateChanged = new EventEmitter<AnimationEvent>();

  private _state: 'void' | 'enter' | 'exit' = 'enter';

  @ViewChild(PortalOutletDirective, { static: true })
  _portalOutlet: PortalOutletDirective;
  @HostBinding('class') class = 'rc-dialog-container';

  private static _throwDialogContentAlreadyAttachedError() {
    throw Error('Attempting to attach dialog content after content is already attached');
  }

  @HostListener('@dialogContainer.start', ['$event'])
  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  @HostListener('@dialogContainer.done', ['$event'])
  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    if (this._portalOutlet.hasAttached()) {
      DialogContainerComponent._throwDialogContentAlreadyAttachedError();
    }

    return this._portalOutlet.attachComponentPortal(portal);
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    if (this._portalOutlet.hasAttached()) {
      DialogContainerComponent._throwDialogContentAlreadyAttachedError();
    }

    return this._portalOutlet.attachTemplatePortal(portal);
  }

  startExitAnimation(): void {
    this._state = 'exit';
    this._changeDetectorRef.markForCheck();
  }

  private _createCloseElm() {
    const host: HTMLElement = this._elementRef.nativeElement;
    const headerElm = document.createElement('div');
    const closeElm = document.createElement('div');
    const closeIcone = document.createElement('i');

    headerElm.classList.add('rc-dialog-header');
    closeElm.classList.add('rc-dialog-close');
    closeElm.id = 'rc-dialog-close';
    closeIcone.classList.add('rc-icon', 'rc-icon--close');

    closeElm.addEventListener('click', () => {
      this.startExitAnimation();
    });

    closeElm.appendChild(closeIcone);
    headerElm.appendChild(closeElm);
    host.appendChild(headerElm);
  }
}
