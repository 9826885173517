import { VetActionTypes, VetActions } from './legacy-vet.actions';
import * as NewVetActions from './vet.actions';
import { initialVetState, VetState } from './vet.state';
import { replaceInArray } from '@app/shared/utils/static-helpers/array.utils';
import { createReducer, on, union } from '@ngrx/store';
/**
 * Vet reducer
 */
export const vetReducer = createReducer(
  initialVetState,
  on(NewVetActions.getVetSuccess, (state, { vet, currentClinic, oktaUser }) => ({
    ...state,
    vet,
    oktaUser,
    currentClinicId: currentClinic?.id,
  })),
  on(NewVetActions.setVetValidity, (state, { value }) => ({
    ...state,
    isVetValid: value,
  })),
  on(NewVetActions.setInvitation, (state, { invitation }) => ({
    ...state,
    invitation,
  })),
  on(NewVetActions.getMarketSuccess, (state, { market }) => ({
    ...state,
    market,
  })),
  on(NewVetActions.updateClinicSuccess, (state, { clinic }) => ({
    ...state,
    vet: {
      ...state.vet,
      clinics: replaceInArray(state.vet.clinics, clinic, (clinic) => clinic.id, false),
    },
  }))
);
/**
 * Make new syntax actions compatible with legacy reducers
 */
const VetActionsUnion = union({ ...NewVetActions });
type Actions = VetActions | typeof VetActionsUnion;
/**
 * Ngrx old syntax
 * Do not add reducers here
 */
export function legacyVetReducer(state: VetState = initialVetState, action: Actions): VetState {
  switch (action.type) {
    case VetActionTypes.SetVet: {
      return {
        ...state,
        isLoading: false,
        vet: Object.assign({}, state.vet, action.vet),
      };
    }
    case VetActionTypes.UpdateVetSuccess: {
      return {
        ...state,
        vet: {
          ...state.vet,
          ...action.vet,
        },
      };
    }
    case VetActionTypes.UpdateVetUserSuccess: {
      return {
        ...state,
        vet: {
          ...state.vet,
          user: {
            ...state.vet.user,
            ...action.vetUser,
          },
        },
      };
    }
    case VetActionTypes.SetCurrentClinicId: {
      return {
        ...state,
        currentClinicId: action.clinicId,
      };
    }
    default:
      return vetReducer(state, action);
  }
}
