export enum AttributeType {
  Text = 'text',
  Date = 'date',
  Age = 'age',
  Gender = 'gender',
  Breed = 'breed',
  PetActivity = 'pet-activity',
  Title = 'title',
  MeasurementSystem = 'measurement-system',
  Species = 'species',
  Lifestage = 'lifestage',
  Neutered = 'neutered',
  Link = 'link',
  FbLink = 'fb-link',
  Image = 'image',
  UserRole = 'user-role',
  UserName = 'user-name',
  Address = 'address',
  Lang = 'lang',
  Email = 'email',
  Segmented = 'segmented',
  Number = 'number',
  Weight = 'weight',
  WeightNumber = 'weight-number',
  Radio = 'radio',
  BCS = 'bcs',
  Reproduction = 'reproduction',
  Size = 'size',
  RadioImage = 'RadioImage',
}
