/** Date validators */

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { endOfDay, isAfter, isBefore, isFuture, isPast, startOfDay } from 'date-fns';
import { Helper } from '@app/shared/utils';

/** A date must be set in the past */
export const pastDateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }
  return isFuture(startOfDay(Helper.parseDate(control.value))) ? { pastDate: { value: control.value } } : null;
};

/** A date must be set in the future */
export const futureDateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }
  return isPast(endOfDay(Helper.parseDate(control.value))) ? { futureDate: { value: control.value } } : null;
};

/** A date must be set after a minDate */
export const minDateValidator = (minDate: string | Date): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    return isBefore(endOfDay(Helper.parseDate(control.value)), Helper.parseDate(minDate))
      ? { minDate: { value: control.value, minDate } }
      : null;
  };
};

/** A date must be set before a maxDate */
export const maxDateValidator = (maxDate: string | Date): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    return isAfter(startOfDay(Helper.parseDate(control.value)), Helper.parseDate(maxDate))
      ? { maxDate: { value: control.value, maxDate } }
      : null;
  };
};
