import { selectConsultationLastConsultation, selectConsultationPatient } from '@app/store/consultation/consultation.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { smartRecoFeatureKey } from '../smart-reco-feature-key';
import { SmartRecoState } from '../smart-reco.state';

const selectSmartReco = createFeatureSelector<SmartRecoState>(smartRecoFeatureKey);
/**
 * Select forms values
 */
export const selectSmartRecoProfile = createSelector(selectSmartReco, (state) => state.profile);
export const selectSmartRecoWeight = createSelector(selectSmartReco, (state) => state.weight);
export const selectSmartRecoPathologies = createSelector(selectSmartReco, (state) => state.pathologies);
/**
 * Select patient Ideal Body Weight
 */
export const selectSmartRecoPatientIbw = createSelector(
  selectConsultationPatient,
  selectConsultationLastConsultation,
  (patient, lastConsultation) => {
    return patient?.pet?.idealBodyWeight || lastConsultation?.visit?.weight;
  }
);
