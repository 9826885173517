export enum InputType {
  Text = 'text',
  Segmented = 'segmented',
  RadioImage = 'radioimage',
  Radio = 'radio',
  Select = 'select',
  Autocomplete = 'autocomplete',
  Number = 'number',
  Date = 'date',
  Textarea = 'textarea',
  Phone = 'phone',
  Weight = 'weight',
}
