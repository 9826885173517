import { MeasurementCodeType } from '@app/shared/utils/enums/measurement-type-code.enum';
import { Constants } from '@app/shared/utils/constants';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';

export class VetPreferences {
  // Changed in function of vet preferences by vet.service
  public static id: string;

  // Metric system units
  public static currentSystemPreferenceCode = Constants.metricSystemCode;
  public static currentSmallMeasurementUnit = MeasurementCodeType.Gram;
  public static currentBigMeasurementUnit = MeasurementCodeType.Kilogram;
  public static maxPetWeight = Constants.LIMIT_INPUT_WEIGHT.measure;

  public static lang = LanguageCode.enGB;
  public static country = 'GB';
  public static loaded = false;

  public static updateUnitSystemPreference = (unitSystemCode) => {
    VetPreferences.currentSystemPreferenceCode = unitSystemCode;
    switch (unitSystemCode) {
      case Constants.metricSystemCode:
        VetPreferences.currentSmallMeasurementUnit = MeasurementCodeType.Gram;
        VetPreferences.currentBigMeasurementUnit = MeasurementCodeType.Kilogram;
        break;
      case Constants.imperialSystemCode:
        VetPreferences.currentSmallMeasurementUnit = MeasurementCodeType.Ounce;
        VetPreferences.currentBigMeasurementUnit = MeasurementCodeType.Pound;
        break;
    }
  };

  public static getInitialPreferences = (countryCode: string) => {
    let systemPreference = Constants.metricSystemCode;
    if (Constants.IMPERIAL_COUNTRIES.includes(countryCode)) {
      systemPreference = Constants.imperialSystemCode;
    }
    return {
      systemPreference,
    };
  };
}
