import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { Overlay } from '@app/core/cdk/overlay/overlay';

@NgModule({
  imports: [CommonModule],
  exports: [],
  providers: [Overlay],
})
export class OverlayModule {}
