<!-- Product Cart -->
<div
  class="product-cart product-footer is-open"
  [class.is-expand]="selectedProducts.length > 0 && productCartBarIsExpanded"
  [class.is-withMobileFilters]="withMobileFilters"
  [class.is-withSelectedProduct]="selectedProducts.length > 0"
>
  <ng-container>
    <!-- Product Cart : Header -->
    <ng-container *ngIf="withMobileFilters; else headerWithoutFilters">
      <div class="product-cart__header">
        <ng-container *ngIf="selectedProducts && selectedProducts.length > 0; else searchOnly">
          <button class="rc-button align-row product-selection" (click)="toggleExpandProductCart()">
            <div class="align-row">
              <p class="header-selection" i18n="@@product-book_selection">selection</p>
              <div class="badge">
                <span>{{ selectedProducts.length }}</span>
              </div>
            </div>
            <div class="align-row">
              <p class="header-panel">{{ productCartBarIsExpanded ? ('action_open' | translateKey) : ('action_close' | translateKey) }}</p>
              <app-icon
                [class.is-expand]="selectedProducts.length > 0 && productCartBarIsExpanded"
                class="icon"
                [name]="IconNameEnum.chevron_down"
              ></app-icon>
            </div>
          </button>
          <ng-container *ngIf="!productCartBarIsExpanded">
            <div class="sub-selection">
              <button class="rc-button" (click)="searchAction.emit()">
                <span class="rc-button__title--link" i18n="@@search">search</span>
              </button>
              <span class="rc-button__separator" i18n="@@action_separator">or</span>
              <button class="rc-button rc-button--filled-small search-button" (click)="actionHandler()">
                <span class="rc-button__title" i18n="@@action_continue">continue</span>
              </button>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #searchOnly>
          <div class="search-section">
            <button class="rc-button rc-button--filled-small search-button" (click)="searchAction.emit()">
              <span class="rc-button__title" i18n="@@product-book_search_product">Search a product</span>
            </button>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #headerWithoutFilters>
      <div class="product-cart__header">
        <button class="product-cart__button rc-button" (click)="toggleExpandProductCart()">
          <span class="rc-button__icon">
            <app-icon class="icon-food" [name]="IconNameEnum.food_2"></app-icon>
          </span>
          <span class="rc-button__title--link">
            <ng-container i18n="@@product-cart_selected-msg">Products selected</ng-container>
            ({{ selectedProducts.length }})
          </span>
        </button>
      </div>
    </ng-template>

    <!-- Product Cart : Content -->
    <div class="product-cart__content">
      <ng-container *ngIf="selectedProducts.length > 0">
        <div class="product-cart__item" *ngFor="let product of selectedProducts | slice: 0:2">
          <!-- Product Cart : Product thumb -->
          <img class="product-cart__item-thumb" [src]="product.image | publicUrl" [alt]="product.name || ''" />

          <!-- Product Cart : Description -->
          <div class="product-cart__description">
            <p class="rc-paragraph">
              {{ product.productType | translationCode: LocaleContentEnum.ProductType }}
            </p>
            <h6 class="rc-paragraph product-cart__item-name">
              {{ product.name || '' | titlecase }}
            </h6>
          </div>

          <!-- Product Cart : Remove Item -->
          <button class="product-cart__item-remove rc-button" (click)="removeItem($event, product)">
            <em class="rc-icon rc-icon--close"></em>
          </button>
        </div>
      </ng-container>
      <!-- Product Cart : Description -->
      <ng-container
        *ngIf="selectedProducts.length <= 1 && useCoupleOfDryWet && [ProductType.Dry, ProductType.Wet].includes(selectedProductType)"
      >
        <div class="product-cart__item product-cart__item--dashed">
          <p class="rc-paragraph--meta" i18n="@@select-wet-product" *ngIf="selectedProductType === ProductType.Wet">Select a wet product</p>
          <p class="rc-paragraph--meta" i18n="@@select-dry-product" *ngIf="selectedProductType === ProductType.Dry">Select a dry product</p>
          <p class="rc-paragraph--meta" i18n="@@select-product" *ngIf="![ProductType.Dry, ProductType.Wet].includes(selectedProductType)">
            Select a product
          </p>
        </div>
      </ng-container>
      <ng-container *ngIf="!useCoupleOfDryWet && selectedProducts.length < 2">
        <div class="product-cart__item product-cart__item--dashed">
          <p class="rc-paragraph--meta" i18n="@@select-other-products">Select other products</p>
        </div>
      </ng-container>

      <div class="product-cart__total" *ngIf="selectedProducts.length > 2 && !useCoupleOfDryWet" (click)="onOpenStarterKitPopin()">
        <p class="rc-paragraph rc-link-footer">
          <ng-container i18n="@@and">and</ng-container>
          {{ selectedProducts.length - 2 }}
          <ng-container i18n="@@product-cart_more-products">more product(s)</ng-container>
        </p>
      </div>

      <!-- Product Cart : Footer -->
      <div class="product-cart__footer" [class.is-expand]="selectedProducts.length > 0 && productCartBarIsExpanded">
        <div class="sub-selection footer--withMobileFilters">
          <button class="rc-button" (click)="searchAction.emit()">
            <span class="rc-button__title--link" i18n="@@search">search</span>
          </button>
          <span class="rc-button__separator" i18n="@@action_separator">or</span>
          <button class="rc-button rc-button--filled-small search-button" (click)="actionHandler()">
            <span class="rc-button__title" i18n="@@action_continue">continue</span>
          </button>
        </div>

        <div class="rc-button-group footer--withoutMobileFilters">
          <button class="rc-button" (click)="toggleExpandProductCart()">
            <span class="rc-button__title--link" i18n="@@action_return">Return</span>
          </button>
          <span class="rc-button__separator" i18n="@@action_separator">or</span>
          <button class="rc-button rc-button--filled-small" id="continueWithTheseProducts" (click)="actionHandler()">
            <span class="rc-button__title" i18n="@@action_continue">Continue</span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
