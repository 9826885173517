<app-rc-dropdown [htmlId]="'activity-tooltip'" [toggleMode]="'hover'">
  <div main *ngIf="speciesCode">
    <app-icon [name]="IconName.alert"></app-icon>
  </div>

  <div dropdown>
    <p *ngFor="let activity of ['low', 'moderate', 'high']">
      <strong>{{ 'activity-' + activity + '_title' | translateKey }}</strong>
      <span>{{ ' - ' }}</span>
      <span *ngIf="speciesCode === SpeciesCode.Dog">{{ 'activity-' + activity + '_description' | translateKey }}</span>
      <span *ngIf="speciesCode === SpeciesCode.Cat">{{ 'activity-' + activity + '_description_cat' | translateKey }}</span>
      <span *ngIf="activity === 'low' && speciesCode === SpeciesCode.Dog">{{ ' ' }}{{ 'average_dog' | translateKey }}</span>
    </p>
  </div>
</app-rc-dropdown>
