import { ProductCategoriesFilters } from '@app/core/models/territories-filters';
import { RangeType } from '../utils/enums/rangeType.enum';
import { TerritoryType } from '../utils/enums/territoryType';
import { IconName } from '../utils/icon/icons';

export const rangeCategory: ProductCategoriesFilters[] = [
  {
    code: TerritoryType.BirthAndGrowth,
    translationCode: 'product-select-territory-birth',
    icon: IconName.territory_birth_and_growth,
    colorHex: '#808285',
    disabled: false,
  },
  {
    code: RangeType.BHN,
    translationCode: 'product-select-bhn',
    icon: IconName.range_bhn,
    colorHex: '#808285',
    disabled: false,
  },
  {
    code: RangeType.FBN,
    translationCode: 'product-select-fbn',
    icon: IconName.range_fbn,
    colorHex: '#808285',
    disabled: false,
  },
  {
    code: RangeType.FCN,
    translationCode: 'product-select-fcn',
    icon: IconName.territory_vital_support,
    colorHex: '#808285',
    disabled: false,
  },
  {
    code: RangeType.FHN,
    translationCode: 'product-select-fhn',
    icon: IconName.territory_health_management,
    colorHex: '#808285',
    disabled: false,
  },
  {
    code: RangeType.CCN,
    translationCode: 'product-select-ccn',
    icon: IconName.range_ccn,
    colorHex: '#808285',
    disabled: false,
  },
  {
    code: RangeType.SHN,
    translationCode: 'product-select-shn',
    icon: IconName.range_shn,
    colorHex: '#808285',
    disabled: false,
  },
];
