import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from './../api.service';
import { OrderStarterKitApiInput, OrderApiOutput } from '@app/core/models';

@Injectable()
export class OrderService extends ApiService {
  constructor(private http: HttpClient, @Inject(LOCALE_ID) protected locale: string) {
    super();
  }

  /**
   * [POST] - Add price to a multiple product
   */
  createOrder(clinicId: string, payload: OrderStarterKitApiInput): Observable<OrderApiOutput> {
    const url = this.pathUrl.orders(clinicId);
    return this.http.post(url, payload).pipe(catchError(this.handleError.bind(this)));
  }

  getOrders(clinicId: string) {
    return this.http.get<any>(this.pathUrl.orders(clinicId)).pipe(catchError(this.handleError.bind(this)));
  }

  getOrderByConsultationId(clinicId: string, consultationId: string): Observable<OrderApiOutput[]> {
    const params = new HttpParams().set(
      'filter',
      JSON.stringify({
        where: { consultationId: consultationId },
      })
    );
    return this.http
      .get<OrderApiOutput[]>(this.pathUrl.orders(clinicId), { params })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getOrderById(clinicId: string, orderId: string): Observable<OrderApiOutput> {
    return this.http.get<any>(this.pathUrl.order(clinicId, orderId)).pipe(catchError(this.handleError.bind(this)));
  }
}
