import { startMufPersonalisedBag } from './../../muf-personalised-bag/store/actions/muf-personalised-bag-steps.actions';
import { setTool } from '@app/store/core';
import {
  resetConsultationData,
  setConsultationLastConsultation,
  setConsultationPatient,
} from '@app/store/consultation/consultation.actions';
import { getConsultationsWithoutVisit, resetPet } from '@app/store/pet/pet.actions';
import { getChartConsultations } from '@app/store/pet/chart.actions';
import { startRenalDetect } from '@app/pages/renal-detect/store/actions/renal-detect-steps.actions';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Dialog } from '@app/core/cdk/dialog';
import { Consultation, Patient, Pet } from '@app/core/models';
import { ConsultationApiData } from '@app/core/models/consultation-api-data';
import {
  ActionCommunicationService,
  ChartService,
  EnumsService,
  LoaderService,
  ObservablesLayerService,
  PetCommunicationService,
  VetService,
} from '@app/core/services';
import { MarketService } from '@app/core/services/utils/localization/market.service';
import { Logger } from '@app/core/services/utils/logger';
import { startSmartReco } from '@app/pages/smart-reco/store/actions/smart-reco-steps.actions';
import { ActiveProgramPopinComponent, ContentWrapperSizes } from '@app/shared/components';
import { Constants, Helper, SectionType, Tool } from '@app/shared/utils';
import { ClinicFeature } from '@app/shared/utils/enums/feature.enum';
import { DataSubscriber } from '@app/shared/utils/static-helpers/data-subscriber';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { startDailyAllowance } from '@app/pages/daily-allowance/store/actions/daily-allowance-steps.actions';
import { startWeightManagement } from '@app/pages/weight-management/store/actions/weight-management-steps.actions';
import { GtmEventsService } from '@app/core/services/tracking';
import { WeightManagementStep } from '@app/pages/weight-management/weight-management-step';
import { CoreFacade } from '@app/store/core/core.facade';
import { RCAlertType } from '@rc/ui';
import { getMUFAvailabilityError, getRDAvailabilityError, getWLPAvailabilityError } from '@app/shared/utils/static-helpers/patient-helper';
import { resetProductsData } from '@app/store/products';

export interface DropdownOption {
  id: string;
  icon: string;
  title: string;
  disabled: boolean;
}

@Component({
  selector: 'app-pet-page',
  templateUrl: './pet-page.component.html',
  styleUrls: ['./pet-page.component.scss'],
  providers: [PetCommunicationService],
})
export class PetPageComponent implements OnInit, OnDestroy {
  public contentWrapperSizes = ContentWrapperSizes;
  patientId: string;
  patient: Patient;
  consultations: Consultation[];
  alertType = RCAlertType;
  // Childs data
  lastConsultation: Consultation;
  // Tabs
  menuOptions = [
    {
      id: 'health',
      link: 'health',
      enabled: true,
      short: $localize`:@@tab_health_short:`,
      long: $localize`:@@tab_health_long:`,
    },
    {
      id: 'weight-management',
      link: 'weight-management',
      enabled: true,
      short: $localize`:@@tab_weight_short:`,
      long: $localize`:@@tab_weight_long:`,
    },
    {
      id: 'pet-profile',
      link: 'pet-profile',
      enabled: true,
      short: $localize`:@@tab_profile_short:`,
      long: $localize`:@@tab_profile_long:`,
    },
  ];
  currentTab = 'pet-profile';
  // pet-profile-header
  dropdownSelectedOption: DropdownOption;
  dropdownIsOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectables: DropdownOption[] = [];
  buttonTitle = $localize`:@@dropdown-list_title:`;
  /** Properties **/
  private _destroyed$ = new Subject();
  private _paginationType = SectionType;

  /** Initializer **/
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private marketService: MarketService,
    private enumsService: EnumsService,
    private loaderService: LoaderService,
    private gtmEventsService: GtmEventsService,
    private petCommunicationService: PetCommunicationService,
    private actionCommunicationService: ActionCommunicationService,
    private vetService: VetService,
    private observablesLayerService: ObservablesLayerService,
    private coreFacade: CoreFacade,
    private dialog: Dialog,
    private logger: Logger,
    private store$: Store<AppState>,
    private chartService: ChartService
  ) {}

  /** Life Cycle **/
  ngOnInit() {
    const url = this.router.url;
    const decomposedUrl = url.match(/[\/][^\?\/]+/g).map((e) => e.substring(1));
    this.currentTab = decomposedUrl[2];

    this.activateRoute.parent.params.subscribe((params) => {
      this.patientId = params['patientID'];
      this._fetchPatientAndConsultation(this.patientId);
      this._fetchMoreData();
    });

    this.petCommunicationService.petUpdates.pipe(takeUntil(this._destroyed$)).subscribe(this._onPetUpdate.bind(this));

    this.actionCommunicationService.showErrorSubject.pipe(takeUntil(this._destroyed$)).subscribe((message: string) => {
      this._handleError(message);
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    this.coreFacade.resetAlerts();
    this.store$.dispatch(resetPet());
  }

  onDropdownClick() {
    this.dropdownIsOpen$.next(!this.dropdownIsOpen$.value);
  }

  onDropdownSelect($event: any) {
    this.dropdownSelectedOption = $event;
    this.dropdownIsOpen$.next(false);
    if (
      this.lastConsultation &&
      this.lastConsultation.tool === Tool.WeightManagement &&
      this.dropdownSelectedOption.id === Tool.WeightManagement
    ) {
      this._dropdownSelectOptions(this.dropdownSelectedOption.id);
    } else if (this.lastConsultation && this.lastConsultation.tool === Tool.WeightManagement) {
      DataSubscriber.subscribe(
        this.dialog
          .open(ActiveProgramPopinComponent, {
            panelClass: 'small',
          })
          .afterClosed(),
        (data: any) => {
          if (data && data.stay) {
            this._dropdownSelectOptions(this.lastConsultation.tool);
          } else if (data && data.start) {
            this._dropdownSelectOptions(this.dropdownSelectedOption.id);
          }
        },
        this._destroyed$,
        this.logger.error
      );
    } else {
      this._dropdownSelectOptions(this.dropdownSelectedOption.id);
    }
  }

  private _fetchPatientAndConsultation(patientId: string) {
    const {
      patient$,
      patientError$,
      patientLoading$,
      consultations$,
      consultationsError$,
      consultationsLoading$,
      lastConsultation$,
      lastConsultationError$,
      lastConsultationLoading$,
      nextConsultation$,
      nextConsultationError$,
      nextConsultationLoading$,
    } = this.observablesLayerService.fetchPatientAndConsultations$(patientId);
    this._handlePatient(patient$);
    this._handleConsultations(consultations$);
    this._handlePatientAndConsultations(patient$, consultations$);
    this._handleLastConsultation(lastConsultation$);
    this._handleNextConsultation(nextConsultation$);
    this._handleErrors(patientError$, consultationsError$, lastConsultationError$, nextConsultationError$);
    this._handleLoading(patientLoading$, consultationsLoading$, lastConsultationLoading$, nextConsultationLoading$);
    this.store$.dispatch(getChartConsultations({ patientId }));
    this.store$.dispatch(getConsultationsWithoutVisit({ patientId }));
  }

  private _handlePatient(patient$: Observable<Patient>): void {
    DataSubscriber.subscribe(patient$, this._onPatientFetched.bind(this), this._destroyed$, console.error);
  }

  private _handlePatientAndConsultations(patient$: Observable<Patient>, consultations$: Observable<ConsultationApiData>) {
    consultations$
      .pipe(
        mergeMap((data: ConsultationApiData) => {
          this.consultations = data.result;
          return patient$;
        })
      )
      .subscribe((patient: Patient) => {
        this.patient = patient;
        this.patient.consultations = this.consultations;
        this.petCommunicationService.setPatient(this.patient);
      });
  }

  private _handleConsultations(consultations$: Observable<ConsultationApiData>): void {
    DataSubscriber.subscribe(consultations$, this._onConsultationsFetched.bind(this), this._destroyed$, console.error);
  }

  private _handleLastConsultation(lastConsultation$: Observable<Consultation>): void {
    DataSubscriber.subscribe(lastConsultation$, this._onLastConsultationFetched.bind(this), this._destroyed$, console.error);
  }

  private _handleNextConsultation(lastConsultation$: Observable<Consultation>): void {
    DataSubscriber.subscribe(lastConsultation$, this._onNextConsultationFetched.bind(this), this._destroyed$, console.error);
  }

  private _handleErrors(
    patientError$: Subject<string>,
    consultationsError$: Subject<string>,
    lastConsultationError$: Subject<string>,
    nextConsultationError$: Subject<string>
  ): void {
    DataSubscriber.subscribe(patientError$, this._onPatientError.bind(this), this._destroyed$, console.error);
    DataSubscriber.subscribe(consultationsError$, this._onConsultationsError.bind(this), this._destroyed$, console.error);
    DataSubscriber.subscribe(lastConsultationError$, this._onLastConsultationError.bind(this), this._destroyed$, console.error);
    DataSubscriber.subscribe(nextConsultationError$, this._onNextConsultationError.bind(this), this._destroyed$, console.error);
  }

  private _handleLoading(
    patientLoading$: BehaviorSubject<boolean>,
    consultationsLoading$: BehaviorSubject<boolean>,
    lastConsultationLoading$: BehaviorSubject<boolean>,
    nextConsultationLoading$: BehaviorSubject<boolean>
  ): void {
    DataSubscriber.subscribeLatest(
      [patientLoading$, consultationsLoading$, lastConsultationLoading$, nextConsultationLoading$],
      this._onLoading.bind(this),
      this._destroyed$,
      console.error
    );
  }

  // Set weight inside patient to display it in header
  private _setPatientWeight(patient: Patient, consultation: Consultation) {
    if (patient && consultation) {
      this.patient.pet.currentDisplayedWeight = consultation.visit.weight;
      this.patient = { ...this.patient };
    }
  }

  /**
   * Function for setting data taking account the patient
   */
  private _onPatientFetched(patient: Patient) {
    if (!patient) {
      return;
    }
    this.patient = patient;

    this._setPatientWeight(patient, this.lastConsultation);
    this.observablesLayerService
      .lifestage$(of(this.patient.pet.speciesCode), of(this.patient.pet.breedCode), of(this.patient.pet.birth.date))
      .lifestage$.subscribe((lifestage) => {
        if (lifestage) {
          this.patient.pet.lifeStage = lifestage;
        }
        this._buildDropdownOptions();
      });

    this.petCommunicationService.setPatient(patient);

    // We need patient.pet.speciesCode in order to filter breeds.
    this.enumsService
      .fetchBreeds()
      .pipe(take(1), takeUntil(this._destroyed$))
      .subscribe((breeds) => {
        this.petCommunicationService.setBreeds(breeds.filter((breed) => breed.speciesCode === patient.pet.speciesCode));
      });
  }

  private _onLastConsultationFetched(lastConsultation: Consultation): void {
    if (!lastConsultation) {
      return;
    }

    if (lastConsultation.tool !== Tool.WeightManagement) {
      if (this.currentTab === 'weight-management') {
        this._redirectToPetProfileTab();
      }
      this.menuOptions[1].enabled = false;
    }

    this.lastConsultation = lastConsultation;

    this._setPatientWeight(this.patient, lastConsultation);

    this.petCommunicationService.updateLastConsultation(lastConsultation);
  }

  private _onConsultationsFetched(data: ConsultationApiData): void {
    if (!data) {
      return;
    }
    const consultations = data.result;
    this.consultations = data.result;
    this.petCommunicationService.setMedicalRecord(consultations, data.count);
  }

  private _onNextConsultationFetched(consultation: Consultation): void {
    if (!consultation) {
      return;
    }
    this.petCommunicationService.updateNextConsultation(consultation);
    this._setPatientWeight(this.patient, consultation);
  }

  private _onPatientError(error: string): void {
    this.logger.errorString('PetCommunicationService - Patient', error);
    this._redirectToPetProfileTab();
    this.menuOptions[0].enabled = false;
  }

  private _onConsultationsError(error: string): void {
    this.logger.errorString('PetCommunicationService - Consultations', error);
    this._redirectToPetProfileTab();
    this.menuOptions[0].enabled = false;
  }

  private _onLastConsultationError(error: string): void {
    this.logger.errorString('PetCommunicationService - LastConsultation', error);
    this._redirectToPetProfileTab();
    this.menuOptions[0].enabled = false;
  }

  private _onNextConsultationError(error: string): void {
    this.logger.errorString('PetCommunicationService - NextConsultation', error);
    this._redirectToPetProfileTab();
    this.menuOptions[1].enabled = false;
  }

  private _redirectToPetProfileTab(): void {
    if (this.currentTab !== 'pet-profile') {
      this.currentTab = 'pet-profile';
      this.router.navigate(['patient', this.patientId, 'pet-profile'], { replaceUrl: true });
    }
  }

  private _onLoading(patientLoading: boolean): void {
    if (patientLoading) {
      this.loaderService.startLoader(true);
    } else {
      this.loaderService.stopLoader();
    }
  }

  /**
   * Fetch more medical record
   */
  private _fetchMoreData() {
    this.actionCommunicationService.paginationSubject.pipe(takeUntil(this._destroyed$)).subscribe(({ pageNumber, type }) => {
      this.actionCommunicationService.showLoader(true, type);

      switch (type) {
        case this._paginationType.Medical:
          this._fetchConsultationByPage(pageNumber, type);
          break;

        case this._paginationType.Weight:
          this.actionCommunicationService.showLoader(false, type);
          break;

        default:
          break;
      }
    });
  }

  /**
   * Fetch more medical record
   * @param pageNumber - number of page
   * @param type - Medical or Weight type
   */
  private _fetchConsultationByPage(pageNumber: number, type: string) {
    if (!this.patient) {
      this.logger.errorString('pet-page > _fetchConsultationByPage > patient not set');
    } else {
      this.vetService
        .consultationsByPatient(this.patient.id, pageNumber * Constants.LIMIT_CONSULTATIONS_PAGE)
        .pipe(take(1), takeUntil(this._destroyed$))
        .subscribe(
          (data: ConsultationApiData) => {
            this.petCommunicationService.setMedicalRecord(data.result, data.count);
            this.actionCommunicationService.showLoader(false, type);
          },
          (error) => {
            this.actionCommunicationService.showLoader(false, type);
            this.coreFacade.setAlert({ message: error, alertType: RCAlertType.ERROR });
            this.logger.errorString('_fetchConsultationByPage', error);
          }
        );
    }
  }

  /**
   * Put new values for a pet on the server and re-update view
   */
  private _onPetUpdate(petUpdate: Pet) {
    let currentPet = this.patient.pet;
    let updatedPet = Helper.mergeDeep({}, currentPet, petUpdate);
    this._updatePatientPet(updatedPet);
    this.vetService
      .updatePet(this.patient.petId, petUpdate)
      .pipe(take(1), takeUntil(this._destroyed$))
      .subscribe(
        (pet) => {
          currentPet = updatedPet;
          updatedPet = Helper.mergeDeep({}, currentPet, pet);
          this._updatePatientPet(updatedPet);
        },
        (error) => {
          this._updatePatientPet(currentPet);
          this.coreFacade.setAlert({ message: error, alertType: RCAlertType.ERROR });
          window.scrollTo(0, 0);
        }
      );
  }

  private _updatePatientPet(pet: Pet) {
    this.patient = {
      ...this.patient,
      pet: pet,
    };
    // Set weight inside patient to display it in header
    this._setPatientWeight(this.patient, this.lastConsultation);
    this.petCommunicationService.setPatient(this.patient);
  }

  /**
   * Function that handles API call error
   */
  private _handleError(error) {
    this.logger.errorString('pet-page > handleError > ', error);
    this.coreFacade.setAlert({ message: error, alertType: RCAlertType.ERROR });
    this.loaderService.stopLoader();
  }

  private _buildDropdownOptions(): void {
    combineLatest([
      this.marketService.isEnabled$(ClinicFeature.WM),
      this.marketService.isEnabled$(ClinicFeature.DA),
      this.marketService.isEnabled$(ClinicFeature.SR),
      this.marketService.isEnabled$(ClinicFeature.MUF),
      this.marketService.isEnabled$(ClinicFeature.RD),
    ])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        ([weightLossPlanEnabled, dailyAllowanceEnabled, productFinderEnabled, mufEnabled, renalDetectEnabled]: [
          boolean,
          boolean,
          boolean,
          boolean,
          boolean
        ]) => {
          this.selectables = [];
          if (weightLossPlanEnabled && !getWLPAvailabilityError(this.patient?.pet?.lifeStage)) {
            this.selectables.push({
              id: Tool.WeightManagement,
              icon: 'weight-loss',
              title: $localize`:@@home-page_weight-title:`,
              disabled: false,
            });
          }
          if (dailyAllowanceEnabled) {
            this.selectables.push({
              id: Tool.Rationing,
              icon: 'daily-allowance',
              title: $localize`:@@title_calculator:`,
              disabled: false,
            });
          }
          if (productFinderEnabled) {
            this.selectables.push({
              id: Tool.SmartReco,
              icon: 'smart-reco',
              title: $localize`:@@header-link_product_finder:`,
              disabled: false,
            });
          }
          if (mufEnabled && !getMUFAvailabilityError(this.patient?.pet?.lifeStage)) {
            this.selectables.push({
              id: Tool.Multifunction,
              icon: 'multifunctions',
              title: $localize`:@@home-page_multi-title:`,
              disabled: false,
            });
          }
          // enable RD menu just for adult Cat having more than 7 years
          if (renalDetectEnabled && !getRDAvailabilityError(this.patient?.pet?.speciesCode, this.patient?.pet?.birth?.date)) {
            this.selectables.push({
              id: Tool.RenalDetect,
              icon: 'multifunctions',
              title: $localize`:@@home-page_renal-detect-btn:`,
              disabled: false,
            });
          }
        }
      );
  }

  /**
   * Option for start consulation
   **/
  private _dropdownSelectOptions(optionId: string) {
    this.store$.dispatch(resetConsultationData());
    this.store$.dispatch(resetProductsData());
    this.store$.dispatch(setConsultationPatient({ value: this.patient }));
    this.store$.dispatch(setConsultationLastConsultation({ value: this.lastConsultation }));
    this.store$.dispatch(setTool({ tool: optionId as Tool, tool_flow_id: Helper.randomId() }));
    this.gtmEventsService.sendStartConsultation({
      patient: this.patient,
      tool: optionId as Tool,
      program: this.lastConsultation?.visit?.program,
    });
    switch (optionId) {
      case Tool.WeightManagement:
        this.store$.dispatch(
          this.lastConsultation?.tool === Tool.WeightManagement
            ? startWeightManagement({ step: WeightManagementStep.FollowUp })
            : startWeightManagement({ initFlow: false })
        );
        break;
      case Tool.Rationing:
        this.store$.dispatch(startDailyAllowance({ initFlow: false }));
        break;
      case Tool.SmartReco: {
        this.store$.dispatch(startSmartReco({ initFlow: false }));
        break;
      }
      case Tool.RenalDetect: {
        this.store$.dispatch(startRenalDetect({ initFlow: false }));
        break;
      }
      case Tool.Multifunction: {
        this.store$.dispatch(startMufPersonalisedBag({ initFlow: false }));
        break;
      }
      default:
        this.logger.warn('dropdownSelectOptions > invalid choice');
    }
  }
}
