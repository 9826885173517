import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ChartService } from '@app/core/services';
import { getChartConsultations, getChartConsultationsSuccess } from '@app/store/pet/chart.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { setAlert } from '@app/store/core';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { RCAlertType } from '@rc/ui';

@Injectable()
export class GetChartDataEffects {
  getChartConsultations = createEffect(() =>
    this.actions$.pipe(
      ofType(getChartConsultations),
      switchMap((action) =>
        this.chartService.getChartConsultationData(action.patientId).pipe(
          switchMap((chartConsultationWeights) => {
            return [getChartConsultationsSuccess({ chartConsultationWeights })];
          }),
          catchError(() => [
            setAlert({
              alert: { message: translateKey('error_general_text'), alertType: RCAlertType.ERROR },
            }),
          ])
        )
      )
    )
  );

  constructor(private actions$: Actions, private chartService: ChartService) {}
}
