import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef, DIALOG_DATA } from '@app/core/cdk/dialog';
import { PopinSize } from '@app/shared/utils';
import { CookieService } from 'ngx-cookie-service';
import { IconName, iconToolSrActive } from '@app/shared/utils/icon/icons';
import { IconRegistryService } from '@app/shared/utils/icon/icon-registry.service';
import { GTMService, welcomeTourPopin, welcomeTourStart, welcomeTourDontShowAgain, welcomeTourDiscard } from '@app/core/services/tracking';

@Component({
  selector: 'app-start-tour-popin',
  templateUrl: './start-tour-popin.component.html',
  styleUrls: ['./start-tour-popin.component.scss'],
})
export class StartTourPopinComponent implements OnInit {
  DialogSize = PopinSize;
  IconNameEnum = IconName;

  constructor(
    private registry: IconRegistryService,
    private cookieService: CookieService,
    private _dialogRef: DialogRef<StartTourPopinComponent>,
    private trackingService: GTMService,
    private router: Router,
    @Inject(DIALOG_DATA) public data: any
  ) {
    this.registry.registerIcons([iconToolSrActive]);
  }

  ngOnInit(): void {
    this.trackingService.sendInteraction(welcomeTourPopin);
  }

  startTour(): void {
    this.trackingService.sendInteraction(welcomeTourStart);
    this._dialogRef.close(true);
    this.router.navigate(['/tutorial']);
  }

  dontShowMessage(): void {
    this.cookieService.get('rc-smart-reco-tutorial') !== 'disabled' && this.trackingService.sendInteraction(welcomeTourDontShowAgain);
    this.cookieService.set('rc-smart-reco-tutorial', 'disabled');
  }

  leave(): void {
    this.trackingService.sendInteraction(welcomeTourDiscard);
    this.data.leaveTour();
    this._dialogRef.close(true);
  }
}
