/**
 * Values are used by CI
 * they should not be modified
 */
export enum Env {
  DEV = 'dev',
  UAT = 'uat',
  STG = 'stg',
  DEMO = 'demo',
  PRD = 'prd',
}
