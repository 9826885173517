import { Tool } from '@app/shared/utils';
import { ModuleNameEnum } from '../types/enums';

export const mapToolToModule = (tool: Tool): ModuleNameEnum =>
  ({
    [Tool.PersonalizedBag]: ModuleNameEnum.MULTIFUNCTION,
    [Tool.StarterKit]: ModuleNameEnum.MULTIFUNCTION,
    [Tool.Rationing]: ModuleNameEnum.DAILY_ALLOWANCE,
    [Tool.RenalDetect]: ModuleNameEnum.RENAL_DETECT,
    [Tool.SmartReco]: ModuleNameEnum.SMART_RECOMMENDATION,
    [Tool.WeightManagement]: ModuleNameEnum.WEIGHT_MANAGEMENT,
    [Tool.CreatePet]: ModuleNameEnum.UNDEFINED,
  }[tool]);
