import { DeprecatedCountryItem } from '@app/core/models';
import { Helper } from '@app/shared/utils';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoreState } from '.';
import { AppState } from '..';

const coreState = (state: AppState) => state.core;

export const selectShowLoader = createSelector(coreState, (state: CoreState) => state.showLoader);
export const selectLoaderTransparent = createSelector(coreState, (state: CoreState) => state.loaderTransparent);
/**
 * Selector for deprecated loader state
 */
export const selectDeprecatedLoaderState = createSelector(coreState, (state: CoreState) => state.deprecatedLoaderState);
/**
 * Selector for show progress bar
 * always returns false if loader or deprecated loader are visible
 */
export const selectShowProgressBar = createSelector(
  coreState,
  selectShowLoader,
  selectDeprecatedLoaderState,
  (state: CoreState, showLoader: boolean, deprecatedLoaderState: boolean) => !showLoader && !deprecatedLoaderState && state.showProgressBar
);
/*
 * Select available countries
 * it should have a translation
 * return format is for retrocompatibilty
 */
export const selectCountries = createSelector(coreState, (state: CoreState) =>
  state.localization?.countries
    .reduce((prev: DeprecatedCountryItem[], curr) => {
      const key = `country_${curr}`;
      const translation = translateKey(key);
      return key !== translation ? [...prev, { code: curr, localizedText: translation }] : prev;
    }, [])
    .sort((a, b) => Helper.alphabeticalOrder(a.localizedText, b.localizedText))
);
/**
 * Select available bcs pdf languages
 */
export const selectBCSLanguages = createSelector(coreState, (state: CoreState) => state.localization?.bcsLanguages);
/**
 * Built-in ngrx router-store selectors
 */
export const selectRouter = createFeatureSelector<RouterReducerState>('router');
export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getSelectors(selectRouter);

/**
 * Select tool information
 */
export const selectToolInfo = createSelector(coreState, ({ tool, tool_flow_id }: CoreState) => ({
  tool,
  tool_flow_id,
}));
/**
 * Select breeds
 */
export const selectBreeds = createSelector(coreState, (state) => state.breeds);
/**
 * Select alerts
 */
export const selectAlerts = createSelector(coreState, (state) =>
  // filter out duplicates
  state.alerts.filter(
    (alert, index, alerts) => index === alerts.findIndex((al) => al.message === alert.message && al.alertType === alert.alertType)
  )
);
