export enum IconType {
  Calculator = 'calculator',
  Consultation = 'consultation',
  Animal = 'animal',
  Medical = 'medical-record',
  PetRecord = 'pet-record',
  Reset = 'reset',
  BodyScore = 'body-score',
  Weight = 'weight',
  WeightTracking = 'weight-tracking',
  profile = 'pet_profile',
  Food = 'food',
  Pathologies = 'pathologies',
  AddPeople = 'add-people',
  ProfileActive = 'profile-active',
  SettingsActive = 'settings-active',
  Shop = 'shop',

  /* Segmented control: Icon */
  SegmentedWeight = 'segmented-weight',
}
