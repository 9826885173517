import { TemplateRef, ViewContainerRef, ElementRef, EmbeddedViewRef } from '@angular/core';

import { Portal, PortalOutlet } from '@app/core/cdk/portal/portal';

export class TemplatePortal<C = any> extends Portal<EmbeddedViewRef<C>> {
  templateRef: TemplateRef<C>;
  viewContainerRef: ViewContainerRef;
  context: C | undefined;

  constructor(template: TemplateRef<C>, viewContainerRef: ViewContainerRef, context?: C) {
    super();
    this.templateRef = template;
    this.viewContainerRef = viewContainerRef;
    this.context = context;
  }

  get origin(): ElementRef {
    return this.templateRef.elementRef;
  }

  attach(host: PortalOutlet, context: C | undefined = this.context): EmbeddedViewRef<C> {
    this.context = context;
    return super.attach(host);
  }

  detach(): void {
    this.context = undefined;
    return super.detach();
  }
}
