import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { loadTranslations } from '@locl/core';
import { getLocalizationData } from 'locale-loader';
import 'zone.js';

if (environment.production) {
  enableProdMode();
}

getLocalizationData().then((data) => {
  loadTranslations(data);
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
