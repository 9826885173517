import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Breed, Consultation, Patient, Pet, PetInfo, PetOwner, Product, Segmented } from '@app/core/models';
import { Market } from '@app/core/models/market';
import { Program } from '@app/core/models/program';
import { LocaleContent } from '@app/shared/data';
import { ActivityCode } from '@app/shared/utils/enums/activity-code.enum';
import { BreedSize } from '@app/shared/utils/enums/breed-size.enum';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';
import { LifestageType } from '@app/shared/utils/enums/lifestage.enum';
import { OrderStatusEnum } from '@app/shared/utils/enums/order-status.enum';
import { Programs } from '@app/shared/utils/enums/programs.enum';
import { AddressHelper } from '@app/shared/utils/static-helpers/address-helper';
import { MeasureHelper } from '@app/shared/utils/static-helpers/measure-helper';
import { VetPreferences } from '@app/shared/utils/vet-preferences';
import { differenceInCalendarYears, differenceInDays, differenceInMonths, differenceInYears, format, parseISO, sub } from 'date-fns';
import { v4 as uuid } from 'uuid';
import { Constants } from '../constants';
import { translateKey } from '@app/shared/utils/static-helpers/translate';

export class Helper {
  static readonly YOUNG_LIFESTAGES = [LifestageType.Junior, LifestageType.Baby, LifestageType.Kitten, LifestageType.Puppy];
  static readonly OLD_LIFESTAGES = [LifestageType.Adult, LifestageType.Senior, LifestageType.Mature];
  static readonly ALL_LIFESTAGES = [
    LifestageType.Junior,
    LifestageType.Baby,
    LifestageType.Kitten,
    LifestageType.Puppy,
    LifestageType.Adult,
    LifestageType.Senior,
    LifestageType.Mature,
    LifestageType.All,
  ];

  /**
   * @method setValueOnLocalStorage()
   * @description set value on the localStorage
   * @param key - Key to get the value from the localStorage
   * @param value - Value to store on the localStorage
   * @returns none
   */
  static setValueOnLocalStorage(key: string, value): void {
    localStorage.setItem(key, value);
  }

  /**
   * @method getValueFromLocalStorage()
   * @description get value on the localStorage
   * @param key - get the value from the localStorage by key
   * @return Object
   */
  static getValueFromLocalStorage(key: string): string | null {
    return localStorage.getItem(key);
  }

  static isInternetExplorer(): boolean {
    return navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
  }

  static isBrowserDeprecated(): boolean {
    const currentBrowser = this.getBrowserNameAndVersion();
    const browserName = currentBrowser.split('/')[0];
    const browserVersion = +currentBrowser.split('/')[1];
    return !(Constants.supportedBrowsers.has(browserName) && browserVersion >= Constants.supportedBrowsers.get(browserName));
  }

  static getBrowserNameAndVersion(): string {
    const userAgent = navigator.userAgent;
    let tem;
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|samsung|qq|baidu|kaios|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE/' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join('/').replace('OPR', 'Opera');
      }
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
      matchTest.splice(1, 1, tem[1]);
    }
    return matchTest.join('/');
  }

  static userUuid(): string {
    return VetPreferences.id;
  }

  static randomId(): string {
    return uuid();
  }

  /**
   * Simple object check.
   * @return boolean
   */
  static isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item) && !(item instanceof Date);
  }

  /**
   * Deep merge two objects.
   */
  static mergeDeep(target, ...sources) {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

  /**
   * Comparing two strings alphabetically
   */
  static alphabeticalOrder(str1: string, str2: string): number {
    const element1 = str1.toLowerCase();
    const element2 = str2.toLowerCase();
    if (element1 < element2) {
      return -1;
    }
    if (element1 > element2) {
      return 1;
    }
    return 0;
  }

  static range(size: number, startAt = 0): number[] {
    return Array(...Array(size)).map(function (_, i) {
      return i + startAt;
    });
  }

  /* Mixed / Undefined breeds */
  static isMixed(breed: Breed): boolean {
    return breed.breedCode.startsWith(Constants.mixedBreedCode);
  }

  static isUndefined(breed: Breed): boolean {
    return breed.breedCode === Constants.undefinedBreedCode;
  }

  /**
   * Check if one series of words in words begins with search string
   */
  static oneWordStartsWithSearch(words: string, search: string) {
    const wordsArray = words.toLowerCase().trim().split(/\s/);
    while (wordsArray.length > 0) {
      if (wordsArray.join(' ').startsWith(search)) {
        return true;
      }
      wordsArray.splice(0, 1);
    }
    return false;
  }

  /**
   * Transform patient data into a flat representation (pet-info), usable by forms
   */
  static patientToPetInfo(patient: Patient, lastConsultation: Consultation, breed: Breed): PetInfo {
    const pet = patient.pet;
    let localizedLastWeight: number;
    let localizedIBW: number;
    let bcs = 5;
    if (lastConsultation) {
      const lastWeight = lastConsultation.visit.weight;
      localizedLastWeight =
        Math.round(MeasureHelper.convertWeightToMeasure(lastWeight, VetPreferences.currentBigMeasurementUnit) * 100) / 100;
      localizedIBW =
        Math.round(MeasureHelper.convertWeightToMeasure(pet.idealBodyWeight, VetPreferences.currentBigMeasurementUnit) * 100) / 100;
      bcs = lastWeight.bcs;
    }

    return {
      name: pet.name,
      speciesCode: pet.speciesCode,
      gender: pet.genderCode,
      neutered: pet.neutered,
      reproductionStatus: pet.reproductionStatusCode,
      breedObject: breed,
      lifestage: pet.lifeStage,
      birthdate: pet.birth.date,
      petActivity: pet.petActivityCode || pet.petActivity || ActivityCode.Moderate,
      bcs: bcs,
      weight: localizedLastWeight,
      IBW: localizedIBW,
      pathologies: lastConsultation?.pathologies || [],
      sensitivities: lastConsultation?.sensitivities || [],
    };
  }

  static compareProductAsc(a: Product, b: Product): number {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  }

  static buildVisitDataUpdate(visitDataId: string, newDate: Date) {
    if (visitDataId === 'next' || visitDataId === 'init-date') {
      return {
        nextVisit: {
          expected: {
            visitDateTime: newDate,
          },
        },
      };
    } else if (visitDataId === 'current') {
      return {
        visit: {
          visitDateTime: newDate,
        },
      };
    }
  }

  /*
  Get the percentage difference between two numbers
   */
  static getPercentageDifference(oldValue, newValue): number {
    const differenceValue = oldValue - newValue;
    return (differenceValue / oldValue) * 100;
  }

  static getProgramTexts(program?: Programs): Program {
    switch (program) {
      case Programs.WEIGHT_LOSS:
        return {
          title: $localize`:@@wm-plan_wl-title:`,
          image: 'loss',
          imagePath: Constants.imgWeightProgramLoss,
          description: $localize`:@@wm-plan_wl-desc:`,
          name: program,
        };
      case Programs.WEIGHT_STABILISATION_STEP_1:
      case Programs.WEIGHT_STABILISATION_STEP_2:
        return {
          title: $localize`:@@wm-plan_ws-title:`,
          image: 'stabilization',
          imagePath: Constants.imgWeightProgramStabilization,
          description: $localize`:@@wm-plan_ws-desc:`,
          name: program,
        };
      default:
        return {
          title: $localize`:@@wm-plan_wm-title:`,
          image: 'maintenance',
          imagePath: Constants.imgWeightProgramMaintenance,
          description: $localize`:@@wm-plan_wm-desc:`,
          name: program,
        };
    }
  }

  static capitalize(string: string): string {
    return string[0].toUpperCase() + string.substring(1);
  }

  static getPetAgeObject(birthDate?: string | number | Date): { years: number; months: number; days: number } {
    if (!birthDate || (typeof birthDate !== 'string' && typeof birthDate !== 'number' && Number.isNaN(birthDate.getTime()))) {
      console.error(`Could not get pet age object. Parameter "${birthDate}" is not a string, number or Date. Returning empty age.`);

      return { years: 0, months: 0, days: 0 };
    }

    const today = new Date();
    const birth = new Date(birthDate);

    const years = differenceInYears(today, birth);
    const months = differenceInMonths(sub(today, { years }), birth);
    const days = differenceInDays(sub(today, { years, months }), birth);

    return { years, months, days };
  }

  /**
   * Calculate age from date of birth
   * Returns years & months & days as text if not equal 0
   */
  static getPetAgeText(birthDate): string {
    const yearsWord = $localize`:@@word_years:`;
    const monthsWord = $localize`:@@word_months:`;
    const daysWord = $localize`:@@word_days:`;
    const petAgeObject = Helper.getPetAgeObject(birthDate);
    let petAgeText = '';
    let countAttributes = 0;
    if (petAgeObject) {
      if (petAgeObject.years && petAgeObject.years > 0) {
        countAttributes++;
        petAgeText += petAgeObject.years + ' ' + yearsWord;
      }
      if (petAgeObject.months && petAgeObject.months > 0) {
        countAttributes++;
        if (petAgeText.includes(yearsWord)) {
          petAgeText += ' & ';
        }
        petAgeText += petAgeObject.months + ' ' + monthsWord;
      }
      if (petAgeObject.days && petAgeObject.days > 0 && countAttributes <= 1) {
        if (petAgeText.includes(yearsWord) || petAgeText.includes(monthsWord)) {
          petAgeText += ' & ';
        }
        petAgeText += petAgeObject.days + ' ' + daysWord;
      }
    }

    return petAgeText;
  }

  static getFormattedDate(date): string {
    return format(new Date(date), 'do MMM, yyyy');
  }

  /**
   * Transform a flat petInfo objects (for form) to a Pet object (for backend)
   */
  static petInfoToPet(petInfo: PetInfo): Pet {
    const now = new Date();
    return {
      neutered: petInfo.neutered,
      petActivityCode: petInfo.petActivity,
      reproductionStatusCode: petInfo.reproductionStatus,
      name: petInfo.name || '',
      speciesCode: petInfo.speciesCode,
      genderCode: petInfo.gender,
      ICD: petInfo.breedObject.ICD,
      breedCode: petInfo.breedObject.breedCode,
      breed: petInfo.breedObject.localName,
      birth: {
        date: petInfo.birthdate,
      },
      idealBodyWeight: {
        weightDate: now,
        bcs: petInfo.bcs,
        measure: petInfo.IBW,
        measureUnit: VetPreferences.currentBigMeasurementUnit,
        type: 3,
      },
    };
  }

  static hasAdultTargetWeight(lifeStageType: LifestageType): boolean {
    return [LifestageType.Baby, LifestageType.Junior, LifestageType.Puppy, LifestageType.Kitten].includes(lifeStageType);
  }

  static cleanSizeCategory(breedSize: string): BreedSize {
    if (!breedSize) {
      return null;
    }
    const breedSizeLowercase = breedSize.toLowerCase();
    if (breedSizeLowercase === 'xsmall' || breedSizeLowercase === 'extra-small' || breedSizeLowercase === 'x-small') {
      return BreedSize.XSmall;
    }
    if (breedSizeLowercase === 'xlarge' || breedSizeLowercase === 'extra-large' || breedSizeLowercase === 'x-large') {
      return BreedSize.XLarge;
    }
    if ([BreedSize.XSmall, BreedSize.Small, BreedSize.Medium, BreedSize.Large, BreedSize.XLarge].includes(breedSize as BreedSize)) {
      return breedSize as BreedSize;
    }
    return null;
  }

  static getEmail(petOwner: PetOwner): string {
    if (petOwner && petOwner.email) {
      return petOwner.email;
    }
    return '';
  }

  static mapTypeToRcSegmented(item): Segmented {
    return {
      label: item.label,
      icon: item.icon,
      value: item.value,
    };
  }

  static parseDate(date: Date | string | number) {
    if (!date) {
      return null;
    }
    switch (typeof date) {
      case 'string':
        return parseISO(date);
      case 'number':
        return new Date(date);
      default:
        return date;
    }
  }

  static getterPetBreedCode(pet: Pet): string {
    return pet.breedCode || pet.speciesCode + '-' + pet.ICD || '';
  }

  static lifestageOld(lifestage: LifestageType): boolean {
    switch (lifestage) {
      case LifestageType.Baby:
      case LifestageType.Puppy:
      case LifestageType.Kitten:
      case LifestageType.Junior:
        return false;
      case LifestageType.Adult:
      case LifestageType.Mature:
      case LifestageType.Senior:
      default:
        return true;
    }
  }

  static includesOne<T>(coll1: T[], coll2: T[]): boolean {
    for (const e of coll1) {
      if (coll2.includes(e)) {
        return true;
      }
    }
    return false;
  }

  static catalogLang(preferredLang: string, market: Market | undefined): string {
    const countryCatalogLanguages = market?.catalog;
    if (!countryCatalogLanguages) {
      return preferredLang || LanguageCode.enGB;
    } else if (countryCatalogLanguages.includes(preferredLang as LanguageCode)) {
      return preferredLang;
    }
    if (!preferredLang) {
      return countryCatalogLanguages[0];
    }
    for (const catalogLang of countryCatalogLanguages) {
      if (catalogLang.substring(0, 2) === preferredLang.substring(0, 2)) {
        return catalogLang;
      }
    }
    return countryCatalogLanguages[0];
  }

  // Country lang is something like "en-GB", lang is "en"
  static countryLangToLang(countryLang: string): string {
    if (!countryLang) {
      return 'en';
    }
    if (countryLang.length === 2) {
      return countryLang;
    }
    if (countryLang === 'no-NO') {
      return 'nb';
    }
    return countryLang.toLowerCase().substring(0, 2);
  }

  static mufTransformCountryCode(countryCode: string, postalCode: string): string {
    if (countryCode === 'FI') {
      if (Constants.axPostalCodes.includes(postalCode)) {
        return 'AX';
      }
      return 'FI';
    }
    return countryCode;
  }

  /************************
   * ============
   * FORM HELPERS
   * ============
   **********************/
  static getFormErrors(form: FormGroup, fields: string[]) {
    for (const field of fields) {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
      control.updateValueAndValidity({ onlySelf: true });
    }
  }

  static resetFieldsValidators(form: FormGroup, fields: string[]): FormGroup {
    for (const field of fields) {
      form.controls[field].clearValidators();
      form.controls[field].updateValueAndValidity();
    }
    return form;
  }

  static setFieldsValidator(form: FormGroup, fields: string[], otherValidator: ValidatorFn[] = []) {
    for (const field of fields) {
      form.controls[field].setValidators([Validators.required, ...otherValidator]);
      form.controls[field].updateValueAndValidity();
    }
  }

  static translateLocalizedContent(localizedContent: LocaleContent[], sort = false): LocaleContent[] {
    let lContent = localizedContent;
    if (sort) {
      lContent = lContent.sort((a, b) => Helper.alphabeticalOrder(a.localizedText, b.localizedText));
    }
    return lContent;
  }

  static mapKeyStatus(status: string): string {
    switch (status) {
      case OrderStatusEnum.Pending:
        return 'pending';
      case OrderStatusEnum.AwaitingApproval:
        return 'awaiting-approval';
      case OrderStatusEnum.Validated:
        return 'validated';
      case OrderStatusEnum.Processed:
        return 'processed';
      case OrderStatusEnum.Personalized:
        return 'personalized';
      case OrderStatusEnum.ReadyForWMS:
        return 'ready-for-wms';
      case OrderStatusEnum.Shipped:
        return 'shipped';
      case OrderStatusEnum.AwaitingLoyalty:
        return 'awaiting-b2b-loyalty';
      case OrderStatusEnum.ReadyForVet:
        return 'ready-for-vet-invoiced';
      case OrderStatusEnum.Invoiced:
        return 'invoiced';
      case OrderStatusEnum.Closed:
        return 'closed';
      case OrderStatusEnum.Refused:
        return 'refused';
      case OrderStatusEnum.Canceled:
        return 'canceled';
    }
  }

  /*
  It returns an object containing only the updated keys:values
  It returns the difference between two objects of same type
   */
  static objectsDifference<T>(updatedObject: T, originalObject: T) {
    if (!originalObject) {
      return updatedObject;
    }

    const updatedKeys: string[] = Object.keys(updatedObject).filter(
      (k) => JSON.stringify(updatedObject[k]) !== JSON.stringify(originalObject[k])
    );

    return Object.keys(updatedObject)
      .filter((key) => updatedKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = updatedObject[key];
        return obj;
      }, {});
  }

  static arrayRemoveDoubles(arr: string[]): string[] {
    return arr.filter((item, pos) => {
      return arr.indexOf(item) === pos;
    });
  }

  static cleanMufTelephoneFormat(telephone: string, defaultDialCode?: string): string {
    if (telephone && telephone.includes(' ') && (telephone.match(/ /g) || []).length === 1) {
      return telephone.replace(/\s/g, '#');
    } else if (telephone && telephone.includes('#') && (telephone.match(/#/g) || []).length === 1) {
      return telephone.replace('#', ' ');
    } else if (telephone && telephone.length > 0 && !telephone.startsWith('+') && defaultDialCode && defaultDialCode.length > 0) {
      return Helper.cleanMufTelephoneFormat(AddressHelper.parseExternalPhoneNumber(telephone, defaultDialCode));
    } else if (!telephone || telephone.length === 0) {
      return '';
    } else {
      console.error('cleanMufTelephoneFormat > contains neither space nor # > ', telephone);
      return telephone;
    }
  }

  /**
   * function return the pet age depends on birthdate
   */
  static _petAge(pet: Pet): string {
    const currentDate = new Date();

    if (pet.birth) {
      return `${differenceInCalendarYears(currentDate, Helper.parseDate(pet.birth.date))}`;
    } else {
      return 'undefined';
    }
  }

  static randomString(length) {
    let string = '';
    const char = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      string += char.charAt(Math.floor(Math.random() * char.length));
    }
    return string;
  }

  static generatePetEmailAdress(petBirthDate: Date | string, petName: string, mailEnd = 'vet.services'): string {
    const petBirthDateEmailFieldFormat = format(new Date(petBirthDate), 'ddMMyyyy');
    const petNameFormatted = petName
      .toLowerCase() // Lower case
      .replace(/\s+/g, '_') // Replace spaces by _
      .normalize('NFD') // Remove accents
      .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); // Whitelist only letters and _
    return `${petNameFormatted}.${petBirthDateEmailFieldFormat}@${mailEnd}`;
  }

  static getNumberOfDays(start, end): number {
    const date1 = new Date(start);
    const date2 = new Date(end);
    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = date2.getTime() - date1.getTime();
    return Math.round(diffInTime / oneDay);
  }

  static daysToWeeks(days): number {
    return Math.floor(days / 7);
  }

  static getLocalBrowser(): { languageCode: string; countryCode: string } {
    const parts = window.navigator.language.split('-');
    return {
      languageCode: parts[0].toLowerCase(),
      countryCode: (parts[1] || '').toUpperCase(),
    };
  }

  static formatBCSText(value: number): string {
    switch (value) {
      case 1:
      case 2:
      case 3:
        return translateKey('bcs_cat_dog_1_2_3_title');
      case 4:
      case 5:
        return translateKey('bcs_cat_dog_4_5_title');
      case 8:
      case 9:
        return translateKey('bcs_cat_dog_8_9_title');
      default:
        return translateKey(`bcs_cat_dog_${value}_title`);
    }
  }
}
