import { Component, EventEmitter, forwardRef, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { AttributeType, FormField, InputType, PetProfileFormValueKeys } from '@app/core/models';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as objectPath from 'object-path';
import { MeasureHelper, VetPreferences } from '@app/shared/utils';
import { InputStateType } from '@app/shared/utils/enums/input-state.enum';
import { getPlaceholders } from '@app/shared/utils/static-helpers/pet-profile-form-helper';
import { IconName } from '@app/shared/utils/icon/icons';

@Component({
  selector: 'app-rc-input',
  templateUrl: './rc-input.component.html',
  styleUrls: ['./rc-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RcInputComponent),
      multi: true,
    },
  ],
})
export class RcInputComponent implements OnInit, ControlValueAccessor {
  public readonly IconName = IconName;
  AttributeType = AttributeType;
  InputType = InputType;
  InputSate = InputStateType;
  objectPath = objectPath;
  weightSuffix = MeasureHelper.measureUnitToSuffix(VetPreferences.currentBigMeasurementUnit);
  fieldInError = false;
  placeholders = getPlaceholders();
  PetProfileFormValueKeysEnum = PetProfileFormValueKeys;

  @Input() field: FormField;
  @Input() formControl: FormControl;
  @Input() value: any = null;

  @Output() changeValue: EventEmitter<any> = new EventEmitter();

  private _lastVal = null;

  constructor(@Inject(LOCALE_ID) protected localeId: string) {}

  ngOnInit() {
    this.formControl.statusChanges.subscribe((_validationStatus) => {
      this.setFieldInError(this.formControl);
    });
  }

  propagateChange(_: any) {
    // empty
  }
  onTouchedFn(_: any) {
    // empty
  }

  writeValue(value: any) {
    if (value === null) {
      this._lastVal = value;
    }

    if (this.value || value) {
      this.value = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouchedFn = fn;
  }

  onTouched() {
    this.onTouchedFn(null);
    this.setFieldInError(this.formControl);
    return true;
  }

  onChange(changed: Date | HTMLInputElement | HTMLTextAreaElement | number | string): void {
    const eventTargetValue = changed instanceof EventTarget ? changed.value : changed;
    if (eventTargetValue !== this.value) this.value = eventTargetValue;

    let val: string | number | Date;

    switch (this.field.type) {
      case AttributeType.Weight:
      case AttributeType.Number: {
        val = eventTargetValue ? +eventTargetValue : null;
        break;
      }
      default: {
        if (this.field.id === 'phone') {
          val = this._onPhoneNumberField();
        } else {
          val = eventTargetValue;
        }
      }
    }

    if (val !== this._lastVal) {
      this._lastVal = val;
      this.propagateChange(val);
      this.changeValue.emit(val);
      this.setFieldInError(this.formControl);
    }
  }

  setFieldInError(formControl: FormControl) {
    this.fieldInError = formControl && formControl.invalid && formControl.dirty && formControl.touched;
  }

  private _onPhoneNumberField(): string {
    let phoneValue = this.value;
    if (phoneValue && phoneValue.startsWith('0')) {
      phoneValue = phoneValue.replace('0', '');
    }
    return phoneValue;
  }
}
