interface DialCode {
  name: string;
  dial_code: string;
  code: string;
  dial_code_number?: number;
}

export class CountriesDialCode {
  static cleanDialCodes(dialCodes: DialCode[]): DialCode[] {
    const dialCodesSet = new Set<number>();
    return dialCodes
      .filter((item) => item.dial_code !== '' && item.dial_code !== undefined && item.dial_code !== null)
      .map((item) => {
        return {
          dial_code: item.dial_code.replace(/\s/gi, ''),
          code: item.code,
          name: item.name,
          dial_code_number: +item.dial_code.replace(/[\s\+]/gi, ''),
        };
      })
      .filter((item) => {
        if (!dialCodesSet.has(item.dial_code_number)) {
          dialCodesSet.add(item.dial_code_number);
          return true;
        }
        return false;
      })
      .sort((item1, item2) => {
        return item1.dial_code_number - item2.dial_code_number;
      });
  }
}
