import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, Inject, Injectable, Injector, NgZone } from '@angular/core';

import { DomPortalOutlet } from '@app/core/cdk/portal';

import { OverlayConfig } from './overlay-config';
import { OverlayContainer } from './overlay-container';
import { OverlayRef } from '@app/core/cdk/overlay/overlay-ref';

/** Next overlay unique ID. */
let nextUniqueId = 0;

@Injectable()
export class Overlay {
  private _appRef: ApplicationRef;

  constructor(
    private _overlayContainer: OverlayContainer,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _ngZone: NgZone,
    @Inject(DOCUMENT) private _document: any
  ) {}

  /**
   * Create Overlay
   */
  create(config?: OverlayConfig) {
    const host = this._createHostElement();
    const pane = this._createPaneElement(host);
    const portalOutlet = this._createPortalOutlet(pane);
    const overlayConfig = new OverlayConfig(config);

    return new OverlayRef(portalOutlet, host, pane, overlayConfig, this._ngZone, this._document);
  }

  private _createHostElement() {
    const host = this._document.createElement('div');
    this._overlayContainer.getContainerElement().appendChild(host);

    return host;
  }

  private _createPaneElement(host: HTMLElement): HTMLElement {
    const pane = this._document.createElement('div');

    pane.id = `rc-overlay-${nextUniqueId++}`;
    pane.classList.add('rc-overlay-pane');
    host.appendChild(pane);

    return pane;
  }

  private _createPortalOutlet(pane: HTMLElement): DomPortalOutlet {
    if (!this._appRef) {
      this._appRef = this._injector.get<ApplicationRef>(ApplicationRef);
    }

    return new DomPortalOutlet(pane, this._componentFactoryResolver, this._appRef, this._injector);
  }
}
