import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class OverlayContainer implements OnDestroy {
  protected _containerElement: HTMLElement;
  protected _document: Document;

  constructor(@Inject(DOCUMENT) document: any) {
    this._document = document;
  }

  ngOnDestroy() {
    if (this._containerElement && this._containerElement.parentNode) {
      this._containerElement.parentNode.removeChild(this._containerElement);
    }
  }

  getContainerElement(): HTMLElement {
    if (!this._containerElement) {
      this._createContainer();
    }

    return this._containerElement;
  }

  private _createContainer() {
    const containerClass = 'rc-overlay-container';
    const previousContainers = this._document.getElementsByClassName(containerClass);
    const container = this._document.createElement('div');

    for (let i = 0; i < previousContainers.length; i++) {
      previousContainers[i].parentNode.removeChild(previousContainers[i]);
    }

    container.classList.add(containerClass);
    this._document.body.appendChild(container);
    this._containerElement = container;
  }
}
