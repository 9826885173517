import { Product } from '@app/core/models';
import { createAction, props } from '@ngrx/store';
import { MufStarterKitStep } from '../../muf-starter-kit-step';
/**
 * Action dispatched to start a daily allowance flow
 * It resets daily allowance store data and consultation store data
 */
export const startMufStarterKit = createAction('[Muf Starter Kit] Start', props<{ products?: Product[]; renew?: boolean }>());
/**
 * Set current step
 */
export const setMufStarterKitStep = createAction('[Muf Starter Kit] Set Step', props<{ value: MufStarterKitStep }>());
export const setMufStarterKitIsRenewal = createAction('[Muf Starter Kit] Set Is Renewal', props<{ isRenewal: boolean }>());
/**
 * Reset daily allowance store
 */
export const resetMufStarterKit = createAction('[Muf Starter Kit] Reset');
