import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output, HostListener, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputStateType } from '@app/shared/utils/enums/input-state.enum';
import * as objectPath from 'object-path';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rc-select',
  templateUrl: './rc-select.component.html',
  styleUrls: ['./rc-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RcSelectComponent),
      multi: true,
    },
  ],
})
export class RcSelectComponent implements ControlValueAccessor, OnDestroy {
  @HostListener('document:click', ['$event'])
  hideDropDown(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this._hideDropdown();
    }
  }
  /**
   * Properties
   */
  @Input() showPrefix = false;
  @Input()
  set labelPath(labelPath: string) {
    this._labelPath$.next(labelPath);
  }
  get labelPath() {
    return this._labelPath$.value;
  }
  @Input()
  set keyPath(keyPath: string) {
    this._keyPath$.next(keyPath);
  }
  get keyPath() {
    return this._keyPath$.value;
  }
  @Input() disabled: boolean;
  @Input() label;
  @Input() required;

  inputError: boolean;
  isFocused = false;
  isSelect = true;

  @Input() set errorState(value: string) {
    this.inputError = value === InputStateType.Failed;
  }

  @Input()
  set value(val) {
    this._changeValue = val;
    this._trySetChangeValue();
  }

  get value() {
    return this._value;
  }

  @Input()
  set items(attributes: any[]) {
    combineLatest([this._keyPath$, this._labelPath$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([keyPath, labelPath]) => {
        if (keyPath && labelPath) {
          this._items = this._mapLabels(attributes);
          this.onItemsSet(this._items);
          this._trySetChangeValue();
        }
      });
  }

  get items() {
    return this._items || [];
  }

  @Input() placeholder: string;

  // Return the key value selected as the event
  @Output() didSelectValue: EventEmitter<any> = new EventEmitter();

  labelValue: string;
  _keyPath$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  _labelPath$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  _items: any[];
  dropDownOpen = false;
  private _value: any;
  private _changeValue: any;
  private _config = {
    backgroundColor: 'transparent',
    zIndex: '0',
    hasDismiss: true,
  };
  _destroyed$ = new Subject();

  _overlay: HTMLElement;

  /**
   * Initializer
   */
  constructor(private elementRef: ElementRef) {}

  private _searchItem(value) {
    if (!this.items) {
      return null;
    }
    return this.items.filter((item) => this.optionValue(item) === value)[0];
  }

  private _trySetChangeValue() {
    if (this._changeValue) {
      // While _changeValue isn't found in items, we can't set it. We keep it in _changeValue and wait for _items to change and retry
      const matchingItem = this._searchItem(this._changeValue);
      if (matchingItem) {
        this.updateValue(matchingItem);
      } else {
        // Ensure that the current value is still in the list of items
        if (!this._searchItem(this._value)) {
          this.updateValue(null);
        }
      }
    } else {
      this.updateValue(this._changeValue);
    }
  }

  updateValue(item?) {
    const prev = this._value;
    const val = this.optionValue(item);
    this._value = val;
    const label = item ? item.__label : '';
    this.labelValue = label;
    this.onValueSet(prev, val, label);
  }

  // Called when value is set (for override)
  onValueSet(prev, next, label): void {
    // empty
  }
  // Called when items are set (for override)
  onItemsSet(items: any[]): void {
    // empty
  }

  /**
   * Four functions for NG_VALUE_ACCESSOR
   */
  propagateChange = (_: any) => {
    // empty
  };

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // empty
  }

  /**
   * Show select list with options
   * @param event - Click or Touch event
   */
  toggleSelectList(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.disabled || !this.items || this.items.length === 0) {
      return;
    }

    this.dropDownOpen ? this._hideDropdown() : this._openDropdown();
  }

  /** Perf improvement: Set __label of every item so that it's quickly retrieved in view **/
  private _mapLabels(items: any[]): any[] {
    if (items && Array.isArray(items)) {
      return items.map((item) => {
        return {
          ...item,
          __key: this.optionValue(item),
          __label: this.optionLabel(item),
        };
      });
    } else {
      return items;
    }
  }

  /**
   * Get value from the key
   * @param item - Single object
   */
  optionLabel(item: any): string {
    if (!item) {
      return '--';
    }
    return objectPath.get(item, this.labelPath ? this.labelPath : this.keyPath, '--');
  }
  optionValue(item: any): any {
    if (!item) {
      return item;
    }
    return objectPath.get(item, this.keyPath, null);
  }

  /**
   * Update value & send event to the productForm
   * @param event - Click or Touch event
   * @param item - Single object
   */
  didSelectOption(event?, item?) {
    if (event) {
      event.preventDefault();
    }

    this.updateValue(item);
    this.writeValue(item);

    const value = this.optionValue(item);
    this.propagateChange(value);
    this.didSelectValue.emit(value);
  }

  /**
   * Show select list with option
   * 1 - Wait 5ms before to listen click event on the transparent overlay
   */
  _openDropdown(): void {
    if (!this.dropDownOpen) {
      this.dropDownOpen = true;
    }
  }

  /**
   * Hide select list
   */
  _hideDropdown() {
    if (this.dropDownOpen) {
      this.dropDownOpen = false;
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
