import { Coverage, PetInfo, Product, ProductRecommendation, ProductRecommendationBack } from '@app/core/models';
import { NotRecommendedProductStatus, NotRecommendedReasons, ProductPrograms, Programs } from '../enums';
import { Helper } from './helper';

/**
 * Round to integer the smart reco score (pmean)
 * @param pmean Reco score
 * Static util
 */
export function roundSmartReco(pmean: number): number {
  if (pmean < 0) {
    return -1;
  }
  return Math.round(pmean * 100);
}

export function areProductsForWeightLoss(products: Product[]): boolean {
  return products.some((product) => product.programs.includes(ProductPrograms.WEIGHT_LOSS));
}

export function getProgramFromPetInfoAndProducts(products: Product[], petInfo: PetInfo, targetProgram?: Programs): Programs {
  if (targetProgram) return targetProgram;

  if (petInfo.lifestage && Helper.hasAdultTargetWeight(petInfo.lifestage)) {
    return Programs.GROWTH;
  }

  if (petInfo.bcs > 5 && areProductsForWeightLoss(products)) {
    return Programs.WEIGHT_LOSS;
  }

  return Programs.NORMAL_ADULT;
}

export function filterCoverageCriterias(coverage: Coverage, petInfo: PetInfo, notRecommendedReasons?: NotRecommendedReasons[]): Coverage {
  return {
    ...coverage,
    criterias: coverage.criterias.filter((criteria) => {
      const isAdult = !Helper.hasAdultTargetWeight(petInfo.lifestage);
      const isHealthy = petInfo.pathologies.length === 0 && petInfo.sensitivities.length === 0;

      const isSizeOrLifestageCriteria = ['size_lifestage', 'lifestage'].includes(criteria.type);
      const isBreedCriteria = ['breed_dog', 'breed_cat'].includes(criteria.type);
      const mkfRelatedCriteria = notRecommendedReasons?.find((reason) => criteria.type.includes(reason) || criteria.key.includes(reason));

      // remove the breed related criterias that must never be displayed
      // remove the size/lifestage criteria if the pet is a sick adult
      return !isBreedCriteria && !(isAdult && !isHealthy && isSizeOrLifestageCriteria) && !mkfRelatedCriteria;
    }),
  };
}

export function formatNotRecommendedProduct(
  notRecommendedProduct: Pick<ProductRecommendationBack, 'coverage' | 'productsFilterPossibilities'>,
  product: Product,
  petInfo: PetInfo
): ProductRecommendation {
  const notRecommendedReasons = Object.keys(notRecommendedProduct.productsFilterPossibilities?.case_all?.notRecommendedReason || {})
    .map((key) => (key.includes(NotRecommendedReasons.LIFESTAGE) ? NotRecommendedReasons.LIFESTAGE : key))
    .filter((key) => Object.values(NotRecommendedReasons).includes(key as NotRecommendedReasons)) as NotRecommendedReasons[];

  return {
    product,
    coverage: notRecommendedProduct.coverage
      ? filterCoverageCriterias(notRecommendedProduct.coverage, petInfo, notRecommendedReasons)
      : null,
    notRecommendedReasons,
  };
}

export function getNotRecommendedProductStatus(product: ProductRecommendation): NotRecommendedProductStatus {
  if (product.coverage?.pmean >= 0.65) {
    return NotRecommendedProductStatus.REQUIREMENTS_OK;
  }
  if (product.coverage?.pmean < 0.65 && product.coverage?.pmean >= 0.4) {
    return NotRecommendedProductStatus.REQUIREMENTS_PARTIAL;
  }
  if (product.coverage?.pmean < 0.4) {
    return NotRecommendedProductStatus.REQUIREMENTS_KO;
  }
}
