import { PanelSectionTypeEnum } from '@app/shared/utils';
import { createAction, props } from '@ngrx/store';
import { DailyAllowanceStep } from '../../daily-allowance-step';
/**
 * Action dispatched to start a daily allowance flow
 * It resets daily allowance store data and consultation store data
 */
export const startDailyAllowance = createAction(
  '[Daily Allowance] Start',
  props<{ initFlow: boolean; withIdentification?: boolean; persist?: boolean }>()
);
/**
 * Set current step
 */
export const setDailyAllowanceStep = createAction('[Daily Allowance] Set Step', props<{ value: DailyAllowanceStep }>());
/**
 * Set steps
 */
export const setDailyAllowanceSteps = createAction('[Daily Allowance] Set Steps', props<{ value: DailyAllowanceStep[] }>());
/**
 * Reset daily allowance store
 */
export const resetDailyAllowance = createAction('[Daily Allowance] Reset');
/**
 * Navigate back to step from panel from another module (allowance) during consultation
 */
export const navigateBackToStep = createAction('[Daily Allowance] Navigate back to step', props<{ value: PanelSectionTypeEnum }>());
