<rc-dialog>
  <rc-dialog-content class="rc-dialog-content--vertical-align">
    <div class="container-dialog">
      <div class="container-dialog__body">
        <!-- Image -->
        <div class="container-dialog__body-thumb">
          <img alt="" src="assets/other/alert_cat.png" />
        </div>

        <!-- Text -->
        <h3 class="rc-title-gamma" i18n="@@popin_active-program_title">Active programme</h3>
        <p class="rc-paragraph" i18n="@@popin_active-program_msg">
          It seams that this pet is already under a weight loss programme, starting a new programme will end the current one.
        </p>
      </div>

      <div class="container-dialog__footer">
        <div class="rc-button-group">
          <button
            class="rc-button rc-button--filled-small"
            type="button"
            (click)="startNewConsultation()"
            id="pet-page__program-popin__start"
          >
            <span class="rc-button__title" i18n="@@popin_active-program_start">Start a new consultation</span>
          </button>
          <span class="rc-button__separator btn-separator" i18n="@@action_separator">or</span>
          <button class="rc-button" type="button" (click)="stay()" id="pet-page__program-popin__stay">
            <span class="rc-button__title rc-button__title--link" i18n="@@popin_active-program_stay">Stay in active programme</span>
          </button>
        </div>
      </div>
    </div>
  </rc-dialog-content>
</rc-dialog>
