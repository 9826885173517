export class OverlayConfig {
  panelClass?: string = '';
  hasBackdrop?: boolean = false;
  backdropClass?: string = 'rc-overlay-backdrop--dark';
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;

  disposeOnNavigation?: boolean = false;

  constructor(config?: OverlayConfig) {
    if (config) {
      const configKeys = Object.keys(config) as Array<keyof OverlayConfig>;

      for (const key of configKeys) {
        if (config[key] !== undefined) {
          const self = this as any;
          self[key] = config[key] as any;
        }
      }
    }
  }
}
