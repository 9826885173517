/**
 * Enum representing features present in clinic.features
 * mostly used for market feature toggle
 * values are matching backend values
 * they should not be changed
 */
export enum ClinicFeature {
  DA = 'daily_allowance',
  RD = 'renal_detect',
  WM = 'weight_management',
  SR = 'smart_reco',
  MUF = 'muf',
}
/**
 * Enum representing features not present in clinic.features
 * mostly used for market feature toggle
 * Values are app scoped
 * they can be changed
 */
export enum AppFeature {
  MUF_SK = 'muf_starter_kits',
  MUF_PB = 'muf_personalized_bags',
}
