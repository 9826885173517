import { selectConsultationRenalDetectRecommendation } from '@app/store/consultation/consultation.selectors';
import { PetInfo } from '@app/core/models';
import { selectBreeds } from '@app/store/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { dailyAllowanceFeatureKey } from '../daily-allowance-feature-key';
import { DailyAllowanceState } from '../daily-allowance.state';
import { selectDailyAllowanceProfile, selectDailyAllowanceWeight } from './daily-allowance-patient.selectors';

const selectDailyAllowance = createFeatureSelector<DailyAllowanceState>(dailyAllowanceFeatureKey);
/**
 * Deactivate identification in SRS mode
 */
export const selectDailyAllowanceSteps = createSelector(selectDailyAllowance, (state) => state.steps);
export const selectDailyAllowanceStep = createSelector(selectDailyAllowance, (state) => state.step);
/**
 * Deprecated pet info selector for old components compatibilty
 */
export const selectDailyAllowanceDeprecatedPetInfo = createSelector(
  selectDailyAllowanceProfile,
  selectDailyAllowanceWeight,
  selectConsultationRenalDetectRecommendation,
  selectBreeds,
  (profile, weight, renalDetectRecommendation, breeds) => {
    return (
      !!profile &&
      <PetInfo>{
        ...profile,
        breedObject: breeds?.find((b) => b.breedCode === profile.breed),
        bcs: weight?.bcs,
        weight: weight?.currentWeight,
        IBW: weight?.targetWeight,
        pathologies: renalDetectRecommendation?.pathologies || [],
        sensitivities: [],
      }
    );
  }
);
/**
 * Selector for panel summary
 */
export const selectDailyAllowanceSummary = createSelector(selectDailyAllowanceDeprecatedPetInfo, (petInfo) => petInfo);
