import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpeciesCode } from '@app/shared/utils';

@Component({
  selector: 'app-product-catalog-filters-popin',
  templateUrl: './product-catalog-filters-popin.component.html',
  styleUrls: ['product-catalog-filters-popin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCatalogFiltersPopinComponent {
  constructor(
    public dialogRef: MatDialogRef<ProductCatalogFiltersPopinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selectedSpeciesCode: SpeciesCode; enablePillarSptRetail: boolean }
  ) {}

  closePopin(): void {
    this.dialogRef.close();
  }
}
