import { PatientPetRecord, PatientSearchInterfaces } from '@app/core/models';

/**
 * Check if patient object for Pet-Record page is coherent
 */
export function filterPatientsForPetRecord(patient: PatientSearchInterfaces): boolean {
  return (
    !!patient.owner &&
    !!patient.pet &&
    !!patient.owner.givenName &&
    !!patient.owner.familyName &&
    !!patient.pet.speciesCode &&
    !!patient.pet.name
  );
}

/**
 * Map a PatientSearch object to a PatientPetRecord object
 */
export function formatPatientSearchToPatientRecord(patient: PatientSearchInterfaces): PatientPetRecord {
  return {
    specie: patient.pet.speciesCode,
    petName: patient.pet.name,
    gender: patient.pet.genderCode,
    petOwner: {
      id: patient.owner._id,
      email: patient.owner.email,
      fullName: patient.owner.givenName ? patient.owner.givenName.concat(' '.concat(patient.owner.familyName)) : '',
      lastName: patient.owner && patient.owner.givenName ? patient.owner.familyName : '',
    },
    lastVisit: 'lastConsultation' in patient && patient?.lastConsultation?.date ? new Date(patient.lastConsultation.date) : null,
    petId: patient.petId,
    patientId: patient.id,
    petOwnerId: patient.owner._id,
  };
}
