<app-rc-dialog-wrapper (closePopin)="closePopin()">
  <div class="product-detail-wrapper">
    <div class="product-detail">
      <!-- Left content on the popin -->
      <div class="product-detail-product" *ngIf="product">
        <div class="edit-button" *ngIf="canEditPrices$ | async">
          <ng-container *ngIf="!isEditView; else editbutton">
            <button id="detail-edit-button" class="product-filter__edit-button rc-button" (click)="showEditView()">
              <app-icon class="icon-edit" [name]="IconNameEnum.edit"></app-icon>
              <span class="rc-button__title rc-button__title--link" i18n="@@price_display-edit">Edit prices</span>
            </button>
          </ng-container>
          <ng-template #editbutton>
            <button id="cancel-edit-button" class="rc-button edit-button" (click)="showEditView()">
              <app-icon class="icon-edit" [name]="IconNameEnum.close"></app-icon>
              <span class="rc-button__title rc-button__title--link" i18n="@@action_cancel">Cancel</span>
            </button>
          </ng-template>
        </div>
        <app-product-card
          *ngIf="!isEditView"
          class="product-detail-card"
          [showSelectButton]="showSelectButton"
          [selectButtonLabel]="selectButtonLabel"
          [showRetailPrices]="!isStandalone"
          [disableDetailsButton]="data?.disableDetailsButton"
          [product]="product"
          [packId]="data?.packId"
          [excludeStarterKitPacks]="data?.excludeStarterKitPacks"
          [displayPackages]="!isStandalone"
          [selectedProductsIds]="selectedProductsIds"
          (selectProduct)="onSelectProduct($event)"
          (removeProduct)="onRemoveProduct($event)"
        ></app-product-card>

        <app-product-edit-card
          *ngIf="isEditView"
          class="product-detail-card"
          [product]="product"
          (editedPriceList)="onPriceListChange($event)"
        ></app-product-edit-card>

        <div class="button-footer" *ngIf="isEditView">
          <button class="rc-button" (click)="showEditView()" id="detail-cancel-button">
            <span class="rc-button__title--link" i18n="@@action_cancel">Cancel</span>
          </button>
          <span class="rc-button__separator" i18n="@@action_separator">or</span>
          <button class="rc-button rc-button--filled-small" (click)="submitPrices()" id="detail-save-prices">
            <span class="rc-button__title" i18n="@@price_display-save">Save prices</span>
          </button>
        </div>
      </div>

      <!-- Right content on the popin -->
      <div class="product-detail-nav">
        <ng-container *ngIf="product">
          <!-- Product Section: Main benefits -->
          <div class="product-detail-section" [class.extended]="currentSection === 1">
            <!-- Product Section header -->
            <div class="section-header section-first-header" (click)="showMoreDetail(1, $event)">
              <h2 class="rc-title-beta rc-title--right-icon">
                <span class="product-detail-section__header--text" i18n="@@dialog-product-detail_benefit-label">Main benefits</span>
                <em class="rc-icon rc-icon--arrow-down" [class.active]="currentSection === 1"></em>
              </h2>
            </div>

            <!-- Product Detail -->
            <ng-container *ngIf="product.benefits?.length > 0; else defaultText">
              <div class="section-detail">
                <!-- Product detail: Skin Barrier -->
                <div class="section-detail-item" *ngFor="let benefit of product.benefits">
                  <img class="section-detail-icon" [src]="benefit.image || placeholderBenefitImage" [alt]="benefit.title || ''" />
                  <p class="section-detail-text">
                    {{ benefit.description | converterHtmlToText }}
                  </p>
                </div>
              </div>
            </ng-container>
            <ng-template #defaultText>
              <div class="section-detail">
                <span>--</span>
              </div>
            </ng-template>
          </div>

          <!-- Product Section: Description -->
          <div class="product-detail-section" [class.extended]="currentSection === 2">
            <!-- Product Section header -->
            <div class="section-header" (click)="showMoreDetail(2, $event)">
              <h2 class="rc-title-beta rc-title--right-icon">
                <span class="product-detail-section__header--text" i18n="@@dialog-product-detail_description-label">Description</span>
                <em class="rc-icon rc-icon--arrow-down" [class.active]="currentSection === 2"></em>
              </h2>
            </div>

            <!-- Product Detail -->
            <div class="section-detail">
              <div class="section-detail-item">
                {{ product.description | converterHtmlToText }}
              </div>
            </div>
          </div>

          <!-- Product Section: Ingredients -->
          <div class="product-detail-section" [class.extended]="currentSection === 3">
            <!-- Product Section header -->
            <div class="section-header" (click)="showMoreDetail(3, $event)">
              <h2 class="rc-title-beta rc-title--right-icon">
                <span class="product-detail-section__header--text" i18n="@@dialog-product-detail_ingredient-label">Ingredients</span>
                <em class="rc-icon rc-icon--arrow-down" [class.active]="currentSection === 3"></em>
              </h2>
            </div>

            <!-- Product Detail -->
            <div class="section-detail">
              <div class="section-detail-item">
                {{ product.composition | converterHtmlToText }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <span *ngIf="showWholesalePrices$ | async">
      {{ 'rc-table_b2b-price-de-ch-precision' | translateKey }}
    </span>
  </div>
</app-rc-dialog-wrapper>
